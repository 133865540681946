import { AuthService } from '../../admin/services/auth.service';
import { Injectable } from '@angular/core';
import { ToolService } from './tool.service';
import { Ingredient } from 'src/app/models/ingredient.model';
import { Tool } from 'src/app/models/tool.model';
import { Allergy } from 'src/app/models/allergy.model';
import { Step } from 'src/app/models/step.model';
@Injectable({
  providedIn: 'root'
})

/**
 * Recipe specific services
 * Contains complete ingredients and allergies received from domainInfo object
 * 
 */
export class RecipeService {

  private _ingredientList: Ingredient[] = [];
  private _allergies: Allergy[] = [];
  private _toolsList: Tool[] = [];
  
  constructor(
    private authSvc: AuthService,
    private toolSvc: ToolService) {

  }

  /**
   * Initiate the ingredients list,allergies list and tool list for the recipes
   * @param currentDomain 
   */
  public async  init(domainInfo: object) {
    for(const ingredient of Object(domainInfo).ingredients) {
      this._ingredientList.push(new Ingredient(ingredient));
    }
    for(const allergy of Object(domainInfo).allergies) {
      this._allergies.push(new Allergy(allergy))
    }
    this.setAllergiesLanguage();
  }

  public get allergies(): Allergy[] {
    return this._allergies;
  }
  public set allergies(value: Allergy[]) {
    this._allergies = value;
  }

  public get ingredients(): Ingredient[] {
    return this._ingredientList;
  }
  public set ingredients(value: Ingredient[]) {
    this._ingredientList = value;
  }

  public get toolsList(): Tool[] {
    return this._toolsList;
  }
  public set toolsList(value: Tool[]) {
    this._toolsList = value;
  }


  /**
   * Returns the formatted ingredientList for the workflow (Recipe)
   * @param steps Workflow _steps (object data)
   */
  public getIngredientList(steps: Step[]) {
    const ingredientList : Ingredient[] = [];
    
    this.cycleThroughSteps(steps, ingredientList);  
    return ingredientList;
  }

  private cycleThroughSteps(steps : Step[], ingredientList: Ingredient[]) {
    for(const step of steps) {
      if(step.ingredient) {
        const newIngredient = ingredientList.find((ingredient) => ingredient.ingredient_name === step.ingredient.ingredient_name);
        if(newIngredient) {
          if(step.measure) {
            newIngredient.totalMeasure += step.measure;
          } else {
            newIngredient.totalQty += step.quantity;
            newIngredient.setDescription(newIngredient.totalQty, this.authSvc.User.accountSettings.language);
          }
        } else {
          // ensure the ingredient exists
          const foundIngredient = this._ingredientList.find((obj) => obj.ingredient_name === step.ingredient.ingredient_name);
          if(foundIngredient) {
            const newIngredient = new Ingredient(foundIngredient);
            if(step.measure) {
              newIngredient.totalMeasure = step.measure;
              newIngredient.setDescription(1, this.authSvc.User.accountSettings.language);
            } else {
              newIngredient.totalQty = step.quantity;
              newIngredient.setDescription(step.quantity, this.authSvc.User.accountSettings.language);
            }
            if(!newIngredient.ingredient_name.includes('bowl')) {
              ingredientList.push(newIngredient);
            }
          }
        }
      }
      if(step.action_steps) {
        if(step.action_steps.length > 0) {
          this.cycleThroughSteps(step.action_steps, ingredientList);
        }
      }
      if(step.check_steps) {
        if(step.check_steps.length > 0) {
          this.cycleThroughSteps(step.check_steps, ingredientList);
        }
      }
      if(step.cycle_steps) {
        if(step.cycle_steps.length > 0) {
          this.cycleThroughSteps(step.cycle_steps, ingredientList);
        }
      }
    }
  }

  public getIngredient(ingredient_name : string) {
    return this._ingredientList.find((ingredient) => {
      return ingredient.ingredient_name === ingredient_name
    });
  }

  public getAllergiesList(allergies : string[]) {
    const allergiesList : Allergy[] = [];
    for(const allergyName of allergies) {
      allergiesList.push(this._allergies.find(allergyObj => {
        return allergyObj.allergyName === allergyName
      }));
    }
    return allergiesList;
  }

  public getAllergy(allergy_name: string) {
    return this._allergies.find((allergy) => {
      return allergy.allergyName === allergy_name
    });
  }


  // Localization utils

  setAllergiesLanguage() {
    for (const allergy of this._allergies) {
      allergy.setDescription(this.authSvc.User.accountSettings.language);
    }
  }


  /**
     * 
     * @param tools collection of tools that are not measuring tools
     * Duplicate as per measure unit
     * We might have to midfy this so that we can insert a series of different tools per ingredient step
     */
  public buildCustomTools() {

    /* for(let i  = this._tools.length; i< this._total_measure; i++) {
        this.tools.push(new Tool(this.toolSvc.getTool(tool)));
    } */
    
}



  // Measuring utils

  buildWeightMeasure(measure: number) {
    if(measure >= 1000) {
        return (measure/1000).toString() + ' Kg';
    } else {
        return measure.toString() + ' g';
    }
  }

  /**
   * 
   * @param measure 
   * @returns list of Tool that correspond to measure of an ingredient
   */
  buildMeasure(measure: number) {
      if(measure >= 240) {
          for(let i = Math.round(Math.floor(measure/240)); i>=1; i--) {
              this._toolsList.push(this.toolSvc.getTool('cup'));
          }
          this.buildMeasure(measure % 240);
      } else if(measure >= 120){
          if((measure % 80)==0) {
            this._toolsList.push(this.toolSvc.getTool('third_cup'));
            this._toolsList.push(this.toolSvc.getTool('third_cup'));
          } else {
              for(let i = Math.round(Math.floor(measure/120)); i>=1; i--) {
                this._toolsList.push(this.toolSvc.getTool('half_cup'));
              }
              this.buildMeasure(measure % 120);
          }
      } else if(measure >= 80) {
          for(let i = Math.round(Math.floor(measure/80)); i>=1; i--) {
            this._toolsList.push(this.toolSvc.getTool('third_cup'));
          }
          this.buildMeasure(measure % 80);
      } else if(measure >= 60) {
          for(let i = Math.round(Math.floor(measure/60)); i>=1; i--) {
            this._toolsList.push(this.toolSvc.getTool('quarter_cup'));
          }
          this.buildMeasure(measure % 60);
      } else if(measure >= 15) {
          for(let i = Math.round(Math.floor(measure/15)); i>=1; i--) {
            this._toolsList.push(this.toolSvc.getTool('table_spoon'));
          }
          this.buildMeasure(measure % 15);
      } else if(measure >= 5) {
          for(let i = Math.round(Math.floor(measure/5)); i>=1; i--) {
            this._toolsList.push(this.toolSvc.getTool('tea_spoon'));
          }
          this.buildMeasure(measure % 5);
      } else if(measure >= 2.5) {
          for(let i = Math.round(Math.floor(measure/2.5)); i>=1; i--) {
            this._toolsList.push(this.toolSvc.getTool('half_tea_spoon'));
          }
          this.buildMeasure(measure % 2.5);
      } else if(measure >= 1.25) {
          for(let i = Math.round(Math.floor(measure/1.25)); i>=1; i--) {
            this._toolsList.push(this.toolSvc.getTool('quarter_tea_spoon'));
          }
          this.buildMeasure(measure % 1.25);
      }
  }

  /**
   * 
   * @param measure 
   * @returns returns imperial measure in text format
   */
  getMeasureText(measure: number) {
      let cupsSum : number = 0;
      let measureText = '';
      if(measure >= 60) {
          if(measure >= 240) {
              cupsSum = Math.round(Math.floor(measure/240));
              measure = measure - cupsSum*240;
          }
          if (measure === 180) {
              measureText = (cupsSum > 0 ? cupsSum+'\u00BE' + ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, cupsSum) : '\u00BE' + ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, 1));
          } else if (measure === 160) {
              measureText = (cupsSum > 0 ? cupsSum+'\u2154' + ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, cupsSum) : '\u2154' + ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, 1));
          } else if (measure === 120) {
              measureText = (cupsSum > 0 ? cupsSum+'\u00BD' + ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, cupsSum) : '\u00BD' + ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, 1));
          } else if (measure === 80) {
              measureText = (cupsSum > 0 ? cupsSum+'\u2153' + ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, cupsSum) : '\u2153' + ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, 1));
          } else if (measure === 60) {
              measureText = (cupsSum > 0 ? cupsSum+'\u00BC' + ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, cupsSum) : '\u00BC' + ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, 1));
          } else if (measure < 60) {
              measureText = (cupsSum > 0 ? cupsSum+ ' ' +
              this.getToolString('cup', this.authSvc.User.accountSettings.language, cupsSum) + ' ' +
              this.getTableSpoonTxt(measure, this.authSvc.User.accountSettings.language) : this.getTableSpoonTxt(measure, this.authSvc.User.accountSettings.language));
          } else {
              measureText = this.getTableSpoonTxt(measure, this.authSvc.User.accountSettings.language);
          }
      } else {
          measureText = this.getTableSpoonTxt(measure, this.authSvc.User.accountSettings.language);
      }

      return measureText;
  }

  getTableSpoonTxt(measure : number, language : string) {
      //let tblspnString : string = "";
      let tblspnSum : number = 0;
      if(measure > 2.5) {
          if(measure >= 15) {
              tblspnSum = Math.round(Math.floor(measure/15));
              measure = measure - tblspnSum*15;
          }
          if (measure === 13.75) {
              return ('\u2154' + ' ' + this.getToolString('table_spoon', language, 1) + ' + ' + '\u00BC' + ' ' + this.getToolString('table_spoon', language, 1));
          }
          if (measure === 12.5) {
              return ('\u2154' + ' ' + this.getToolString('table_spoon', language, 1) + ' + ' + '\u00BD' + ' ' + this.getToolString('tea_spoon', language, 1));
          }
          if (measure === 11.25) {
              return (tblspnSum > 0 ? tblspnSum+'\u00BE' + ' ' + this.getToolString('table_spoon', language, tblspnSum) : '\u00BE' + ' ' + this.getToolString('table_spoon', language, 1));
          } else if (measure === 10) {
              return (tblspnSum > 0 ? tblspnSum+'\u2154' + ' ' + this.getToolString('table_spoon', language, tblspnSum) : '2' + ' ' + this.getToolString('tea_spoon', language, 2));
          } else if (measure === 7.5) {
              return (tblspnSum > 0 ? tblspnSum+'\u00BD' + ' ' + this.getToolString('table_spoon', language, tblspnSum) : '\u00BD' + ' ' + this.getToolString('table_spoon', language, 1));
          } else if (measure === 5) {
              return (tblspnSum > 0 ? tblspnSum+'\u2153' + ' ' + this.getToolString('table_spoon', language, tblspnSum) : '1' + ' ' + this.getToolString('tea_spoon', language, 1));
          } else if (measure === 3.75) {
              return (tblspnSum > 0 ? tblspnSum+'\u00BC' + ' ' + this.getToolString('table_spoon', language, tblspnSum) : '\u00BC' + ' ' + this.getToolString('table_spoon', language, 1));
          } else {
              return (tblspnSum > 0 ? tblspnSum + ' ' + this.getToolString('table_spoon', language, tblspnSum) + ' ' + this.getTeaSpoonTxt(measure, language) : this.getTeaSpoonTxt(measure, language));
          }   
      } else {
        return this.getTeaSpoonTxt(measure, language);
      }
  }

  getTeaSpoonTxt(measure: number, language : string) {
      if(measure === 2.5) {
          return '\u00BD' + ' ' + this.getToolString('tea_spoon', language, 1);
      } else if (measure === 1.25) {
          return '\u00BC' + ' ' + this.getToolString('tea_spoon', language, 1);
      } else {
          return '';
      }
  }

  getToolString(tool_string : string, language: string, qty: number) {
      if(language === 'fr') {
          switch(tool_string) {
              case 'cup' : if(qty > 1) {
              return 'Tasses'
              } else {
              return 'Tasse';
              }
              case 'table_spoon' : if(qty > 1) {
              return 'Cuillères à soupe'
              } else {
              return 'Cuillère à soupe';
              }
              case 'tea_spoon' : if(qty > 1) {
              return 'Cuillères à thé'
              } else {
              return 'Cuillère à thé';
              }
              default : return 'Tasse';
          }
      } else {
          switch(tool_string) {
              case 'cup' : if(qty > 1) {
              return 'Cups'
              } else {
              return 'Cup';
              }
              case 'table_spoon' : if(qty > 1) {
              return 'Tablespoons'
              } else {
              return 'Tablespoon';
              }
              case 'tea_spoon' : if(qty > 1) {
              return 'Teaspoons'
              } else {
              return 'Teaspoon';
              }
              default : return 'Cup';
          }
      }
  }

  /* addPortion(workflow : Recipe) {
    if(workflow.maxYield < workflow.maxPortionIndex) {
      let yieldDifference = 0;
      if(workflow.maxYield) {
        yieldDifference = workflow.maxYield - workflow.minYield;
      }
      if(workflow.portionsMultiplier > 0) {
        workflow.portionDelta = workflow.portionDelta + workflow.portionsMultiplier;
        if(workflow.portionDelta ===0) {
          workflow.minYield = workflow.initialMinYield;
        } else {
          if(workflow.minYield < workflow.initialMinYield) {
            workflow.minYield = workflow.minYield + Math.round(workflow.initialMinYield * Math.abs(workflow.portionDelta));
          } else {
            workflow.minYield = workflow.initialMinYield + Math.round(workflow.initialMinYield * Math.abs(workflow.portionDelta));
          }
        }
      } else {
        workflow.minYield++;
        workflow.portionDelta++;
      }
      if(workflow.maxYield) {
        workflow.maxYield = workflow.minYield  + yieldDifference;
      }
      for(const ingredient of this._ingredientList) {
        if(ingredient.modifyPortion) {
          if(ingredient.totalQty) {
            if(workflow.portionDelta === 0) {
              ingredient.totalQty = ingredient.initialTotalQtyMeasure;
            } else {
              if(ingredient.totalQty < ingredient.initialTotalQtyMeasure) {
                ingredient.totalQty = ingredient.totalQty + Math.round(ingredient.initialTotalQtyMeasure * Math.abs(this._current_recipe.portionDelta));
              } else {
                ingredient.totalQty = ingredient.initialTotalQtyMeasure + Math.round(ingredient.initialTotalQtyMeasure * Math.abs(this._current_recipe.portionDelta));
              }
            }
          }
          if(ingredient.totalMeasure) {
            if(ingredient.unit === 'g') {
              ingredient.totalMeasure = ingredient.initialTotalQtyMeasure + Math.round(ingredient.initialTotalQtyMeasure * this._current_recipe.portionDelta);
            } else {
              if(workflow.portionDelta === 0) {
                ingredient.totalMeasure = ingredient.initialTotalQtyMeasure;
              } else {
                let tmpMeasure = ingredient.initialTotalQtyMeasure * Math.abs(this._current_recipe.portionDelta);
                const modMeasure = tmpMeasure % 2.5; // divide by smallest measurment unit
                tmpMeasure = Math.floor(tmpMeasure / 2.5) * 2.5;
                if(modMeasure > 1.25) {
                  tmpMeasure += 2.5;
                }
                if(ingredient.totalMeasure < ingredient.initialTotalQtyMeasure) {
                  ingredient.totalMeasure = ingredient.totalMeasure + tmpMeasure;
                } else {
                  ingredient.totalMeasure = ingredient.initialTotalQtyMeasure + tmpMeasure;
                }
              }
            }
          }
        }
      }
      return workflow;
    }
  }

  substractPortion() {
    if(this._current_recipe.minYield > this._current_recipe.minPortionIndex) {
      let yieldDifference = 0;
      if(this._current_recipe.maxYield) {
        yieldDifference = this._current_recipe.maxYield - this._current_recipe.minYield;
      }
      if(this._current_recipe.portionsMultiplier > 0) {
        this._current_recipe.portionDelta = this._current_recipe.portionDelta - this._current_recipe.portionsMultiplier;
        if(this._current_recipe.portionDelta ===0) {
          this._current_recipe.minYield = this._current_recipe.initialMinYield;
        } else {
          if(this._current_recipe.minYield > this._current_recipe.initialMinYield) {
            this._current_recipe.minYield = this._current_recipe.minYield - Math.round(this._current_recipe.initialMinYield * Math.abs(this._current_recipe.portionDelta));
          } else {
            this._current_recipe.minYield = this._current_recipe.initialMinYield - Math.round(this._current_recipe.initialMinYield * Math.abs(this._current_recipe.portionDelta));
          }
        }
      } else {
        this._current_recipe.minYield--;
        this._current_recipe.portionDelta--;
      }
      if(this._current_recipe.maxYield) {
        this._current_recipe.maxYield = this._current_recipe.minYield  + yieldDifference;
      }
      for(const ingredient of this._ingredientList) {
        if(ingredient.modifyPortion) {
          if(ingredient.totalQty) {
            if(this._current_recipe.portionDelta === 0) {
              ingredient.totalQty = ingredient.initialTotalQtyMeasure;
            } else {
              const tmpQty = ingredient.initialTotalQtyMeasure * Math.abs(this._current_recipe.portionDelta);
              if(ingredient.totalQty > ingredient.initialTotalQtyMeasure) {
                if(tmpQty % 1 > 0.5) {
                  ingredient.totalQty = ingredient.totalQty - Math.round(tmpQty);
                } else {
                  ingredient.totalQty = ingredient.totalQty - Math.floor(tmpQty);
                }
              } else {
                if(tmpQty % 1 > 0.5) {
                  ingredient.totalQty = ingredient.initialTotalQtyMeasure - Math.round(tmpQty);
                } else {
                  ingredient.totalQty = ingredient.initialTotalQtyMeasure - Math.floor(tmpQty);
                }
              }
            }
          }
          if(ingredient.totalMeasure) {
            if(ingredient.unit === 'g') {
              ingredient.totalMeasure = ingredient.initialTotalQtyMeasure - Math.round(ingredient.initialTotalQtyMeasure * this._current_recipe.portionDelta);
            } else {
              if(this._current_recipe.portionDelta === 0) {
                ingredient.totalMeasure = ingredient.initialTotalQtyMeasure;
              } else {
                let tmpMeasure = ingredient.initialTotalQtyMeasure * Math.abs(this._current_recipe.portionDelta);
                const modMeasure = tmpMeasure % 2.5; // divide by smallest measurment unit
                tmpMeasure = Math.floor(tmpMeasure / 2.5) * 2.5;
                if(modMeasure > 1.25) {
                  tmpMeasure += 2.5;
                }
                if(ingredient.totalMeasure > ingredient.initialTotalQtyMeasure) {
                  ingredient.totalMeasure = ingredient.totalMeasure - tmpMeasure;
                } else {
                  ingredient.totalMeasure = ingredient.initialTotalQtyMeasure - tmpMeasure;
                }
              }
            }
          }
        }
      }
    }
  }

  adjustWfIngredientPortion(step : Step) {
    if(step.ingredient.modifyPortion) {
      if(step.quantity) {
        const tmpQty = step.quantity * Math.abs(this._current_recipe.portionDelta);
        if(this._current_recipe.portionDelta > 0) {
          // add
          step.quantity += Math.round(tmpQty);
        } else {
          // substract
          if(tmpQty % 1 > 0.5) {
            step.quantity -= Math.round(tmpQty);
          } else {
            step.quantity -= Math.floor(tmpQty);
          }
        }
      } 
      if(step.measure) {
        if(step.ingredient.unit === 'g') {
          if(this._current_recipe.portionDelta > 0) {
            //add
            step.measure += Math.round(step.measure * this._current_recipe.portionDelta);
          } else {
            ///substract
            step.measure -= Math.round(step.measure * this._current_recipe.portionDelta);
          }
        } else {
          let tmpMeasure = step.measure * Math.abs(this._current_recipe.portionDelta);
          const modMeasure = tmpMeasure % 2.5; // divide by smallest measurment unit
          tmpMeasure = Math.floor(tmpMeasure / 2.5) * 2.5;
          if(modMeasure > 1.25) {
            tmpMeasure += 2.5;
          }
          if(this._current_recipe.portionDelta > 0){
            //add
            step.measure += tmpMeasure;
          } else {
            step.measure -= tmpMeasure;
          }
        }
      }
    }
  } */

}