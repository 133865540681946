<div class="job-video-container">
    <div class="video-wrapper">
        <div class="video-content">
          <div class="video-player">
            <video #videoPlayer muted playsinline controls >
              <source [src]="videoUrlMp4" type="video/mp4">
              <source [src]="videoUrlWebM" type="video/webm">
              Your browser does not support this video file.
            </video>
          </div>
        </div>
    </div>
    <div class="next-button-container"
        (touchstart)='disableTouchMove()'
        (touchend)='enableTouchMove()'
        (mouseenter)='overButton()'
        (mouseleave)='leaveButton()'>
        <img id="nextStepBtn" class="next-step-button"  [class.display-none]="!step.current" src="/assets/base_icons/icon_step_check.svg" (click)="next_step()" alt="Go to nest step">
    </div> 
</div>
