
<div #profile_modal class="profile-container">
    <div class="profile-wrapper">
        <div class="modal-header">
            <h3>{{translatorSvc.getLabel('settings')}}</h3></div>
        <section class="upper-section">
            
            <div class="name-container">
                <div class="name-wrapper">
                    <input id="firstNameInput" class="name" type="text" [(ngModel)]="authSvc.User.accountSettings.firstName" value="{{authSvc.User.accountSettings.firstName}}" placeholder="{{translatorSvc.getLabel('firstName')}}">
                </div>
                <div class="name-wrapper">
                    <input id="lastNameInput" class="name" type="text" [(ngModel)]="authSvc.User.accountSettings.lastName" value="{{authSvc.User.accountSettings.lastName}}" placeholder="{{translatorSvc.getLabel('lastName')}}">
                </div>
                <div class="name-wrapper">
                    <input id="emailInput" class="name read-only" type="text" value="{{authSvc.User.accountSettings.email}}" placeholder="{{translatorSvc.getLabel('email')}}">
                </div>
            </div>
        </section>
        <section class="middle-section">
            <!-- <div class="modal-section-header">{{translatorSvc.getLabel('currentPlan')}}</div>
            <div class="subscription-container">
                <div class="plan">
                    <p *ngFor="let product of products">{{product.name}}</p>
                    <button (click)="openSubscriptionPlans()">{{translatorSvc.getLabel('modify')}}</button>
                </div>
            </div> -->
            <h4>{{translatorSvc.getLabel('preferences')}}</h4>
            <div class="competency-container">
                <div class="plan">
                    <p>{{translatorSvc.getLabel('level')}}</p>
                    <select [(ngModel)]="authSvc.User.accountSettings.competency">
                        <option *ngFor="let lvl of levels" [ngValue] = 'lvl.id'><p>{{lvl.lbl}}</p></option>
                    </select>
                </div>
            </div>
            <div class="competency-container">
                <div class="plan">
                    <p>{{translatorSvc.getLabel('language')}}</p>
                    <select [(ngModel)]="authSvc.User.accountSettings.language" (change)="onItemChange()">
                        <option *ngFor="let language of languages" [ngValue] = 'language'><p>{{translatorSvc.getLocaleLbl(language)}}</p></option>
                    </select>
                </div>
            </div>
            <!-- <div class="toggle-container">
                <div class="plan">
                    <p>{{translatorSvc.getLabel('videos')}}</p>
                        <label class="switch">
                            <input [(ngModel)]="authSvc.User.accountSettings.video" type="checkbox">
                            <span class="slider round"></span>
                        </label>
                </div>
            </div>
            <div class="toggle-container">
                <div class="plan">
                    <p>{{translatorSvc.getLabel('timers')}}</p>
                    <label class="switch">
                        <input [(ngModel)]="authSvc.User.accountSettings.timer" type="checkbox">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div> -->
        </section>
        <section class="button-section">
            <button type="button" (click)="saveProfile()" class="autonome-button-orange"><p>{{translatorSvc.getLabel('save')}}</p></button>
            <button type="button" (click)="cancelClicked()" class="autonome-button-orange"><p>{{translatorSvc.getLabel('cancel')}}</p></button>
        </section>
    </div>
</div>
