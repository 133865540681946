import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ModalsModule } from "../modal/modals.module";
import { FormsModule } from "@angular/forms";
import { OrganisationService } from "./services/organisation.service";
import { OrganisationComponent } from "./components/organisation/organisation.component";
import { OrganisationsComponent } from "./components/organisations/organisations.component";

@NgModule({
    declarations: [
        OrganisationComponent,
        OrganisationsComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        ModalsModule,
        FormsModule,
        /*GoogleTagManagerModule.forRoot({
            id: environment.GTM_ID,
        }),*/
    ],
    providers: [
        OrganisationService
    ],
    exports: [
    ]
})
export class OrganisationModule { }