import { RecipeDetailComponent } from './core/components/recipe-detail/recipe-detail.component';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecipesComponent } from './core/components/recipes/recipes.component';
import { WorkflowComponent } from './core/components/workflow/workflow.component';
import { ProductsComponent } from './core/components/products/products.component';
import { ShippingComponent } from './payment/components/shipping/shipping.component';
import { AuthGuard } from './admin/auth.guard';
import { WelcomeComponent } from './admin/components/welcome/welcome.component';
import { PaymentComponent } from './payment/components/payment/payment.component';
import { OrderComponent } from './payment/components/order/order.component';
import { RegisterFirstComponent } from './admin/components/register-first/register-first.component';
import { RegisterFirstBetaComponent } from './admin/components/register-first-beta/register-first-beta.component';
import { AutonomeWorldComponent } from './academy/components/autonome-world/autonome-world.component';
import { OrganisationComponent } from './organisations/components/organisation/organisation.component';
import { DomainsComponent } from './core/components/domains/domains.component';
import { DomainComponent } from './core/components/domain/domain.component';
import { ProductsDomainComponent } from './core/components/products-domain/products-domain.component';
import { ProductsWorkflowsComponent } from './core/components/products-workflows/products-workflows.component';
import { ShoppingCartComponent } from './payment/components/shopping-cart/shopping-cart.component';
import { RecipeEditorComponent } from './editor/components/recipe-editor/recipe-editor.component';
import { FamiliarizationComponent } from './research-projects/components/familiarization/familiarization.component';
import { MasterChefWorkflowComponent } from './core/components/master-chef-workflow/master-chef-workflow.component';
import { OrganisationsComponent } from './organisations/components/organisations/organisations.component';

const routes: Routes = [
  {
    path: '', 
    component: AppComponent,
  },
  {
    path: 'home',
    component : WelcomeComponent,
  },
  {
    path: 'products',
    component : ProductsComponent,
  },
  {
    path: 'products-domain',
    component : ProductsDomainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'products-workflow',
    component : ProductsWorkflowsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'register-first',
    component : RegisterFirstComponent,
  },
  {
    path: 'register-first-beta',
    component : RegisterFirstBetaComponent,
  },
  {
    path: 'recipes',
    component : RecipesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'recipe-detail',
    component : RecipeDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'workflow',
    component : WorkflowComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'master-chef-workflow',
    component : MasterChefWorkflowComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'domains',
    component : DomainsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'familiarization',
    component : FamiliarizationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'domain',
    component: DomainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shopping-cart',
    component : ShoppingCartComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shipping',
    component : ShippingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order',
    component : OrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment',
    component : PaymentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'organisations',
    component : OrganisationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'organisation',
    component : OrganisationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'recipe-editor',
    component : RecipeEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'grocery',
    component : AutonomeWorldComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
}
