<div class="domain-promo-container">
    <div class="domain-promo-wrapper">
        <h3 class="domain-promo-title">
            {{title}}
        </h3>
        <div class="domain-promo-slider-container">
            <div id="domain-promo-slider-container" class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let photoString of domainSvc.currentDomain.promotions.pictures">
                        <img src="/assets/photos/promos/{{photoString}}">
                    </div>
                </div>
                <!-- Add Arrows -->
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-pagination"></div>
            </div>

        </div>
        <div class="domain-promo-description-container">
            <div class="domain-promo-description-wrapper">
                {{description}}
            </div>
            <div class="domain-promo-price-wrapper">
                {{translatorSvc.getLabel('price')}} : {{domainSvc.currentDomain.promotions.price}}$ (CAN)
                <div class="domain-promo-price-details">{{translatorSvc.getLabel('shippingIncluded')}}</div> 
            </div>
            <div class="domain-promo-quantity-container">
                <div class="domain-promo-quantity-text">{{translatorSvc.getLabel('quantity')}} : </div>
                <input class="domain-promo-quantity" type="number" onKeyPress="if(this.value.length>1) return false" [(ngModel)]="quantity"/>
            </div>
            <div class="domain-promo-button-container">
                <button class="modal-text-button" [disabled]="purchased" (click)="addToCart()">{{translatorSvc.getLabel('addToCart')}}</button>
                <button class="modal-text-button" (click)="closeModal()">{{translatorSvc.getLabel('cancel')}}</button>
            </div>
        </div>
    </div>
</div>
