import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { AuthService } from 'src/app/admin/services/auth.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';


@Component({
  selector: 'app-new-features',
  templateUrl: './new-features.component.html',
  styleUrls: ['./new-features.component.scss']
})
export class NewFeaturesComponent implements AfterViewInit{

  private newFeatureSwiper: Swiper;
  public featureList : any[];
  newFeatures : boolean = false;

  constructor ( 
    public dialogRef: MatDialogRef<NewFeaturesComponent>,
    public authSvc : AuthService,
    public translatorSvc: TranslatorService,
    @Inject(MAT_DIALOG_DATA) modalData: any) {
      this.featureList = [];
      for(const featureJSON of modalData.featuresCollection) {
        const featureDate = Object.keys(featureJSON)[0];
        switch (modalData.language) {
          case 'fr':
            this.featureList.push({title: featureJSON[featureDate].title_fr, description: featureJSON[featureDate].description_fr});
            break;
          default :
            this.featureList.push({title: featureJSON[featureDate].title_en, description: featureJSON[featureDate].description_en});
            break;
        }
      }
    }

  ngAfterViewInit(): void {
    this.newFeatureSwiper = new Swiper("#new-feature-swiper-container", {
      modules: [Navigation, Pagination],
      spaceBetween: 40,
      slidesPerView: 1,
      centeredSlides: true,
      observeParents: true,
      observer: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      loop: true
      });
  }



  closeModal() {
    this.dialogRef.close(false);
    if(this.newFeatures) {
      this.authSvc.updateNewFeature(false);
    }
  }
}
