import { Component } from '@angular/core';

@Component({
  selector: 'app-products-workflows',
  templateUrl: './products-workflows.component.html',
  styleUrls: ['./products-workflows.component.scss']
})
export class ProductsWorkflowsComponent {

}
