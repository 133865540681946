import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Domain } from 'src/app/models/domain.model';
import { DomainService } from '../../services/domain.service';
import { WorkflowService } from '../../services/workflow.service';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { utils } from '../../../utils/utils';
import Swiper from 'swiper';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddDomainComponent } from 'src/app/modal/components/add-domain/add-domain.component';
import { ProductService } from '../../services/product.service';
import { UserConfirmationComponent } from 'src/app/modal/components/user-confirmation/user-confirmation.component';
import { AuthService } from 'src/app/admin/services/auth.service';
import { DomainPromoComponent } from 'src/app/modal/components/domain-promo/domain-promo.component';
import { DomainExtraInfoComponent } from 'src/app/modal/components/domain-extra-info/domain-extra-info.component';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss']
})
export class DomainsComponent implements AfterViewInit {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private elem: any;
  
  public screenWidth: number;
  private domainSwiper: Swiper;
  public overflow: boolean = false;
  public description_overflow: boolean = false;
  @ViewChild("workflow_example_wrapper") example_wrapper: ElementRef;
  @ViewChild("phone_domain_container_domains") domains_container: ElementRef;
  @ViewChild("domain_description_container") domain_description_container : ElementRef;
  domainSelectEventSubscription : Subscription;
  domain: Domain;
  domains: Domain[] = [];

  constructor(
    public domainSvc : DomainService,
    public workflowSvc : WorkflowService,
    public authSvc : AuthService,
    public productSvc: ProductService,
    public spinnerSvc : SpinnerOverlayService,
    private matDialog: MatDialog,
    public translatorSvc : TranslatorService
  ) {
    this.elem = document.documentElement;
  }

  ngAfterViewInit(): void {
    
    this.screenWidth = window.innerWidth;
    if(this.domainSvc.domains.length > 2) {
      if(this.example_wrapper) {
        if(this.example_wrapper.nativeElement.children) {
          if(this.example_wrapper.nativeElement.children.length * this.example_wrapper.nativeElement.children[0].offsetHeight > this.example_wrapper.nativeElement.offsetHeight) {
            this.overflow = true;
          }
        }
      }
      
      if(this.domains_container) {
        if(this.domains_container.nativeElement.children) {
          if(this.domains_container.nativeElement.children.length * this.domains_container.nativeElement.children[0].offsetHeight > this.domains_container.nativeElement.offsetHeight) {
            this.overflow = true;
          }
        }
      }
    }

    if(this.domain_description_container) {
      if(this.domain_description_container.nativeElement.offsetHeight > 286) {
        this.description_overflow = true;
      }
    }
  }

  goToDomain() {
    if (document.fullscreenElement === null) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    }
    this.domainSvc.goToDomains();
  }

  goToProducts() {

  }

  goToRecipeEditor() {
    this.domainSvc.goToRecipeEditor();
  }

  purchaseDomain(){
    if(this.productSvc.itemIsInCart(this.domainSvc.currentDomain.id) || this.productSvc.domainIsInCart(this.domainSvc.currentDomain.id)) {
      this.openPurchaseDomainModal();
    } else {
      this.domainSvc.goToProductsDomain();
    }
  }

  openExtraInfoModal(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "domain-extra-info";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    this.matDialog.open(DomainExtraInfoComponent, dialogConfig).afterClosed().subscribe(() => {
    
    });
    
  }

  openPurchaseDomainModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "add-domain-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    if(this.productSvc.itemIsInCart(this.domainSvc.currentDomain.id) || this.productSvc.domainIsInCart(this.domainSvc.currentDomain.id)) {
      dialogConfig.data = {
        name: "modal-domain-already-in-cart",
        actionButtonText1 : this.translatorSvc.getLabel('close'),
        message : this.translatorSvc.getLabel('domainAlreadyInCart'),
        hideButton2: true,
      };
      this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
  
      });
    } else {
      this.matDialog.open(AddDomainComponent, dialogConfig).afterClosed().subscribe(() => {
        
      });
    }
  }

  

  openDomainPromoModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "full-screen-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";

    this.matDialog.open(DomainPromoComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });

  }

  scrollWorkflowsDown() {
    utils.scrollDown(this.example_wrapper, 200);
  }

  isJobExample(domain: Domain) {
    return domain.id.includes('job_example');
  }

  onWorkflowScroll() {
    if(this.example_wrapper.nativeElement.scrollTop === 0 && this.example_wrapper.nativeElement.children.length > 2) {
      this.overflow = true;
    } else {
      this.overflow = false;
    }
  }

  scrollDomainsDown() {
    utils.scrollDown(this.domains_container, 220);
  }

  onDomainScroll() {
    if(this.domains_container.nativeElement.scrollTop === 0 && this.domains_container.nativeElement.children.length > 2) {
      this.overflow = true;
    } else {
      this.overflow = false;
    }
  }

  scrollDescriptionsDown() {
    utils.scrollDown(this.domains_container, 220);
  }

  onDescriptionScroll() {
    if(this.domain_description_container.nativeElement.scrollTop === 0 && this.domain_description_container.nativeElement.offsetHeight > 286) {
      this.description_overflow = true;
    } else {
      this.description_overflow = false;
    }
  }
}
