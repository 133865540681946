import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/admin/services/auth.service';
import { DomainService } from 'src/app/core/services/domain.service';
import { ProductService } from 'src/app/core/services/product.service';
import { ItemI } from 'src/app/interfaces/item.interface';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements AfterViewInit {

  totalYearSubscriptions: number = 0;
  totalItems: number = 0;
  screenWidth: number;
  promoCode: string;

  constructor(
    public productSvc : ProductService,
    private domainSvc : DomainService,
    public translatorSvc : TranslatorService,
    private router: Router,
    public authSvc : AuthService,
    public paymentSvc : PaymentService
  ) {
    if(this.productSvc.workflowsToPurchase.length > 0) {
      this.totalYearSubscriptions = this.productSvc.workflowsToPurchase.length * 0.49;
    }
    if(this.productSvc.domainsToPurchase.length > 0) {
      this.totalYearSubscriptions = this.productSvc.domainsToPurchase.length * 5.99;
    }
    for (const item of this.productSvc.itemsToPurchase) {
      this.totalItems = this.totalItems + item.quantity * item.price;
    }
  }

  ngAfterViewInit(): void {
    this.screenWidth = window.innerWidth;
  }

  getDomainTitle(domainId:string) {
    return this.domainSvc.domains.find((obj) => {
      return obj.id === domainId;
    }).title;
  }

  goToShipping() {
    if (this.productSvc.itemsToPurchase && this.productSvc.itemsToPurchase.length > 0) {
      this.router.navigate(['shipping']);
    } else {
      this.paymentSvc.proceedToOrder();
    }
  }

  goToDomainProducts() {
    this.router.navigate(['products-domain']);
  }

  async removeDomainItem(domain: string) {
    this.productSvc.domainsToPurchase.splice(this.productSvc.domainsToPurchase.indexOf(domain), 1);
    this.totalYearSubscriptions = this.totalYearSubscriptions - 5.99;
    if(this.productSvc.domainsToPurchase.length === 0 || this.productSvc.itemsToPurchase.length === 0) {
      this.goToDomainProducts();
    }

    if(this.authSvc.User.purchaseDomains && this.authSvc.User.purchaseDomains.length > 0) {
      await this.authSvc.deleteUserFields(['purchaseDomains']);
      this.authSvc.User.purchaseDomains = [];
    }
  }

  removeWorkflowItem(workflow: string) {
    this.productSvc.workflowsToPurchase.splice(this.productSvc.workflowsToPurchase.indexOf(workflow), 1);
    this.totalYearSubscriptions = this.totalYearSubscriptions - 0.49;
  }

  async removePurchaseItem(item: ItemI) {
    this.productSvc.itemsToPurchase.splice(this.productSvc.itemsToPurchase.findIndex((obj: ItemI) => obj.id === item.id));
    this.totalItems = this.totalItems - item.price;
    if(this.productSvc.domainsToPurchase.length === 0 || this.productSvc.itemsToPurchase.length === 0) {
      this.goToDomainProducts();
    }
    if(this.authSvc.User.purchaseBeta) {
      await this.authSvc.deleteUserFields(['purchaseBeta']);
      this.authSvc.User.purchaseBeta = false;
    }
  }

  applyPromoCode() {
    //this.productSvc.applyPromoCode(this.promoCode);
    
  }


}
