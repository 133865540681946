import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditorService } from 'src/app/editor/services/editor.service';
import { AddWorkflowItemDetailsComponent } from '../../../modal/components/add-workflow-item-details/add-workflow-item-details.component';
import { ActionI } from 'src/app/interfaces/action.interface';
import { IngredientI } from 'src/app/interfaces/ingredient.interface';

@Component({
  selector: 'app-add-workflow-item-type',
  templateUrl: './add-workflow-item-type.component.html',
  styleUrls: ['./add-workflow-item-type.component.scss']
})

export class AddWorkflowItemTypeComponent implements AfterViewInit {

  @Input() item: object;
  public noPicto: boolean = false;
  public pictoPrefix : string = '/assets/pictos/';
  public itemName : string;

  public action : ActionI;
  public ingredient : IngredientI;

  constructor(
    public editorSvc : EditorService,
    private matDialog: MatDialog) {

  }

  ngAfterViewInit(): void {
    /* if (this.item.action) {
      this.itemName = this.item.action.action_name;
    }
    if (this.item.ingredient) {
      this.itemName = this.item.ingredient.ingredient_name;
      fetch('/assets/pictos/ingredients/' + this.item.ingredient.ingredient_name + '.svg').then(res => {
        this.itemPicto = '/assets/pictos/ingredients/' + this.item.ingredient.ingredient_name + '.svg'
        if(res.status === 404) {
          this.itemPicto = '/assets/pictos/domains/icon_img_not_found.svg';
        }
      }).catch((error) => {
      });
    }
    if(this.item.verification) {
      this.itemName = this.item.verification.verification_name;
      this.noPicto = true;
    } */

      if (Object(this.item).action) {
        this.action = Object(this.item).action;
      } else {
        this.ingredient = Object(this.item).ingredient;
      }
  }

  showWorkflowItemDetails() {
    this.editorSvc.addWorkflowItemSelectedItemName = this.itemName;
    this.editorSvc.addWorkflowItemSelectedItem = this.item;
    if(this.matDialog.openDialogs.length)
    this.showWorkflowItemDetailsModal();
  }

  showWorkflowItemDetailsModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "full-screen-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    this.matDialog.open(AddWorkflowItemDetailsComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }

  closeDialog() {
    this.matDialog.closeAll();
  }

}
