import { DomainType } from "../enums/domain-type.enum";
import { DomainI } from "../interfaces/domain.interface";
import { Recipe } from "./recipe.model";
import { WorkflowCategory } from "./workflow-category.model";
import { Workflow } from "./workflow.model";

export class Domain implements DomainI{
    private _title: string='';
    private _id: string;
    private _selected: boolean;
    private _productId: string;
    private _description: string;
    private _type: DomainType;
    private _picto: string;
    private _purchased: boolean = false;
    private _categories: WorkflowCategory[] = [];
    private _current_category: WorkflowCategory;

    description_en: string;
    description_fr: string;
    public: boolean;
    title_en: string;
    title_fr: string;
    workflows?: (Recipe | Workflow)[];
    price?: number;
    free? : boolean;
    price_description_en?: string;
    price_description_fr?: string;
    price_subscription_term?: string;
    promotions? : PromotionI;
    extra_information?: ExtraInformationI;


    constructor(data: object, categories: object[]) {
        this._id = Object.keys(data)[0];
        const dataObject = Object(data)[this._id].domainData;
        this._type = DomainType[this._id];

        // initiate domain data
        this.description_en = dataObject.description_en;
        this.description_fr = dataObject.descritpion_fr;
        this.public = dataObject.public;
        this.title_en = dataObject.title_en;
        this.title_fr = dataObject.title_fr;
        this.price = dataObject.price;
        this.free = dataObject.free;
        this.price_description_en = dataObject.price_description_en;
        this.price_description_fr = dataObject.price_description_fr;
        this.price_subscription_term = dataObject.price_subscription_term;

        // Job Example Data
        if(dataObject.extra_information) {
            this.extra_information = JSON.parse(JSON.stringify(dataObject.extra_information));
        }
        

        if (Object(data)[this._id].workflows && Object(data)[this._id].workflows.length > 0) {
            let tmpCategories: string[] = [];
            this.workflows = [];
            for (const workflow of Object(data)[this._id].workflows) {
                if (this._id === 'cooking') {
                    this.workflows.push(new Recipe(workflow, this._id));
                } else {
                    this.workflows.push(new Workflow(workflow, this._id));
                }
                tmpCategories = [...tmpCategories, ...workflow[Object.keys(workflow)[0]].data.categories.filter((a: string) => !tmpCategories.includes(a))];
            }
            //if (dataObject.categories && dataObject.categories.length > 0) {
            this.addDomainCategories(categories, tmpCategories);
            //}
            this.setWorkflowsInCategories();
        }

        if (dataObject.promotions) {
            this.promotions = {
                description_en : "",
                description_fr : "",
                id : "",
                name_en:  "",
                name_fr:  "",
                pictures: [],
                price: 0,
                shipping_fee: 0,
                shipping_method: "",
                taxable: false,
                title_en: "",
                title_fr: "",
            };
            Object.assign(this.promotions, dataObject.promotions);
        }
        
       /*  if(this._data[this._id].domainData) {
            if(this._data[this._id].domainData.purchased) {
                this._purchased = true;
            }
        } */
    }
    

    public get title(): string {
        return this._title;
    }
    public set title(value: string) {
        this._title = value;
    }

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get selected(): boolean {
        return this._selected;
    }
    public set selected(value: boolean) {
        this._selected = value;
    }

    public get type(): DomainType {
        return this._type;
    }
    public set type(value: DomainType) {
        this._type = value;
    }

    public get description(): string {
        return this._description;
    }
    public set description(value: string) {
        this._description = value;
    }

    public get picto(): string {
        return this._picto;
    }
    public set picto(value: string) {
        this._picto = value;
    }

    public get purchased(): boolean {
        return this._purchased;
    }
    public set purchased(value: boolean) {
        this._purchased = value;
    }

    public get categories(): WorkflowCategory[] {
        return this._categories;
    }
    public set categories(value: WorkflowCategory[]) {
        this._categories = value;
    }

    public get current_category(): WorkflowCategory {
        return this._current_category;
    }
    public set current_category(value: WorkflowCategory) {
        this._current_category = value;
    }

    setDescription(language: string) {
        switch(language) {
            case 'fr': 
                this._title = this.title_fr;
                this._description = this.description_fr;
                break;
            default:
                this._title = this.title_en;
                this._description = this.description_en;
                break;
        }

        for (const category of this._categories) {
            category.setTitle(language);
        }
    }

    /**
     * Builds an array of WorkflowCategory which is unique to this domain
     *
     * @param {object[]} categoriesCollection - collection of category objects in domainInfo.categories
     * @param {string} categories - singular array of category names
     */
    addDomainCategories(categories: object[], DomainWorkflowCategories: string[]) {
        // Build the collection of categories for this domain
        for (const category of DomainWorkflowCategories) {
            this._categories.push(new WorkflowCategory(categories.find((obj) => Object.keys(obj)[0] === category)));
        }
    }

    setCategoriesAlphabetical(language: string) {
        this._categories.sort((a, b) => a.getTitle(language).toLowerCase().localeCompare(b.getTitle(language)));
    }

    /**
     * Sets the workflows in the right categories
     */
    setWorkflowsInCategories() {
        for (const category of this._categories) {
            for (const workflow of this.workflows) {
                if (workflow.categories.some(categoryObj => categoryObj === category.category_name)) {
                    category.workflows.push(workflow); 
                } 
            }
        }
    }
 
}

interface PromotionI {
    description_en: string;
    description_fr: string;
    id: string;
    name_en: string;
    name_fr: string;
    pictures?: string[];
    price: number;
    shipping_fee: number;
    shipping_method: string;
    taxable: boolean;
    title_en: string;
    title_fr: string;
}

interface ExtraInformationI  { 
    link_text_en: string;
    link_text_fr: string;
    logos: string[];
    text_en: string;
    text_fr: string;
}

