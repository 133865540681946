<div class="ingredient-qty-container">
    <div *ngIf="authSvc.User.accountSettings.competency < 1 then sous_chef else chef"></div>
    <div class="next-button-container"
    (touchstart)='disableTouchMove()'
    (touchend)='enableTouchMove()'
    (mouseenter)='overButton()'
    (mouseleave)='leaveButton()'>
        <img class="next-step-button" src="/assets/base_icons/icon_step_check.svg" [class.hideNextStepBtn]="!step.current" (click)="next_step()" alt="Go to next step">
    </div> 
</div>
    

<ng-template #chef>
    <div class="chef-ingredient-container">
        <div class="chef-ingredient-wrapper">
            <div class="chef-ingredient-qty-wrapper">
                <div class="chef-ingredient-picto-wrapper" [class.chef-qty-four-less]="step.quantity < 5">
                    <img *ngFor="let image of pictoString" src="{{image}}">
                </div>
                <div class="chef-ingredient-qty-text-wrapper" [class.qty-description-four-less]="step.quantity < 5">
                    <div *ngIf="step.quantity > 0; else measureText">
                        <h2 class="chef-ingredient-qty">{{step.quantity}}</h2>
                    </div>
                    <h4>{{step.ingredient!.description!}}</h4>
                </div>
            </div>
        </div>
        <hr class="displayNoneTablet">
        <div class="action-wrapper">
            <div class="action-list">
                <app-action-list-item class="action-list-item" *ngFor="let stepItem of step.action_steps" [stepItem]="stepItem"></app-action-list-item>
            </div>
        </div>

    </div>
    
</ng-template>


<ng-template #sous_chef>
    <div class="ingredient-qty-wrapper">
        <div class="ingredient-picto-wrapper" [class.qty-four-less]="step.quantity < 5">
            <img *ngFor="let image of pictoString" src="{{image}}">
        </div>
        <div class="ingredient-qty-text-wrapper">
            <div *ngIf="step.quantity > 0; else measureText">
                <h1 class="ingredient-qty">{{step.quantity}}</h1>
            </div>
            <h2>{{step.ingredient!.description!}}</h2>
        </div>
    </div>
</ng-template>

<ng-template #measureText>
    <h1 class="ingredient-qty">{{step.ingredient.measureText}}</h1>
</ng-template>