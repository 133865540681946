import { TranslatorService } from '../../../tools/services/translator.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../admin/services/auth.service';
import { UserConfirmationComponent } from '../user-confirmation/user-confirmation.component';

@Component({
  selector: 'app-email-reset',
  templateUrl: './email-reset.component.html',
  styleUrls: ['./email-reset.component.scss']
})
export class EmailResetComponent implements OnInit {

  email : string;

  constructor(
    public dialogRef: MatDialogRef<EmailResetComponent>,
    private authSvc : AuthService,
    private matDialog : MatDialog,
    public translatorSvc : TranslatorService
  ) { }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  async onSubmit(event: any) {
    if(event.target.emailInput.value !== undefined) {
      await this.authSvc.passwordReset(event.target.emailInput.value)
      .then(res => {
        this.closeDialog();
        this.confirmEmailSent();
      })
    }
  }

  async confirmEmailSent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-confirm-pw-reset-email";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "modal-confirm-pw-reset-email",
      actionButtonText1 : 'OK',
      message : this.translatorSvc.getLabel('confirmEmailSent'),
      hideButton2 : true
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(result => {
      
    });
  }

}
