
<div #video_modal class="video-container">
    <div class="video-content">
      <div class="close-video-btn" (click)="closeVideo()">
        <img src="/assets/base_icons/icon_close.svg">
      </div>
      <div class="video-player">
        <video #videoPlayer muted playsinline controls >
          <source [src]="videoUrlMp4" type="video/mp4">
          <source [src]="videoUrlWebM" type="video/webm">
          Your browser does not support this video file.
        </video>
      </div>
    </div>
</div>
