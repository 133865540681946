<p class="combatant-name">{{combatant.name}}</p>
<p class="combatant-level">{{combatant.level}}</p>
<!-- <div class="combatant-character-crop">
    <img class="combatant-character" alt="{{combatant[combatant.id].name}}" src="{{combatant[combatant.id].src}}"/>
</div> -->
<img class="combatant-type" alt="{{combatant.type}}" src="{{combatant.icon}}"/>
<svg viewBox="0 0 26 3" class="combatant-life-container">
    <rect x=0 y=0 attr.width="{{hpPercent}}%" height=1 fill="#82ff71"/>
    <rect x=0 y=1 attr.width="{{hpPercent}}%" height=2 fill="#3ef126"/>
</svg>
<svg viewBox="0 0 26 2" class="combatant-xp-container">
    <rect x=0 y=0 attr.width="{{xpPercent}}%" height=1 fill="#ffd76a"/>
    <rect x=0 y=1 attr.width="{{xpPercent}}%" height=1 fill="#ffc934"/>
</svg>
<p class="combatant-status"
    *ngIf="combatant.status"
    [class.display-none]="combatant.status.type===''">{{combatant.status.type}}</p>

