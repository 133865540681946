import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EditorService } from 'src/app/editor/services/editor.service';

@Component({
  selector: 'app-add-workflow-item-details',
  templateUrl: './add-workflow-item-details.component.html',
  styleUrls: ['./add-workflow-item-details.component.scss']
})
export class AddWorkflowItemDetailsComponent {
  constructor(
    private dialogRef: MatDialogRef<AddWorkflowItemDetailsComponent>,
    public editorSvc : EditorService
  ) {
    
  }

  addItemToWorkflow() {
    this.editorSvc.addWorkflowItemToWorkflow();
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
