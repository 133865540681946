import { JobVideoI } from "../interfaces/job-video.interface";

export class JobVideo implements JobVideoI {
    video_name: string;
    private _header: string;

    constructor(data : object) {
        this.video_name = Object(data).video_name;
    }

    public get header(): string {
        return this._header;
    }

    setLanguage(language) {
        switch(language) {
            case 'fr' :
                this._header = 'Extrait vidéo de ...'
                break;
            default : 
                this._header = 'Video of ...'
        }
    }

}