import { TranslatorService } from 'src/app/tools/services/translator.service';
import { Workflow } from './../../../models/workflow.model';
import { AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WorkflowService } from '../../services/workflow.service';
import { UserConfirmationComponent } from 'src/app/modal/components/user-confirmation/user-confirmation.component';
import { DomainService } from '../../services/domain.service';
import { AuthService } from 'src/app/admin/services/auth.service';

@Component({
  selector: 'app-workflow-item',
  templateUrl: './workflow-item.component.html',
  styleUrls: ['./workflow-item.component.scss']
})
export class WorkflowItemComponent implements AfterViewInit {

  @Input() workflow: Workflow;
  confirm_msg_txt!: string;
  confirm_btn_txt!: string;
  cancel_btn_txt!: string;

  public pictoIsJpg: boolean = false;

  constructor(
    public matDialog: MatDialog,
    public workflowSvc : WorkflowService,
    public domainSvc : DomainService,
    private authSvc : AuthService,
    private translatorSvc: TranslatorService) { }


  ngAfterViewInit(): void {
    this.pictoIsJpg = this.workflow.picto.includes('.jpg')
  }
  
  @HostListener("click") onClick(){
    this.workflowSvc.selectedWorkflow = this.workflow;
    if(!this.workflowSvc.selectedWorkflow.purchased) {
      this.openConfModal();
    }
  }

  clickRecipeEvent() {
    /* const gtmTag = {
      event: 'workflow-click',
      data: this.workflow.id,
    }; */
    //this.gtmService.pushTag(gtmTag);
  }

  openConfModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "access-recipe",
      message: this.translatorSvc.getText('accessRecipe'),
      actionButtonText1 : this.translatorSvc.getLabel('no'),
      actionButtonText2 : this.translatorSvc.getLabel('yes'),
      
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  openStopCurrentRecipeModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "change-recipe",
      message: this.translatorSvc.getText('confirmGoToRecipeDetail'),
      actionButtonText1 : this.translatorSvc.getLabel('no'),
      actionButtonText2 : this.translatorSvc.getLabel('yes'),
      wfId: this.workflow.id,
      
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
     
    });
  }

}



