import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/admin/services/auth.service';
import { OrganisationService } from 'src/app/organisations/services/organisation.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';

@Component({
  selector: 'app-organisation-edit',
  templateUrl: './organisation-edit.component.html',
  styleUrls: ['./organisation-edit.component.scss']
})
export class OrganisationEditComponent implements OnInit {

  orgName : string = "";
  orgNameValid : boolean = true;
  ignoreNewFeatures

  constructor(
    @Inject(MAT_DIALOG_DATA) modalData: object,
    public dialogRef: MatDialogRef<OrganisationEditComponent>,
    public translatorSvc : TranslatorService,
    private organisationSvc : OrganisationService,
    private authSvc : AuthService,
    private router : Router) { }

  ngOnInit(): void {
  }

  async onSubmit() {
    /* this.organisationSvc.orgExists(this.orgName).then((res) => {
      this.orgNameValid = !res;
      if(!res) { // org doesn't exist
        this.organisationSvc.addUpdateOrganisation(this.orgName).then(() => {
          this.closeOrgEdit();
          this.router.navigate(['organisation']);
        }).catch((error) => {
          console.log('Add organisation error : ', error);
        })
      }
    }); */
  }

  closeOrgEdit() {
    this.dialogRef.close(false);
  }

}
