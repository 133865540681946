import { AfterViewInit, Component } from '@angular/core';
import { Battle } from '../../models/battle';
import { AcademyService } from '../../services/academy.service';

@Component({
  selector: 'app-battle',
  templateUrl: './battle.component.html',
  styleUrls: ['./battle.component.scss']
})
export class BattleComponent implements AfterViewInit {

  
  private _hero_img: string;
  private _enemy_img: string;
  private _battle: Battle;
  private _ctx

  constructor(public academySvc: AcademyService) { 
    this._hero_img = "/assets/academy/characters/people/hero.png"
    this._enemy_img = "/assets/academy/characters/people/npc3.png"
    
    
  }

  ngAfterViewInit(): void {
    
  }

  

  public get hero_img(): string {
    return this._hero_img;
  }
  public set hero_img(value: string) {
    this._hero_img = value;
  }

  public get enemy_img(): string {
    return this._enemy_img;
  }
  public set enemy_img(value: string) {
    this._enemy_img = value;
  }

  public get battle(): Battle {
    return this._battle;
  }
  public set battle(value: Battle) {
    this._battle = value;
  }
}
