<div #set_password_modal class="set-password-container">
    <div class="set-password-wrapper">
        <div class="input-field-wrapper">
            <label>{{translatorSvc.getLabel('createPassword')}}</label> 
            <input
            autocomplete="new-password"
            #pwInput
            name="pwInput" 
            type="password" 
            class="modal-input-field"
            (focusout) = "pwPrimaryFocusOut()"
            [(ngModel)]="passwordPrimary" 
            required 
            autofocus>
            <input
            autocomplete="new-password"
            #pwConfirmInput
            name="pwInput" 
            type="password" 
            class="modal-input-field"
            (focusout) = "pwConfirmFocusOut()"
            [(ngModel)]="passwordConfirm" 
            required>
        </div>
        <div class="input-field-wrapper">
            <button type="button" (click)="setPassword()" class="modal-text-button" [disabled]="formValid">{{translatorSvc.getLabel('create-account')}}</button>
            <button type="button" (click)="closeModal()" class="modal-text-button">{{translatorSvc.getLabel('cancel')}}</button>
        </div>
    </div>
</div>