<div class="verification-container">
    <div class="verification-image-wrapper">
        <div class="verification-image-not-ready">
            <img class="mobile-first-check-step-button"
                (touchstart)='disableTouchMove()'
                (touchend)='enableTouchMove()'
                (mouseenter)='overButton()'
                (mouseleave)='leaveButton()' src="/assets/base_icons/icon_verification_back_button.svg" (click)="first_checkStep()" alt="Go to first check step">
            <img src="{{'/assets/photos/' + step.verification.not_ready + '.jpg'}}" class="verification-image-not-ready-img">
        </div>
        <img class="question-mark" src="/assets/base_icons/icon_interogation_mark.svg">
        <div class="verification-image-ready">
            <img src="{{'/assets/photos/' + step.verification.ready + '.jpg'}}" class="verification-image-ready-img">
            <img class="mobile-next-step-button"
                (touchstart)='disableTouchMove()'
                (touchend)='enableTouchMove()'
                (mouseenter)='overButton()'
                (mouseleave)='leaveButton()'
                src="/assets/base_icons/icon_step_check.svg" (click)="next_step()" alt="Go to next step">
        </div>
    </div>
    <div class="verification-navigation-wrapper">
        <img class="first-check-step-button"
        [class.displayNone] = "!step.current"
        (touchstart)='disableTouchMove()'
        (touchend)='enableTouchMove()'
        (mouseenter)='overButton()'
        (mouseleave)='leaveButton()' src="/assets/base_icons/icon_verification_back_button.svg" (click)="first_checkStep()" alt="Go to first check step">
        <img [class.displayNone] = "!step.current" class="arrow-left" src="/assets/base_icons/icon_verification_left.svg">
        <h3 class="verification-text">{{step.verification!.description!}}</h3>
        <img [class.displayNone] = "!step.current" class="arrow-right" src="/assets/base_icons/icon_verification_right.svg">
        <img class="next-step-button"
        [class.displayNone] = "!step.current"
        (touchstart)='disableTouchMove()'
        (touchend)='enableTouchMove()'
        (mouseenter)='overButton()'
        (mouseleave)='leaveButton()'
        src="/assets/base_icons/icon_step_check.svg" (click)="next_step()" alt="Go to next step">

    </div>
</div>
