import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, Subject, Subscription, first, firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/admin/services/auth.service';
import { Organisation } from 'src/app/models/organisation.model';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { Domain } from 'src/app/models/domain.model';
import { DomainService } from '../../core/services/domain.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { OrganisationI } from 'src/app/interfaces/organisation.interface';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  private _organisations: Organisation[];
  private _current_organisation: Organisation;
  private orgInitEventSubscription : Subscription;

  constructor(private fns : AngularFireFunctions,
    private authSvc: AuthService,
    private domainSvc: DomainService,
    private translatorSvc : TranslatorService,
    private spinnerSvc : SpinnerOverlayService) {
    
      this.orgInitEventSubscription = this.authSvc.getOrgInitEvent().subscribe(() => {
        // this will change in the future to see if there is a 'cooking' domain to allow access to the recipes
        this.initOrgSvc();
        
      });
  }

  public async initOrgSvc() {
    this._organisations = [];
    this._current_organisation = null;
    // Spinner
    if(!this.spinnerSvc.active) {
      this.spinnerSvc.show(this.translatorSvc.getLabel('loadingOrganisations'));;
    } else if(this.spinnerSvc.active) {
      this.spinnerSvc.text = this.translatorSvc.getLabel('loadingOrganisations');
    }

    if(this.authSvc.User.organisations && this.authSvc.User.organisations.length > 0) {
      this.getOrganisations(this.authSvc.User.organisations);
    }
  }

  public getWorkflow(workflowName: string) {
    return this.domainSvc.currentDomain.workflows.find(wf => wf.id === workflowName);
    //return this._current_organisation.currentDomain.workflows.find(wf => wf.name === workflowName);
  }

  public async orgExists(orgName : string) {
    return await this.orgExistsFn(orgName);
  }

  private async orgExistsFn(orgName : string) : Promise<unknown> {
    const wfCall = this.fns.httpsCallable('orgExists');
    return await firstValueFrom(wfCall({orgName:orgName}).pipe(first()));
  }

  public async addOrganisation(orgDetails: OrganisationI) {
    if(this._organisations === undefined) {
      this._organisations = [];
    }
    this.addUpdateOrganisationFn(orgDetails).then(() => {
      this._organisations.push(new Organisation(orgDetails));
      this.authSvc.addOrganisation(orgDetails.id);
    });
  }

  private async addUpdateOrganisationFn(orgDetails: OrganisationI) : Promise<unknown> {
    const wfCall = this.fns.httpsCallable('httpsOrgAddUpdate');
    return await firstValueFrom(wfCall({orgDetails: orgDetails}).pipe(first()));
  }

  public setCurrentOrganisation(currentOrg: string) {
    if(this._organisations !== undefined && this._organisations !== null) {
      for(const org of this._organisations) {
        if(org.title === currentOrg) {
          this._current_organisation = org;
        }
      }
    }
  }

  public async getOrganisations(organisations: string[]) {
    this._organisations = [];
  
    const orgs = await this.getOrganisationsFn(organisations);
    if (orgs && orgs.length > 0) {
      for (const org of orgs) {
        this._organisations.push(new Organisation({
          id : Object(org).id,
          data : {
            contact_email: Object(org).data.contact_email,
            cooking_kits: Object(org).data.cooking_kits,
            domains: Object(org).data.domains,
            members: Object(org).data.members,
            name: Object(org).data.name,
            subscriptions: Object(org).data.subscriptions,
        }})); 
      }
    } else {
      // contact administrator to access the organizations module
    }
    
    if(this.spinnerSvc.active) {
      this.spinnerSvc.hide();
    }
  }

  private async getOrganisationsFn(organisations: string[]) : Promise<object[]> {
    const wfCall = this.fns.httpsCallable('httpsGetOrganisations');
    return await firstValueFrom(wfCall({userOrgs: organisations}).pipe(first()));
  }

  public async getOrganisation(orgName : string) {
    this.getOrganisationFn(orgName).then((orgData) => {
        this._current_organisation = new Organisation({
          id : Object(orgData).id,
          data : {
            contact_email: Object(orgData).data.contact_email,
            cooking_kits: Object(orgData).data.cooking_kits,
            domains: Object(orgData).data.domains,
            members: Object(orgData).data.members,
            name: Object(orgData).data.name,
            subscriptions: Object(orgData).data.subscriptions,
        }});
      }).catch((error) => {
        console.log('getOrganisation error : ', error);
      });
  }

  private async getOrganisationFn(orgName: string) : Promise<object> {
    const wfCall = this.fns.httpsCallable('httpsGetOrganisation');
    return await firstValueFrom(wfCall({orgName: orgName}).pipe(first()));
  }

  removeDomain(domain : Domain) {
    this.removeDomainFn(domain.id).then(()=> {
      /* this._current_organisation.domains.splice(this._current_organisation.domains.findIndex((foundObj) => {
        foundObj.id === domain.id;
      }), 1); */
      console.log(this._current_organisation.members);
    });
  }

  private async removeDomainFn(id: string) : Promise<object> {
    const removeDomainFnCall = this.fns.httpsCallable('httpsRemoveDomain');
    return await firstValueFrom(removeDomainFnCall({id: id, orgId: this._current_organisation.id}).pipe(first()));
  }

  removeMember(uid : string) {
    this.removeMemberFn(uid).then(()=> {
      this._current_organisation.members.splice(this._current_organisation.members.findIndex((foundObj) => {
        return foundObj.uid === uid;
      }), 1);
      console.log(this._current_organisation.members);
    });
  }

  private async removeMemberFn(uid: string) : Promise<object> {
    const removeMemberFnCall = this.fns.httpsCallable('httpsRemoveMember');
    return await firstValueFrom(removeMemberFnCall({uid: uid, orgId: this._current_organisation.id}).pipe(first()));
  }

  async updateMember(uid : string, role: string) {
    await this.updateMemberFn(uid, role);
  }

  private async updateMemberFn(uid: string, role: string) : Promise<object> {
    const updateMemberFnCall = this.fns.httpsCallable('httpsUpdateMember');
    return await firstValueFrom(updateMemberFnCall({uid: uid, role: role, orgId: this._current_organisation.id}).pipe(first()));
  }

  /**
   * Sends an invite to to new user or adds existing member to organisation
   * Return values:
   * sendMail info on successful invite
   * UserRecord on user found
   * error on sendMail error
   * @param email
   * @param role
   */
  public async inviteMemberToOrganisation(email: string, role: string) : Promise<object> {
    return new Promise((resolve, reject) => {
      this.inviteMemberFn(email, role, this.authSvc.User.accountSettings.language)
        .then((result) => {
        // user added to pending invites collection
        // add pending new user to organisation members with pending status
        this._current_organisation.members.push({
          role: role,
          status: 'pending',
          uid: '',
          email: email,
          first_name: '',
          last_name: '',
        });
        resolve(result);
      }).catch((error) => {
        reject(error);
      })
    });
  }

  private async inviteMemberFn(email: string, role: string, language: string) : Promise<object> {
    const inviteMemberFnCall = this.fns.httpsCallable('httpsInviteMember');
    return await firstValueFrom(inviteMemberFnCall({email: email, role: role, orgId: this._current_organisation.id, language: language}).pipe(first()));
  }

  public get organisations(): Organisation[] {
    return this._organisations;
  }
  public set organisations(value: Organisation[]) {
    this._organisations = value;
  }

  public get currentOrganisation(): Organisation {
    return this._current_organisation;
  }
  public set currentOrganisation(value: Organisation) {
    this._current_organisation = value;
  }

  private orgLoadedSubject = new Subject<number>();
  sendOrgLoadedEvent() {
    this.orgLoadedSubject.next(1);
  }
  getOrgLoadedEvent(): Observable<number>{ 
    return this.orgLoadedSubject.asObservable();
  }
}
