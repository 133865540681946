import { Component, HostListener, Input } from '@angular/core';
import { Domain } from 'src/app/models/domain.model';
import { DomainService } from '../../services/domain.service';

@Component({
  selector: 'app-domain-item',
  templateUrl: './domain-item.component.html',
  styleUrls: ['./domain-item.component.scss']
})
export class DomainItemComponent {

  @Input() domain: Domain;

  constructor(
    public domainSvc : DomainService
  ) {
  }
  
  @HostListener("click") onClick() {
    this.domainSvc.selectedDomain = this.domain;
  }
}
