import { WorkflowI } from '../interfaces/workflow.interface';
import { Step } from './step.model';

export class Workflow implements WorkflowI {

    id: string;
    categories?: string[];
    author: string;
    description_en: string;
    description_fr: string;
    picto: string;
    production: boolean;
    isPublic: boolean;
    title_en: string;
    title_fr: string;
    steps: Step[] = [];

    private _description: string;
    private _description_header : string;
    private _step_indicator_workflow_list : Step[];
    private _current_index : number;
    private _step_count : number;
    private _current_step : Step | null;
    private _timer_step : Step | null;
    private _title: string;
    private _unlocked: boolean = true;
    private _favorite : boolean;
    private _recent : boolean;
    private _lastViewedDate : number;
    private _difficulty: number;
    private _domain_id: string;
    private _time_to_complete: number;
    private _job_answered: boolean = false;
    private _purchased: boolean = false;
    private _recipe_jpg: string;
    
    /**
     * Build raw data object
     * WorkflowService should contain the functions to adapt to competency and language
     * WorkflowService will create the display workflows from the basic structure
     */
    constructor(data: object, domainId : string) { 
        this.id = Object.keys(data)[0];
        const wfObjectData = data[this.id].data;
        this.author = wfObjectData.author;
        this.categories = wfObjectData.categories;
        this.description_en = wfObjectData.description_en;
        this.description_fr = wfObjectData.description_fr;
        if(wfObjectData.svg_string) {
            this.picto = wfObjectData.svg_string.svg_string;
        } else {
            this.picto = this.id;
        }
        this.production = wfObjectData.production;
        this.isPublic = wfObjectData.public;
        this.title_en = wfObjectData.title_en;
        this.title_fr = wfObjectData.title_fr;

        if (wfObjectData.workflow.steps && wfObjectData.workflow.steps.length > 0) {
            this.steps = this.buildSteps(wfObjectData.workflow.steps);
        }

        this._domain_id = domainId;
        this._time_to_complete = wfObjectData.time_to_complete;
        if(wfObjectData.purchased) {
            this._purchased = true;
        }
        this._step_indicator_workflow_list = [];
        this._current_index = 0;
        this._step_count = 0;
        this._current_step = null;
        this._timer_step = null;
    }

    get stepIndicatorWorkflowList() {
        return this._step_indicator_workflow_list;
    }
    set stepIndicatorWorkflowList(list : Array<Step>) {
        this._step_indicator_workflow_list = list;
    }

    get currentIndex() {
        return this._current_index;
    }
    set currentIndex(index : number) {
        this._current_index = index;
    }

    get stepCount() {
        return this._step_count;
    }
    set stepCount(count : number) {
        this._step_count = count;
    }

    get currentStep() : Step | null {
        return this._current_step;
    }
    set currentStep(current_step : Step | null) {
        this._current_step = current_step;
    }

    get timerStep() : Step | null{
        return this._timer_step;
    }
    set timerStep(timer_step : Step | null) {
        this._timer_step = timer_step
    }

    public get title(): string {
        return this._title;
    }
    public set title(value: string) {
        this._title = value;
    }

    public get unlocked(): boolean {
        return this._unlocked;
    }
    public set unlocked(value: boolean) {
        this._unlocked = value;
    }

    public get difficulty(): number {
        return this._difficulty;
    }
    public set difficulty(value: number) {
        this._difficulty = value;
    }

    public get description(): string {
        return this._description;
    }
    public set description(value: string) {
        this._description = value;
    }

    public get domain_id(): string {
        return this._domain_id;
    }
    public set domain_id(value: string) {
        this._domain_id = value;
    }

    public get job_answered(): boolean {
        return this._job_answered;
    }
    public set job_answered(value: boolean) {
        this._job_answered = value;
    }

    public get time_to_complete(): number {
        return this._time_to_complete;
    }
    public set time_to_complete(value: number) {
        this._time_to_complete = value;
    }

    public get purchased(): boolean {
        return this._purchased;
    }
    public set purchased(value: boolean) {
        this._purchased = value;
    }

    public get recipe_jpg(): string {
        return this._recipe_jpg;
    }
    public set recipe_jpg(value: string) {
        this._recipe_jpg = value;
    }

    setDescriptions(language: string) {
        switch(language) {
            case 'fr' : 
                this._title = this.title_fr;
                this._description = this.description_fr;
                break;
            default: 
                this._title = this.title_en;
                this._description = this.description_en;
        }
    }

    buildSteps(value : object[]) {
        const steps : Step[] = [];
        for (const stepObject of value) {
            steps.push(new Step(stepObject));
        }
        return steps;
    }
}