import { RecipeI } from '../interfaces/recipe.interface';
import { Allergy } from './allergy.model';
import { Ingredient } from './ingredient.model';
import { Tool } from './tool.model';
import { Workflow } from './workflow.model';

export class Recipe extends Workflow implements RecipeI {
    
    public min_prepTime : number;
    public max_prepTime : number;
    public min_cookTime : number;
    public max_cookTime : number;
    public min_yield : number;
    public max_yield : number;
    public min_level : number;
    private _portions_multiplier : number;
    private _max_portion_index : number;
    private _min_portion_index : number;
    private _portion_delta : number;
    private _initial_min_yield : number;
    public allergies?: string[];
    public tools?: string[];


    private _allergyList: Allergy[] = [];
    private _toolList: Tool[] = [];
    private _ingredientList: Ingredient[] = [];

    constructor(data : object, domainId : string) {
        super(data, domainId);
        const wfObjectData = data[this.id].data;
        this.min_prepTime = wfObjectData.min_prepTime;
        this.max_prepTime = wfObjectData.max_prepTime;
        this.min_cookTime = wfObjectData.min_cookTime;
        this.max_cookTime = wfObjectData.max_cookTime;
        this.min_yield = wfObjectData.min_yield;
        this._initial_min_yield = this.min_yield;
        this.max_yield = wfObjectData.max_yield;
        this.min_level = wfObjectData.min_level;
        this._portions_multiplier = 0;
        this._max_portion_index = 0;
        this._min_portion_index = 0;
        this._portion_delta = 0;
        this.allergies = wfObjectData.allergies;
        this.tools = wfObjectData.workflow.tools;
    }

    get portionsMultiplier() {
        return this._portions_multiplier;
    }
    set portionsMultiplier(portion : number) {
        this._portions_multiplier = portion;
    }

    get maxPortionIndex() {
        return this._max_portion_index;
    }
    set maxPortionIndex(portions : number) {
        this._max_portion_index = portions;
    }

    get minPortionIndex() {
        return this._min_portion_index;
    }
    set minPortionIndex(portions : number) {
        this._min_portion_index = portions;
    }

    get portionDelta() {
        return this._portion_delta;
    }
    set portionDelta(delta : number) {
        this._portion_delta = delta;
    }

    get initialMinYield() {
        return this._initial_min_yield;
    }
    set initialMinYield(min_yield : number) {
        this._initial_min_yield = min_yield;
    }

    public get allergyList(): Allergy[] {
        return this._allergyList;
    }
    public set allergyList(value: Allergy[]) {
        this._allergyList = value;
    }

    public get toolList(): Tool[] {
        return this._toolList;
    }
    public set toolList(value: Tool[]) {
        this._toolList = value;
    }

    public get ingredientList(): Ingredient[] {
        return this._ingredientList;
    }
    public set ingredientList(value: Ingredient[]) {
        this._ingredientList = value;
    }
}