import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/admin/services/auth.service';
import { DisplayService } from 'src/app/tools/services/display.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';

@Component({
  selector: 'app-familiarization-modal',
  templateUrl: './familiarization-modal.component.html',
  styleUrl: './familiarization-modal.component.scss'
})
export class FamiliarizationModalComponent implements AfterViewInit {
  chk_box: boolean = false;
  
  // default tablet text
  visitFamiliarization: string;

  constructor(
    public translatorSvc : TranslatorService,
    public dialogRef: MatDialogRef<FamiliarizationModalComponent>,
    private authSvc : AuthService,
    private displaySvc : DisplayService
  ) {}

  ngAfterViewInit(): void {
    this.displaySvc.screenWidth <= 600 ? 
      this.visitFamiliarization = this.translatorSvc.getText("visitFamiliarizationPortrait") : this.translatorSvc.getText("visitFamiliarization");
  }

  closeModal() {
    if (this.chk_box) {
      localStorage.setItem("autonome_modal_hide", 'true');
      this.authSvc.isFamiliarizationHide = true;
    }
    
    this.dialogRef.close(false);
  }
}
