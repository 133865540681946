import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from 'src/app/admin/services/auth.service';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { ToolService } from './tool.service';
import { Subscription, first, firstValueFrom } from 'rxjs';
import { Domain } from 'src/app/models/domain.model';
import { WorkflowService } from './workflow.service';
import { Router } from '@angular/router';
import { StripeProducts } from 'src/app/enums/stripe-products.enum';
import { ProductService } from './product.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { WorkflowCategory } from 'src/app/models/workflow-category.model';
import { RecipeService } from './recipe.service';
@Injectable({
  providedIn: 'root'
})
export class DomainService {

  private domainInitEventSubscription : Subscription;
  private _domains: Domain[] = [];
  private _currentDomain: Domain;
  private _selectedDomain: Domain;
  private _currentCategory: WorkflowCategory;
  
  public promoText;

  constructor(private fns : AngularFireFunctions,
    private authSvc: AuthService,
    private spinnerSvc : SpinnerOverlayService,
    private toolSvc : ToolService,
    private workflowSvc : WorkflowService,
    private recipeSvc : RecipeService,
    private translatorSvc : TranslatorService,
    private router: Router,
    private productSvc: ProductService) {
      this.domainInitEventSubscription = this.authSvc.getDomainInitEvent().subscribe(() => {
        this.init(true);
      });
    }

    /**
     * Initiates the domain service by getting all the domains for the User
     * Sends the login event as the user config should be complete at this point
     * 
     * @param showSpinner - on loading non org 
     */
    public async init(showSpinner: boolean) {
      if(showSpinner && !this.spinnerSvc.active) {
        this.spinnerSvc.show(this.translatorSvc.getLabel('loadingDomains'));;
      } else if(showSpinner && this.spinnerSvc.active) {
        this.spinnerSvc.text = this.translatorSvc.getLabel('loadingDomains');
      }
      this.getUserDomains();
      
    }

    /**
   * Gets all domains and workflow data for each user domain
   * By default 'cooking' domain is accessible to everyone
   * 
   */
    public async getUserDomains() {
      // Load tools 
      this.spinnerSvc.text = 'Loading tools...';
      await this.toolSvc.init();

      // return user domains as well as the public ones
      this.spinnerSvc.text = this.translatorSvc.getLabel('loadingDomains')
      const domainInfo = await this.getDomainsFn(this.authSvc.User.domains, true);
      if(domainInfo) {
        for (const domain of Object(domainInfo).domains) {
          const newDomain = new Domain(domain, Object(domainInfo).categories);
          if (newDomain.id === 'cooking') {
            this.recipeSvc.init(domainInfo);
          }
          if(this.authSvc.User.domains && this.authSvc.User.domains.includes(newDomain.id)) {
            newDomain.purchased = true;
          }

          fetch('/assets/pictos/domains/' + newDomain.id + '.svg').then(res => {
            if(res.status !== 404) {
              newDomain.picto = newDomain.id;
            } else {
              newDomain.picto = 'icon_img_not_found';
            }
          });
          this._domains.push(newDomain);

          this.workflowSvc.initDomainWorkflows(newDomain);
        }

        // Set initial domain and initial workflow
        this._currentDomain = this._domains.find((domain) => domain.id === 'cooking');
        this.currentCategory = this._currentDomain.categories[0];
        this.workflowSvc.selectedWorkflow = this._currentCategory.workflows[0];

        this.updateDomainsDescriptions();
        this.currentDomain = this._domains[0];

        // Initialize translatorSvc language
        this.authSvc.sendLoginEvent();

        // At this point we can display the domains component unless
        // it's a new user with pending purchases
        if(this.authSvc.User.purchaseBeta || this.authSvc.User.purchaseDomains) {
          const promoDomain = this._domains.find((obj)=> {
            return obj.id === 'cooking'
          });
          if(this.authSvc.User.purchaseBeta) {
            this.productSvc.addItemToCart({
              id : promoDomain.promotions.id,
              stripe_id : StripeProducts[promoDomain.promotions.id],
              domain_id: promoDomain.id,
              name : promoDomain.promotions['name_'+this.authSvc.User.accountSettings.language],
              price: promoDomain.promotions.price,
              shipping_method: promoDomain.promotions.shipping_method,
              shipping_fee: promoDomain.promotions.shipping_fee,
              taxable: promoDomain.promotions.taxable
            });
          }
          if(this.authSvc.User.purchaseDomains && this.authSvc.User.purchaseDomains.length > 0) {
            for (const domain of this.authSvc.User.purchaseDomains) {
              this.productSvc.addDomainToCart(domain);
            }
          }
          if(this.productSvc.itemsToPurchase.length > 0 || this.productSvc.domainsToPurchase.length > 0) {
            this.router.navigate(['domains']);
            this.router.navigate(['shopping-cart']);
          } else {
              this.currentDomain = this._domains.find((obj)=> { return obj.id === 'cooking' });
              this.router.navigate(['recipes']);
          }
        } else {
          // TODO : temp routing while only domain avail is cooking
          if(this.authSvc.isFamiliarizationHide) {
            this.currentDomain = this._domains.find((obj)=> { return obj.id === 'cooking' });
            this.router.navigate(['recipes']);
          } else {
            this.router.navigate(['recipes']);
          }
        }
        
        if(this.spinnerSvc.active) {
          this.spinnerSvc.hide();
        }

        // verify if there have been new application features
        if(this.authSvc.User.accountSettings.new_features && this.authSvc.User.metadata) {
          this.authSvc.showNewFeatures(new Date(Object(this.authSvc.User.metadata).creationTime), new Date(Object(this.authSvc.User.metadata).lastSignInTime));
        }
      }

  }

    /**
     * Update domain information language
     * 
     */
    private updateDomainsDescriptions() {
      switch (this.authSvc.User.accountSettings.language) {
        case 'fr' :
          for(const domain of this._domains) {
            domain.description = domain.description_fr;
            domain.title = domain.title_fr;
          }
          break;
        default :
          for(const domain of this._domains) {
            domain.description = domain.description_en;
            domain.title = domain.title_en;
          }
      }
    }

    getDomain(domainId : string) {
      return this._domains.find((domain) => domain.id === domainId);
    }

    
    /**
     * Update the newly purchased domains by setting ourchased value of workflows to true
     */
    updateDomains(purchasedDomains : string[]) {
      for (const purchasedDomainString of purchasedDomains) {
        const purchasedDomain = this.getDomain(purchasedDomainString);
        purchasedDomain.purchased = true;
        this.unlockDomainWorkflows(purchasedDomain);
      }
    }

    // Set domain workflows as purchased
    unlockDomainWorkflows(domain : Domain) {
      for (const workflow of domain.workflows) {
        workflow.purchased = true;
      }
    }

    getWorkflow(workflowId : string) {
      return this._currentDomain.workflows.find((workflow) => workflow.id === workflowId);
    }

    /**
     *  Navigate to domain component
     * But first we need to ensure the workflow service inherits the correct workflow collection
     * 
     */
    goToDomains() {
      this.currentDomain = this.domains.find((obj) => obj.selected);
      this.router.navigate(['domain']);
    }

    isResearchProject(domain : Domain) {
      return domain.id.includes('job_examples');
    }

    goToRecipeEditor() {
      this.router.navigate(['recipe-editor']);
    }

    goToProductsDomain() {
      this.router.navigate(['products-domain']);
    }

    /* public getOrgDomains(org: OrganisationInterface) {
      return new Promise((resolve, reject) => {
        if(!org.domains) {
          org.domains = new Array(); 
        }
      }); 
    } */
  
    private async getDomainsFn(domains: string[], includePublicDomains: boolean) : Promise<object> {
      const wfCall = this.fns.httpsCallable('httpsGetDomains');
      return await firstValueFrom(wfCall({domains: domains, includePublicDomains: includePublicDomains}).pipe(first()));
    }

    public get domains(): Domain[] {
      return this._domains;
    }
    public set domains(value: Domain[]) {
      this._domains = value;
    }

    public get currentDomain(): Domain {
      return this._currentDomain;
    }
    public set currentDomain(value: Domain) {
      if(this._currentDomain !== value) {
        this._currentDomain = value;
        this.setPromoText();
      }
    }

    public get selectedDomain(): Domain {
      return this._selectedDomain;
    }
    public set selectedDomain(value: Domain) {
      if(this._selectedDomain !== value) {
        for(const dom of this._domains) {
          dom.selected = false;
        }
        this._selectedDomain = value;
        this.currentCategory = this._selectedDomain.categories[0];
        this._selectedDomain.selected = true;
      }
    }

    public get currentCategory(): WorkflowCategory {
      return this._currentCategory;
    }
    public set currentCategory(value: WorkflowCategory) {
      if(!this._currentCategory) {
        this._currentCategory = value;
        this.workflowSvc.selectedWorkflow = this._currentCategory.workflows[0];
        this._currentCategory.selected = true;
      } else if (value.category_name !== this._currentCategory.category_name) {
        this._currentCategory.selected = false;
        this._currentCategory = value;
        this.workflowSvc.selectedWorkflow = this._currentCategory.workflows[0];
        this._currentCategory.selected = true;
      }
    }
    
    setPromoText() {
      this.promoText = undefined;
      if (this.currentDomain.promotions) {
        switch (this.authSvc.User.accountSettings.language) {
          case 'fr': this.promoText = this._currentDomain.promotions.name_fr;
            break;
          default : this.promoText = this._currentDomain.promotions.name_en;
        }
      }
    }

    userHasPromoProduct() : boolean {
      if(this.authSvc.User.items && this.currentDomain.promotions && this.currentDomain.promotions.id) {
        return this.authSvc.User.items.some((obj) => obj === StripeProducts[this.currentDomain.promotions.id]);
      } else {
        return false;
      }
    }
}