<div class="ingredient-list-item-container">
    <div class="checkbox"></div>
    <div class="ingredient-picto">
        <img src="/assets/pictos/ingredients/{{ingredientItem.picto}}.svg">
    </div>
    <p class="measure-qty-label" *ngIf="ingredientItem.totalQty !== undefined && ingredientItem.totalQty > 0 then quantity else measure"></p>
    <div class="add-remove-icon"></div>
    <div class="replace-icon"></div>
</div>

<ng-template #quantity>
    <p>{{ingredientItem.totalQty}}{{measureLabel}}</p>
</ng-template>

<ng-template #measure>
    <div *ngIf="(ingredientItem.unit ==='g' && ingredientItem.totalMeasure >= 1) then kg else g"></div>
</ng-template>

<ng-template #kg>
    <p>{{ingredientItem.totalMeasure / 1000}}{{measureLabel}}</p>
</ng-template>

<ng-template #g>
    <p>{{ingredientItem.totalMeasure}}{{measureLabel}}</p>
</ng-template>

