import { ElementRef } from "@angular/core";

export const utils = {
    randomFromArray(array) {
        return array[Math.floor(Math.random()*array.length)];
    },

    oppositeDirection(direction:string) {
        switch(direction) {
          case "left":
            return "right";
          case "right":
            return "left";
          case "up":
            return "down";
          case "down":
            return "up";
          default:
            return "left";
        }
      },
    
    emitEvent(name, detail) {
      const event = new CustomEvent(name, {
        detail
      });
      document.dispatchEvent(event);
    },
  
    wait(ms) {
      return new Promise<void>(resolve => {
        setTimeout(() => {
          resolve()
        }, ms)
      })
    },

    isSameDay(date1: Date, date2: Date) {
      if(date2.getFullYear() === date1.getFullYear() &&
      date2.getMonth() === date1.getMonth() &&
      date2.getDate() === date1.getDate()) {
        return true;
      } else {
        return false
      }
    },

    isOverflowedHeight(element: ElementRef) {
      return element.nativeElement.scrollHeight > element.nativeElement.clientHeight;
    },

    scrollDown(element: ElementRef, shiftDown: number){
      element.nativeElement.scrollTo({
        top: shiftDown,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollToTop(element: ElementRef) {
      element.nativeElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    styleModals(element: string, cdkGlobal: string, mdcDialog: string) {
      if(document.getElementsByTagName(element)) {
        const cdkOverlayElement = document.getElementsByClassName("cdk-global-overlay-wrapper").item(0) as HTMLElement;
        cdkOverlayElement.classList.add(cdkGlobal);
        const cdkSurfaceElement = document.getElementsByClassName("mat-mdc-dialog-surface").item(0) as HTMLElement;
        cdkSurfaceElement.classList.add(mdcDialog);
      }
    }, 
    getTableAnswer(value: number, language: string) {
        switch(language) {
            case 'fr' :
              switch(value) {
                case 0 :
                  return "Ça ne me dérange pas du tout";
                case 1 :
                  return "Ça me dérange un peu";
                case 2 : 
                  return "Ça me dérange beaucoup";
                case 3 : 
                  return "Je ne sais pas"
                default :
                  return 'N/D'
              }
              break;
            default :
              switch(value) {
                case 0 :
                  return "It does not bother me at all";
                case 1 :
                  return "It bothers me a little";
                case 2 : 
                  return "It bothers me a lot";
                case 3 : 
                  return "I don't know"
                default :
                  return 'N/A'
              }
        }
    },
    getPreferenceIcon(answer : number) {
      switch(answer) {
        case 0 :
          return '/assets/base_icons/happy.svg';
        case 1 :
          return '/assets/base_icons/neutral.svg';
        case 2 :
          return '/assets/base_icons/unhappy.svg';
        default : return '/assets/base_icons/neutral.svg';
      }
    },
    getMultiIcon(answer: boolean) {
      return answer ? '/assets/base_icons/correct.svg' : '/assets/base_icons/error.svg';
    }
    
    
}