<div class="showcase-container">
    <div class="showcase-wrapper">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <app-showcase-item class="showcase-swiper-slide" *ngFor="let s of slides" [slide]="s">
        
                </app-showcase-item>
            </div>
            <div class="swiper-pagination"></div>
        
            <!-- Add Arrows -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
            
        </div>
    </div>
</div>
