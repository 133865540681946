import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Combatant } from '../../models/combatant';

@Component({
  selector: 'app-glob',
  templateUrl: './glob.component.html',
  styleUrls: ['./glob.component.scss']
})
export class GlobComponent implements OnInit {

  @HostListener('animationend', ['event']) animationEnd(event: any) {
    // TODO : determine which animation is running with event.animationName
    this.combatant.glob=false;
  }

  @Input() combatant: Combatant;

  constructor() { }

  ngOnInit(): void {
  }

}
