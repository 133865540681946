import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Combatant } from '../../models/combatant';

@Component({
  selector: 'app-pizza',
  templateUrl: './pizza.component.html',
  styleUrls: ['./pizza.component.scss']
})
export class PizzaComponent implements OnInit {

  @HostListener('animationend', ['event']) animationEnd(event: any) {
    // TODO : determine which animation is running with event.animationName
    this.combatant.spin =false;
  }

  @Input() combatant: Combatant;

  constructor() { }

  ngOnInit(): void {
    
  }

}
