import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule} from '@angular/fire/compat/functions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { AdminModule } from './admin/admin.module';
import { CoreModule } from './core/core.module';
import { ToolModule } from './tools/tool.module';
import { PaymentModule } from './payment/payment.module';
//import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { ChatComponent } from './support/components/chat/chat.component';
import { AcademyModule } from './academy/academy.module';
import { EditorModule } from './editor/editor.module';
import { ResearchProjectsModule } from './research-projects/research-projects.module';
import { OrganisationModule } from './organisations/organisation.module';

@NgModule({ declarations: [
        AppComponent,
        ChatComponent,
    ],
    bootstrap: [AppComponent], 
    imports: [BrowserModule,
        CommonModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule.enablePersistence(),
        MatSidenavModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        FormsModule,
        AdminModule,
        CoreModule,
        ResearchProjectsModule,
        EditorModule,
        ToolModule,
        PaymentModule,
        OrganisationModule,
        AcademyModule
        /*GoogleTagManagerModule.forRoot({
            id: environment.GTM_ID,
        }),*/
    ], providers: [
        {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE'
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { 
}



