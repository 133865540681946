import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { Component, AfterViewInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { AuthService } from 'src/app/admin/services/auth.service';
import { DomainService } from '../../services/domain.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { WorkflowService } from '../../services/workflow.service';
import { Workflow } from 'src/app/models/workflow.model';
import { Domain } from 'src/app/models/domain.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomainPromoComponent } from 'src/app/modal/components/domain-promo/domain-promo.component';
import { UserConfirmationComponent } from 'src/app/modal/components/user-confirmation/user-confirmation.component';
import { StripeProducts } from 'src/app/enums/stripe-products.enum';
import { Router } from '@angular/router';
import { Swiper } from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { ItemI } from 'src/app/interfaces/item.interface';

@Component({
  selector: 'app-products-domain',
  templateUrl: './products-domain.component.html',
  styleUrls: ['./products-domain.component.scss']
})
export class ProductsDomainComponent implements AfterViewInit {

  public workflows : Workflow[];
  screenWidth : number;
  iHeightText : string;
  viewDomain : boolean = true;
  priceDescription : string;
  promoTitle : string;
  promoDescription: string;
  domainPurchased: boolean = false;
  promoPurchased: boolean = false;
  kitDescription: string;
  kitTitle : string;
  private kitSwiper: Swiper;
  public quantity: number = 1;
  
  constructor(
    public productSvc : ProductService,
    private authSvc : AuthService,
    public domainSvc : DomainService,
    public translatorSvc : TranslatorService,
    public workflowSvc : WorkflowService,
    public spinnerSvc : SpinnerOverlayService,
    public matDialog: MatDialog,
    private router : Router
    
  )
  {
    switch(this.authSvc.User.accountSettings.language) {
      case 'fr' :
        this.kitDescription = 'description_fr';
        this.kitTitle = 'title_fr';
        break;
      default : 
        this.kitDescription = 'description_en';
        this.kitTitle = 'title_en';
        break;
    }
    if(this.productSvc.domainsToPurchase.find((obj) => {
      return obj === this.domainSvc.currentDomain.id;
    })) {
      this.domainPurchased = true;
    }
    if(this.authSvc.User.items) {
      if(this.authSvc.User.items.find((obj) => {
        return obj === StripeProducts[this.domainSvc.currentDomain.id];
      })) {
        this.promoPurchased = true;
      }
    }
  }

  ngAfterViewInit(): void {
    this.screenWidth = window.innerWidth;
    switch(this.authSvc.User.accountSettings.language) {
      case 'fr' :
        this.priceDescription = this.domainSvc.currentDomain.price_description_fr;
        if (this.domainSvc.currentDomain.promotions) {
          this.promoTitle = this.domainSvc.currentDomain.promotions.title_fr;
          this.promoDescription = this.domainSvc.currentDomain.promotions.description_fr;
        }
        break;
      default :
        this.priceDescription = this.domainSvc.currentDomain.price_description_en;
        if (this.domainSvc.currentDomain.promotions) {
          this.promoTitle = this.domainSvc.currentDomain.promotions.title_en;
          this.promoDescription = this.domainSvc.currentDomain.promotions.description_en;
        }
    }
    this.workflows = this.domainSvc.currentDomain.workflows;

    if(!this.kitSwiper) {
      this.kitSwiper = new Swiper("#domain-promo-slider-container", {
        modules: [Autoplay, Navigation, Pagination],
        spaceBetween: 40,
        slidesPerView: 1,
        centeredSlides: true,
        observeParents: true,
        observer: true,
        autoplay: {
          delay: 3000,
        },
        direction: "vertical",
        speed: 1500,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: true
        });
    }
    
    // set button disable booleans for nav
    if((this.productSvc.domainsToPurchase && this.productSvc.domainsToPurchase.length > 0) || this.productSvc.itemsToPurchase.some((obj: ItemI) => obj.domain_id === 'cooking')) {
      this.domainPurchased = true;
    }
  }

  selectDomain(value: Domain) {
    this.domainSvc.currentDomain = this.domainSvc.getDomain(value.id);
  }

  openDomainPromoModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "full-screen-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";

    this.matDialog.open(DomainPromoComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });

  }

  addDomainToCart() {
    this.domainPurchased = true;
    this.productSvc.addDomainToCart(this.domainSvc.currentDomain.id);
    this.openDomainAddedUserConfModal();
    
  }

  addKitToCart() {
    if(this.productSvc.itemsToPurchase && this.quantity > 0) {
      this.productSvc.addItemToCart({
        id : this.domainSvc.currentDomain.promotions.id,
        stripe_id : StripeProducts[this.domainSvc.currentDomain.promotions.id],
        domain_id: this.domainSvc.currentDomain.id,
        name : this.domainSvc.currentDomain.promotions['name_'+this.authSvc.User.accountSettings.language],
        price: this.domainSvc.currentDomain.promotions.price,
        shipping_method: this.domainSvc.currentDomain.promotions.shipping_method,
        shipping_fee: this.domainSvc.currentDomain.promotions.shipping_fee,
        taxable: this.domainSvc.currentDomain.promotions.taxable,
        quantity: this.quantity,
      });
      this.promoPurchased = true;
    }
    this.openDomainAddedUserConfModal();
  }

  onHeightResize(event) {
    this.iHeightText = (event.target.innerHeight - 88).toString() + 'px';
  }

  openDomainAddedUserConfModal() {
    const msgLbl = this.productSvc.itemsToPurchase.length > 0 ? 'itemAddedToCart' : 'domainAddedToCart';
    const msgContent = this.productSvc.itemsToPurchase.length > 0 ? this.productSvc.itemsToPurchase[this.productSvc.itemsToPurchase.length-1].name : this.domainSvc.currentDomain.title;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "full-screen-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "modal-add-domain-to-cart",
      actionButtonText1 : this.translatorSvc.getLabel('close'),
      message : this.translatorSvc.getLabel(msgLbl) + msgContent,
      actionButtonText2: this.translatorSvc.getLabel('goToShoppingCart'),
    };

    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result) {
        //this.routerExt.goBack();
      } else {
        //this.routerExt.goBack();
      }
    });
  }

  goToRecipes() {
    this.router.navigate(['recipes']);
  }

}
