import { VerificationI } from './../interfaces/verification.interface';

export class Verification implements VerificationI {
    public descriptions : object = [];
    public verification_name : string;
    private _description : string;
    public svg_string : string;
    public ready : string;
    public not_ready : string;
    public picto : string;
    
    constructor(value : object) {
        const verificationData = Object(value);
        this.descriptions = verificationData.descriptions;
        this.verification_name = verificationData.verification_name;
        this._description = '';
        this.svg_string = verificationData.svg_string;
        this.ready = verificationData.ready;
        this.not_ready = verificationData.not_ready;
        this.picto = verificationData.picto;
    }

    get description() {
        return this._description;
    }
    set description(description : string) {
        this._description = description;
    }

    setDescription(lang : string) {
        this._description = this.descriptions[lang].singular;
    }
}