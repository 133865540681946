import { AuthService } from 'src/app/admin/services/auth.service';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { WorkflowService } from '../../../core/services/workflow.service';
import { Step } from '../../../models/step.model';
import { TranslatorService } from '../../../tools/services/translator.service';
import { TimerService } from '../../services/timer.service';

@Component({
  selector: 'app-cycle-step',
  templateUrl: './cycle-step.component.html',
  styleUrls: ['./cycle-step.component.scss']
})
export class CycleStepComponent implements OnInit {

  timerMinutes : string;  
  timerSeconds : string;
  modalData : object;
  cycleTimerId : number;
  cycleTimerObservable : Observable<number>;
  alertPlayer = new Audio;
  alertPlaying : boolean = false;
  actionDescription : string;
  timerDescriptionSuffix : string;
  step : Step;
  actionGif : string= "assets/animations/";
  

  constructor(public dialogRef: MatDialogRef<CycleStepComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) data: object,
    public matDialog: MatDialog,
    public translatorSvc : TranslatorService,
    private timerSvc : TimerService,
    private wfSvc : WorkflowService,
    private authSvc: AuthService) {
     this.modalData = data;
    }

  ngOnInit(): void {
    this.alertPlayer.src = "assets/audio/timer.mp3"
    this.step = this.wfSvc.displaySteps[this.wfSvc.currentStepId].cycle_steps[0];
    this.actionGif = this.actionGif + this.step.action.picto+".gif";
    this.timerSvc.addTimer(Object(this.modalData).time);
    this.cycleTimerId = this.timerSvc.newId;
    this.timerSvc.notifications[this.cycleTimerId].paused.next(false);
    this.cycleTimerObservable = this.timerSvc.notifications[this.cycleTimerId].obs.stepTimer;

    this.actionDescription = this.step.action.description;
    
    switch(this.authSvc.User.accountSettings.language) {
      case 'en' : this.timerDescriptionSuffix = " for: ";
        break;
      case 'fr' : this.timerDescriptionSuffix = " pendant: ";
        break;
    }
    this.actionDescription = this.actionDescription  + this.timerDescriptionSuffix;
      
    this.cycleTimerObservable.subscribe(
      time => {
        if(time >= Object(this.modalData).time - 4 && !this.alertPlaying) {
          this.alertPlayer.play();
          this.alertPlaying = true;
        } else if(time < Object(this.modalData).time - 3 && this.alertPlaying) {
          this.alertPlayer.pause();
          this.alertPlaying = false;
        }
        if(time >= 0) {
          this.timerMinutes = "" + Math.floor(time/60);
          if((time % 60) < 10) {
            this.timerSeconds = "0" + (time % 60);
          } else {
            this.timerSeconds = "" + (time % 60);
          }
        } 
        if(time === 0) {
          this.timerSvc.notifications[this.cycleTimerId].paused.next(false);
          this.timerSvc.stopTimer(this.cycleTimerId);
          this.dialogRef.close();
        }
      });
  }

}
