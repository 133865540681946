<div class="fs-container">
    <div class="modal-style">
        <div class="modal-warning"><img src="/assets/base_icons/exclamation_mark.svg"/> </div>
        <p>{{translatorSvc.getLabel('goFS')}}</p>
        <div class="modal-button-wrapper">
            <button type="button" (click)="closeDialog()" class="autonome-button-white"><p>{{translatorSvc.getLabel('no')}}</p></button> 
            <button type="button" (click)="goFS()" class="autonome-button-orange"><p>{{translatorSvc.getLabel('yes')}}</p></button>
        </div>
    </div>
</div>
