
<app-video-modal *ngIf="hasVideo" #videoModal [step]="step"></app-video-modal>
<ng-template stepHost>

</ng-template>
<div *ngIf="!step.current" class="step-overlay-class">
</div>
<img  *ngIf="!hideVideoBtn" class="video-button"
    [class.hideNextStepBtn]="!step.current" 
    src="/assets/base_icons/icon_play_video.svg"
    (touchstart)='disableTouchMove()'
    (touchend)='enableTouchMove()'
    (mouseenter)='overButton()'
    (mouseleave)='leaveButton()'
    (click)="play_video()" alt="Play video">




