import { utils } from '../../utils/utils';
import { AcademyService } from './../services/academy.service';
import { AutonomeWorldMap } from './autonome-world-map';
import { GameObject } from "./game-object"

export class Person extends GameObject {

    private _movingProgressRemaining: number;
    private _directionUpdate: any;
    private _isPlayerControlled: boolean;
    
    
    constructor(config, private _academySvc: AcademyService) {
        super(config, _academySvc);
        this.movingProgressRemaining = 0;
        this.isStanding = false;

        this.isPlayerControlled = config.isPlayerControlled || false;
        this.directionUpdate = {
            "up": ["y", -1],
            "down": ["y", 1],
            "left": ["x", -1],
            "right": ["x", 1],
        }
    }

    public get movingProgressRemaining(): number {
        return this._movingProgressRemaining;
    }
    public set movingProgressRemaining(value: number) {
        this._movingProgressRemaining = value;
    }

    public get directionUpdate(): any {
        return this._directionUpdate;
    }
    public set directionUpdate(value: any) {
        this._directionUpdate = value;
    }

    public get isPlayerControlled(): boolean {
        return this._isPlayerControlled;
    }
    public set isPlayerControlled(value: boolean) {
        this._isPlayerControlled = value;
    }


    update(state: any):void {
        if(this.movingProgressRemaining > 0) {
            this.updatePosition();
        } else {
            // TODO : more walking
            // case: keyword ready and have arrow press
            if(this.isPlayerControlled && state.arrow && !state.map.isCutscenePlaying) {
                this.startBehaviour(state, {
                    type: "walk",
                    direction: state.arrow,
                }); 
            }
            this.updateSprite();
        }     
    }

    startBehaviour(state, behaviour) {
        // cher direction to behaviour has
        this.direction = behaviour.direction;
        if(behaviour.type === "walk") {
            // space not free
            if((state.map as AutonomeWorldMap).isSpaceTaken(this.x, this.y, this.direction)) {
                behaviour.retry && setTimeout(()=> {
                    this.startBehaviour(state, behaviour);
                }, 10)
                return;
            }
            state.map.moveWall(this.x, this.y, this.direction); 
            this.movingProgressRemaining = 16;

            this.updateSprite();
        }

        if(behaviour.type === "stand") {
            this.isStanding = true;
            setTimeout(()=> {
                utils.emitEvent("PersonStandComplete", {
                    whoId : this._id,
                });
                this.isStanding = false;
            }, behaviour.time);
        }
    }

    updatePosition() {
        const [property, change] = this.directionUpdate[this.direction]
        this[property] += change;
        this.movingProgressRemaining -= 1;

        if(this._movingProgressRemaining === 0) {
            utils.emitEvent("PersonWalkingComplete", {
                whoId: this._id,
            })
        }
    }

    updateSprite() {
        if(this._movingProgressRemaining > 0) {
            this.sprite.setAnimation("walk-"+this.direction);
            return;
        }
        this.sprite.setAnimation("idle-"+this.direction);
    }
}