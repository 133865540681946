<div class="add-domain-container">
    <section class="add-domain-title">
        <h3>{{translatorSvc.getLabel('domain')}} : {{domainSvc.currentDomain.title}}</h3>
    </section>
    <section class="add-domain-info-container">
        <div class="add-domain-info-wrapper">
            <div *ngIf="!promoPurchased" class="add-domain-promo-container">
                <button class="modal-text-button" class="add-domain-promo modal-text-button" (click)="openDomainPromoModal()">{{promoTitle}}<br>{{translatorSvc.getLabel('seeDetails')}}</button>
            </div>
            <p class="add-domain-info">{{translatorSvc.getText('recipeDomainPurchase')}}</p>
        </div>
        <div class="add-domain-price">
            {{domainSvc.currentDomain.price}}$ (CAN)/ {{translatorSvc.getLabel(domainSvc.currentDomain.price_subscription_term)}}
        </div>
        <div class="add-domain-price-description">
            {{priceDescription}}
        </div>
    </section>
    <section class="add-domain-button-container">
        <button class="modal-text-button" (click)="addDomainToDomain()" [disabled]="purchased">{{translatorSvc.getLabel('addToCart')}}</button>
        <button class="modal-text-button" (click)="closeDialog()">{{translatorSvc.getLabel('cancel')}}</button>
    </section>
</div>
