<div #confirmation_modal class="conf-modal-container">
    <div id="conf-modal-content" class="modal-style">
        <div class="modal-warning"><img src="/assets/base_icons/exclamation_mark.svg"/> </div>
        <p>{{message}}</p>
        
        <div class="modal-button-wrapper">
            <button class="autonome-button-white" id="modal-action-button" (click)="actionFunction1()">
                <p>{{actionButtonText1}}</p>
            </button>
            <button [class.hide-button]="hide_button" class="autonome-button-orange" id="modal-cancel-button" (click)="actionFunction2()">
                <p>{{actionButtonText2}}</p>
            </button>
        </div>
        <div *ngIf="check_box_message" class="modal-checkbox">
            <input id="chk_bx" type="checkbox" [(ngModel)]="modalSvc.chk_box">
            <label for="chk_bx">{{check_box_message}}</label> 
        </div>
    </div>
</div>
