import { PresenceService } from './presence.service';
import { User } from './../../models/user.model';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider } from '@angular/fire/auth';
import { first, firstValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { SpinnerOverlayService } from '../../tools/services/spinner-overlay.service';
import getUserLocale from 'get-user-locale';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewFeaturesComponent } from 'src/app/modal/components/new-features/new-features.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { AccountSettingsI } from 'src/app/interfaces/acct-settings.interface';
import { UserI } from 'src/app/interfaces/user.interface';
import { utils } from 'src/app/utils/utils';


@Injectable()
export class AuthService {

  private _authUser: Observable<User>;
  private _user: User;
  private _userLocale;
  private _loggedIn:boolean;
  private _emailVerified: boolean = false;
  private _flowFromBeta : boolean = false;
  private _hasRecipes: boolean;
  private _hasOrgs: boolean;
  private userDocSubscription: Subscription;
  private isLoadingUser: boolean = false;
  private _isFamiliarizationHide: boolean;
  private _isFamiliarizationModalOpen: boolean = false;
  private _presence$;

  constructor(
    private auth: AngularFireAuth,
    private fns: AngularFireFunctions,
    private angularFirestore: AngularFirestore,
    private router: Router,
    private spinnerSvc : SpinnerOverlayService,
    private translatorSvc : TranslatorService,
    private presenceSvc: PresenceService,
    public matDialog: MatDialog,
    ) {
      // verify local storage for hiding of familiarization modal
      if(localStorage.getItem("autonome_modal_hide")) {
        this.isFamiliarizationHide = true;
      }

      this._userLocale = getUserLocale();
      if(this._userLocale.length>2) {
          this._userLocale = this._userLocale.slice(0,2);
      }

      auth.authState.subscribe(async user => {
        if(user) {
          // verify if user has to reset their password
          const idTokenResult = (await auth.currentUser).getIdTokenResult();
          if((await idTokenResult).claims.resetPW) {
              // sendPWReset event to welcome page
              this.sendPasswordResetEvent();
          } else {
            // user is logged in
            if(user.emailVerified || user.providerData[0].providerId === 'facebook.com') {
              if(!user.emailVerified) {
                await this.updateEmailVerified(true);
              }
              
              this._emailVerified = true;
              if(!this._user) {
                // Instantiate presence service for user
                this._presence$ = this.presenceSvc.getPresence(user.uid);
                const isGoogleUser = user.providerData.some((obj) => {
                  return obj.providerId === 'google.com'
                });
                this.loadUser(user.uid, user.metadata, isGoogleUser);

                /* if(this.userDocSubscription === undefined) {
                  this.angularFirestore.collection('users').doc(user.uid)
                  .snapshotChanges()
                  .subscribe(async (change) => {

                    const userdata = await change.payload.data()
                    
                    if(this._user && userdata !== this.trimmedUserObject() && !this.isLoadingUser && userdata) {
                      
                    }
                  });
                
                } */

              }
            } else {
              // user email not verified, log out
              this.signOut();
            }
          }
        } else {
          // this.sendProductInitEvent();
        }
    });
  }

  trimmedUserObject() {
    if (this.User) {
      const userObject = Object.assign({}, this.User.data);
      if (Object(userObject)["stripeData"]) {
        delete Object(userObject)["stripeData"];
      }
      if (Object(userObject)["uid"]) {
        delete Object(userObject)["uid"];
      }
      return userObject;
    }
    return null;
  }

  async loadUser(uid: string, metaData: object | null | undefined, isGoogleUser: boolean) {
    this.isLoadingUser = true;
    if(!this.spinnerSvc.active) {
      this.spinnerSvc.show(this.translatorSvc.getLabel('loadingUser'));
    }
    const result = await this.getFBUser(this._userLocale);
    if(result) {
      this._user = new User(result, metaData, this._userLocale);
      this.translatorSvc.setLanguage(this._user.accountSettings.language);
      if(this.User.accountSettings.language === 'en') {
        this.isFamiliarizationHide = true;
      }
      this.isLoadingUser = false;
      /**
       * Verify if EULA signed
       * if not open modal that has no close option and mandatory agreement
       */
      if(!this._user.accountSettings.eulaSigned) {
        this.sendEulaNotSignedEvent();
      } else {
        this.sendDomainInitEvent();
      }
    } else {
      // Assume user account does not exist
      // Show complete profile modal
      // Create temp accountSettings for user except if provider google or facebook
      if(!isGoogleUser){
        this.createUser(uid);
        // TODO : show eula modal
        this.sendDomainInitEvent();
      }
    } 
  }

  createUser(uid : string = null) {
    
    const acctSettings : AccountSettingsI= {
      firstName: '',
      lastName: '',
      email: '',
      competency: 1,
      emailSubscribed: false,
      eulaSigned: false,
      emailVerified: true,
      promoCode: '',
      language: this._userLocale,
      video: true,
      timer: true,
      new_features: false,
    };
    
    const userData : UserI = {
      uid: uid,
      accountSettings : acctSettings
    };
    
    this._user = new User(userData, this._userLocale);
  }

  /* facebookAuth(beta: boolean) {
    this.flowFromBeta = true;
    var facebookAuthProvider = new FacebookAuthProvider();
    return this.AuthLogin(facebookAuthProvider, beta);
  } */

  googleAuth(beta: boolean, domains: string[]) {
    this.flowFromBeta = true;
    const googleAuthProvider = new GoogleAuthProvider();
    googleAuthProvider.setCustomParameters({
      prompt: 'select_account'
    });
    
    return this.AuthLogin(googleAuthProvider, beta, domains);
  }
  
  async AuthLogin(provider: GoogleAuthProvider, beta: boolean, domains: string[]) {
    await this.auth.signInWithPopup(provider).then(async (result) => {
      if (result.additionalUserInfo.isNewUser) {
        const acctSettings : AccountSettingsI = {
          firstName : Object(result.additionalUserInfo.profile)["given_name"],
          lastName : Object(result.additionalUserInfo.profile)["family_name"],
          email: result.user.email,
          competency: 1,
          language: this._userLocale,
          new_features: false,
          emailSubscribed: false,
          eulaSigned: false,
          emailVerified: true,
          promoCode: '',
          video: true,
          timer: true,
        }
        await this.updateNewProviderUserAcct(
          acctSettings,
          beta,
          domains);
      }
      
    });
  }

  async updateNewProviderUserAcct(acctSettings : AccountSettingsI, beta: boolean, domains: string[]) {
    this.updateUserAccountSettings(acctSettings, beta, domains).then(()=> {
      
      // Get FBUSer if user is not defined in AuthSvc. If not, simply load acctSettings to user
      /* if (!this._user) {
        this.getFBUser(this._userLocale).then((result) => {
          this._user = new User(result, this._userLocale);
          if(this.spinnerSvc.active) {
            this.spinnerSvc.hide();
          }
          this.sendLoginEvent();
        }).catch(error => {
          console.log("Auth Provider Update GetUser Error :", error);
          if(this.spinnerSvc.active) {
            this.spinnerSvc.hide();
          }
        });
      } else {
        this._user.accountSettings = this._newUser.accountSettings;
      } */
    }). catch(error => {
      console.log("Auth Provider Update User Error : ", error.message);
    });

  }

  async confirmSignIn(url) {
    const promise = new Promise((resolve, reject) => {
      if(this.auth.isSignInWithEmailLink(url)) {
        let email = window.localStorage.getItem('autonomeEmailForSignIn');
        if(!email) {
          email = window.prompt('Please provide your email for confirmation');
        }

        this.auth.signInWithEmailLink(email, url)
        .then(async (userCredential) => {
          window.localStorage.removeItem('autonomeEmailForSignIn');
          resolve(userCredential.user);
        })
        .catch(function(error) {
          const errorMessage = error.message;
          console.log(error);
          reject(errorMessage);
        });

      }
    });
    return promise;
  }

  emailPwLogin(email: string, pw: string) {
    const promise = new Promise((resolve, reject) => {
      this.auth.signInWithEmailAndPassword(email, pw)
      .then((result) => {
        if (!result.user.emailVerified) {
          resolve(false);
        }
        resolve(true);
      })
      .catch(error => {
        console.log("Login error: ", error.message);
        reject(error)
      });
    });
    return promise;
    
  }

  async passwordReset(email : string) {
    this.auth.sendPasswordResetEmail(email).then(result => {
      return result;
    })
  }

  private async oAuthLogin(provider: GoogleAuthProvider) {
    return await this.auth.signInWithPopup(provider);
  }

  async signOut() {
    await this.presenceSvc.setPresence('offline');
    this.auth.signOut().then(async () => { 
      this.User = null;
      this.loggedIn = false;
      this.sendSignOutEvent()
      return this.router.navigate(['/']);
    });
  }

  // Sign in with a custom token
  customSignIn(token) {
    return this.auth.signInWithCustomToken(token)
    .then(()=> window.close());
  }

  getAuthUser() {
    return firstValueFrom(this.auth.authState.pipe(first()));
  }

  public addOrganisation(id: string) {
    if(this.User.organisations) {
      this.User.organisations.push(id);
    } else {
      this.User.organisations = [];
      this.User.organisations.push(id);
      if(!this.User.defaultOrganisation) {
        this.User.defaultOrganisation = id;
      }
    }
    //this.addOrganisationFn(orgName).then((res) => {
      // add org details?
      //this.User.default_organisation = orgName;
    //});
  }

  showNewFeatures(createdDate: Date, lastLoginDate: Date) {
    if(utils.isSameDay(createdDate, new Date(Date.now()))) {
      // assume this is a new user so collect all new features json files
    } else {
      // assume this is not a new user there loop through all new features json files
      fetch('./assets/new_features/features.json').then(res => res.json())
      .then(async json => {
        if(json) {
          const featuresCollection = [];
          for(const featureJSON of json) {
            const featureDate = Object.keys(featureJSON)[0];
            if(new Date(parseInt(featureDate)) >= lastLoginDate){
              featuresCollection.push(featureJSON);
            }
          }
          if(featuresCollection.length > 0) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.id = "modal-new-features-component";
            dialogConfig.height = "auto";
            dialogConfig.width = "auto";
            dialogConfig.data = {
              featuresCollection : featuresCollection,
              language: this.User.accountSettings.language
            }
            this.matDialog.open(NewFeaturesComponent, dialogConfig).afterClosed().subscribe(() => {
              
            });
          }
        }
      });
    }
  }

  public get userLocale() {
    return this._userLocale;
  }
  public set userLocale(value) {
    this._userLocale = value;
  }

  get User() : User {
    return this._user;
  }
  set User(user: User){
    this._user = user;
  }

  get AuthUser() : Observable<User> {
    return this._authUser;
  }
  set AuthUser(authUser: Observable<User>) {
    this._authUser = authUser;
  }

  get loggedIn() {
    return this._loggedIn;
  }
  set loggedIn(loggedIn: boolean) {
    this._loggedIn = loggedIn;
  }

  get emailVerified() {
    return this._emailVerified;
  }
  set emailVerified(value : boolean) {
    this._emailVerified = value;
  }

  get flowFromBeta() {
    return this._flowFromBeta;
  }
  set flowFromBeta(value : boolean) {
    this._flowFromBeta = value;
  }

  public get hasRecipes(): boolean {
    return this._hasRecipes;
  }
  public set hasRecipes(value: boolean) {
    this._hasRecipes = value;
  }

  public get hasOrgs(): boolean {
    return this._hasOrgs;
  }
  public set hasOrgs(value: boolean) {
    this._hasOrgs = value;
  }

  public get isFamiliarizationHide(): boolean {
    return this._isFamiliarizationHide;
  }
  public set isFamiliarizationHide(value: boolean) {
    this._isFamiliarizationHide = value;
  }

  public get isFamiliarizationModalOpen(): boolean {
    return this._isFamiliarizationModalOpen;
  }
  public set isFamiliarizationModalOpen(value: boolean) {
    this._isFamiliarizationModalOpen = value;
  }

  public get presence$() {
    return this._presence$;
  }
  public set presence$(value) {
    this._presence$ = value;
  }

  public hasProduct(product_id: string): boolean {
    if (Object(this.User.data).products && Object(this.User.data).length > 0) {
        if(Object(this.User.data).products.find((obj) => {
          return obj === product_id;
        })) {
          return true;
        } else {
          return false;
        }
    } else {
      return false;
    }
  }

  private profileUpdateSubject = new Subject<User>();
  sendProfileUpdateEvent() {
    this.profileUpdateSubject.next(this.User);
  }
  getProfileUpdateEvent(): Observable<User>{
    return this.profileUpdateSubject.asObservable();
  }

  private passwordResetSubject = new Subject<User>();
  sendPasswordResetEvent() {
    this.passwordResetSubject.next(this.User);
  }
  getPasswordResetEvent(): Observable<User>{
    return this.passwordResetSubject.asObservable();
  }

  private subject = new Subject<User>();
  sendLoginEvent() {
    if(!this._loggedIn) { 
      this._loggedIn = true;
    }
    this.subject.next(this.User);
  }
  getLoginEvent(): Observable<User>{ 
    return this.subject.asObservable();   
  }

  private organisationInitSubject = new Subject<boolean>();
  sendOrgInitEvent() {
    this.organisationInitSubject.next(true);
  }
  getOrgInitEvent() : Observable<boolean>{
    return this.organisationInitSubject.asObservable();
  }

  private domainInitSubject = new Subject<User>();
  sendDomainInitEvent() {
    this.domainInitSubject.next(this.User);
  }
  getDomainInitEvent() : Observable<User>{
    return this.domainInitSubject.asObservable();
  }

  private emailNotVerifiedSubject = new Subject<User>();
  sendEmailNotVerifiedEvent() {
    this.emailNotVerifiedSubject.next(this.User);
  }
  getEmailNotVerifiedEvent() : Observable<User> {
    return this.emailNotVerifiedSubject.asObservable();
  }

  private eulaNotSignedSubject = new Subject<User>();
  sendEulaNotSignedEvent() {
    this.eulaNotSignedSubject.next(this.User);
  }
  getEulaNotSignedEvent() : Observable<User> {
    return this.eulaNotSignedSubject.asObservable();
  }

  private signOutSubject = new Subject<User>();
  sendSignOutEvent() {
    this.signOutSubject.next(this.User);
  }
  getSignOutEvent(): Observable<User>{ 
    return this.signOutSubject.asObservable();
  }

  async updateNewFeature(newFeatures: boolean) : Promise<object> {
    const updateNewFeaturesCall = this.fns.httpsCallable('httpsUpdateNewFeatures');
    return await firstValueFrom(updateNewFeaturesCall({newFeature: newFeatures}).pipe(first()));
  }

  async createNewEmailUser(email: string, password:string, beta:boolean, domains:string[]) : Promise<object> {
    const createNewEmailUserCall = this.fns.httpsCallable('httpsCreateNewEmailUser');
    return await firstValueFrom(createNewEmailUserCall({email: email, password: password, beta:beta, domains:domains, language:this._userLocale}).pipe(first()));
  }

  async verifyAuthCode(email: string, code: string) : Promise<string> {
    const verifyAuthCodeCall = this.fns.httpsCallable('httpsVerifyAuthCode');
    return await firstValueFrom(verifyAuthCodeCall({email: email, authCode: code}).pipe(first()));
  }

  async resendAuthCode(email: string) : Promise<object> {
    const resendAuthCodeCall = this.fns.httpsCallable('httpsResendAuthCode');
    return await firstValueFrom(resendAuthCodeCall({email: email, language:this._userLocale}).pipe(first()));
  }

  async getFBUser(language: string) : Promise<object> {
    const wfCall = this.fns.httpsCallable('httpsGetUser');
    return await firstValueFrom(wfCall({language: language}).pipe(first()));
  }

  updateUserEULASetting() : Promise<object> {
    const updateUserEULASettingCall = this.fns.httpsCallable('httpsUpdateUserEULASetting');
    return firstValueFrom(updateUserEULASettingCall({}).pipe(first()));
  }

  updateUserEmailSubscribeSetting(emailSubscribe: boolean) : Promise<object> {
    const updateUserEmailSubscribeSettingCall = this.fns.httpsCallable('httpsUpdateUserEmailSubscribeSetting');
    return firstValueFrom(updateUserEmailSubscribeSettingCall({emailSubscribe :emailSubscribe}).pipe(first()));
  }

  async updateUserAccountSettings(acctSettings: AccountSettingsI, beta: boolean =false, domains: string[]=[]) : Promise<object> {
    const updateAcctSettingsCall = this.fns.httpsCallable('httpsUpdateUserAcctSettings');
    return await firstValueFrom(updateAcctSettingsCall({acctSettings: acctSettings, beta: beta, domains:domains}).pipe(first()));
  }

  removeProduct(productId: string) : Promise<object> {
    const removeProductCall = this.fns.httpsCallable('removeProduct');
    return firstValueFrom(removeProductCall({productCode: productId}).pipe(first()));
  }
  
  async updateUserDomains(domains : string[]) : Promise<object> {
    const updateProductIdCall = this.fns.httpsCallable('httpsUpdateUserDomains');
    return await firstValueFrom(updateProductIdCall({domains : domains}).pipe(first()));
  }

  async updateUserItems(items: string[]) : Promise<object> {
    const updateUserItemsCall = this.fns.httpsCallable('httpsUpdateUserItems');
    return await firstValueFrom(updateUserItemsCall({items: items}).pipe(first()));
  }

  async deleteUserFields(fields: string[]) : Promise<object> {
    const deleteUserFieldsCall = this.fns.httpsCallable('httpsDeleteUserFields');
    return await firstValueFrom(deleteUserFieldsCall({fields: fields}).pipe(first()));  
  }

  async getCreateStripeCustomer(productId:string) : Promise<object> {
    const getCreateStripeCustomerCall = this.fns.httpsCallable('stripeGetCreateCustomer');
    return await firstValueFrom(getCreateStripeCustomerCall({productId: productId}).pipe(first()));
  }

  /* object */

  async sendVerificationEmail(value: boolean) : Promise<object> {
    const sendVerificationEmailCall = this.fns.httpsCallable('httpsSendVerificationEmail');
    return await firstValueFrom(sendVerificationEmailCall({language: this._userLocale, beta: value}).pipe(first()));
  }

  async updateEmailVerified(value: boolean) : Promise<object> {
    const updateEmailVerifiedCall = this.fns.httpsCallable('httpsUpdateEmailVerified');
    return await firstValueFrom(updateEmailVerifiedCall({emailVerified: value}).pipe(first()));
  }

  async updatePassword(newPW: string) : Promise<object> {
    const updatePasswordCall = this.fns.httpsCallable('httpsSetPassword');
    return await firstValueFrom(updatePasswordCall({newPW: newPW}).pipe(first()));
  }

  async authenticateWithCode(code: string) : Promise<object> {
    const updatePasswordCall = this.fns.httpsCallable('httpsSetPassword');
    return await firstValueFrom(updatePasswordCall({code: code}).pipe(first()));
  }

  async resendCode(email: string) : Promise<object> {
    const updatePasswordCall = this.fns.httpsCallable('httpsSetPassword');
    return await firstValueFrom(updatePasswordCall({email: email}).pipe(first()));
  }

  public async logoutFn() {
    const orgId = Object(this.User.data).defaultOrganisation ? Object(this.User.data).defaultOrganisation :
      (Object(this.User.data).organisations && Object(this.User.data).organisations.length > 0 ? Object(this.User.data).organisations[0] : '');
    const logoutCallFn = this.fns.httpsCallable('httpsLogout');
    await firstValueFrom(logoutCallFn({orgId:orgId}).pipe(first()));
    
  }

}