<div class="edit-member-modal-container">
    <div class="edit-member-modal-wrapper">
        <div class="top-wrapper">
            <div>{{member.last_name + ', ' + member.first_name}}</div>
            <div class="login-modal-title">
                <img (click)="closeDialog()" src="/assets/base_icons/icon_close.svg">
            </div>
        </div>

        <div class="form-wrapper">
            <div>
                <select [(ngModel)]="organisationSvc.currentOrganisation.members[index].role">
                    <option *ngFor="let role of roleTypes" [ngValue]="role">
                        {{role}}
                    </option>
                </select>
            </div>
        </div>
        <div>
            <button class="modal-text-button" (click)="removeMember()">Remove member</button> 
        </div>
    </div>
</div>
