import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../admin/services/auth.service';
import { ProductService } from '../../../core/services/product.service';
import { TranslatorService } from '../../../tools/services/translator.service';
import { PaymentService } from '../../services/payment.service';
import { DomainService } from 'src/app/core/services/domain.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements AfterViewInit {

  subtotalString : string;
  totalAmount : number = 0;
  totalAmountString : string;
  taxMap = new Map();
  gst: number;
  pst: number;
  hst: number;
  currencyFormatter: Intl.NumberFormat;
  percentageFormatter : Intl.NumberFormat;
  screenWidth: number;

  constructor(
    public productSvc : ProductService,
    public paymentSvc : PaymentService,
    public domainSvc : DomainService,
    public translatorSvc : TranslatorService,
    public authSvc : AuthService,
    private router : Router) { 
      this.currencyFormatter = new Intl.NumberFormat('en-CA', {
        style: 'currency', 
        currency: 'CAD',
      });
  
      this.percentageFormatter = new Intl.NumberFormat('en-CA', {
        style: 'percent',
        maximumFractionDigits: 3
      });
      this.getSubtotal();
      this.taxMap.clear();
      this.getTaxes();
    }

  ngAfterViewInit(): void {
    this.screenWidth = window.innerWidth;
  }

  getSubtotal() {
    this.subtotalString = this.currencyFormatter.format(this.paymentSvc.invoice.subTotal);
  }

  getTaxes() {
    this.paymentSvc.invoice.totalAmount = this.paymentSvc.invoice.subTotal;
    for(const tax_item of this.paymentSvc.invoice.taxes) {
      const taxText = tax_item.taxName + ' (' + this.percentageFormatter.format(tax_item.taxAmount) + ')';
      const tmpAmt = this.paymentSvc.invoice.subTotal * tax_item.taxAmount;
      this.taxMap.set(taxText, this.currencyFormatter.format(tmpAmt));
      this.paymentSvc.invoice.totalAmount = this.paymentSvc.invoice.totalAmount + tmpAmt;
    }
    
    this.totalAmountString = this.currencyFormatter.format(this.paymentSvc.invoice.totalAmount);
  }

  goToPayment() {
    this.router.navigate(['/payment']);
  }

  goToShipping() {
    this.router.navigate(['/shipping']);
  }

  goToShoppingCart() {
    this.router.navigate(['/shopping-cart']);
  }

}
