import { WorkflowService } from '../../services/workflow.service';
import { Step } from '../../../models/step.model';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Swiper } from 'swiper';
import { Subscription } from 'rxjs';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { Router } from '@angular/router';
import { DomainService } from '../../services/domain.service';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit, AfterViewInit, OnDestroy {

  //currentStepIndex : number;
  steps: Step[] = [];
  initialCheckStepId : number;
  displaySwiper : Swiper;
  iHeightText : string;
  competency_level : number;
  hideTracker : boolean = false;

  clickEventSubscription:Subscription;
  continueWorkflowEventSubscription: Subscription;
  disableSwiperEventSubscription:Subscription;
  enableSwiperEventSubscription:Subscription;
  disableTouchMoveEventSubscription:Subscription;
  enableTouchMoveEventSubscription: Subscription;

  constructor(
    public workflowSvc : WorkflowService,
    public translatorSvc: TranslatorService,
    private domainSvc : DomainService,
    private router: Router,
    private spinnerSvc : SpinnerOverlayService) {
    if(this.enableTouchMoveEventSubscription === undefined) {
      this.enableTouchMoveEventSubscription = this.workflowSvc.getEnableTouchMoveEvent()
      .subscribe(() => {
        if(!this.displaySwiper.allowTouchMove) {
          this.displaySwiper.allowTouchMove = true;
        }
      });
    }

    if(this.disableTouchMoveEventSubscription === undefined) {
      this.disableTouchMoveEventSubscription = this.workflowSvc.getDisableTouchMoveEvent()
      .subscribe(() => {
        if(this.displaySwiper.allowTouchMove) {
          this.displaySwiper.allowTouchMove = false;
        }
      });
    }

    if(this.disableSwiperEventSubscription === undefined) {
      this.disableSwiperEventSubscription = this.workflowSvc.getDisableSwiperEvent()
      .subscribe(() => {
        if(this.displaySwiper.allowSlideNext && this.displaySwiper.allowSlidePrev) {
          this.displaySwiper.allowSlideNext = this.displaySwiper.allowSlidePrev = false;
        }
      });
    }

    if(this.enableSwiperEventSubscription === undefined) {
      this.enableSwiperEventSubscription = this.workflowSvc.getEnableSwiperEvent()
      .subscribe(() => {
        if(!this.displaySwiper.allowSlideNext && !this.displaySwiper.allowSlidePrev) {
          this.displaySwiper.allowSlideNext = this.displaySwiper.allowSlidePrev = true;
        }
      });
    }

    if(this.clickEventSubscription === undefined || this.clickEventSubscription.closed) {
      this.clickEventSubscription = this.workflowSvc.getNextStepEvent()
      .subscribe(() => {
        this.next_step();
      }); 
    }

    if(this.continueWorkflowEventSubscription === undefined || this.continueWorkflowEventSubscription.closed) {
      this.continueWorkflowEventSubscription = this.workflowSvc.getContinueWorkflowEvent()
      .subscribe((id) => {
        this.displaySwiper.slideTo(id);
      }); 
    }
  }
  ngOnDestroy(): void {
    this.clickEventSubscription.unsubscribe();
    this.disableSwiperEventSubscription.unsubscribe();
    this.enableSwiperEventSubscription.unsubscribe();
    this.disableTouchMoveEventSubscription.unsubscribe();
    this.enableTouchMoveEventSubscription.unsubscribe();
  }

  ngOnInit(): void {
    if(this.workflowSvc.displaySteps[0].job_video) {
      this.hideTracker = true;
    }
  }

  ngAfterViewInit() : void {
    this.displaySwiper = new Swiper("#workflow-display-swiper-container", {
      slideClass : 'swiper-slide',
      //autoHeight: true, //enable auto height
      spaceBetween: 20,
      slidesPerView: 1,
      loop: false,
      });
    this.displaySwiper.slideTo(this.workflowSvc.currentStepId);
    this.iHeightText = (window.innerHeight - 88).toString() + 'px';
    /* if(this.steps[this.currentStepIndex].time > 0) {
      this.workflowSvc.startTimerEvent();
    } */

    if(this.spinnerSvc.active) {
      this.spinnerSvc.hide(1);
    }
  }

  goToRecipes() {
    if(this.domainSvc.currentDomain.id === 'cooking') {
      // pause stop all timers
      this.workflowSvc.pauseTimers();
      this.router.navigate(['recipes']);
    } else {
      this.router.navigate(['familiarization']);
    }
  }

  onHeightResize(event) {
    this.iHeightText = (event.target.innerHeight - 88).toString() + 'px';
  }
  
  next_step() {
    this.resetSwiperNavigation();
    this.displaySwiper.slideTo(this.workflowSvc.currentStepId);
  }

  private resetSwiperNavigation() {
    if(!this.displaySwiper.allowTouchMove) {
      this.displaySwiper.allowTouchMove = true;
    }
    if(!this.displaySwiper.allowSlideNext && !this.displaySwiper.allowSlidePrev) {
      this.displaySwiper.allowSlideNext = this.displaySwiper.allowSlidePrev = true;
    }
  }

}
