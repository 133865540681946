<div class="capture-cam">
    <div class="header-container">
        <div></div>
        <h4>{{translatorSvc.getLabel('takePhotoHeader')}}</h4>
        <img (click)="closeModal()" src="/assets/base_icons/icon_close_blue.svg"/>
    </div>
    <div class="display-container" [class.unsetHeight]="imageTaken">
        <video [class.display-none]="imageTaken" #video id="video" autoplay></video>
        <canvas [class.display-none]="!imageTaken" #canvas id="canvas"></canvas>
        <div *ngIf="!imageTaken" class="capture-square"></div>
    </div>
    
    <button *ngIf="!imageTaken" class="autonome-button-orange" (click)="captureImage();"><p>{{translatorSvc.getLabel('takePhoto')}}</p></button>
    <div *ngIf="imageTaken" class="button-container">
        <button class="autonome-button-orange" (click)="resetImage();"><p>{{translatorSvc.getLabel('reTakePhoto')}}</p></button>
        <button class="autonome-button-orange" (click)="saveImage();"><p>{{translatorSvc.getLabel('savePhoto')}}</p></button>
    </div>
    
</div>
