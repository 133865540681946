import { Tool } from '../../models/tool.model';
import { Injectable } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { AuthService } from '../../admin/services/auth.service';
import { first, firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })

export class ToolService {

    private _tools: Tool[];

    constructor(private fns : AngularFireFunctions, private authSvc: AuthService) {
        this._tools = [];
    }

    public async init() {
        this._tools = [];
        return new Promise((resolve, reject) => {
            this.getToolsFn().then((toolsData) => {
                for(const tool of Object(toolsData)) {
                    this._tools.push(new Tool(tool));
              }
              resolve(true)
            }).catch((error) => {
                console.error('error #%d', error);
                reject(error);
            });
        });
    }

    public update() {
        for(const tool of this._tools) {
            tool.setDescription(this.authSvc.User.accountSettings.language);
      }
    }

    public getTool(tool_name : string) {
        return this._tools.find((obj) => { return obj.id === tool_name });
    }

    public getWorkflowToolList(tools : string[]) {
        this.update();
        const toolList: Tool[] = [];
        for(const tool of tools) {
            const newTool = this._tools.find(toolObj => {
                return toolObj.id === tool;
            });
            if(newTool) {
                newTool.setDescription(this.authSvc.User.accountSettings.language);
                toolList.push(newTool);
            }
        }
        return toolList;
    }

    private async getToolsFn() : Promise<object> {
        const getToolsCall = this.fns.httpsCallable('getAllTools');
        return await firstValueFrom(getToolsCall({}).pipe(first()));
      }

    public get tools() {
        return this._tools;
    }
    public set tools(tools : Tool[]) {
        this._tools = tools;
    }
}
