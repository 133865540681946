import { ImagesService } from './../../../tools/services/images.service';
import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';


@Component({
  selector: 'app-image-capture',
  templateUrl: './image-capture.component.html',
  styleUrls: ['./image-capture.component.scss']
})
export class ImageCaptureComponent implements AfterViewInit {

  @ViewChild("video")
  public video: ElementRef<HTMLVideoElement>;
  @ViewChild("canvas")
  public canvas: ElementRef;

  videoDevice : MediaDeviceInfo;

  public imageTaken = false;

  
  
  constructor(
    @Inject(MAT_DIALOG_DATA) modalData: object,
    public dialogRef: MatDialogRef<ImageCaptureComponent>,
      private imageSvc: ImagesService,
      private workflowSvc : WorkflowService,
      private spinnerSvc : SpinnerOverlayService,
      public translatorSvc: TranslatorService
  ) {
    this.videoDevice = Object(modalData).videoDevice;
  }

  ngAfterViewInit(): void {

    const constraints = {
      video: {
        width: 640,
        height: 480,
        facingMode: { exact: "environment" },
      },

    };

    if('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
      const updatedConstraints = {
        ...constraints,
        deviceId: {
          exact: this.videoDevice.deviceId
        }
      };

      this.startStream(updatedConstraints);
      
    }
      
  }

  startStream = async (constraints) => {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    this.handleStream(stream);
  }

  handleStream = (stream) => {
    const _video = this.video.nativeElement;
    _video.srcObject = stream;
    _video.play();
  }

  captureImage() {
    this.canvas.nativeElement.getContext("2d")
      .drawImage(this.video.nativeElement, 140, 60, 400, 400, 0, 0, 300 ,150);
    this.imageTaken = true;
  }

  resetImage() {
    this.imageTaken = false;
  }

  saveImage() {
    this.spinnerSvc.show('Saving Recipe Image');
    
    const imageData = {
      recipe_name: this.workflowSvc.currentWorkflow.id,
      data: this.canvas.nativeElement.toDataURL()
    }
    this.imageSvc.setRecipeImage(imageData).then(() => {
      this.workflowSvc.selectedWorkflow.recipe_jpg = imageData.data;
      this.spinnerSvc.hide();
    }).catch(error => {
      console.log("image capture save error : ", error);
    });
  }

  closeModal() {
    this.dialogRef.close(false);
  }

}
