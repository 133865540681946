import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { AuthService } from 'src/app/admin/services/auth.service';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';

@Component({
  selector: 'app-auth-code',
  templateUrl: './auth-code.component.html',
  styleUrls: ['./auth-code.component.scss']
})
export class AuthCodeComponent {

  public digits: string[];
  public modalData: any;
  public wrongCode: boolean = false;
  public codeExpired: boolean = false;
  public unknownError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AuthCodeComponent>,
    @Inject(MAT_DIALOG_DATA) modalData: any,
    public translatorSvc: TranslatorService,
    private authSvc: AuthService,
    private spinnerSvc : SpinnerOverlayService
  ) {
    this.modalData = modalData;
    this.digits = [null,null,null,null,null,null];
  }

  newValue(event) {
    if(event.data) {
      if(!event.target.nextSibling) {
        if (!this.goToNextEmptySibling(event.target.parentElement.children[0]).nextSibling) {
          this.submitCode();
        }
      } else {
        this.goToNextEmptySibling(event.target.nextSibling).focus();
      }
    }
  }

  goToNextEmptySibling(sibling: HTMLDataElement) {
    if(sibling.value && sibling.nextSibling) {
      return this.goToNextEmptySibling(sibling.nextSibling as HTMLDataElement);
    } else {
      return sibling;
    }
  }

  submitCode() {
    this.wrongCode = false;
    this.codeExpired = false;
    this.unknownError = false;
    this.spinnerSvc.show(this.translatorSvc.getLabel('verifyingAuthCode'));
    this.authSvc.verifyAuthCode(this.modalData.email, this.digits.toString()).then((result)=> {
      switch(result) {
        case 'authCodeOk':
          this.closeDialog();
          this.authSvc.emailPwLogin(this.modalData.email, this.modalData.pw);
          break;
        case 'wrongCode':
          this.wrongCode = true;
          this.resetDigit();
          break;
        case 'timeStampExpired':
          this.codeExpired = true;
          break;
        default:
          this.unknownError = true;
      }
      this.spinnerSvc.hide();
    });
  }

  resetDigit() {
    for (let digit of this.digits) {
      digit = null;
    }
  }

  resendCode() {
    this.authSvc.resendAuthCode(this.modalData.email).then((result) => {
      this.resetDigit();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
