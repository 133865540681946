import { TranslatorService } from '../../../tools/services/translator.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-email-sent-confirm',
  templateUrl: './email-sent-confirm.component.html',
  styleUrls: ['./email-sent-confirm.component.scss']
})
export class EmailSentConfirmComponent implements OnInit {

  emailSentMsg : string;
  emailSentBtnTxt : string;
  _userLocale : string;

  constructor(
    public dialogRef: MatDialogRef<EmailSentConfirmComponent>,
    public translatorSvc : TranslatorService) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close(false);
  }

}
