<div class="ingredient-tool-container">
    <div *ngIf="authSvc.User.accountSettings.competency < 1 then sous_chef else chef"></div>
    <div class="next-button-container"
    (touchstart)='disableTouchMove()'
    (touchend)='enableTouchMove()'
    (mouseenter)='overButton()'
    (mouseleave)='leaveButton()'>
        <img class="next-step-button" src="/assets/base_icons/icon_step_check.svg" [class.hideNextStepBtn]="!step.current" (click)="next_step()" alt="Go to next step">
    </div> 
</div>

<ng-template #chef>
    <div class="recipe-detail-container">
        <div *ngIf="displaySvc.screenWidth > 600 then chefTablet else chefPhone"></div>
    </div>
</ng-template>

<ng-template #chefTablet>
    <div class="chefTablet-ingredient-container">
        <div class="chefTablet-ingredient-wrapper">
            <div class="chefTablet-ingredient-picto-wrapper">
                <img src="/assets/pictos/ingredients/{{step.ingredient!.picto!}}.svg" alt={{step.ingredient!.description!}}>
            </div>
            <div class="chefTablet-indredient-description-wrapper">
                <h3 class="chefTablet-ingredient-description">{{step.ingredient!.description!}}</h3>
            </div>
            <div class="chefTablet-qty-wrapper">
                <div class="chefTablet-tools-wrapper">
                    <img *ngFor="let image of toolPictoList" src="{{image}}">
                </div>
                <div class="qty-text-wrapper">
                    <h4 class="chefTablet-qty-text">{{measureText}}</h4>
                </div>
            </div>
        </div>
        <div class="chefTablet-action-wrapper">
            <div class="action-list">
                <app-action-list-item class="action-list-item" *ngFor="let stepItem of step.action_steps" [stepItem]="stepItem"></app-action-list-item>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #chefPhone>
    <div class="chefPhone-ingredient-container">
        <div class="chefPhone-ingredient-wrapper" [class.h100]="toolPictoListEmpty">
            <div class="chefPhone-ingredient-picto-wrapper" [class.flex1]="toolPictoListEmpty">
                <img src="/assets/pictos/ingredients/{{step.ingredient!.picto!}}.svg" alt={{step.ingredient!.description!}}>
                <div class="chefPhone-indredient-description-wrapper">
                    <h4 class="chefPhone-ingredient-description">{{step.ingredient!.description!}}</h4>
                </div>
            </div>
            <div class="chefPhone-qty-wrapper">
                <div class="chefPhone-tools-wrapper">
                    <img *ngFor="let image of toolPictoList" src="{{image}}">
                </div>
                <div class="qty-text-wrapper">
                    <h4 class="chefPhone-qty-text">{{measureText}}</h4>
                </div>
            </div>
        </div>
        <hr [class.displayNone]="toolPictoListEmpty">
        <div class="chefPhone-action-wrapper" [class.displayNone]="toolPictoListEmpty">
            <div class="action-list">
                <app-action-list-item class="action-list-item" *ngFor="let stepItem of step.action_steps" [stepItem]="stepItem"></app-action-list-item>
            </div>
        </div>
    </div>
    
</ng-template>

<ng-template #sous_chef>
    <div class="ingredient-tool-wrapper">
        <div class="ingredient-wrapper">
            <div class="ingredient-picto-wrapper">
                <span class="helper"></span>
                <img src="/assets/pictos/ingredients/{{step.ingredient!.picto!}}.svg" alt={{step.ingredient!.description!}}>
            </div>
            <div class="ingredient-description-wrapper">
                <h2 *ngIf="displaySvc.screenWidth > 600" class="ingredient-description">{{step.ingredient!.description!}}</h2>
                <h3 *ngIf="displaySvc.screenWidth <= 600" class="ingredient-description">{{step.ingredient!.description!}}</h3>
            </div>
        </div>
        <div class="qty-wrapper">
            <div class="tools-wrapper">
                <img *ngFor="let image of toolPictoList" src="{{image}}">
            </div>
            <div class="qty-text-wrapper">
                <h2 *ngIf="displaySvc.screenWidth > 600"  class="qty-text">{{measureText}}</h2>
                <h3 *ngIf="displaySvc.screenWidth <= 600" class="qty-text">{{measureText}}</h3>
            </div>
        </div>
    </div>
</ng-template>
