<div class="workflow-item-detail-container">
    <div *ngIf="editorSvc.addWorkflowItemSelectedItemType === 'actions' then action else nextDecision"></div>
</div>

<ng-template #action>
    <!-- <div class="workflow-item-detail-picto">
        <img src="{{editorSvc.addWorkflowItemSelectedItem.action.picto}}">
    </div>
    <div class="workflow-item-detail-name">{{editorSvc.addWorkflowItemSelectedItem.action.action_name}}</div>

    <div class="workflow-item-detail-descriptions-container">
        <div class="workflow-item-detail-descriptions-wrapper">
            <div class="workflow-item-detail-description">{{editorSvc.addWorkflowItemSelectedItem.action.descriptions.en.singular}}</div>
            <div class="workflow-item-detail-description">{{editorSvc.addWorkflowItemSelectedItem.action.descriptions.en.plural}}</div>
            <div class="workflow-item-detail-description">{{editorSvc.addWorkflowItemSelectedItem.action.descriptions.fr.singular}}</div>
            <div class="workflow-item-detail-description">{{editorSvc.addWorkflowItemSelectedItem.action.descriptions.fr.plural}}</div>
        </div>
        <div class="workflow-item-detail-descriptions-wrapper">
            <div class="workflow-item-detail-description">{{editorSvc.addWorkflowItemSelectedItem.action.video}}</div>
            <div class="workflow-item-detail-description">svg_string</div>
            
        </div>
    </div>
    <div class="workflow-item-detail-button-container">
        <div class="workflow-item-detail-button" (click)="closeDialog()">Cancel</div>
        <div class="workflow-item-detail-button" (click)="addItemToWorkflow()">Add to Workflow</div>
    </div>
     -->
</ng-template>

<ng-template #nextDecision>
    <div *ngIf="editorSvc.addWorkflowItemSelectedItemType === 'ingredients' then ingredient else verification"></div>
</ng-template>

<ng-template #ingredient>
    
</ng-template>

<ng-template #verification>
    
</ng-template>
