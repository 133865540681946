<div class="auth-code-container">
    <div class="auth-code-text">
        {{translatorSvc.getLabel(modalData.message)}}
    </div>
    <div class="auth-code-wrapper">
        <input class="auth-code-digit-container" onKeyPress="if(this.value.length>1) return false" #digit0 [(ngModel)]="digits[0]" (input)="newValue($event)" type="number" maxlength="1" required autofocus>
        <input class="auth-code-digit-container" onKeyPress="if(this.value.length>1) return false" #digit1 [(ngModel)]="digits[1]" (input)="newValue($event)" type="number" maxlength="1" required>
        <input class="auth-code-digit-container" onKeyPress="if(this.value.length>1) return false" #digit2 [(ngModel)]="digits[2]" (input)="newValue($event)" type="number" maxlength="1" required>
        <input class="auth-code-digit-container" onKeyPress="if(this.value.length>1) return false" #digit3 [(ngModel)]="digits[3]" (input)="newValue($event)" type="number" maxlength="1" required>
        <input class="auth-code-digit-container" onKeyPress="if(this.value.length>1) return false" #digit4 [(ngModel)]="digits[4]" (input)="newValue($event)" type="number" maxlength="1" required>
        <input class="auth-code-digit-container" onKeyPress="if(this.value.length>1) return false" #digit5 [(ngModel)]="digits[5]" (input)="newValue($event)" type="number" maxlength="1" required>
    </div>
    <div *ngIf="wrongCode">Incorrect code. Please try again</div>
    <div *ngIf="codeExpired">Your code has expired. Click the link below to send a new one</div>
    <div *ngIf="unknownError">Unknown Error</div>
    <div class="auth-code-resend-code" (click)="resendCode()">
        {{translatorSvc.getLabel("authCodeResend")}}
    </div>

</div>
