import { ActionI } from '../interfaces/action.interface';

export class Action implements ActionI {

    action_name : string;
    descriptions : object;
    svg_string : string;
    video : string;
    picto: string;

    private _description : string;
    
    /* private _video_running : boolean = false;
    private _key_words : string[];
    private _tools : Tool[];
    private _competencies : Array<Competency>; */
    
    
    constructor(value : object) {
        const actionData = Object(value);
        this.descriptions = actionData.descriptions;
        this.action_name = actionData.action_name;
        this.svg_string = actionData.svg_string;
        this._description = '';
        if(actionData.video) {
            this.video = actionData.video;
        } 
        this.picto = actionData.picto;
    }

    get description() {
        return this._description;
    }
    set description(decription : string) {
        this._description = decription;
    }

    setDescription(lang : string, qty: number) {
        if(this.descriptions) {
            if(qty && qty > 1 && this.descriptions[lang].plural !== '') {
                this._description = this.descriptions[lang].plural;
            } else {
                this._description = this.descriptions[lang].singular;
            }
        }
        

        // POC key words
        /*const en_description : string = this._description_array.find(({language}) => language === 'en');
        for (const key_word of this._key_words) {
            this._description = this._description.replace(key_word, '<a (click)="openKeyWordModal('+key_word+')">'+key_word+'</a>');
        }
        */
    }

}