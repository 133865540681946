import { AddressI } from "../interfaces/address.interface";
import { MemberI } from "../interfaces/member.interface";
import { OrganisationI } from "../interfaces/organisation.interface";
import { Domain } from "./domain.model";
import { User } from "./user.model";

export class Organisation {
    private _admin: User;
    private _address: AddressI;
    private _contact_email: string;
    private _contact_phone: string;
    private _domains: Domain[];
    private _title : string;
    private _id: string;
    private _name: string;
    private _picto: string;
    private _members: MemberI[];
    private _data: object;
    
    constructor(organisation : OrganisationI) {
        this._id = organisation.id;
        if(organisation.data) {
            this._title = organisation.data.name;
            this._contact_email = organisation.data.contact_email;
        }
        this._data = organisation.data;
    }

    public get admin(): User {
        return this._admin;
    }
    public set admin(value: User) {
        this._admin = value;
    }

    public get address(): AddressI {
        return this._address;
    }
    public set address(value: AddressI) {
        this._address = value;
    }

    public get contact_email(): string {
        return this._contact_email;
    }
    public set contact_email(value: string) {
        this._contact_email = value;
    }

    public get contact_phone(): string {
        return this._contact_phone;
    }
    public set contact_phone(value: string) {
        this._contact_phone = value;
    }

    public get domains(): Array<Domain> {
        return this._domains;
    }
    public set domains(value: Array<Domain>) {
        this._domains = value;
    }
    
    public get title(): string {
        return this._title;
    }
    public set title(value: string) {
        this._title = value;
    }

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }

    public get picto(): string {
        return this._picto;
    }
    public set picto(value: string) {
        this._picto = value;
    }

    public get members(): MemberI[] {
        return this._members;
    }
    public set members(value: MemberI[]) {
        this._members = value;
    }

    public get data(): object {
        return this._data;
    }
    public set data(value: object) {
        this._data = value;
    }

    /**
     * Return interface of organisation data
     * @param data object received from FB fns calls
     * Format: {
     *  id: string,
     *  data: {
     *      contact_email: string,
     *      cooking_kits: number,
     *      domains: string[],
     *      members: MemberInterface[],
     *      name: string,
     *      subscriptions: number
     *  }
     * }
     */
    getInterface(data: object) : OrganisationI {
        return {
            id: Object(data).id,
            data: {
                contact_email : Object(data).data.contact_email,
                cooking_kits: Object(data).data.cooking_kits,
                domains: Object(data).data.domains,
                members: Object(data).data.members,
                name: Object(data).data.name,
                subscriptions: Object(data).data.subscriptions 
            }
        }
    }

    getEmptyInterface(id: string) : OrganisationI {
        return this.getInterface({
            id : id,
            data: {
                contact_email : '',
                cooking_kits: 0,
                domains: [],
                members: [],
                name: '',
                subscriptions: 0
            }
        })
    }


    updateDetails(orgDetails: OrganisationI) {
        this._title = orgDetails.data.name;
        // call function to change the meber to admin   this._admin = orgDetails.data.admin;
        this._contact_email = orgDetails.data.contact_email;
    }

    updateLanguage(language: string) {
        for (const domain of this._domains) {
            domain.setDescription(language);
        }
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            contact_email: this.contact_email,
            domains: this.domains,
            members: this.members,
          };
    }

}