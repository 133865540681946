import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OrganisationService } from 'src/app/organisations/services/organisation.service';
import { OrgRole } from 'src/app/enums/org-roles.enum';
import { TranslatorService } from 'src/app/tools/services/translator.service';

@Component({
  selector: 'app-add-organisation-member',
  templateUrl: './add-organisation-member.component.html',
  styleUrls: ['./add-organisation-member.component.scss']
})
export class AddOrganisationMemberComponent {

  public email : string;
  public role : string;
  public roleTypes;
  private emailValid: boolean;

  constructor(
    public dialogRef: MatDialogRef<AddOrganisationMemberComponent>,
    private organisationSvc: OrganisationService,
    public translatorSvc: TranslatorService) { 
      this.roleTypes = Object.values(OrgRole).filter(value => typeof value === 'string');
      this.role = 'viewer';
    }

  addMember() {
    // verify if this email already exists in the organisation
    if(!this.organisationSvc.currentOrganisation.members.find(member => member.email === this.email)) {
      if(this.emailValid) {
        // send email invite to member
        this.organisationSvc.inviteMemberToOrganisation(this.email, this.role).then(() => {
          // send email with temporary pw
          this.closeDialog();
        }).catch(() => {

        })
      }
    } else {
      // TODO : throw error on email already exidsts in org
    }
  }

  validateEmail(email : string) {
    const regularExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

  emailFocusOut(email: string) {
    this.emailValid = this.validateEmail(email);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
