import { Step } from '../../../models/step.model';
import { StepWrapperComponent } from '../../../interfaces/step-wrapper.component';
import { AfterContentInit, Component, Input } from '@angular/core';
import { WorkflowService } from '../../services/workflow.service';
import { AuthService } from 'src/app/admin/services/auth.service';

@Component({
  templateUrl: './ingredient-qty.component.html',
  styleUrls: ['./ingredient-qty.component.scss']
})
export class IngredientQtyComponent implements StepWrapperComponent, AfterContentInit {
  @Input() step : Step;

  pictoString : string[] = [];
  folderString = '/assets/pictos/ingredients/';
  competency: number;

  constructor(public wfSvc: WorkflowService,
    public authSvc : AuthService) {

  }

  next_step() {
    this.wfSvc.sendNextStepEvent(this.step._display_id, true);
  }

  overButton() {
    // handle mouse and touch events through eventListener
    this.wfSvc.sendDisableSwiperEvent();
  }

  leaveButton() {
    this.wfSvc.sendEnableSwiperEvent();
  }

  disableTouchMove() {
    this.wfSvc.sendDisableTouchMoveEvent();
  }

  enableTouchMove() {
    this.wfSvc.sendEnableTouchMoveEvent();
  }

  ngAfterContentInit(): void {
    if(this.step.quantity > 0) {
      for (let i = 0; i < this.step.quantity; i++) {
        this.pictoString.push(this.folderString + this.step.ingredient.picto + '.svg');
      }
    } else {
      this.pictoString.push(this.folderString + this.step.ingredient.picto + '.svg');
    }
    

  }

}
