<div class="modal-container">
    <div class="modal-swiper-wrapper">
        <div id="new-feature-swiper-container" class="new-feature-swiper-container">
            <div class="swiper-wrapper swiper-adjustment">
                <div class="swiper-slide" *ngFor="let feature of featureList">
                    <h3 class="swiper-slide-header">{{feature.title}}</h3>
                    <div class="swiper-slide-text">{{feature.description}}</div>
                </div>
            </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-pagination"></div>
    </div>
    
    <div class="modal-controls-wrapper">
        <button class="modal-text-button" (click)="closeModal()">{{translatorSvc.getLabel('close')}}</button>
    </div>
    <div>
        <input type="checkbox" id="newFeatureInput" [(ngModel)]="newFeatures">
        <label for="newFeatureInput">{{translatorSvc.getLabel('doNotShowAgain')}}</label><br>
    </div>
</div>
