import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      required: 'Required',
      invalidCreditCard: 'Is invalid credit card number',
      invalidEmailAddress: 'Invalid email address',
      invalidPassword: 'Invalid password. Password must be at least 8 characters long, contain a number, a captial letter and a symbol',
      minlength: `Minimum length ${validatorValue.requiredLength}`
    };

    if(validatorName) {
      return config[validatorName];
    }
  }

  creditCardValidator(control) {
    // Visa, MasterCard, AE, DD, Discover, JCB
    if(
      control.value.match(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
      )
    ) {
      return null;
    } else {
      return { invalidCreditCard: true } ;
    }
  }

  emailValidator(value: string) {
    // RFC 2822 compliant regex
    if(
      value.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      return true;
    } else {
      return false ;
    }
  }

  passwordValidator(value: string) {
    // {8, 100}     - Assert pw is between 8 and 100 chars
    // (?=.*[0-9])  - Assert a string has at least one number, cacapital lettre and symbol
    if(
      value.match(
        /^(?=.*[A-Z0-9!?@#$%^&*])[a-zA-Z0-9!?@#$%^&*]{8,100}$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
