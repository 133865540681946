<div class="action-container">
    <div class="action-wrapper">
        <div class="action-picto-wrapper">
            <img src="/assets/pictos/actions/{{step.action!.picto!}}.svg" alt={{step.action!.description!}}>
        </div>
        <div class="action-text-wrapper">
            <h2 class="action-description">{{actionDescription!}}</h2>
            <!--<div class="video-player-dots" [class.show-video]="!hideTime">
                <video #videoPlayerDots preload="auto" autoplay muted loop>
                    <source src="/assets/videos/dots.mp4" type="video/mp4">
                </video>
            </div> -->
            <h2 class="timerH2" [class.hideTimer]="hideTime">{{timerSvc.timer_minutes}}:{{timerSvc.timer_seconds}}</h2>
            <div [class.hideTimer]="hideTime" class="modal-control-container">
                <div class="modal-control-button"
                (touchstart)='disableTouchMove()'
                (touchend)='enableTouchMove()'
                (mouseenter)='overButton()'
                (mouseleave)='leaveButton()' 
                [style.background-image]="'url(' + timerSvc.timerButton + ')'" 
                (click)="startPauseTimer()"></div>
                <div class="stopBtn modal-control-button right-button"
                (touchstart)='disableTouchMove()'
                (touchend)='enableTouchMove()'
                (mouseenter)='overButton()'
                (mouseleave)='leaveButton()'
                (click)="stopTimer()"></div>
            </div> 
        </div>
        
    </div>
    <div class="next-button-container"
        (touchstart)='disableTouchMove()'
        (touchend)='enableTouchMove()'
        (mouseenter)='overButton()'
        (mouseleave)='leaveButton()'>
        <img id="nextStepBtn" class="next-step-button"  [class.hideNextStepBtn]="!step.current || hideNextStepBtn" src="/assets/base_icons/icon_step_check.svg" (click)="next_step()" alt="Go to next step">
    </div> 
</div>
