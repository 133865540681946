<div class="domains-container" *ngIf="domainSvc.selectedDomain !== undefined" [class.display-none]="spinnerSvc.active">
    <div *ngIf="screenWidth <= 660 then phone else tablet"></div>
</div>

<ng-template #phone>
    <div class="phone-domain-top-wrapper">
        <div class="phone-domain-container">
            <div class="phone-domain-label">
                <h3>{{domainSvc.selectedDomain.title}}</h3>
            </div>
            <div class="phone-selected-domain-container">
                <div class="phone-selected-domain-container-upper">
                    <div #domain_description_container class="phone-selected-domain-container-upper-left" (scroll)="onDescriptionScroll()" >
                        <div class="phone-selected-container-picto">
                            <div class="phone-selected-container-picto-wrapper">
                                <img src="/assets/pictos/domains/{{domainSvc.selectedDomain.picto!}}.svg" alt={{domainSvc.selectedDomain.title!}}>
                            </div>
                            
                            <p>{{domainSvc.selectedDomain.description}}</p>
                            <div *ngIf="domainSvc.selectedDomain.extra_information"
                                class="domain-extra-info" (click)="openExtraInfoModal()">
                                {{domainSvc.selectedDomain.extra_information['link_text_'+authSvc.User.accountSettings.language]}}
                            </div>
                            <!-- <div class="domain-promo" *ngIf="!productSvc.itemIsInCart(domainSvc.currentDomain.id)
                                && !domainSvc.userHasPromoProduct()
                                && domainSvc.promoText" (click)="openDomainPromoModal()">
                                <div class="domain-promo-text">{{domainSvc.promoText}}</div>
                                <div id="badge-ribbon"></div>
                            </div> -->
                        </div>
                    </div>
                    <div class="phone-chevron-container">
                        <img [class.display-none]="!description_overflow" class="description-chevron-down" (click)="scrollDescriptionsDown()" src="/assets/base_icons/icon_chevron_down.svg">
                    </div>
                    <div class="phone-selected-domain-container-buttons">
                        <button *ngIf="!domainSvc.selectedDomain.purchased && 
                                !domainSvc.selectedDomain.public &&
                                domainSvc.selectedDomain.type === 1" 
                            (click)="purchaseDomain()"
                            style="margin-bottom: 12px;" 
                            class="modal-text-button">{{translatorSvc.getLabel('buyRecipeDomain')}} 5.99$ (CAN)/ 
                            {{translatorSvc.getLabel(domainSvc.selectedDomain.price_subscription_term)}}</button>
                        <button *ngIf="domainSvc.selectedDomain.type === 1" class="modal-text-button" (click)="goToDomain()">{{translatorSvc.getLabel('viewRecipes')}}</button>
                        <button *ngIf="domainSvc.selectedDomain.type === 5" class="modal-text-button" (click)="goToDomain()">{{translatorSvc.getLabel('viewJobExamples')}}</button>
                        <button *ngIf="domainSvc.selectedDomain.type !== 1 && domainSvc.selectedDomain.type !== 5"class="modal-text-button" (click)="goToDomain()">{{translatorSvc.getLabel('goToDomain')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div #phone_domain_container_domains class="phone-domain-container-domains"  (scroll)="onDomainScroll()">
        <ng-container *ngFor="let domain of domainSvc.domains">
            <app-domain-item *ngIf="isJobExample(domain)" class="domain-swiper-slide"  [domain]="domain"></app-domain-item>
        </ng-container>
    </div>
    <div class="phone-chevron-container">
        <img [class.display-none]="!overflow" class="chevron-down" (click)="scrollDomainsDown()" src="/assets/base_icons/icon_chevron_down.svg">
    </div>
    
   
</ng-template>

<ng-template #tablet>
    <div class="selected-domain-container">
        <div class="domains-picto-container-left">
            <div class="selected-domain-label">
                <h3>{{domainSvc.selectedDomain.title}}</h3>
            </div>
            <div>
                <img src="/assets/pictos/domains/{{domainSvc.selectedDomain.picto!}}.svg" alt={{domainSvc.selectedDomain.title!}}>
            </div>
            <!-- <div class="domain-promo" *ngIf="!productSvc.itemIsInCart(domainSvc.currentDomain.id)
                && !domainSvc.userHasPromoProduct()
                && domainSvc.promoText" (click)="openDomainPromoModal()">
                <div class="domain-promo-text">{{domainSvc.promoText}}</div>
                <div id="badge-ribbon"></div>
            </div> -->
        </div>
        <div class="selected-domain-container-center">
            <div class="domain-information-top">
                <p>{{domainSvc.selectedDomain.description}}</p>
                <div *ngIf="domainSvc.selectedDomain.extra_information"
                    class="domain-extra-info" (click)="openExtraInfoModal()">
                    {{domainSvc.selectedDomain.extra_information['link_text_'+authSvc.User.accountSettings.language]}}
                </div>
            </div>
            <button *ngIf="!domainSvc.selectedDomain.purchased
                                && !domainSvc.userHasPromoProduct()
                                && !domainSvc.selectedDomain.public 
                                && domainSvc.selectedDomain.type === 1" 
                            (click)="openDomainPromoModal()"
                            class="modal-text-button">{{translatorSvc.getLabel('purchaseCookingKit')}}</button>
        </div>
        <div class="selected-domain-container-right">
            <h4 *ngIf="translatorSvc.startsWithVowel(domainSvc.currentDomain.title) === -1">{{translatorSvc.getLabel('domainContent')}}{{domainSvc.selectedDomain.title}}</h4>
            <h4 *ngIf="translatorSvc.startsWithVowel(domainSvc.currentDomain.title) !== -1">{{translatorSvc.getLabel('domainContentVowel')}}{{domainSvc.selectedDomain.title}}</h4>
            
            <div #workflow_example_wrapper (scroll)="onWorkflowScroll()" class="workflow-example-container">
                <ng-container #workflow_example_container *ngFor="let workflow of domainSvc.selectedDomain.workflows">
                    <app-workflow-example-item [workflow]="workflow"></app-workflow-example-item>
                </ng-container>
            </div>
            <img [class.display-none]="!overflow" class="chevron-down" (click)="scrollWorkflowsDown()" src="/assets/base_icons/icon_chevron_down.svg">
            <div class="domain-button">
                <!-- <div class="modal-text-button" (click)="goToRecipeEditor()">Recipe Editor</div> -->
                <div *ngIf="domainSvc.selectedDomain.type === 1" class="modal-text-button" (click)="goToDomain()">{{translatorSvc.getLabel('viewRecipes')}}</div>
                <div *ngIf="domainSvc.selectedDomain.type === 5" class="modal-text-button" (click)="goToDomain()">{{translatorSvc.getLabel('viewJobExamples')}}</div>
                <div *ngIf="domainSvc.selectedDomain.type !== 1 && domainSvc.selectedDomain.type !== 5"class="modal-text-button" (click)="goToDomain()">{{translatorSvc.getLabel('goToDomain')}}</div>
            </div>
        </div>
    </div>
    <div class="selected-domain-workflow-container">
        <div id="domain-swiper-container" class="domain-swiper-container">
            <div class="swiper-wrapper swiper-adjustment">
                <ng-container *ngFor="let domain of domainSvc.domains">
                    <app-domain-item *ngIf="isJobExample(domain)" class="domain-swiper-slide"  [domain]="domain"></app-domain-item>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

