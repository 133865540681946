<div class="results-container">
    <div class="results-wrapper">
        <div class="results-container-info-top">
            <h3>{{translatorSvc.getLabel('jobResults')}}</h3>
        </div>
        <div class="results-container-info-bottom" [class.display-none]="!workflowSvc.jobAssessment">
            <div class="question-item-wrapper-horizontal">
                <p>{{translatorSvc.getLabel('assessmentInterest')}}</p>
                <img [src]="workflowSvc.preference.src">
            </div>
            <div class="question-item-wrapper-verticle">
                <p [class.display-none]="somethingBothersMe">{{translatorSvc.getLabel('assessmentBotherNone')}}</p>
                <p [class.display-none]="!somethingBothersMe">{{translatorSvc.getLabel('assessmentBother')}}</p>
                <div [class.display-none]="!somethingBothersMe" class="table-container">
                    <div *ngFor="let question of workflowSvc.table">
                        <div class="table-wrapper">
                            <p class="table-question">{{question.question}}: </p>
                            <p class="table-answer">{{question.answer_text}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="question-item-wrapper-verticle multi-choice">
                <p>{{translatorSvc.getLabel('multiChoiceHeader')}}</p>
                <div class="multi-container">
                    <div *ngFor="let question of workflowSvc.multiple_choice.questions">
                        <p class="table-question">{{question.question}}</p>
                        <div class="question-item-wrapper-horizontal">
                            <p class="table-answer">{{question.choice}}</p>
                            <img class="question-check-icon" [src]="question.src">
                        </div>
                        <p class="multi-answer" *ngIf="question.answer !== undefined">{{question.answer}}</p>
                    </div>
                </div>
                
            </div>
        </div>
        
        <div class="button-container">
            <div class="button-wrapper">
                <button type="button" (click)="closeModal()" class="autonome-button-orange"><p>{{translatorSvc.getLabel('close')}}</p></button>
            </div>
        </div>
    </div>
</div>