import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AcademyService } from '../../services/academy.service';
import { Combatant } from '../../models/combatant';
import { KeyboardMenu } from '../../models/keyboard-menu';
import { menus } from '../../content/keyboardMenus';
import { KeyPressListener } from '../../models/key-press-listener';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-submission-menu',
  templateUrl: './submission-menu.component.html',
  styleUrls: ['./submission-menu.component.scss']
})
export class SubmissionMenuComponent implements OnInit, AfterViewInit {

  @ViewChildren('menuItems') menuItems : QueryList<ElementRef>;
  @Input() combatant: Combatant;

  private keyboardSubmitEvent: Subscription;
  private up: KeyPressListener;
  private down: KeyPressListener;

  constructor(public academySvc : AcademyService) {
    this.academySvc.keyBoardMenu = new KeyboardMenu();
    this.academySvc.keyBoardMenu.setOptions(menus.getPages().root)
    this.academySvc.keyBoardMenu.currentKeyboardMenuItem = this.academySvc.keyBoardMenu .keyboardMenuItems[0];
   }
  
  ngAfterViewInit(): void {
    this.academySvc.keyBoardMenu.keyboardMenuItems[0].focus = true;
  }
  

  ngOnInit(): void {
    this.academySvc.menuText = true;
    this.academySvc.textMessage = this.academySvc.keyBoardMenu.currentKeyboardMenuItem.description;
    this.up = new KeyPressListener("ArrowUp", ()=> {
      
      let index = this.academySvc.returnMenuIndexOf(this.academySvc.keyBoardMenu.currentKeyboardMenuItem.label);
      if(index > 0) {
        if(!this.academySvc.keyBoardMenu.keyboardMenuItems[index-1].disabled) {
          this.academySvc.keyBoardMenu.currentKeyboardMenuItem = this.academySvc.keyBoardMenu.keyboardMenuItems[index-1];
          this.academySvc.sendKeyboardMenuSelectEvent(index-1);
        } else {
          while(this.academySvc.keyBoardMenu.keyboardMenuItems[index-1].disabled && index > 0) {
            index--;
          }
          if(index > 0 && !this.academySvc.keyBoardMenu.keyboardMenuItems[index-1].disabled) {
            this.academySvc.keyBoardMenu.currentKeyboardMenuItem = this.academySvc.keyBoardMenu.keyboardMenuItems[index-1];
            this.academySvc.sendKeyboardMenuSelectEvent(index-1);
          }
        }
      }
    })
    this.down = new KeyPressListener("ArrowDown", ()=> {
      let index = this.academySvc.returnMenuIndexOf(this.academySvc.keyBoardMenu.currentKeyboardMenuItem.label);
      if(index < this.academySvc.keyBoardMenu.keyboardMenuItems.length) {
        if(!this.academySvc.keyBoardMenu.keyboardMenuItems[index+1].disabled) {
          this.academySvc.keyBoardMenu.currentKeyboardMenuItem = this.academySvc.keyBoardMenu.keyboardMenuItems[index+1];
          this.academySvc.sendKeyboardMenuSelectEvent(index+1);
        } else {
          while(this.academySvc.keyBoardMenu.keyboardMenuItems[index+1].disabled && index < this.academySvc.keyBoardMenu.keyboardMenuItems.length) {
            index++;
          }
          if(index < this.academySvc.keyBoardMenu.keyboardMenuItems.length && !this.academySvc.keyBoardMenu.keyboardMenuItems[index+1].disabled) {
            this.academySvc.keyBoardMenu.currentKeyboardMenuItem = this.academySvc.keyBoardMenu.keyboardMenuItems[index+1];
            this.academySvc.sendKeyboardMenuSelectEvent(index+1);
          }
        }
      }
    })
  }

  closeSubmissionMenu() {

    if(this.academySvc.submissionMenuShow) {
      this.academySvc.submissionMenuShow = false;
      this.academySvc.sendSubmissionMenuClosedEvent();
    }
  }

}
