<div class="welcome-container" (window:resize)="onHeightResize($event)">
    <div class="welcome-wrapper">
        <img class="login-container-logo" src="/assets/logo/autonome_kitchen_logo.svg"/>
        <form name="loginForm" id="loginForm" (ngSubmit)= "onSubmit()">
            <div>
                <label>{{translatorSvc.getLabel('email')}}</label>
                <input
                [(ngModel)]="email"
                #emailInput 
                name="emailInput" 
                type="text" 
                [class.input-error]="!emailValid"
                [class.input-valid]="emailValid"
                ng-model="email" 
                (focusout) = "emailFocusOut()"
                (keypress) = "onInputChange()"
                placeholder="{{translatorSvc.getLabel('email')}}" noncapitalize required appFocusOnShow>
            </div>
            <div>
                <label>{{translatorSvc.getLabel('password')}}</label>
                <div class="password-wrapper">
                    <input
                    [(ngModel)]="password"
                    #pwInput
                    name="pwInput"
                    type="{{showPassword ? 'text' : 'password'}}"
                    [class.input-error]="!pwdValid"
                    [class.input-valid]="pwdValid"
                    (focusout) = "pwFocusOut()"
                    (keypress) = "onInputChange()"
                    placeholder="{{translatorSvc.getLabel('pwd')}}">
                    <img class="eye-icon" (click)="toggleShowPassword()" [src]="showPassword ? '/assets/base_icons/eye-password-hide.svg' : '/assets/base_icons/eye-password-show.svg'"/>
                </div>
            </div>
            <div *ngIf="loginError" class="error-message">{{translatorSvc.getFirebaseMessage(errorMsg)}}</div>
        </form>
        <button type="submit" form="loginForm" class="autonome-button-orange" [disabled]="!emailValid || !pwdValid">
            <p>{{translatorSvc.getLabel('login')}}</p>
        </button>
        <button type="button" class="social-login-button" (click)="authSvc.googleAuth(false, [])">
            <img class="google-login-svg" src="../../../../assets/brands/google.svg">{{translatorSvc.getLabel('signInGoogle')}}</button>
        <div class="clickable-text-underlined" (click)="showRegisterModal(false, false)">{{translatorSvc.getLabel('create-account')}}</div>
        <div class="clickable-text-underlined" (click)="resetPassword()">{{translatorSvc.getLabel('forgot-pwd')}}</div>
    </div>
</div>
            

