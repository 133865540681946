import { TranslatorService } from 'src/app/tools/services/translator.service';
import { Component, HostListener, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserConfirmationComponent } from 'src/app/modal/components/user-confirmation/user-confirmation.component';
import { PaymentService } from '../../services/payment.service';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';

@Component({
  selector: 'app-stripe-card-item',
  templateUrl: './stripe-card-item.component.html',
  styleUrls: ['./stripe-card-item.component.scss']
})
export class StripeCardItemComponent{

  @Input() paymentMethodObject : object;

  public cardIconString: string;

  public cardBrand: string;
  public lastFour: string;

  constructor(
    public translatorSvc :TranslatorService,
    private paymentSvc : PaymentService,
    private spinnerSvc : SpinnerOverlayService,
    public matDialog: MatDialog,
  ) {}

  @HostListener("click") onClick(){
    this.paymentSvc.paymentMethodId = Object(this.paymentMethodObject).id;
    this.cardBrand = Object(this.paymentMethodObject).card.brand;
    this.lastFour = Object(this.paymentMethodObject).card.last4;
    this.openPaymentConfirmationModal();
  }


  async openPaymentConfirmationModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "confirmPaymentMethod";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "pay-payment-method",
      message: this.translatorSvc.getText('paymentMethodConfirmation') + Object(this.paymentMethodObject).card.last4,
      actionButtonText1 : this.translatorSvc.getLabel('cancel'),
      actionButtonText2 : this.translatorSvc.getLabel('confirm'),
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(async result => {
      if(result) {
        if (this.paymentSvc.invoice.domain_subscription_total > 0) {
          this.spinnerSvc.show(this.translatorSvc.getLabel('processingSubscription'));
          await this.paymentSvc.processSubscriptions(null);
          this.spinnerSvc.hide();
        }
        if (this.paymentSvc.invoice.totalAmount > 0) {
          this.spinnerSvc.show(this.translatorSvc.getLabel('processingPayment'));
          await this.paymentSvc.processPurchase(null);
          this.spinnerSvc.hide();
        }
      }
    });
  }
}
