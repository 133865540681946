<div class="familiarization-modal-container">
    <div class="familiarization-modal-header">
        <img id="cirris" src="/assets/partner_logos/cirris.svg">
        <img id="autonome" src="/assets/logo/autonome_at_work.svg">
    </div>
    <div class="familiarization-modal-text">
        <h3>{{translatorSvc.getLabel('welcomeAutonome')}}</h3>
        <p>{{translatorSvc.getText('introFamiliarization')}}</p>
        <p>{{visitFamiliarization}}</p>
    </div>
    <div class="familiarization-modal-button-container">
        <label class="familiarization-input" >{{translatorSvc.getLabel('doNotShow')}}
            <input #noShow type="checkbox" [(ngModel)]="chk_box"/>
        </label>
        <div class="autonome-button-orange" (click)="closeModal()">
            <p>{{translatorSvc.getLabel('iUnderstand')}}</p>
        </div>
    </div>
</div>
