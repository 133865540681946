import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BattleComponent } from '../battle/battle.component';
import { Battle } from '../../models/battle';

@Component({
  selector: 'app-combatant',
  templateUrl: './combatant.component.html',
  styleUrls: ['./combatant.component.scss']
})
export class CombatantComponent implements OnInit {

  @Input() combatant: any;
  @Input() battle: Battle;

  
  constructor() { 
    
  }

  ngOnInit(): void {
    
  }

  get hpPercent() {
    const percent = this.combatant.hp / this.combatant.maxHp * 100;
    return percent > 0 ? percent: 0;
  }

  get xpPercent() {
    return this.combatant.xp / this.combatant.maxXp * 100;
  }



}
