import { Component } from '@angular/core';
import { PaymentService } from '../../services/payment.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../admin/services/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EulaComponent } from 'src/app/modal/components/eula/eula.component';
import { TranslatorService } from 'src/app/tools/services/translator.service';

@Component({
  selector: 'app-shipping-element',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent {

  confirmation : object;
  loading = false;
  includeEmailField = false;

  addressUpdateEventSubscription : Subscription;
  loginEvent : Subscription;

  constructor(
    public paymentSvc : PaymentService,
    public authSvc : AuthService,
    private router : Router,
    public translatorSvc: TranslatorService,
    public matDialog: MatDialog) { 

    if(!this.authSvc.User) {
      this.includeEmailField = true;
    }

    if (!this.loginEvent) {
      this.loginEvent = this.authSvc.getLoginEvent().subscribe(() => {
        
      });
    }
  }

  openEulaModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "eula-modal";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.panelClass = "custom-dialog-container";
    this.matDialog.open(EulaComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  goToShoppingCart() {
    this.router.navigate(['shopping-cart']);
  }
}                                                                                                                                                                        
