import { AfterContentInit, Component, Input } from '@angular/core';
import { EditorService } from '../../services/editor.service';

@Component({
  selector: 'app-recipe-step-item',
  templateUrl: './recipe-step-item.component.html',
  styleUrls: ['./recipe-step-item.component.scss']
})


export class RecipeStepItemComponent implements AfterContentInit {

  @Input() workflowItem: any;
  wfItemType : string;
  wfName : string;
  pathString : string = '/assets/pictos/'
  completeWfItem: boolean = false;


  constructor(
    public editorSvc : EditorService
  ) {
    
  }

  ngAfterContentInit(): void {
    if (this.workflowItem[Object.keys(this.workflowItem).find((obj) => { return obj === 'action'})]) {
      this.wfName = this.workflowItem['action'].action_name;
      if(this.workflowItem['action'].default_picto) {
        this.pathString = this.pathString + 'actions/'+this.workflowItem['action'].default_picto+'.svg';
      } else if (this.workflowItem.svgString) {
        this.pathString = this.pathString + 'actions/'+this.workflowItem.svgString+'.svg';
      } else {
        this.pathString = this.pathString + 'actions/'+this.workflowItem['action'].action_name+'.svg';
      }
    } else if (this.workflowItem[Object.keys(this.workflowItem).find((obj) => { return obj === 'ingredient'})]) {
      this.wfName = this.workflowItem['ingredient'].ingredient_name
      if(this.workflowItem['ingredient'].default_picto) {
        this.pathString = this.pathString + 'ingredients/'+this.workflowItem['ingredient'].default_picto+'.svg';
      } else {
        this.pathString = this.pathString + 'ingredients/'+this.workflowItem['ingredient'].ingredient_name+'.svg';
      }
    } else {
      this.wfName = 'unknown';
      this.pathString = this.pathString + 'domains/icon_img_not_found.svg';
    }

    this.completeWfItem = this.workflowItem.complete;
  }

  removeWorkflowItem() {
    this.editorSvc.removeWorkflowItem(this.workflowItem.index);
  }

}
