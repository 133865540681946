import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Step } from '../../../models/step.model';
import { WorkflowService } from '../../services/workflow.service';

@Component({
  selector: 'app-action-list-item',
  templateUrl: './action-list-item.component.html',
  styleUrls: ['./action-list-item.component.scss']
})
export class ActionListItemComponent implements OnInit, OnDestroy {

  @Input() stepItem : Step;
  hideVideoBtn : boolean = true;
  closeVideoEventsubscription : Subscription;

  constructor(private wfSvc : WorkflowService) {
    
  }
  ngOnDestroy(): void {
    if(this.closeVideoEventsubscription) {
      this.closeVideoEventsubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if(this.stepItem.action.video) {
      this.hideVideoBtn = false;
      this.closeVideoEventsubscription = this.wfSvc.getCloseVideoEvent().subscribe(() => {
        this.close_video();
      });
    }
  }

  play_video() {
    this.hideVideoBtn = true;
    this.wfSvc.playVideo(this.stepItem.action.video);
  }

  close_video() {
    this.hideVideoBtn = false;
  }

  overButton() {
    // handle mouse and touch events through eventListener
    this.wfSvc.sendDisableSwiperEvent();
  }

  leaveButton() {
    this.wfSvc.sendEnableSwiperEvent();
  }

  disableTouchMove() {
    this.wfSvc.sendDisableTouchMoveEvent();
  }

  enableTouchMove() {
    this.wfSvc.sendEnableTouchMoveEvent();
  }

}
