import { Swiper } from 'swiper';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ShowcaseItem } from 'src/app/models/showcase-item';

@Component({
  selector: 'app-autonome-showcase',
  templateUrl: './autonome-showcase.component.html',
  styleUrls: ['./autonome-showcase.component.scss']
})
export class AutonomeShowcaseComponent implements OnInit, AfterViewInit {

  private showcaseSwiper: Swiper;
  public slides : Array<ShowcaseItem>

  constructor() { }

  ngOnInit(): void {
    this.slides = new Array();
    this.slides.push(new ShowcaseItem("recipes.jpg", "Our recipes, easy to find, easy to cook"));
    this.slides.push(new ShowcaseItem("recipe-detail.jpg", "All the information you need to choose, prepare and start your recipe"));
    this.slides.push(new ShowcaseItem("sous-chef-ingredient-tool.jpg", "Simple steps with visual references using our custom Autonomē cooking kit"));
    this.slides.push(new ShowcaseItem("sous-chef-action-timer.jpg", "Integrated timers to help the cooks with some of the steps that require a bit of timing"));
    this.slides.push(new ShowcaseItem("chef-action-video.jpg", "Videos to assist the cooks that want visual examples to enhance learning"));
    this.slides.push(new ShowcaseItem("verification.jpg", "Verification steps that allow the cook to go back and ensure the steps are correctly done"));
    this.slides.push(new ShowcaseItem("chef-ingredient-qty.jpg", "Advanced cooking perspectives for those who are able to follow more complex cooking instructions"));
  }

  ngAfterViewInit(): void {
    this.showcaseSwiper = new Swiper(".swiper-container", {
      slideClass : 'showcase-swiper-slide',
      slidesPerView: 1,
      centeredSlides: true,
      centeredSlidesBounds: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      loop: false
      });
  }

}
