import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, defer, interval, Observable, Subject } from 'rxjs';
import { take, filter, map, share, withLatestFrom } from 'rxjs/operators'
import { CycleStepComponent } from '../components/cycle-step/cycle-step.component';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  private _timerButton: string;
  private _timerPaused: boolean;
  private _mainTimerId: number;
  private _passiveTimerId: number;
  private _currentTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private _timer_minutes: string = '';
  private _timer_seconds: string = '';

  private _step_paused_time: number;

  alertPlayer : HTMLAudioElement = new Audio;
  
  mainTimerObservable$ : Observable<number>;
  passiveTimerObservable$ : Observable<number>;

  constructor(
    public matDialog: MatDialog,
  ) { }

  notifications : NotificationI[] = [];
  keys : number[] = [];
  newId : number;
  
  private _timer_running : boolean = false;
  private _passive_time_running: boolean = false;
  

  addTimer(time : number, passive_time: number | undefined = undefined, cycle_time: number | undefined = undefined) {
    this.alertPlayer.src = "assets/audio/timer.mp3"
    // run one timer at a time
    if(!this._timer_running) {
      const subject = new BehaviorSubject<boolean>(true);
      const notification : NotificationI = { id: new Date().getTime(), paused: subject, obs: this.getPausableTimer(time+1, subject) }
      if(!this.notifications) {
        this.notifications = [];
      }
      this.notifications[notification.id] = notification;
      this.newId = notification.id;
      this._timer_running = true;
      this.keys.push(notification.id);

      this.mainTimerId = this.newId;
      this.notifications[this.mainTimerId].paused.next(false);
      this.mainTimerObservable$ = this.notifications[this.mainTimerId].obs.stepTimer;

      this.mainTimerObservable$.subscribe(
        time => {
          if(!this._passive_time_running){
            if(passive_time && passive_time > 0) {
              if(cycle_time > 0) {
                const intervalTime = cycle_time + passive_time;
                if(time > intervalTime) {
                  this.addTimer(intervalTime, undefined, undefined);
                  this._passive_time_running = true;
                  this._passiveTimerId = this.newId;
                  this.notifications[this.passiveTimerId].paused.next(false);
                  this.passiveTimerObservable$ = this.notifications[this.passiveTimerId].obs.stepTimer;
                  this.passiveTimerObservable$.subscribe(
                    PCTime => {
                      if(PCTime === cycle_time && !this.timerPaused && this.timerRunning) {
                        // show cyclestep modal
                        this.openCycleStepModal(cycle_time);
                      }
                      if(PCTime === 0) {
                        this.notifications[this.passiveTimerId].paused.next(true);
                        this.stopTimer(this.passiveTimerId);
                        this._passive_time_running = false;
                      }
                    });
                }
              }
            }
          }
          
          if(time >= 0) {
            this._step_paused_time = time;
            this._timer_minutes = "" + Math.floor(time/60);
            if((time % 60) < 10) {
              this._timer_seconds = "0" + (time % 60);
            } else {
              this._timer_seconds = "" + (time % 60);
            }
            if(time <= 5 && time >= 1) {
              this.alertPlayer.play();
            }
          } 
          if(time === 0) {
            this._step_paused_time = 0;
            this._passive_time_running = false;
            this.alertPlayer.pause();
            this.sendTimerStoppedSubjectEvent();
          }
        }
      );
    }
  }

  stopTimer(id : number) {
    this._timer_running = false;
    this._timer_minutes = '';
    this._timer_seconds = '';
    delete this.notifications[id];
  } 

  stopAllTimers() {
    this._timer_running = false;
    this._timer_minutes = '';
    this._timer_seconds = '';
    delete this.notifications;
  }

  dismiss(key: number) {
    this.keys = this.keys.filter(v => v !== key);
    delete this.notifications[key];
  }

  getPausableTimer(time: number, pause: BehaviorSubject<boolean>): { stepTimer: Observable<number>} {
    const pausableTimer$ = defer(() => {
      let seconds = time-1;
      return interval(1000).pipe(
        withLatestFrom(pause),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filter(([v, paused]) => !paused),
        take(time),
        map(() => this._currentTime.next(seconds)),
        map(() => 
          seconds--
        )
      )
    }).pipe(share());

    return { stepTimer: pausableTimer$ }
  }

  private getTimerStoppedSubject = new Subject<boolean>();
  sendTimerStoppedSubjectEvent() {
    this.getTimerStoppedSubject.next(true);
  }
  getTimerStoppedSubjectEvent(): Observable<boolean> {
    return this.getTimerStoppedSubject.asObservable();
  }

  get timerRunning() {
    return this._timer_running;
  }

  public get passiveTimeRunning(): boolean {
    return this._passive_time_running;
  }

  public getTimerById(index: number) {
    return this.notifications[index];
  }

  public get timerButton(): string {
    return this._timerButton;
  }
  public set timerButton(value: string) {
    this._timerButton = value;
  }
   
  public get timerPaused(): boolean {
    return this._timerPaused;
  }
  public set timerPaused(value: boolean) {
    this._timerPaused = value;
  }

  public get mainTimerId(): number {
    return this._mainTimerId;
  }
  public set mainTimerId(value: number) {
    this._mainTimerId = value;
  }

  public get passiveTimerId(): number {
    return this._passiveTimerId;
  }
  public set passiveTimerId(value: number) {
    this._passiveTimerId = value;
  }
  public get currentTime(): BehaviorSubject<number> {
    return this._currentTime;
  }
  public set currentTime(value: BehaviorSubject<number>) {
    this._currentTime = value;
  }

  public get timer_minutes(): string {
    return this._timer_minutes;
  }
  public set timer_minutes(value: string) {
    this._timer_minutes = value;
  }
  public get timer_seconds(): string {
    return this._timer_seconds;
  }
  public set timer_seconds(value: string) {
    this._timer_seconds = value;
  }
  public get step_paused_time(): number {
    return this._step_paused_time;
  }
  public set step_paused_time(value: number) {
    this._step_paused_time = value;
  }

  openCycleStepModal(cycleTime : number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-register-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "profile",
      time: cycleTime
    };
    this.matDialog.open(CycleStepComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }
}

interface NotificationI {
  id: number,
  paused: BehaviorSubject<boolean>,
  obs: {
    stepTimer : Observable<number>
    }}
