import { IngredientI } from '../interfaces/ingredient.interface';
import { IngredientMeasure } from './ingredient_measure.model';

export class Ingredient implements IngredientI {
    
    ingredient_name : string;
    descriptions: object;
    picto? : string;
    svg_string? : string;
    unit? : string;

    private _description : string;
    private _total_qty : number = 0;
    private _total_measure : number = 0;
    private _measure_text : string;
    private _ingredient_measure : IngredientMeasure | null;
    private _initial_total_qty_measure : number;
    private _modify_portion : boolean = true;
    private _custom_tool: boolean = false;

    constructor(value : object) {
        const ingredientData = Object(value);
        if(ingredientData.ingredient_name) { // Is ingredient from workflow steps
            this.ingredient_name = ingredientData.ingredient_name;
            this.descriptions = ingredientData.descriptions;
            if(ingredientData.picto) {
                this.picto = ingredientData.picto;
            } else {
                this.picto = this.ingredient_name ;
            }
            // TODO
            this.unit = ingredientData.unit ? ingredientData.unit : this.unit = 'ml';
        /* } else if (ingredientData.id) { 
            this._id = ingredientData.id;
            this._description_array = ingredientData.description_array;
            this._picto = ingredientData.picto;
            ingredientData.unit ? this._unit = ingredientData.unit : this._unit = 'ml'; */
        }  else { // Is ingredient from ingredient collection
            this.ingredient_name = Object.keys(ingredientData)[0];
            this.descriptions = ingredientData[this.ingredient_name].descriptions;
            if(!ingredientData[this.ingredient_name].svg_string) {
                this.picto = !ingredientData[this.ingredient_name].svg_string ? this.ingredient_name : ingredientData[this.ingredient_name].svg_string;
            }
            this.unit = ingredientData[this.ingredient_name].unit ? ingredientData[this.ingredient_name].unit : this.unit = 'ml';
        }
        this._description = '';
        
    }

    get description() {
        return this._description;
    }
    set description(description: string) {
        this._description = description;
    }

    get totalQty() {
        return this._total_qty;
    }
    set totalQty(total_qty : number) {
        this._total_qty = total_qty;
    }

    get totalMeasure() {
        return this._total_measure;
    }
    set totalMeasure(total_measure : number) {
        this._total_measure = total_measure;
    }

    get ingredientMeasure() : IngredientMeasure | null {
        return this._ingredient_measure;
    }
    set ingredientMeasure(ingredient_measure : IngredientMeasure | null) {
        this._ingredient_measure = ingredient_measure;
    }

    get measureText() {
        return this._measure_text;
    }
    set measureText(mText : string) {
        this._measure_text = mText;
    }

    get initialTotalQtyMeasure() {
        return this._initial_total_qty_measure;
    }
    set initialTotalQtyMeasure(qtyMeasure : number) {
        this._initial_total_qty_measure = qtyMeasure;
    }

    get modifyPortion() {
        return this._modify_portion;
    }
    set modifyPortion(modify : boolean) {
        this._modify_portion = modify;
    }

    public get custom_tool(): boolean {
        return this._custom_tool;
    }
    public set custom_tool(value: boolean) {
        this._custom_tool = value;
    }


    setDescription(qty: number, language : string) {
        if(qty > 1 && this.descriptions[language].plural !== '') {
            this.description = this.descriptions[language].plural;
        } else {
            this.description = this.descriptions[language].singular;
        }
    }
}