import { WorkflowService } from '../../services/workflow.service';
import { Router } from '@angular/router';
import { AfterViewInit, Component } from '@angular/core'
import { SpinnerOverlayService } from '../../../tools/services/spinner-overlay.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslatorService } from '../../../tools/services/translator.service';
import { Recipe } from 'src/app/models/recipe.model';
import { Workflow } from 'src/app/models/workflow.model';

@Component({
  selector: 'app-recipe-detail',
  templateUrl: './recipe-detail.component.html',
  styleUrls: ['./recipe-detail.component.scss']
})
export class RecipeDetailComponent implements AfterViewInit {

  hideResume : boolean = true;
  bodyHeight : number;
  iHeight : number;
  navHeight : number;
  iHeightText : string;
  screenWidth : number;
  
  constructor(
    private router: Router,
    public workflowSvc: WorkflowService,
    public spinnerSvc : SpinnerOverlayService,
    public matDialog: MatDialog,
    public translatorSvc : TranslatorService) {
   }

  ngAfterViewInit(): void {
    // adjusting height minus the autonome bar(64) and padding(12)
    this.iHeightText = (window.innerHeight - 88).toString() + 'px';
    this.screenWidth = window.innerWidth;
  }

  goToRecipes() {
    this.router.navigate(['recipes']);
  }

  asRecipe(value: Workflow) : Recipe {
    return (value as Recipe);
  }

  onHeightResize(event) {
    this.iHeightText = (event.target.innerHeight - 88).toString() + 'px';
  }
  
  onLaunchRecipe() {
    this.workflowSvc.launchWorkflow();
  }

  onRestartRecipe() {
    this.workflowSvc.currentStepId = 0;
    // reset workflow
    this.workflowSvc.resetStepTime();
    this.router.navigate(['workflow']);
  }
  
  toggleFavorite() {
    // TODO : this.recipeSvc.updateFavoriteRecipes();
  }

  shareRecipe() {
    // share recipe with friends
  }

  openList(evt, listName) {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for(let i=0; i<tabcontent.length; i++) {
      (tabcontent[i] as HTMLElement).style.display = "none";
    }

    const tablinks = document.getElementsByClassName("tablinks");
    for(let i=0; i< tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(listName).style.display = "flex";
    evt.currentTarget.className += " active";
  }
  
}
