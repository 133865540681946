

export class Tool {
    private _id : string;
    private _description : string;
    private _measure : number;
    private _unit : string;
    private _qty : number;
    private _svg_string: string;
    private _picto: string;
    private _description_array : Array<any>;

    constructor(toolData : any) {
        this._description_array = new Array();
        this.initializeDescriptions(toolData);
        this._id = toolData.tool_name;
        this._measure = 0;
        this._unit = '';
        this._qty = 0;
        this._svg_string = toolData.svg_string;
        this._picto = this._id;
    }

    get id() {
        return this._id;
    }
    set id(value: string) {
        this._id = value;
    }

    get measure() {
        return this._measure;
    }
    set measure(measure : number) {
        this._measure = measure;
    }

    get description() {
        return this._description;
    }
    set description(description : string) {
        this._description = description;
    }

    get svg_string() {
        return this._svg_string;
    }
    set svg_string(svg_string : string) {
        this._svg_string = svg_string;
    }

    get picto() {
        return this._picto;
    }
    set picto(picto: string) {
        this._picto = picto;
    }

    initializeDescriptions(toolData : any) {
        this._description_array.push({language: 'en', singular: toolData.description_en});
        this._description_array.push({language: 'fr', singular: toolData.description_fr});
    }

    setDescription(lang : string) {
        const description = this._description_array.find((descriptionObj) => {
            return descriptionObj.language === lang
        });
        this._description = description.singular;
    }
}