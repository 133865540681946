import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AcademyService } from '../../services/academy.service';

@Component({
  selector: 'app-text-message',
  templateUrl: './text-message.component.html',
  styleUrls: ['./text-message.component.scss']
})
export class TextMessageComponent implements AfterViewInit {

  @ViewChild('textContainer') textContainer: ElementRef; 
  private _onComplete;
  private _element: HTMLElement;
  
  constructor(
    public academySvc : AcademyService) {
      
  }

  ngAfterViewInit(): void {
  
  }

  closeTextMessage() {

    if(this.academySvc.textMessageShow) {
      this.academySvc.textMessageShow = false;
      this.academySvc.sendTextMsgClosedEvent();
    }
  }

  public get onComplete() {
    return this._onComplete;
  }
  public set onComplete(value) {
    this._onComplete = value;
  }

  public get element(): HTMLElement {
    return this._element;
  }
  public set element(value: HTMLElement) {
    this._element = value;
  }

}
