import { Component, OnInit } from '@angular/core';
import { SpinnerOverlayService } from '../../services/spinner-overlay.service';

@Component({
  selector: 'app-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent implements OnInit {

  constructor(public spinnerSvc : SpinnerOverlayService) { }

  ngOnInit(): void {
  }

}
