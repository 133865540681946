import { Workflow } from './../../models/workflow.model';
import { RecipeService } from './recipe.service';
import { ToolService } from './tool.service';
import { Step } from '../../models/step.model';
import { AuthService } from '../../admin/services/auth.service';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions'
import { Observable, Subject, first, firstValueFrom } from 'rxjs';
import { Recipe } from '../../models/recipe.model';
import { SpinnerOverlayService } from '../../tools/services/spinner-overlay.service';
import { TranslatorService } from '../../tools/services/translator.service';
import { Router } from '@angular/router';
import { WorkflowCategory } from 'src/app/models/workflow-category.model';
import { StepType } from 'src/app/enums/step-type.enum';
import { RouterExtService } from 'src/app/tools/services/router-ext.service';
import { Domain } from 'src/app/models/domain.model';
import { JobExampleI } from 'src/app/interfaces/job-example.interface';
import { utils } from 'src/app/utils/utils';
import { TableQuestionI } from 'src/app/interfaces/table-question.interface';
import { TimerService } from 'src/app/modal/services/timer.service';

@Injectable({
  providedIn: 'root'
})

export class WorkflowService {
  private _display_steps: Step[];
  private _wf_display_steps: Step[];         // We only need the object information
  private _initial_checkstep_id: number;
  private _paused_step_id: number;
  private _paused_timer_id: number;
  private _paused_time: number;
  private _current_step_id: number = 0;
  private _current_route : string;
  private _nav_back: boolean;

  _difficultyPicto : string;
  _difficultyText : string;

  // new members
  private _currentWorkflow: (Recipe | Workflow);
  private _selectedWorkflow: (Recipe | Workflow);

  // Job examples
  private _jobAssessment: boolean = false;
  

  constructor(private fns : AngularFireFunctions,
    private authSvc : AuthService,
    private toolSvc : ToolService,
    private recipeSvc : RecipeService,
    private spinnerSvc : SpinnerOverlayService,
    private translatorSvc : TranslatorService,
    public timerSvc : TimerService,
    private extRouter : RouterExtService,
    private router : Router) {
      
   }

  /**
   * Set decriptions, allergies, ingredientList, toolList and pictos for workflow
   * @param domain new Domain class created when looping through the data received
   * via getDomains. There should always be items in the domains, e.g. cooking.
   * 
   */ 
  public initDomainWorkflows(domain: Domain) {
    // if domain is part of user domain collection then domain is purchased
    const domainPurchased : boolean = (this.authSvc.User.domains && this.authSvc.User.domains.includes(domain.id));
    // Add free category for free recipes if the domain is not purchased
    if(!domainPurchased && domain.id === 'cooking') {
      if(!domain.categories || domain.categories.length ===0 || !domain.categories.some((obj) => obj.category_name === 'free')) {
        const freeCategory = new WorkflowCategory({free:{title_en: 'Free Recipes', title_fr: 'Recettes Gratuites'}});   
        for(const workflow of domain.workflows) {
          if(workflow.isPublic) {
            freeCategory.workflows.push(workflow);
          }
        }
        domain.categories.unshift(freeCategory);
      }
    }

    this.currentStepId = 0;

    for (const workflow of domain.workflows) {
      // set domain worklfows language
      workflow.setDescriptions(this.authSvc.User.accountSettings.language);

      // Set allergyList, toolList, ingredientList for workflow
      if (domain.id === 'cooking') {
        if((workflow as Recipe).allergies && (workflow as Recipe).allergies.length > 0) {
          (workflow as Recipe).allergyList = this.recipeSvc.allergies.filter(allergyObj => (workflow as Recipe).allergies.some(allergyName => allergyName === allergyObj.allergyName));
        }
        if ((workflow as Recipe).tools && (workflow as Recipe).tools.length > 0) {
          (workflow as Recipe).toolList = this.toolSvc.tools.filter(toolObj => (workflow as Recipe).tools.some(toolString => toolString === toolObj.id));
        }
        (workflow as Recipe).ingredientList = this.recipeSvc.getIngredientList(workflow.steps);
      
        // Set image and picto
        if(this.authSvc.User.recipe_images && this.authSvc.User.recipe_images.length > 0) {
          (workflow as Recipe).recipe_jpg = this.authSvc.User.recipe_images.find((recipeName) => recipeName === workflow.id);
          if (!(workflow as Recipe).recipe_jpg) {
            fetch('/assets/pictos/workflows/' + (workflow as Recipe).id + '.svg').then(res => {
              (workflow as Recipe).picto = (workflow as Recipe).id + '.svg';
              if(res.status === 404) {
                (workflow as Recipe).picto = 'icon_img_not_found.svg';
              }
            });
          }
        }
      } else {
        if(this.authSvc.User.job_examples) {
          workflow.job_answered = this.authSvc.User.job_examples.some(jobExample => jobExample.example_name === workflow.id);
        }
        switch(domain.id) {
          case 'job_examples_manufacturing' :
            fetch('/assets/pictos/workflows/' + workflow.id + '.jpg').then(res => {
              workflow.picto = workflow.id + '.jpg'
              if(res.status === 404) {
                workflow.picto = 'icon_img_not_found.svg';
              }
            }).catch(() => {
            });
            break;
          default :
            fetch('/assets/pictos/workflows/' + workflow.id + '.svg').then(res => {
              workflow.picto = workflow.id + '.svg'
              if(res.status === 404) {
                workflow.picto = 'icon_img_not_found.svg';
              }
            }).catch(() => {
            });
        }
      }
      
      // Set workflow purchased boolean
      workflow.purchased = domainPurchased || workflow.isPublic === true;
    }
  }

  public get nav_back(): boolean {
    return this._nav_back;
  }
  public set nav_back(value: boolean) {
    this._nav_back = value;
  }

  public get currentWorkflow(): (Recipe | Workflow) {
    return this._currentWorkflow;
  }
  public set currentWorkflow(value: (Recipe | Workflow)) {
    if(value !== this._currentWorkflow) {
      this._currentWorkflow = value;
      this.setDifficultyInfo();
      this.updateCurrentWorkflowJobExamples();
      this.createDisplaySteps();
      this.resetWorkflowSteps();
      this.setDisplayStepsDescriptions(this._display_steps);
    }
  }

  public get selectedWorkflow(): (Recipe | Workflow) {
    return this._selectedWorkflow;
  }
  public set selectedWorkflow(value: (Recipe | Workflow)) {
    if(value !== this._selectedWorkflow) {
      this._selectedWorkflow = value;
      if (this._selectedWorkflow) {
        this._selectedWorkflow.setDescriptions(this.authSvc.User.accountSettings.language);
        this.setDifficultyInfo();
        if (this._selectedWorkflow && this._selectedWorkflow.domain_id !== 'cooking') {
          this.updateCurrentWorkflowJobExamples();
        }
      }
    }
  }

  /**
   * Reset current workflow to first step
   * Reset display steps
   * Reset workflow tracker display steps
   */
  resetWorkflowSteps() {
    this.currentStepId = 0;
    if (this._display_steps && this._display_steps.length > 0) {
      for (const step of this._display_steps) {
        step.completed = false;
        step.current = false;
      }
      this._display_steps[this.currentStepId].current = true;
    }
    if (this._wf_display_steps && this._wf_display_steps.length > 0) {
      for (const step of this._wf_display_steps) {
        step.completed = false;
        step.current = false;
      }
      this._wf_display_steps[this.currentStepId].current = true;
    }
  }

  /**
   * Setup workflow details by type (Recipe, Workflow, etc...)
   * Build workflow
   */
  setDifficultyInfo() {
    if(this._selectedWorkflow) {
      if(this._selectedWorkflow.difficulty !== undefined && this._selectedWorkflow.difficulty !== null) {
        this.difficultyPicto = "/assets/base_icons/icon_difficulty_level_" + (this._selectedWorkflow.difficulty+1).toString() + ".svg";
        this.difficultyText = this.translatorSvc.getDifficultytLabel(this._selectedWorkflow.difficulty);
      } else {
        this.difficultyPicto = "/assets/base_icons/icon_difficulty_level_1.svg";
        this.difficultyText = this.translatorSvc.getDifficultytLabel(0);
      }
    }
  }

  /**
   * Set descriptions for all display steps
   * Will skip ingredient steps as they are already set
   */
  setDisplayStepsDescriptions(steps : Step[]) {
    for (const step of steps) {
      switch (step.stepType) {
        case 0 : if (step.quantity && step.quantity > 1) {
          step.action.setDescription(this.authSvc.User.accountSettings.language, step.quantity);
        } else {
          step.action.setDescription(this.authSvc.User.accountSettings.language, 1);
        }
        break;
        case 1 : 
        case 2 : if (step.quantity && step.quantity > 1) {
            step.ingredient.setDescription(step.quantity, this.authSvc.User.accountSettings.language);
          } else {
            step.ingredient.setDescription(1, this.authSvc.User.accountSettings.language);
          }
        break;
        case 3 : step.verification.setDescription(this.authSvc.User.accountSettings.language);
        break;
      }
      if (step.action_steps && step.action_steps.length > 0) {
        this.setDisplayStepsDescriptions(step.action_steps);
      }
      if (step.check_steps && step.check_steps.length > 0) {
        this.setDisplayStepsDescriptions(step.check_steps);
      }
      if (step.cycle_steps && step.cycle_steps.length > 0) {
        this.setDisplayStepsDescriptions(step.cycle_steps);
      }
    }
   
  }

  /**
   * Update workflows (localization and competency)
   */
  public update() {
    this._selectedWorkflow.setDescriptions(this.authSvc.User.accountSettings.language);
  }

  /**
   * Set current workflow categories
   */
  private setCategories() {
    
  }


  /* private setFavoriteCategory() {
    const favCategory = {categoryData : { category_name : 'favorite_recipes', }};
    this._recipe_categories.push(new WorkflowCategory(favCategory));
  }

  private setRecentCategory() {
    const recCategory = {categoryData : { category_name : 'recent_recipes', }};
    this._recipe_categories.push(new WorkflowCategory(recCategory));
  } */

  get currentRoute() {
    return this._current_route;
  }
  set currentRoute(route : string) {
    this._current_route = route;
  }

  public get jobAssessment(): boolean {
    return this._jobAssessment;
  }
  public set jobAssessment(value: boolean) {
    this._jobAssessment = value;
  }
  private _preference = {
    question: '',
    answer: 0,
    src: ''
  };
  public get preference() {
    return this._preference;
  }
  public set preference(value) {
    this._preference = value;
  }
  private _table: TableQuestionI[];
  public get table(): TableQuestionI[] {
    return this._table;
  }
  public set table(value: TableQuestionI[]) {
    this._table = value;
  }
  private _multiple_choice = {
    total: 0,
    right: 0,
    score: '',
    questions : [],
  };
  public get multiple_choice() {
    return this._multiple_choice;
  }
  public set multiple_choice(value) {
    this._multiple_choice = value;
  }

  launchWorkflow() {
    if(!this.currentWorkflow) {
      this.currentWorkflow = this.selectedWorkflow;
    } else if(this.currentWorkflow.id !== this.selectedWorkflow.id) {
      this.currentWorkflow = this.selectedWorkflow;
    }
    if (this.currentWorkflow.job_answered) {
      this.resetCurrentWorkflowJobExamples();
    }
    if (this.authSvc.User.accountSettings.competency === 2 && this.selectedWorkflow.domain_id === 'cooking') {
      this.router.navigate(['master-chef-workflow']);
    } else {
      // check for timer
      if(this._current_step_id > 0) {
        if (this.timerSvc.timerRunning) {
          this.pausedTimerId = 0;
          this.timerSvc.timerPaused = false;
          this.timerSvc.notifications[this.timerSvc.mainTimerId].paused.next(this.timerSvc.timerPaused);
          this.timerSvc.timerButton = this.timerSvc.timerPaused ? "/assets/base_icons/time_play.svg" : "/assets/base_icons/time_pause.svg";
        }
      }
      this.router.navigate(['workflow']);
    }
  }

  private wfTrackerSubject = new Subject<number>();
  sendWfTrackerEvent() {
    this.wfTrackerSubject.next(1);
  }
  getWfTrackerEvent(): Observable<number> {
    return this.wfTrackerSubject.asObservable();
  }

  private subject = new Subject<number>();
  sendNextStepEvent(id : number, goToNextStep : boolean) {
    if (this.getWFStepById(id) && this.getWFStepById(id+1)) {
      this.getWFStepById(id).current = false;
    }
    if(goToNextStep) {
      if (this.getWFStepById(id)) {
        this.getWFStepById(id).completed = true;
      }
      
      // handle the job_example question
      this.handleJobExampleStep(this.getDisplayStep(id));
  
      // mark display step as complete
      this.getDisplayStep(id).completed = true;
      this.getDisplayStep(id).current = false;
      this._current_step_id++;
      this.getDisplayStep(this._current_step_id).current = true;
      // Verify if the current step is a check step
      if (this.getWFStepById(this._current_step_id)) {
        this.getWFStepById(this._current_step_id).current = true;
      }
      if(this.getDisplayStep(this._current_step_id).initialCheckStep){
        this.initialCheckStepId = this._current_step_id;
      }
    } else {
      // verification step, going back to initial check step
      this.getDisplayStep(id).completed = true;
      this.getDisplayStep(id).current = false;
      this._paused_step_id = id;
      this.resetCheckSteps(id);
      this._current_step_id = this.initialCheckStepId;
      this.getDisplayStep(this._current_step_id).current = true;

      if (this.getWFStepById(this._current_step_id)) {
        this.getWFStepById(this._current_step_id)!.current = true;
      }
    }
    if(this.getDisplayStep(this._current_step_id).time) {
      if(this.getDisplayStep(this._current_step_id).time > 0) {
          this.startTimerEvent();
        }
    }
    this.subject.next(this._current_step_id);
  }
  getNextStepEvent(): Observable<number>{ 
    return this.subject.asObservable();
  }

  private continueWorkflowSubject = new Subject<number>();
  sendContinueWorkflowEvent() {
    this.continueWorkflowSubject.next(this._current_step_id);
  }
  getContinueWorkflowEvent(): Observable<number> {
    return this.continueWorkflowSubject.asObservable();
  }

  private disableSwiperSubject = new Subject<number>();
  sendDisableSwiperEvent() {
    this.disableSwiperSubject.next(1);
  }
  getDisableSwiperEvent(): Observable<number>{
    return this.disableSwiperSubject.asObservable();
  }

  private enableSwiperSubject = new Subject<number>();
  sendEnableSwiperEvent() {
    this.enableSwiperSubject.next(1);
  }
  getEnableSwiperEvent(): Observable<number>{
    return this.enableSwiperSubject.asObservable();
  }

  private disableTouchMoveSwiperSubject = new Subject<number>();
  sendDisableTouchMoveEvent() {
    this.disableTouchMoveSwiperSubject.next(1);
  }
  getDisableTouchMoveEvent(): Observable<number>{
    return this.disableTouchMoveSwiperSubject.asObservable();
  }

  private enableTouchMoveSwiperSubject = new Subject<number>();
  sendEnableTouchMoveEvent() {
    this.enableTouchMoveSwiperSubject.next(1);
  }
  getEnableTouchMoveEvent() {
    return this.enableTouchMoveSwiperSubject.asObservable();
  }

  private closeVideoSubject = new Subject<number>();
  closeVideoEvent() {
    this.closeVideoSubject.next(1);
  }
  getCloseVideoEvent(): Observable<number>{ 
    return this.closeVideoSubject.asObservable();
  }

  private timerSubject = new Subject<number>();
  startTimerEvent() {
    this.timerSubject.next(1);
  }
  getStartTimerEvent(): Observable<number> {
    return this.timerSubject.asObservable();
  }

  private playVideoSubject = new Subject<string>();
  playVideo(videoname : string) {
    this.playVideoSubject.next(videoname);
  }
  getPlayVideoEvent(): Observable<string> {
    return this.playVideoSubject.asObservable();
  }

  get displaySteps() {
    return this._display_steps;
  }

  get wfDisplaySteps() {
    return this._wf_display_steps;
  }

  get initialCheckStepId() {
     return this._initial_checkstep_id;
  }
  set initialCheckStepId(id : number) {
    this._initial_checkstep_id = id;
  }

  get currentStepId() {
    return this._current_step_id;
  }
  set currentStepId(id : number) {
    this._current_step_id = id;
  }

  get pausedStepId() {
    return this._paused_step_id;
  }
  set pausedStepId(id : number) {
    this._paused_step_id = id;
  }


  /**
   * Create the main display steps and the workflow tracker display steps
   */
  private createDisplaySteps() {
    // create different instance of step for display steps (deep copy)
    const currentWorkflowStepsCopy : Step[] = JSON.parse(JSON.stringify(this._currentWorkflow.steps));
    let displayIdCounter = 0;
    this._display_steps = [];
    this._wf_display_steps = [];
    for(const step of currentWorkflowStepsCopy) {
      const newStep = new Step(step);
      newStep._display_id = displayIdCounter;
      this._display_steps.push(newStep);

      const newWFStep = new Step(step);
      newWFStep.stepsTotal = 1;
      newWFStep._display_id = displayIdCounter;

        
      if(step.action_steps && step.action_steps.length > 0) {
        newWFStep.stepsTotal = newWFStep.action_steps.length + 1;
        if (this.authSvc.User.accountSettings.competency === 0) {
          // sous chef - action steps are part of the display steps
          for(const aStep of step.action_steps) {
            displayIdCounter++;
            const newAStep = new Step(aStep);
            newAStep._display_id = displayIdCounter;
            this._display_steps.push(newAStep);
          }
        }
      } else {
        step.action_steps = null;
      }
      this._wf_display_steps.push(newWFStep);
        
      if(step.check_steps && step.check_steps.length > 0) {
        for(let i = 0; i < step.check_steps.length; i++) {
          displayIdCounter++;
          const newCStep = new Step(step.check_steps[i]);
          if (i === 0) {
            newCStep.initialCheckStep = true;
          }
          newCStep._display_id = displayIdCounter;
          
          this._display_steps.push(newCStep);

          const newWFCStep = new Step(step.check_steps[i]);
          newWFCStep._display_id = displayIdCounter;
          this._wf_display_steps.push(newWFCStep);
        }
        step.check_steps = null;
      }
      displayIdCounter++;
    }
  }

  getDisplayStep(id: number) {
    return this._display_steps.find((obj) => obj._display_id ===id);
  }

  getWFStepById(id : number) {
    for(const step of this._wf_display_steps) {
      if(step.  _display_id === id) {
        return step;
      }
      if(step.action_steps !== undefined && step.action_steps.length > 0) {
        for(const aStep of step.action_steps) {
          if(aStep._display_id === id) {
            return aStep;
          }
        }
      }
    }
    return null;
  }

  // reset to original times and also reset timerFlags
  resetStepTime() {
    for(const step of this.displaySteps) {
      if(step.time !== undefined && step.time !== null) {
        // remove timer event subscriptions
        step.timerFlag = true;
        if(step.extra_time === step.time) {
          step.time = step.originalTime;
        }
      }
    }
  }

  resetCheckSteps(id : number) {
    for(let i=this.initialCheckStepId; i<id; i++) {
      this.getDisplayStep(i).completed = false;
      if(this.getDisplayStep(i).extra_time !== undefined && this.getDisplayStep(i).extra_time > 0) {
        this.getDisplayStep(i).time = this.getDisplayStep(i).extra_time;
      }
    }
  }

  public isMainCurrent(step: Step) {
    if(step.current) {
        return true;
    }
    if(step.action_steps && step.action_steps.length > 0) {
        for(const aStep of step.action_steps) {
            if(aStep.current) {
                return true;
            }
        }
    }
    return false;
}

  isCheckStep(id: number) {
    for(const step of this._display_steps) {
      if(step.check_steps && step.check_steps.length > 0) {
        if (step.check_steps.some((obj) => obj._display_id === id)) {
          return true;
        }
      }
    }
    return false;
  }

  pauseTimers() {
    this.pausedTimerId = this.timerSvc.mainTimerId;
    if (this.timerSvc.timerRunning) {
      this.timerSvc.timerPaused = true;
      this.timerSvc.notifications[this.timerSvc.mainTimerId].paused.next(true);
      this.timerSvc.timerButton = this.timerSvc.timerPaused ? "/assets/base_icons/time_play.svg" : "/assets/base_icons/time_pause.svg";
    }
  }

  get difficultyPicto() {
    return this._difficultyPicto;
  }
  set difficultyPicto(picto: string) {
    this._difficultyPicto = picto;
  }

  get difficultyText() {
    return this._difficultyText;
  }
  set difficultyText(difficulty : string) {
    this._difficultyText = difficulty;
  }


  get pausedTimerId() {
    return this._paused_timer_id;
  }
  set pausedTimerId(id : number) {
    this._paused_timer_id = id;
  }

  get pausedTime() {
    return this._paused_time;
  }
  set pausedTime(time : number) {
    this._paused_time = time;
  }

  // Job example section
  /**
   * Submit the jobExample question results in the users profile
   */
  async submitJobExampleResults() {
    const questionArray = [];
    for (const step of this._currentWorkflow.steps) {
      if(step.preference) {
        questionArray.push({preference:{question: step.preference.question, answer: step.preference.answer}});
      }
      if(step.table) {
        questionArray.push({table:{question: step.table.question, answer: step.table.answer}});
      }
      if(step.multiple_choice) {
        if(step.multiple_choice.answer) {
          questionArray.push({multiple_choice:{question: step.multiple_choice.question, choice: step.multiple_choice.choice, answer: step.multiple_choice.answer}});
        } else {
          questionArray.push({multiple_choice:{question: step.multiple_choice.question, choice: step.multiple_choice.choice}});
          }
      }
    }
    
    const submitValue : object = {
      [this._currentWorkflow.id] : questionArray
    }
    if(!this.spinnerSvc.active) {
      this.spinnerSvc.show(this.translatorSvc.getLabel('submittingResults'));
    }
    await this.setJobExampleResultsFn(submitValue);
    const foundExampleIndex = this.authSvc.User.job_examples.findIndex((obj: unknown) => obj[this._currentWorkflow.id] !== undefined);
    if(foundExampleIndex !== -1) {
      this.authSvc.User.job_examples.splice(foundExampleIndex, 1);
    }
    this.authSvc.User.job_examples.push(submitValue as JobExampleI);
    this.spinnerSvc.hide();
    this._currentWorkflow.job_answered = true;
    this.router.navigate(['domain']);
  }

  private async setJobExampleResultsFn(result: object) : Promise<object> {
    const wfCall = this.fns.httpsCallable('httpsSetJobExampleResults');
    return await firstValueFrom(wfCall({result: result}).pipe(first()));
  }

  resetCurrentWorkflowJobExamples() {
    this._multiple_choice.questions = [];
    this._multiple_choice.total = 0;
    this._multiple_choice.right =0;
    this._multiple_choice.score = null;
    this._preference.answer = null;
    this._table = [];
    this._current_step_id = 0;
    this._selectedWorkflow.job_answered = false;
  }

  updateCurrentWorkflowJobExamples() {
    this._jobAssessment = false;
    if(this.authSvc.User.job_examples) {
      this._jobAssessment = true;
      this.resetCurrentWorkflowJobExamples()
      for(const workflow of this.authSvc.User.job_examples) {
        const workflowId = Object.keys(workflow)[0];
        if( workflowId === this.selectedWorkflow.id) {
          this._selectedWorkflow.job_answered = true;
          for(const question of workflow[workflowId]) {
            const questionId = Object.keys(question)[0];
            switch(questionId) {
              case 'preference' :
                this._preference.src = utils.getPreferenceIcon(question[questionId].answer);
                break;
              case 'table' :
                this._table.push({
                  question: question[questionId].question,
                  answer: question[questionId].answer,
                  answer_text: utils.getTableAnswer(question[questionId].answer, this.authSvc.User.accountSettings.language)
                });
                break;
              case 'multiple_choice' :
                if(question[questionId].answer) {
                  this._multiple_choice.questions.push({
                    question: question[questionId].question,
                    choice: question[questionId].choice,
                    answer: question[questionId].answer,
                    src: utils.getMultiIcon(false)
                  });
                  // answered wrong
                  this._multiple_choice.total++;
                  this._multiple_choice.score = this._multiple_choice.right.toString() + '/' + this._multiple_choice.total.toString();
                } else {
                  this._multiple_choice.questions.push({
                    question: question[questionId].question,
                    choice: question[questionId].choice,
                    src: utils.getMultiIcon(true)
                  });
                  this._multiple_choice.total++;
                  this._multiple_choice.right++;
                  this._multiple_choice.score = this._multiple_choice.right.toString() + '/' + this._multiple_choice.total.toString();
                }
                break;
            }
          }
        }
      }
    } else {
      this.resetCurrentWorkflowJobExamples();
      this._jobAssessment = false;
    }
  }

  handleJobExampleStep(step: Step) {
    switch(step.stepType) {
      case StepType.Preference :
        this._preference.question = step.preference.question;
        this._preference.answer = step.preference.answer;
        this._preference.src = utils.getPreferenceIcon(this._preference.answer);
        break;
      case StepType.Table:
        this._table.push({
          question: step.table.question,
          answer: step.table.answer,
          answer_text: utils.getTableAnswer(step.table.answer, this.authSvc.User.accountSettings.language)
        });
        break;
      case StepType.MultipleChoice:
        for(const choice of step.multiple_choice.choices) {
          if(choice.selected) {
            if(choice.answer) {
              this._multiple_choice.questions.push({
                question: step.multiple_choice.question,
                choice: choice.choice,
                answer: choice.answer,
                src: utils.getMultiIcon(false)
              });
            } else {
              this._multiple_choice.questions.push({
                question: step.multiple_choice.question,
                choice: choice.choice,
                src: utils.getMultiIcon(true),
              });
            }
            
            if(choice.answer) {
              // answered wrong
              this._multiple_choice.total++;
              this._multiple_choice.score = this._multiple_choice.right.toString() + '/' + this._multiple_choice.total.toString();
            } else {
              this._multiple_choice.total++;
              this._multiple_choice.right++;
              this._multiple_choice.score = this._multiple_choice.right.toString() + '/' + this._multiple_choice.total.toString();
            }
          }
        }
        break;
    }
  }
}
