<div class="recipe-editor-container" cdkDropListGroup>
    <div class="recipe-editor-wrapper">
        <div class="recipe-editor-list-container">
            <app-recipe-editor-list-item *ngFor="let recipe of editorSvc.workflows; trackBy:trackIndex" [recipe]="recipe"></app-recipe-editor-list-item>
            <div class="modal-text-button" (click)="createNewWorkflow()">+ Add new workflow</div>
        </div>
        <div class="recipe-editor-recipe-details-container">
            <div class="top-detail-container">
                <!-- <div class="picto-container">
                    <img class="recipe-img" src="/assets/pictos/workflows/{{editorSvc.selectedWorkflowName!}}.svg" alt={{editorSvc.selectedWorkflowName!}}>
                </div> -->
                <div class="detail-group-container">
                    <div class="detail-field">
                        <label for="titleEn">Title (EN)</label>
                        <input #titleEn [(ngModel)]="editorSvc.selectedWorkflow.title_en"/>
                    </div>
                    <div class="detail-field">
                        <label for="titleFr">Title (FR)</label>
                        <input #titleFr [(ngModel)]="editorSvc.selectedWorkflow.title_fr"/>
                    </div>
                    <div class="detail-field-container">
                        <div class="detail-field">
                            <label for="minYield">Yield (min)</label>
                            <input #minYield [(ngModel)]="asRecipe(editorSvc.selectedWorkflow).min_yield"/>
                        </div>
                        <div class="detail-field" style="margin-left: 8px;">
                            <label for="maxYield">Yield (max)</label>
                            <input #maxYield [(ngModel)]="asRecipe(editorSvc.selectedWorkflow).max_yield"/>
                        </div>
                    </div>
                    <div class="detail-field-container">
                        <div class="detail-field">
                            <label for="minPrep">Prep Time (min)</label>
                            <input #minPrep [(ngModel)]="asRecipe(editorSvc.selectedWorkflow).min_prepTime"/>
                        </div>
                        <div class="detail-field" style="margin-left: 8px;">
                            <label for="maxPrep">Prep Time (max)</label>
                            <input #maxPrep [(ngModel)]="asRecipe(editorSvc.selectedWorkflow).max_prepTime"/>
                        </div>
                    </div>
                    <div class="detail-field-container">
                        <div class="detail-field">
                            <label for="minCook">Cook Time (min)</label>
                            <input #minCook [(ngModel)]="asRecipe(editorSvc.selectedWorkflow).min_cookTime"/>
                        </div>
                        <div class="detail-field" style="margin-left: 8px;">
                            <label for="maxCook">Cook Time (max)</label>
                            <input #maxCook [(ngModel)]="asRecipe(editorSvc.selectedWorkflow).max_cookTime"/>
                        </div>
                    </div>
                    <div class="detail-field-container">
                        <div class="detail-field">
                            <label for="difficulty">Difficulty</label>
                            <input #difficulty [(ngModel)]="asRecipe(editorSvc.selectedWorkflow).difficulty"/>
                        </div>
                        <div class="detail-field" style="margin-left: 8px;">
                            <label for="production">In Production</label>
                            <input type="checkbox" #production [(ngModel)]=" editorSvc.selectedWorkflow.production"/>
                        </div>
                    </div>
                </div>
                <div class="detail-field-vertical">
                    <label for="descr_en">Description (EN)</label>
                    <textarea #descr_en rows="4" [(ngModel)]="editorSvc.selectedWorkflow.description_en"></textarea>
                    <label for="descr_fr">Description (FR)</label>
                    <textarea #descr_fr rows="4" [(ngModel)]="editorSvc.selectedWorkflow.description_fr"></textarea>
                </div>
                <div class="button-container">
                    <div class="modal-text-button" (click)="saveWorkflow()" >Save Workflow</div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="editableWorkflow-container">
        <div class="workflow-item-container firstWfElement">
            <div class="workflow-item-picto">
                <img src="/assets/pictos/actions/wash_hands.svg">
                <div class="workflow-item-title">Wash Hands</div>
            </div>
        </div>
        
        <div id="recipe_steps"
            class="recipe-step-container"
            cdkDropList
            [cdkDropListConnectedTo]="['actionsList', 'ingredientsList']"
            cdkDropListOrientation="horizontal" 
            [cdkDropListData]="editorSvc.selectedWorkflow.steps"
            (cdkDropListDropped)="drop($event)">
                <app-recipe-step-item
                (click)="editorSvc.selectItem(workflowItem)"
                class="recipe-step-item"
                cdkDrag 
                *ngFor="let workflowItem of editorSvc.selectedWorkflow.steps" 
                [workflowItem]="workflowItem"></app-recipe-step-item>
        </div>
    </div>
    
    <div class="workflow-viewer-tabs-container">
        <div class="tab">
            <button class="tablinks active" (click)="openTab($event, 'details')">{{editorSvc.selectedWfItemName}}</button>
            <button class="tablinks" (click)="openTab($event, 'actions');selectItemType('actions')">{{translatorSvc.getLabel('actions')}}</button>
            <button class="tablinks" (click)="openTab($event, 'ingredients');selectItemType('ingredients')">{{translatorSvc.getLabel('ingredients')}}</button>
            <!-- <button class="tablinks" (click)="openTab($event, 'verifications');selectItemType('verifications')">{{translatorSvc.getLabel('verifications')}}</button> -->
        </div>
        <div id="details" class="details-container tabcontent">
            <div class="workflow-item-details">
                <div *ngIf="editorSvc.selectedWorkflow.steps[editorSvc.newWorkflowSelectedIndex].action then action else ingredient"></div>
            </div>
        </div>
        <div id="actions" class="actions-container tabcontent">
            <div id="actionsList"
            cdkDropList 
            [cdkDropListConnectedTo]="['recipe_steps', 'ingredient_action_steps']" 
            [cdkDropListData]="editorSvc.actions"
            [cdkDropListEnterPredicate]="noReturnPredicate"
            class="actions-list"><!-- (cdkDropListDropped)="drop($event)" -->
                <app-add-workflow-item-type class="dragItem" cdkDrag *ngFor="let item of editorSvc.actions" [item]="item"></app-add-workflow-item-type>
            </div>
        </div>
        <div id="ingredients" class="ingredients-container tabcontent">
            <div id="ingredientsList"
            cdkDropList 
            [cdkDropListConnectedTo]="['recipe_steps', 'ingredient_action_steps', 'ingredient_action_steps']" 
            [cdkDropListData]="editorSvc.ingredients"
            [cdkDropListEnterPredicate]="noReturnPredicate"
            class="ingredients-list"><!-- (cdkDropListDropped)="drop($event)" -->
                <app-add-workflow-item-type class="dragItem" cdkDrag *ngFor="let item of editorSvc.ingredients" [item]="item"></app-add-workflow-item-type>
            </div>
        </div>
        <!-- <div id="verifications" class="verifications-container tabcontent">
            <div id="verificationsList"
            cdkDropList 
            [cdkDropListConnectedTo]="['swiperList']" 
            [cdkDropListData]="editorSvc.verifications"
            [cdkDropListEnterPredicate]="noReturnPredicate"
            (cdkDropListDropped)="drop($event)"
            class="verifications-list">
                <app-add-workflow-item-type class="dragItem" cdkDrag *ngFor="let item of editorSvc.verifications" [item]="item"></app-add-workflow-item-type>
            </div>
        </div> -->
    </div>
    
    
</div>

<ng-template #notAction>
    <div *ngIf="editorSvc.selectedWorkflow.steps[editorSvc.newWorkflowSelectedIndex-1].ingredient then ingredient else verification"></div>
</ng-template>

<ng-template #ingredient>
    <div class="selected-ingredient-workflow-step-container">
        <div>
            <div>Name</div>
            <input type="text" [(ngModel)]="editorSvc.selectedWorkflow.steps[editorSvc.newWorkflowSelectedIndex].ingredient.ingredient_name"/>
            <div id="ingredient_action_steps"
                class="ingredient-action-step-container"
                cdkDropList
                [cdkDropListConnectedTo]="['actionsList', 'ingredientsList']"
                cdkDropListOrientation="horizontal" 
                [cdkDropListData]="editorSvc.selectedWorkflow.steps[editorSvc.newWorkflowSelectedIndex].action_steps"
                (cdkDropListDropped)="drop($event)">
                    <app-recipe-step-item
                        (click)="editorSvc.ingredientActionSelectItem(action_step)"
                        class="ingredient-action-item"
                        cdkDrag 
                        *ngFor="let action_step of editorSvc.selectedWorkflow.steps[editorSvc.newWorkflowSelectedIndex].action_steps" 
                        [workflowItem]="action_step"></app-recipe-step-item>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #action>
    <div class="selected-action-workflow-step-container">
        <div>
            <div>Name</div>
            <input type="text" [(ngModel)]="editorSvc.selectedWorkflow.steps[editorSvc.newWorkflowSelectedIndex].action.action_name"/>
            <div id="action_action_steps"
                class="action-action-step-container"
                cdkDropList
                [cdkDropListConnectedTo]="['actionsList', 'ingredientsList']"
                cdkDropListOrientation="horizontal" 
                [cdkDropListData]="editorSvc.selectedWorkflow.steps[editorSvc.newWorkflowSelectedIndex].action_steps"
                (cdkDropListDropped)="drop($event)">
                    <app-recipe-step-item
                        (click)="editorSvc.actionActionSelectItem(action_step)"
                        class="action-action-item"
                        cdkDrag 
                        *ngFor="let action_step of editorSvc.selectedWorkflow.steps[editorSvc.newWorkflowSelectedIndex].action_steps" 
                        [workflowItem]="action_step"></app-recipe-step-item>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #verification>
    Verification
</ng-template>