import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Subscription, firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from '../../admin/services/auth.service';
import { ProductService } from '../../core/services/product.service';
import { Invoice, TaxItem } from '../../models/invoice.model';
import { SpinnerOverlayService } from '../../tools/services/spinner-overlay.service';
import { Canada_tax } from '../canada_tax.model';
import { environment } from 'src/environments/environment';
import { DomainService } from 'src/app/core/services/domain.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserConfirmationComponent } from 'src/app/modal/components/user-confirmation/user-confirmation.component';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { PaymentMethodI } from 'src/app/interfaces/stripe/payment-method.interface';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

export class PaymentService {

  private _total_charge : number;
  private _total_tax: number;
  private _chargeValid : boolean = false;
  private _subscription_id : string;
  private _invoice : Invoice;
  private loginEvent : Subscription;
  clientSecret : string;
  paymentIntent: object;
  public customerPaymentMethods : PaymentMethodI;
  paymentMethodId: string;
  public stripe : stripe.Stripe;

  private _shipping: ShippingI;

  constructor(
    private functions: AngularFireFunctions,
    private authSvc : AuthService,
    private productSvc : ProductService,
    private spinnerSvc : SpinnerOverlayService,
    private matDialog: MatDialog,
    private domainSvc : DomainService,
    private translatorSvc : TranslatorService,
    private router : Router
    ) { 
      if(this.authSvc.User) {
        this._invoice = new Invoice();
        this.stripe = Stripe(environment.stripePublishable, {
          locale: this.authSvc.User.accountSettings.language  
        });
      }
      
    }

  getSubTotal() {
    this._invoice.domain_subscription_total = this.productSvc.domainsToPurchase.length * 5.99;
    this._invoice.workflow_subscription_total = this.productSvc.workflowsToPurchase.length * 0.49;
      
    for(const item of this.productSvc.itemsToPurchase) {
      this._invoice.subTotal += item.price*item.quantity;
    }

  }

  resetTaxes() {
    this._invoice.taxes =[];
    this._invoice.totalAmount = 0;
  }

  getCountryTaxes(state: string, country: string) {
    // search the taxRates to return the right map
    switch(country) {
      case "Canada" :
        { const canadaTax = new Canada_tax();
          return canadaTax.getTaxes(state);
        }
      default :
      return null;
    }
  }
  

  async proceedToOrder() {
    this.spinnerSvc.show(this.translatorSvc.getLabel('loadingUserPaymentMethods'));
    this.customerPaymentMethods = await this.getPaymentMethods();
    this.spinnerSvc.hide();
    this.updateInvoice();
    this._chargeValid = true;
    this.router.navigate(['order']);
    
  }

  /* private subject = new Subject<number>();
  async sendAddressUpdatedEvent() {
    this.subject.next(1);
  }
  getAddressUpdatedEvent(): Observable<number>{ 
    return this.subject.asObservable();   
  } */

  async updateInvoice() {
    this._invoice.subTotal = 0;
    this._invoice.totalAmount = 0;
    this._total_tax = 0;
    if (this.productSvc.itemsToPurchase && this.productSvc.itemsToPurchase.length > 0) {
      
      //this._invoice.workflow_subscription_total = 0.49 * this.productSvc.workflowsToPurchase.length;
      

      if(this._invoice.taxes.length > 0) {
        this.resetTaxes();
      }
      const taxes : Map<string, number> = this.getCountryTaxes(this._shipping.address.state, this._shipping.address.country);
      if(taxes) {
        this.total_tax = 0;
      }
      for(const tax_item of taxes) {
        this._invoice.taxes.push(new TaxItem(tax_item[0], tax_item[1]));
        }
      for (const item of this.productSvc.itemsToPurchase) {
        if (item.taxable) {
          for (const tax_item of this._invoice.taxes) {
            this._total_tax = this._total_tax + ((item.price*item.quantity) * tax_item.taxAmount);
          }
        }
        this._invoice.subTotal = this._invoice.subTotal + item.price*item.quantity;
      }
    
    
      this._invoice.totalAmount = this._invoice.subTotal + this._total_tax;

      if(this._invoice.totalAmount > 0) {
        if(!this.paymentIntent) {
          console.log('amt :', this._invoice.totalAmount);
          this.paymentIntent = await this.createPaymentIntent();
        } else {
          this.paymentIntent = await this.updatePaymentIntent();
        }
      }
    } else {
      this._invoice.taxes = [];
      // purchase of domain
      this._invoice.domain_subscription_total = 5.99 * this.productSvc.domainsToPurchase.length;
      this._invoice.totalAmount = this._invoice.subTotal = this._invoice.subTotal + this._invoice.domain_subscription_total;
    }
  }

  async processPurchase(card : stripe.elements.Element) {
    let paymentIntentResult : stripe.PaymentIntentResponse = null;
    if(Object(this.paymentIntent).status === 'succeeded' || Object(this.paymentIntent).status === 'requires_payment_method') {
      if (!this.paymentMethodId) {
        const paymentMethod = {
          card: card,
          billing_details: {
            name: this.authSvc.User.accountSettings.firstName + ' ' + this.authSvc.User.accountSettings.lastName,
            email: this.authSvc.User.accountSettings.email
          }
        }
        paymentIntentResult = await this.stripe.confirmCardPayment(
        Object(this.paymentIntent).client_secret,
        {payment_method : paymentMethod,
          receipt_email : this.authSvc.User.accountSettings.email,
        });
      } else {
        paymentIntentResult = await this.stripe.confirmCardPayment(
          Object(this.paymentIntent).client_secret,
          {payment_method : this.paymentMethodId,
            receipt_email : this.authSvc.User.accountSettings.email,
          });
      }
      
      if(paymentIntentResult.error) {
        console.log(paymentIntentResult.error.message);
      }

      await this.updateDefaultPaymentMethod(paymentIntentResult.paymentIntent.payment_method);
      if(paymentIntentResult.paymentIntent.status === 'succeeded') {
        this.processPaymentResult();
      }

      // update stripe info with shipping address and name
      this.spinnerSvc.show(this.translatorSvc.getLabel('updatingAddressInformation'));
      await this.updateStripeCustomer({shipping:this.shipping});
      this.spinnerSvc.hide();

    } else {
      console.log('Payment Intent not successful');
    }
  }

  async processSubscriptions(card : stripe.elements.Element) {
    if (card) {
      // create a default payment method
      const paymentMethodCreateResult = await this.stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: this.authSvc.User.accountSettings.firstName + ' ' + this.authSvc.User.accountSettings.lastName,
          email: this.authSvc.User.accountSettings.email
        }
      });
      // attach payment method and set as default  
      await this.attachPaymentMethod(paymentMethodCreateResult.paymentMethod.id);
    } else if(this.paymentMethodId) {
      if(Object(this.authSvc.User.data).stripeData.invoice_settings.default_payment_method !== this.paymentMethodId) {
        await this.updateDefaultPaymentMethod(this.paymentMethodId);
      }
    }
    const creatDomainSubscriptionResult = await this.createDomainSubscription(this.productSvc.domainsToPurchase, []);
    if(creatDomainSubscriptionResult) {
      this.confirmSubscription();
      this.productSvc.domainsToPurchase = [];
      // Reload User
      this.authSvc.deleteUserFields(['purchaseDomains']);
    }
  }

  async processPaymentResult() {
      const domainsFromItems = this.getDomainsFromItems();
      const updateUserDomainsResult = await this.authSvc.updateUserDomains(domainsFromItems);
      const purchasedItems = [];
      for(const item of this.productSvc.itemsToPurchase) {
        purchasedItems.push(Object(item).stripe_id);
      }
      await this.authSvc.updateUserItems(purchasedItems);
      if(updateUserDomainsResult) {
        this.confirmDomainChange();
        // Remove items from products
        this.productSvc.itemsToPurchase = [];
        // Reload User
        this.authSvc.deleteUserFields(['purchaseItems']);
      }
  }

  getDomainsFromItems() {
    const itemsDomains : string[] = [];
    for(const item of this.productSvc.itemsToPurchase) {
      itemsDomains.push(Object(item).domain_id);
    }
    return itemsDomains;
  }

  private confirmDomainChange() {
    let purchasedDomainString : string = '';
    for (const domainString of this.getDomainsFromItems()) {
      const domain = this.domainSvc.getDomain(domainString);
      if (purchasedDomainString.length === 0) {
        purchasedDomainString = domain.title;
      } else {
        purchasedDomainString = ', ' + domain.title;
      }
    }
    let buttonTxt = 'Close'
    let txtMsge = 'Purchase successful.\nYou now have access to the ' + purchasedDomainString + ' domain';
    switch(this.authSvc.User.accountSettings.language) {
      case 'fr':
        buttonTxt = 'Fermer'
        txtMsge = 'Achat réussi.\nVous avez maintenant accès au domaine ' + purchasedDomainString;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-confirn-plan-change-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "confirm-plan",
      actionButtonText1 : buttonTxt,
      hideButton2 : true,
      message: txtMsge
    };
    
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  private confirmSubscription() {
    let purchasedDomainString : string = '';
    
    for (const domainString of this.productSvc.domainsToPurchase) {
      
      if (purchasedDomainString.length === 0) {
        purchasedDomainString = domainString;
      } else {
        purchasedDomainString = ', ' + domainString;
      }
    }
    let buttonTxt = 'Close'
    let txtMsge = 'Payment successful.\nYou are now subscribed to the ' + purchasedDomainString + ' domain';
    switch(this.authSvc.User.accountSettings.language) {
      case 'fr':
        buttonTxt = 'Fermer'
        txtMsge = 'Paiement réussi.\nVous êtes maintenant abonné au domaine ' + purchasedDomainString;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-confirn-plan-change-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "confirm-plan",
      actionButtonText1 : buttonTxt,
      hideButton2 : true,
      message: txtMsge
    };
    
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  get chargeValid() {
    return this._chargeValid;
  }
  set chargeValid(cv : boolean) {
    this._chargeValid = cv;
  }

  get subscriptionId() {
    return this._subscription_id;
  }
  set subscriptionId(subId :string) {
    this._subscription_id = subId;
  }

  get invoice() {
    return this._invoice;
  }

  public get total_tax(): number {
    return this._total_tax;
  }
  public set total_tax(value: number) {
    this._total_tax = value;
  }

  public get shipping(): ShippingI {
    return this._shipping;
  }
  public set shipping(value: ShippingI) {
    this._shipping = value;
  }

  private async paymentsGetInvoice(productId: string, state: string, country:string) : Promise<object> {
    const paymentsGetInvoiceCall = this.functions.httpsCallable('paymentsGetInvoice');
    return await firstValueFrom(paymentsGetInvoiceCall({productId:productId, state: state, country: country}).pipe(first()));
  }

  private async createPaymentIntent() : Promise<object> {
    const paymentIntentCall = this.functions.httpsCallable('stripeCreatePaymentIntent');
    return await firstValueFrom(paymentIntentCall({amount: Math.round(this.invoice.totalAmount*100)}).pipe(first()));
  }

  private async updatePaymentIntent() : Promise<object> {
    const updatePaymentIntentCall = this.functions.httpsCallable('stripeUpdatePaymentIntent');
    return await firstValueFrom(updatePaymentIntentCall({paymentIntentId: Object(this.paymentIntent).id, amount: Math.round(this.invoice.totalAmount*100)}).pipe(first()));
  }

  private async attachPaymentMethod(paymentMethodId: string) : Promise<object> {
    const attachPaymentMethodCall = this.functions.httpsCallable('stripeAttachPaymentMethod');
    return await firstValueFrom(attachPaymentMethodCall({paymentMethodId: paymentMethodId}).pipe(first()));
  }

  private async updateDefaultPaymentMethod(payment_method_id : string) : Promise<object> {
    const updateDefaultPaymentMethodCall = this.functions.httpsCallable('stripeUpdateDefaultPaymentMethod');
    return await firstValueFrom(updateDefaultPaymentMethodCall({paymentMethodId: payment_method_id}).pipe(first()));
  }

  private async createDomainSubscription(domains: string[], workflows: string[]) : Promise<object> {
    const createSubscriptionCall = this.functions.httpsCallable('stripeCreateSubscription');
    return await firstValueFrom(createSubscriptionCall({domains: domains, workflows: workflows}).pipe(first()));
  }

  private getPaymentMethods() : Promise<PaymentMethodI> {
    const getPaymentMethodsCall = this.functions.httpsCallable('stripeGetCustomerPaymentMethods');
    return firstValueFrom(getPaymentMethodsCall({}).pipe(first()));
  }

  private updateStripeCustomer(data: object) : Promise<unknown> {
    const wfCall = this.functions.httpsCallable('stripeUpdateCustomer');
    return firstValueFrom(wfCall({data: data}).pipe(first()));
  }
}

interface ShippingI {
  address : {
    ship_address: string;
    address2 : string;
    locality : string;
    state : string;
    postcode : string;
    country : string;
  },
  name : string;
  
}

