import { AcademyService } from '../../services/academy.service';
import { GameObject } from '../../models/game-object';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { AutonomeWorldMap } from '../../models/autonome-world-map';
import { Person } from '../../models/person';
import { DirectionInput } from '../../models/direction-input';
import { KeyPressListener } from '../../models/key-press-listener';

@Component({
  selector: 'app-grocery-store',
  templateUrl: './autonome-world.component.html',
  styleUrls: ['./autonome-world.component.scss']
})
export class AutonomeWorldComponent implements AfterViewInit {
  @ViewChild('groceryCanvas') groceryCanvas: ElementRef<HTMLCanvasElement>;
  private ctx : CanvasRenderingContext2D;
  private map : AutonomeWorldMap;
  private directionInput : DirectionInput;
  

  constructor(
    private spinnerSvc : SpinnerOverlayService,
    public academySvc : AcademyService
  ) { 
    if(spinnerSvc.active) {
      spinnerSvc.hide(1);
    }
    this.map = null;
    
  }

  ngAfterViewInit(): void {
    this.ctx = this.groceryCanvas.nativeElement.getContext("2d");
    this.startMap(this.autonomeWorldMaps.Kitchen);
    this.bindActionInput();
    this.bindHeroPositionCheck();
    this.directionInput = new DirectionInput();
    this.directionInput.init();
    this.startGameLoop();
    this.map.startCutscene([
      { type: "battle" },
      //{ type: "changeMap", map: "DemoRoom"},
      //{ type: "textMessage", text: "Hello there! How are you doing today?"},
    ])

  }

  startMap(mapConfig) {
    this.map = new AutonomeWorldMap(mapConfig, this.academySvc);
    this.map.autonomeWorld = this;
    this.map.mountObjects();
  }

  bindActionInput() {
    new KeyPressListener("Enter", () => {
      // is there a person here to talk to
      this.map.checkForActionCutscene();
    });
  }

  bindHeroPositionCheck() {
    document.addEventListener("PersonWalkingComplete", e => {
      if((e as CustomEvent).detail.whoId === "hero") {
        // Hero's position has changed
        this.map.checkForFootstepCutscene();
      }
    });
  }


  startGameLoop() {
    type GameObjectOrPerson = GameObject | Person;
    const step = () => {
      this.ctx.clearRect(0,0, this.groceryCanvas.nativeElement.width, this.groceryCanvas.nativeElement.height);

      // Establish the camera person
      const cameraPerson = this.map.gameObjects.hero;

      Object.values(this.map.gameObjects).forEach((object)  => {
        if(this.academySvc.isPerson(object as Person | GameObject)) {
          (object as Person).update({
            arrow: this.directionInput.direction,
            map: this.map,
          });
        }
      });

      // Lower layer
      this.map.drawLowerImage(this.ctx, cameraPerson);

      Object.values(this.map.gameObjects).sort((a,b) => {
        return (a as GameObjectOrPerson).y - (b as GameObjectOrPerson).y;
      }).forEach((object)  => {
        if(this.academySvc.isPerson(object as Person | GameObject)) {
          (object as Person).sprite.draw(this.ctx, cameraPerson);
        } else {  
          (object as GameObjectOrPerson).sprite.draw(this.ctx, cameraPerson);
        }
      });

      // Upper layer
      this.map.drawUpperImage(this.ctx, cameraPerson);

      requestAnimationFrame(() => {
        step();
      });
    }
    step();
  }

  autonomeWorldMaps = {
    DemoRoom: {
        lowerSrc: "/assets/academy/maps/DemoLower.png",
        upperSrc: "/assets/academy/maps/DemoUpper.png",
        gameObjects: {
            hero: new Person({
                isPlayerControlled : true,
                x:this.academySvc.withGrid(5),
                y:this.academySvc.withGrid(6),
            }, this.academySvc),
            npc1: new Person({
                x:this.academySvc.withGrid(7),
                y:this.academySvc.withGrid(9),
                src: "/assets/academy/characters/people/npc1.png",
                behaviourLoop: [
                  { type: "stand", direction: "left", time: 800},
                  { type: "stand", direction: "up", time: 800},
                  { type: "stand", direction: "right", time: 1200},
                  { type: "stand", direction: "down", time: 300},
                ],
                talking: [
                  {   
                    events: [
                        { type: "textMessage", text: "I'm busy...", faceHero: "npc1"},
                        { type: "textMessage", text: "Go away!"},
                        { who: "hero", type: "walk", direction: "up"},
                      ]
                  }
                ]
              }, this.academySvc), 
            npc2: new Person({
                x:this.academySvc.withGrid(8),
                y:this.academySvc.withGrid(5),
                src: "/assets/academy/characters/people/npc2.png",
                /* behaviourLoop: [
                  { type: "walk", direction: "left"},
                  { type: "stand", direction: "up", time: 800},
                  { type: "walk", direction: "up"},
                  { type: "walk", direction: "right"},
                  { type: "walk", direction: "down"},
                ] */
              }, this.academySvc), 
        },
        walls : {
          [this.academySvc.asGridCoord(7,6)] : true,
          [this.academySvc.asGridCoord(8,6)] : true,
          [this.academySvc.asGridCoord(7,7)] : true,
          [this.academySvc.asGridCoord(8,7)] : true,
        },
        cutsceneSpaces : {
          [this.academySvc.asGridCoord(7,4)] : [ {
            events: [
              { who: "npc2", type:"walk", direction: "left"},
              { who: "npc2", type:"stand", direction: "up", time: 800},
              { type: "textMessage", text: "You can't be in there!!"},
              { who: "npc2", type:"walk", direction: "right"},
              { who: "hero", type:"walk", direction: "down"},
              { who: "hero", type:"walk", direction: "left"},

            ]
          }],
          [this.academySvc.asGridCoord(5,10)] : [ {
            events: [
              { type:"changeMap", map: "Kitchen"}
            ]
          }]
        }

    },
    Kitchen: {
        lowerSrc: "/assets/academy/maps/KitchenLower.png",
        upperSrc: "/assets/academy/maps/KitchenUpper.png",
        gameObjects: {
            hero: new Person({
              isPlayerControlled : true,
              x:this.academySvc.withGrid(3),
              y:this.academySvc.withGrid(5),
            }, this.academySvc),
            npcA: new Person({
              x:this.academySvc.withGrid(10),
              y:this.academySvc.withGrid(8),
              src: "/assets/academy/characters/people/npc3.png",
              talking: [
                {
                  events: [
                    { type: "textMessage", text: "You made it!!", faceHero:"npcA"}
                  ]
                }
              ]
            }, this.academySvc)  
        }
    }
}

}
