import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import getUserLocale from 'get-user-locale';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../admin/services/auth.service'
import { Product } from '../../../models/product.model';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslatorService } from '../../../tools/services/translator.service';
import { SpinnerOverlayService } from '../../../tools/services/spinner-overlay.service';
import { ProductService } from 'src/app/core/services/product.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent{

  @ViewChild('profile_modal', {static: false}) profileModal: ElementRef;

  _userLocale : string = "en";

  modalTitle : string = "Profile Settings";

  readOnlyFirstName : boolean = true;
  readOnlyLastName : boolean = true;
  readOnlyEmail : boolean = true;

  languageChangedBool : boolean = false;

  pencilEdit_Icon : string = "/assets/base_icons/icon_pencil_edit.svg";
  checkMark_Icon : string = "/assets/base_icons/icon_step_checked.svg";
  firstNameEditIcon : string = this.pencilEdit_Icon;
  lastNameEditIcon : string = this.pencilEdit_Icon;
  emailEditIcon : string = this.pencilEdit_Icon;
  levels : LvlI[];
  products: Product[];
  languages : string[];
  openProfileEventSubscription!: Subscription;


  constructor(
    public dialogRef: MatDialogRef<ProfileComponent>,
    public authSvc: AuthService,
    private productSvc : ProductService,
    public translatorSvc : TranslatorService,
    private spinnerSvc: SpinnerOverlayService,
    private router: Router) 
    {

      this.levels = [];
      this.initiateLevels();
      this.languages = [];
      this.initiateLanguages();
      if(!this.authSvc.User.accountSettings.language) {
        this._userLocale = getUserLocale();
        if(this._userLocale.length>2) {
          this._userLocale = this._userLocale.slice(0,2);
        }
      } else {
        this._userLocale = this.authSvc.User.accountSettings.language;
      }
      switch (this._userLocale) {
        case 'fr' :
          this.modalTitle = "Paramètres de Profil";
      }
      /* if(this.authSvc.User.products) {
        this.products = [];
        for(const product of this.authSvc.User.products) {
          const foundProduct = this.productSvc.products.find((obj) => {
            return obj.id = product;
          })
          if(foundProduct) {
            this.products.push(foundProduct);
          }
        }
      } */
    }

  initiateLevels() {
    if(this.levels.length === 0) {
      this.levels.push({
        id: 0,
        lbl: this.translatorSvc.getLabel('sousChef')
      });
      this.levels.push({
        id: 1,
        lbl: this.translatorSvc.getLabel('chef')
      });
      this.levels.push({
        id: 2,
        lbl: this.translatorSvc.getLabel('masterChef')
      });
    }
  }

  initiateLanguages() {
    if(this.languages.length === 0) {
      this.languages.push('en');
      this.languages.push('fr');
    }
  }

  onItemChange() {
    this.languageChangedBool = true;
  }

  cancelClicked() {
    this.dialogRef.close(false);
  }

  async saveProfile() {
    const actSets = {
      firstName: this.authSvc.User.accountSettings.firstName,
      lastName: this.authSvc.User.accountSettings.lastName,
      competency: this.authSvc.User.accountSettings.competency,
      language: this.authSvc.User.accountSettings.language,
      emailSubscribed : this.authSvc.User.accountSettings.emailSubscribed,
      promoCode : this.authSvc.User.accountSettings.promoCode,
      video : this.authSvc.User.accountSettings.video,
      timer : this.authSvc.User.accountSettings.timer,
      email : this.authSvc.User.accountSettings.email,
      new_features : this.authSvc.User.accountSettings.new_features,
      eulaSigned: this.authSvc.User.accountSettings.eulaSigned,
      emailVerified: this.authSvc.User.accountSettings.emailVerified
    }
    this.spinnerSvc.show();
    
    await this.authSvc.updateUserAccountSettings(actSets, false);
    this.spinnerSvc.hide();
    this.dialogRef.close(false);
    if(this.languageChangedBool) {
      window.location.reload();
      this.languageChangedBool = false;
    }
    this.authSvc.sendProfileUpdateEvent();
  }

  openSubscriptionPlans() {
    this.dialogRef.close(false);
    this.router.navigate(['products']);
  }

  editFirstName() {
    if(this.firstNameEditIcon === this.pencilEdit_Icon) {
      // make editable
      this.readOnlyFirstName = false;
      this.firstNameEditIcon = this.checkMark_Icon;
    } else {
      // save the information in firebase
      this.readOnlyFirstName = true;
      this.firstNameEditIcon = this.pencilEdit_Icon;
    }
  }

  editLastName() {
    if(this.lastNameEditIcon === this.pencilEdit_Icon) {
      // make editable
      this.readOnlyLastName = false;
      this.lastNameEditIcon = this.checkMark_Icon;
    } else {
      // save the information in firebase
      this.readOnlyLastName = true;
      this.lastNameEditIcon = this.pencilEdit_Icon;
    }
  }

  editEmail() {
    if(this.emailEditIcon === this.pencilEdit_Icon) {
      // make editable
      this.readOnlyEmail = false;
      this.emailEditIcon = this.checkMark_Icon;
    } else {
      // save the information in firebase
      this.readOnlyEmail = true;
      this.emailEditIcon = this.pencilEdit_Icon;
    }
  }

}

interface LvlI {
  id: number,
  lbl: string,
}