import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/admin/services/auth.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { UserConfirmationComponent } from '../user-confirmation/user-confirmation.component';
import { DomainService } from 'src/app/core/services/domain.service';


@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EulaComponent implements OnInit {

  public emailAgreement = false;
  public eulaAgreement = false;

  constructor(
    public dialogRef: MatDialogRef<EulaComponent>,
    private authSvc : AuthService,
    private matDialog : MatDialog,
    public translatorSvc : TranslatorService,
    private sanitizer : DomSanitizer,
    // Initialize DomainService for domainInit event listener
    private domainSvc : DomainService
  ) { }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  async submitEula(event: any) {
    if(!this.eulaAgreement) {
      // show modal warning that you have to accept the eula
      this.openEulaNotSignedModal();
    } else {
      // register eula and email subscribe bools in acct settings
      // go ahead with login
      this.authSvc.updateUserEULASetting().then(() => {})
        .catch(error => {
          console.error('updateEULAsetting error :', error);
        });
      this.authSvc.updateUserEmailSubscribeSetting(this.emailAgreement).then(() => {})
        .catch(error => {
          console.error('updateEmailSetting error : ', error)
        });
      this.closeDialog();
      this.authSvc.User.accountSettings.eulaSigned = true;
      this.authSvc.sendDomainInitEvent();
    }
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.translatorSvc.getEula());
  }

  openEulaNotSignedModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-eula-not-signed";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    
    dialogConfig.data = {
      name: "modal-eula-not-signed",
      actionButtonText1 : this.translatorSvc.getLabel('continue'),
      message : this.translatorSvc.getLabel('eulaNotSigned'),
      hideButton2 : true
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(result => {
      
    });
  }

}
