import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OrganisationService } from 'src/app/organisations/services/organisation.service';

@Component({
  selector: 'app-organisation-item',
  templateUrl: './organisation-item.component.html',
  styleUrls: ['./organisation-item.component.scss']
})
export class OrganisationItemComponent implements OnInit {

  @Input() organisationItem: string

  @Output() chooseOrganisation = new EventEmitter<string>();


  constructor(
    private router : Router,
    private organisationSvc : OrganisationService
  ) {}

  @HostListener("click") onClick(){
    console.log("Selected Org ==> " + this.organisationItem);
    this.organisationSvc.getOrganisation(this.organisationItem);
    this.router.navigate(['organisation']);
    this.chooseOrganisation.emit(this.organisationItem);      
  }

  ngOnInit(): void {
  }

  

}
