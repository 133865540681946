import { Component, Input } from '@angular/core';
import { Workflow } from 'src/app/models/workflow.model';
import { WorkflowService } from '../../services/workflow.service';
import { Recipe } from 'src/app/models/recipe.model';
import { DomainService } from '../../services/domain.service';

@Component({
  selector: 'app-workflow-example-item',
  templateUrl: './workflow-example-item.component.html',
  styleUrls: ['./workflow-example-item.component.scss']
})
export class WorkflowExampleItemComponent {
  @Input() workflow: Workflow | Recipe;

  constructor(
    public workflowSvc : WorkflowService,
    public domainSvc : DomainService
  ) { }
}
