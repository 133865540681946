

export class SceneTransition {

    private _element: HTMLElement;

    constructor() {
        this._element = null;
    }

    createElement() {
        this._element = document.createElement("div");
        this._element.classList.add("scene-transition")
    }

    fadeOut() {
        this._element.classList.add("fade-out");
        this._element.addEventListener("animationend", () => {
            this._element.remove();
        },{once: true});
    }

    init(container: HTMLElement, callback) {
        this.createElement();
        container.appendChild(this.element);

        this._element.addEventListener("animationend", () => {
            callback();
        },{once: true});
    }

    public get element(): HTMLElement {
        return this._element;
    }
    public set element(value: HTMLElement) {
        this._element = value;
    }
}