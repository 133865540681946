import { Injectable } from '@angular/core';
import getUserLocale from 'get-user-locale';
import { Subscription } from 'rxjs';
import { AuthService } from '../../admin/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  private _userLocale: string;
  loginEvent: Subscription;

  constructor(private authSvc: AuthService) { 

    if(this.authSvc && this.authSvc.User) {
      this._userLocale = this.authSvc.User.accountSettings.language;
    } else {
      this._userLocale = getUserLocale();
        if(this._userLocale.length>2) {
            this._userLocale = this._userLocale.slice(0,2);
        }
    }
  }

  initTranslatorSvc() {
    this._userLocale = this.authSvc.User.accountSettings.language;
  }

  setLanguage(language: string) {
    this._userLocale = language;
  }

  getFirebaseMessage(code: string ) {
    if(code !== undefined) {
      switch (this._userLocale) {
        case 'fr' :
          switch (code) {
            case 'user-exists':
              return 'L\'adresse courriel existe déjà. Utilisez une adresse différente ou connectez vous en utilsant celle-ci';
            case 'auth/invalid-password' :
            case 'auth/wrong-password':
              return 'Le mot de passe est invalide';
            case 'auth/user-not-found' :
              return 'L\'usager n\'existe pas';
          }
        break;
        default :
          switch (code) {
            case 'user-exists':
              return 'This email address is already in use. Please login with the email address and reset the password if required';
            case 'auth/invalid-password' :
            case 'auth/wrong-password':
              return 'Invlid password';
            case 'auth/user-not-found' :
              return 'User does not exist';
          }
      }
      return 'Unknown error'
    }
    return null
  }

  getDifficultytLabel(lvl: number) {
    switch (this._userLocale) {
      case 'fr' :
        switch (lvl) {
          case 0:
            return 'Très facile'
          case 1:
            return 'Facile'
          case 2:
            return 'Difficile'
          case 3:
            return 'Très Difficile'
          default:
            return 'Inconnu'
        }
      default :
      switch (lvl) {
        case 0:
          return 'Very Easy'
        case 1:
          return 'Easy'
        case 2:
          return 'Difficult'
        case 3:
          return 'Very Difficult'
        default:
          return 'Unknown'
      }
    }
  }

  getErrorMsg(msg: string) {
    switch (this._userLocale) {
      case 'fr' :
        switch(msg) {
          case 'pwLengthError':
            return 'Le mot de passe doit avoir un minimum de 6 caractères'
          case 'pwMismatch' :
            return 'Les mots de passe doivent être les mêmes'
        }
      break;
      default : 
        switch(msg) {
          case 'pwLengthError':
            return 'Password must be at least 6 characters in length'
          case 'pwMismatch' :
            return 'Both passwords must match'
        }
    }
    return 'Unknown label'
  }

  getLabel(lbl : string) {
    switch (this._userLocale) {
      case 'fr' :
        switch (lbl) {

          /** Navigation */
          case 'backToRecipes' :
            return 'Retour aux recettes';
          case 'backToFamiliarization':
            return 'Retour à Familiarisation';
          case 'backToDomainProducts' :
            return 'Retour aux produits'
          case 'backToShipping':
            return 'Retour à Livraison'
          case 'backToShoppingCart':
            return 'Retour au panier'
          case 'proceedToOrder':
            return 'Procéder à la commande'

          /** Generic */
          case 'for':
            return 'pour'
          case 'iUnderstand':
            return 'J\'ai compris'
          case 'yes':
            return 'Oui';
          case 'add':
            return 'Ajouter';
          case 'no':
            return 'Non'
          case 'search':
            return 'Rechercher';
          case 'signout':
            return 'Déconnexion';
          case 'register':
            return 'Enregistrer';
          case 'submit':
            return 'Soumettre';
          case 'close':
            return 'Fermer';
          case 'continue':
            return 'Continuer';
          case 'confirm':
            return 'Confirmer';
          case 'more' :
            return 'En savoir plus...';
          case 'doNotShow':
            return 'Ne plus afficher ce message';
          case 'loginTitle':
            return 'Entrez votre adresse courriel et votre mot de passe';
          case 'pwdResetTitle' :
            return 'Entrez votre adresse courriel afin de réinitialiser votre mot de passe';
          case 'registerTitle':
            return 'Entrez votre adresse courriel afin de vous enregistrer';
          case 'accountInfoTitle':
            return 'Information pour votre compte';
          case 'view' :
            return 'Voir items de ';
          case 'viewRecipes' :
            return 'Voir les recettes';
          case 'viewJobExamples' :
            return 'Voir les exemples de travail';
          case 'seeDetails' :
            return 'Voir les détails';
          case 'processingRequest' :
            return 'Requête en traitement'
          case 'quantity':
            return 'Quantité'

          case 'welcomeAutonome':
            return 'Bienvenue à Autonomē.'

          // Login
          case 'login':
            return 'Connexion'
          case 'signInGoogle' :
            return 'Connexion avec Google'

          // Account Settings
          case 'chef':
            return 'Chef';
          case 'sousChef':
            return 'Sous Chef'
          case 'masterChef':
            return 'Maître Chef'

          // Spinner Texts
          case 'loadingUser':
            return 'Téléchargement d\'information d\'utilisateur'
          case 'loadingDomains':
            return 'Téléchargement des domaines'
          case 'loadingTools':
            return 'Téléchargement des outils'
          case 'loadingOrganisations':
            return 'Téléchargement des organisations'
          case 'submittingResults':
            return 'Enregistrement des résultats'
          case 'verifyingAuthCode':
            return 'Vérification du code d\'autorisation'
          case 'processingPayment':
            return 'Traitment du paiement'
          case 'processingSubscription':
            return 'Traitement de l\'abonnement'
          case 'loadingUserPaymentMethods':
            return 'Téléchargement des méthodes de paiement de l\'utilisateur'
          case 'updatingAddressInformation':
            return 'Traitement de l\'adresse'

          // Routing
          case 'proceedShipping':
            return 'Procéder à la livraison'
          case 'haveAccount':
            return 'J\'ai déjà un compte'
            
          // password
          case 'password':
            return 'Mot de passe'
          case 'resetPwd':
            return 'Cliquez ici pour réinitialiser votre mot de passe'
          case 'initialPasswordReset':
            return 'A courriel de changement de mot de passe vous à été envoyé. Veuillez cliquer sur le lien dans celui-ci afin de changer votre mot de passe temporaire'
          
          // User Creation
          case 'authCodeText':
            return 'Veullez entrer le code à six chiffres envoyé à votre adresse courriel'
          case 'authCodeResend':
            return 'Renvoyer le code de vérification'
          case 'confirmEmailSent':
            return 'Veuillez cliquer sur le lien dans le courriel envoyé à votre adresse'
          case 'registerEmailSent':
            return 'Un courriel de confirmation vous à été envoyé.\nVeuillez cliquer sur le lien dans le courriel afin d\'activer votre compte.\nCela pourrait prendre jusqu\'à 30 minutes pour recevoir le courriel.'
          case 'emailNotVerified':
            return 'Votre adresse courriel n\'a pas encore été vérifiée. Un nouveau code de vérification vous à été envoyé à votre adresse courriel.'
          case 'newVerificationEmail':
            return 'Envoyer un nouveau courriel de vérification'
          case 'sendVerificationEmail':
            return 'Click here to receive a new verification email'
          case 'eaulModalText' :
            return 'Il faudra confirmer votre accord avec le contrat d\'utilisateur d\'Autonomē avant d\'utiliser l\'application'
          case 'eulaAgreementPre':
            return 'J\'ai lu et j\'accepte les '
          case 'eulaAgreement':
            return 'conditions du contrat d\'utilisateur d\'Autonomē'
          case 'emailAgreement':
            return 'J\'accepte de recevoir des courriels m\'informant des nouvelles et nouveautés d\'Autonomē'

          // Recipes
          case 'replacePictoWithPicture' :
            return 'Remplacer le picto de cette recette avec une photo'
          case 'takePhotoHeader' :
            return 'Prenez une photo do votre recette'
          case 'takePhoto' :
            return 'Capturer une photo'
          case 'reTakePhoto' :
            return 'Reprendre la photo'
          case 'savePhoto' :
            return 'Sauvegarder la photo'

          // Workflow
          case 'steps' :
            return 'Étapes'

          case 'cancel':
            return 'Annuler'
          case 'email':
            return 'Courriel'
          case 'pwd':
            return 'Mot de passe'
          case 'confirmPwd':
            return 'Confirmer le mot de passe'
          case 'confirmGoToRecipeDetail':
            return 'Êtes-vous sûr de vouloir arrêter la recette en cours?'
          
          case 'registerFree' :
            return 'Veuillez vous inscrire pour recevoir votre accès gratuit à l\'application' 
          case 'registerPurchase' :
            return 'Veuillez vous inscrire avant de procèder à votre achat'
          case 'registerSubscribe' :
            return 'Veuillez vous inscrire avant de procèder à votre abonnement'
          
            
          case 'confirmTimerStop':
            return 'Êtes-vous sûr de vouloir arrêter la minuterie?'
          case 'timeNextStep':
            return 'C\'est le temps de passer à la prochaine étape'
          case 'startRecipe':
            return 'Commencer la recette'
          case 'continueRecipe':
            return 'Continuer la recette'
          case 'restartRecipe':
            return 'Recommencer la recette'
          case 'actions':
            return 'Actions'
          case 'verifications':
            return 'Vérifications'
          case 'ingredients':
            return 'Ingrédients'
          case 'seeIngredients':
            return 'Voir les ingrédients'
          case 'tools':
            return 'Outils'
          case 'yield':
            return 'Portions'
          case 'prepTime':
            return 'Temps de préparation'
          case 'cookTime':
            return 'Temps de cuisson'
          case 'difficulty':
            return 'Niveau de difficulté'
          case 'requiredField':
            return 'champ requis'
          case 'deliverTo':
            return 'Livré à*'
          case 'address2':
            return 'Apartement, unité, suite, ou no. d\'étage'
          case 'city':
            return 'Ville*'
          case 'stateProvince':
            return 'Province/État*'
          case 'postCodeZip':
            return 'Code postale/Code zip*'
          case 'countryRegion':
            return 'Pays ou région*'
          case 'saveAddress':
            return 'Sauvegarder mon adresse'
          case 'submitAddress':
            return 'Soumettre l\'adresse'
          case 'welcome!':
            return 'Bienvenue!'
          case 'connect!':
            return 'Connectez-vous!'
          case 'forgot-pwd':
            return 'J\'ai oublié mon mot de passe'
          case 'no-account':
            return 'Essayez nos recettes gratuites'
          case 'here' :
            return 'ici!'
          case 'tryOut' :
            return 'Essayez nos'
          case 'freeRecipes' :
            return 'recettes GRATUITES!'
          case 'create-account':
            return 'Créer le compte'
          case 'price':
            return 'Prix'
          case 'access-recipes':
            return 'Pour l\'accès à toutes nos recettes, choisissez notre'
          case 'BETA-PLAN':
            return 'PLAN BÊTA'
          case 'more-info-plans':
            return 'Plus d\'information sur nos plans'
          case 'start-cooking-now':
            return 'Cuisinez dès maintenant'
          case 'view-video':
            return 'Visioner la vidéo'
          case 'free':
            return 'Gratuit'
          case 'beta':
            return 'Bêta'
          case 'select':
            return 'Choisir'
          case 'buyNowAndSave' :
            return 'Abonnez vous au plan Bêta dès maintenant et économisez 66%'
          case 'createFreeAccount':
            return 'Créer mon compte gratuit'
          case 'order':
            return 'Commande'
          case 'product':
            return 'Produit'
          case 'subTotal':
            return 'Sous-total'
          case 'goToPayment':
            return 'Procéder au paiement'
          case 'changeAddress':
            return 'Modifier l\'adresse'
          case 'creditCard':
            return 'Carte de crédit'
          case 'infoOnPlans':
            return 'Cliquez ici pour en savoir plus sur notre plan Bêta'
          case 'goToProducts':
            return 'Continuer vers l\'achat de l\'abonnmement Bêta'
          case 'allergies':
            return 'Allergies'
          case 'contains':
            return 'Peu contenir'
          case 'plan':
            return 'Plan d\'abonnement'
          case 'modify':
            return 'Modifier'
          case 'level' :
            return 'Niveau'
          case 'videos' :
            return 'Vidéos'
          case 'timers' :
            return 'Minuteries'
          case 'settings':
            return 'Réglages'
          case 'language' :
            return 'Langue'
          case 'save':
            return 'Sauvegarder'
          case 'firstName':
            return 'Prénom'
          case 'lastName':
            return 'Nom de famille'
          case 'promoCode':
            return 'Code promotionnel'
          case 'currentPlan':
            return 'Plan actual'
          case 'preferences':
            return 'Préférences'
          case 'existingAccount':
            return 'J\'ai déjà un compte:'
          case 'accessRecipes' :
            return 'Accedez aux recettes gratuites:'
          case 'notConvinced' :
            return 'Pas convaincu?'
          case 'seeAutonomeAction' :
            return 'Voir Autonomē Cuisine en action'
          case 'visitAutonomeWebsite' :
            return 'Visitez notre site web pour plus d\'information'
          case 'createPassword' :
            return 'Veuillez créer un mot de passe (min 6 caractères)'
          case 'emailAccount' :
            return 'Entrez une adresse courriel fonctionnelle pour votre nouveau compte Autonomē'
          case 'moreRecipes' :
            return 'Plus de recettes'
          case 'featuredRecipe' :
            return 'Recette en vedette'
          case 'goFS' :
            return 'Aimeriez-vous activer le mode plein écran afin d\'améliorer votre expérience?'
          case 'completeRecipe' :
            return 'Il est maintenant le temps de savourer votre plat!'
          case 'recipeCategory' :
            return 'Catégorie de recette'
          case 'eulaNotSigned' :
            return 'Vous devez signer l\'entente d\'utilisation d\'usager avant de procéder'
          case 'doNotShowAgain' :
            return 'Ne plus afficher'
          case 'timeToComplete':
            return 'Temps pour compléter (min):'
          case 'instructionSetDetails':
            return 'Détails sur la série d\'instruction'  
          
          // org section
          case 'executeTime' :
            return 'Temps d\'execution'
          case 'launchWorkflow' :
            return 'Lancer les instructions'
          case 'viewDetails':
            return 'Voir les détails'
          case 'goToOrg' :
            return 'Aller aux organisations?'
          case 'congratsWfComplete' :
            return 'Félicitations! Vous avez complété: '
          case 'availOrgs' :
            return 'Organisations disponibles'
          case 'organisation':
            return 'Organisation'
          case 'organisations':
            return 'Organisations'
          case 'orgName' :
            return 'Nom de l\'organisation'
          case 'orgNameExists' :
            return 'Ce nom d\'organisation existe déjà'

          // org member
          case 'memberRoleChange' :
            return 'Êtes-vous certain de vouloir changer le role de ce membre ?'
          case 'memberRemove' :
            return 'Êtes-vous certain de vouloir enlever ce membre de l\'organisation ?'

          // products section
          case 'products' :
            return 'Produits'
          case 'ourProducts' :
            return 'Nos produits'
          case 'purchaseCookingKit':
            return 'Acheter l\'ensemble Autonomē Cuisine'
          case 'recipesSubscriptionTitle':
            return 'Abonnement aux recettes'
          case 'autonomeKitTitle':
            return 'Ensemble de cuisine Autonomē'
          
          // Shopping-cart
          case 'items' :
            return 'Items'

          // Shipping
          case 'shipping':
            return 'Livraison'
          case 'shippingIncluded':
            return 'Livraison incluse via Poste Canada'
          case 'shippingAddress':
            return 'Adresse de livraison'
          case 'enterShippingAddress':
            return 'Entrez l\'adresse de livraison'

          // domains section
          case 'cooking' :
            return 'Cuisine'
          case 'jobExamples' :
            return 'Familiarisation à l\'emploi'
          case 'moreInfo' :
            return 'Informations supplémentaires'
          case 'goToDomain' :
            return 'Consulter le domaine'
          case 'domainContent' :
            return 'Contenu de '
          case 'domainContentVowel' :
            return 'Contenu d\''
          case 'availableDomains' :
            return 'Domaines disponibles'
          case 'purchaseDomain' :
            return 'Acheter ce domaine'
          case 'subscribeDomain':
            return 'M\'abonner à ce domaine'
          case 'purchasedDomain' :
            return 'Domaine acheté'
          case 'workflows' : 
            return 'Instructions de travail'
          case 'domain':
            return 'Domaine'
          case 'domains' :
            return 'Domaines'
          case 'getAccessRecipeDomain' :
            return 'Vous pouvez avoir accès à toutes les recettes pour '
          case 'buyRecipeDomain' :
            return 'Acheter l\'accès à toutes les recettes'
          case 'domainAlreadyInCart' :
            return 'Ce domaine à déjà été ajouté à votre panier d\'achat. Cliquez sur l\'icône du panier d\'achat pour finaliser votre achat'
          case 'subscription' :
            return 'Abonnement'
          case 'subscriptions' :
            return 'Abonnements'
          case 'purchases' :
            return 'Achats'

          // Familiarization
          case 'examplesInclude' :
            return 'Voici la liste des '

          // job examples section
          case 'tableHeader' :
            return 'Indiquez à quel point l\'énoncé suivant vous dérange'
          case 'startAssessment' :
            return 'Regardez la vidéo et répondez aux questions'
          case 'continueAssessment' :
            return 'Continuez l\'évaluation'
          case 'restartAssessment' :
            return 'Recommencez l\'évaluation'
          case 'startAssessmentOver' :
            return 'Reprendre l\'évaluation'
          case 'viewResults' :
            return 'Visionnez les résultats'
          case 'jobResults' :
            return 'Résultats d\'évaluation pour ce type de travail'
          case 'assessmentNotCompleted' :
            return 'Évaluation non complétée'
          case 'assessmentInterest' :
            return 'Mon intérêt pour ce travail :'
          case 'assessmentBother' :
            return 'Ce qui me dérange de ce travail'
          case 'assessmentBotherNone' :
            return 'Rien me dérange de ce travail'
          case 'assessmentScore' :
            return 'Questions répondues correctement à propos de ce travail :'
          case 'multiChoiceHeader' :
            return 'Résultats des questions à choix multiple'
          case 'goToFamiliarization' :
            return 'Accéder aux exemples de travail'
          case 'goToAutonomeDomains':
            return 'Accéder aux domaines Autonomē'

          // Price & payments
          case 'payment':
            return 'Paiement'
          case 'monthly' :
            return 'Mois'
          case 'yearly' :
            return 'An'
          case 'addToCart' :
            return 'Ajouter au panier'
          case 'domainAddedToCart' :
            return 'Ce domaine à été ajoutée à votre panier : '
          case 'recipeAddedToCart':
            return 'Cette recette  à été ajoutée à votre panier : '
          case 'workflowAddedToCart' :
            return 'Cette série d\'instructions à été ajoutée à votre panier : '
          case 'itemAddedToCart' :
            return 'Cet item à été ajoutée à votre panier : '
          case 'shoppingCart' :
            return 'Panier d\'achat'
          case 'goToShoppingCart' :
            return 'Aller au panier d\'achat'    
          case 'totalPurchases' :
            return 'Total Achats'
          case 'totalSubscriptions' :
            return 'Total Abonnements'
          case 'applyPromo' :
            return 'Appliquer le code-promo'
          case 'submitPayment':
            return 'Soumettre le paiement'
          case 'availablePaymentMethods' :
            return 'Méthodes de paiement disponibles'
          case 'proceedPurchase' :
            return 'Procéder à l\'achat'
          case 'payNow' :
            return 'Payer maintenant'

          
          
        }
      break;
      default :
        switch (lbl) {
          /** Navigation */
          case 'backToRecipes' :
            return 'Back To Recipes';
          case 'backToFamiliarization':
            return 'Back to Familiarization';
          case 'backToDomainProducts' :
            return 'Back to Products'
          case 'backToShipping':
            return 'Back to Shipping'
          case 'backToShoppingCart':
            return 'Back to Shopping Cart'
          case 'proceedToOrder':
            return 'Proceed to Order'
          
          /** Generic */
          case 'for':
            return 'for'
          case 'iUnderstand':
            return 'I understand'
          case 'yes':
            return 'Yes'
          case 'add':
            return 'Add'
          case 'no':
            return 'No'
          case 'search':
            return 'Search';
          case 'signout':
            return 'Sign Out'
          case 'register':
            return 'Register'
          case 'submit':
            return 'Submit'
          case 'close':
            return 'Close'
          case 'continue':
            return 'Continue'
          case 'confirm':
            return 'Confirm'
          case 'more' :
            return 'More...'
          case 'doNotShow':
            return 'Do not show this message anymore'
          case 'loginTitle':
            return 'Enter your email and password'
          case 'pwdResetTitle' :
            return 'Enter your email address to reset your password'
          case 'registerTitle':
            return 'Enter your email to register'
          case 'accountInfoTitle':
            return 'Account Information'
          case 'view' :
            return 'View items of '
          case 'viewRecipes' :
            return 'View Recipes'
          case 'viewJobExamples' :
            return 'See Job Examples'
          case 'seeDetails' :
            return 'See Details'
          case 'processingRequest' :
            return 'Processing Request'
          case 'quantity':
            return 'Quantity'

          case 'welcomeAutonome':
            return 'Welcome to Autonomē.'

          // Login
          case 'login':
            return 'Login'
          case 'signInGoogle' :
            return 'Sign in with Google'

          // Account Settings
          case 'chef':
            return 'Chef';
          case 'sousChef':
            return 'Sous Chef'
          case 'masterChef':
            return 'Master Chef'

          // Loaders
          case 'loadingUser':
            return 'Loading User Information'
          case 'loadingDomains':
            return 'Loading Domains'
          case 'loadingTools':
            return 'Loading Tools'
          case 'loadingOrganisations':
            return 'Loading Organisations'
          case 'submittingResults':
            return 'Submitting results'
          case 'verifyingAuthCode':
            return 'Verifying Authorization Code'
          case 'processingPayment':
            return 'Processing Payment'
          case 'processingSubscription':
            return 'Processing Subscription'
          case 'loadingUserPaymentMethods':
            return 'Loading User\'s Payment Methods'
          case 'updatingAddressInformation':
            return 'Updating Address Information'

          // Routing
          case 'proceedShipping':
            return 'Proceed to shipping'
          case 'haveAccount':
            return 'I already have an account'

          // Password
          case 'password':
            return 'Password'
          case 'resetPwd':
            return 'Click here to reset your password'
          case 'initialPasswordReset':
            return 'A password reset email has been sent to you. Please click on the link within the email to change your temporary password'
          case 'confirmEmailSent':
            return 'Please click on the link in the email sent to your email address to complete the password reset'

          // User creation
          case 'authCodeText':
            return 'Please enter the six digit code that has been sent to your email'
          case 'authCodeResend':
            return 'Resend the code'
          case 'registerEmailSent':
            return 'A confirmation email has been sent to your email address.\nPlease verify your email account and click on the link provided within the email to activate your account.\nPlease allow up to 30 minutes to receive the email.'
          case 'emailNotVerified':
            return 'Your email address has not yet been verified. A new verification code has been sent to your email address.'
          case 'newVerificationEmail':
            return 'Send a new verification email'
          case 'sendVerificationEmail':
            return 'Click here to receive a new verification email'
          case 'eulaAgreementPre':
            return 'I have read and agree with '
          case 'eaulModalText' :
            return 'You will need to agree with the End User Level Agreement before using Autonomē\'s application'
          case 'eulaAgreement':
            return 'Autonomē\'s End User Agreeement terms'
          case 'emailAgreement':
            return 'I agree to receive emails containing news and new features from Autonomē'


          // Recipes
          case 'replacePictoWithPicture' :
            return 'Replace picto of recipe with a photo'
          case 'takePhotoHeader' :
            return 'Take a Photo of Your Recipe'
          case 'takePhoto' :
            return 'Take Photo of Recipe'
          case 'reTakePhoto' :
            return 'Re-Take Photo'
          case 'savePhoto' :
            return 'Save as Recipe Photo'

          // Workflow
          case 'steps' :
            return 'Steps'

          case 'cancel':
            return 'Cancel'
          case 'email':
            return 'Email'
          case 'pwd':
            return 'Password'
          case 'confirmPwd':
            return 'Confirm Password'
          case 'confirmGoToRecipeDetail':
            return 'Are you certain you want to stop the current recipe?'
          case 'registerFree' :
            return 'Please register to receive your free access to the application' 
          case 'registerPurchase' :
            return 'You must register before proceeding to your purchase'
          case 'registerSubscribe' :
            return 'You must register before proceeding to your subscription'
          case 'confirmTimerStop':
            return 'Are you certain you want to stop the timer?'
          case 'timeNextStep':
            return 'Time to go to the next step'
          case 'startRecipe':
            return 'Start Recipe'
          case 'continueRecipe':
            return 'Continue Recipe'
          case 'restartRecipe':
            return 'Restart Recipe'
          case 'actions':
            return 'Actions'
          case 'verifications':
            return 'Verifications'
          case 'ingredients':
            return 'Ingredients'
          case 'seeIngredients':
            return 'See Ingredients'
          case 'tools':
            return 'Tools'
          case 'yield':
            return 'Yield'
          case 'prepTime':
            return 'Preperation time'
          case 'cookTime':
            return 'Cooking time'
          case 'difficulty':
            return 'Difficulty level'
          case 'requiredField':
            return 'required field'
          case 'deliverTo':
            return 'Deliver To*'
          case 'address2':
            return 'Apartment, unit, suite, or floor #'
          case 'city':
            return 'City*'
          case 'stateProvince':
            return 'Province/State*'
          case 'postCodeZip':
            return 'Postal/Zip Code*'
          case 'countryRegion':
            return 'Country or Region*'
          case 'saveAddress':
            return 'Save my address'
          case 'submitAddress':
            return 'Submit Address'
          case 'changeAddress':
            return 'Change Address'
          case 'welcome!':
            return 'Welcome!'
          case 'connect!':
            return 'Connect!'
          case 'forgot-pwd':
            return 'I forgot my password'
          case 'no-account':
            return 'Try out our FREE recipes'
          case 'here' :
            return 'here!'
          case 'tryOut' :
            return 'Try our'
          case 'freeRecipes' :
            return 'FREE recipes!'
          case 'create-account':
            return 'Create account'
          case 'price':
            return 'Price'
          
          case 'access-recipes':
            return 'Access ALL our recipes now, choose our'
          case 'BETA-PLAN':
            return 'BETA PLAN'
          case 'more-info-plans':
            return 'More information on our plans'
          case 'start-cooking-now':
            return 'Start cooking now'
          case 'view-video':
            return 'View the video'
          case 'free':
            return 'Free'
          case 'beta':
            return 'Beta'
          case 'select':
            return 'Select'
          case 'buyNowAndSave' :
            return 'Subscribe to the Beta plan now and save 66%'
          case 'createFreeAccount':
            return 'Create my free account'
          case 'order':
            return 'Order'
          case 'product':
            return 'Product'
          case 'subTotal':
            return 'Sub-total'
          case 'goToPayment':
            return 'Go to payment'
          case 'creditCard':
            return 'Credit Card'
          case 'infoOnPlans':
            return 'Click here to find out more on our Beta plan offer'
          case 'goToProducts':
            return 'Continue to the Beta plan purchase'
          
          case 'allergies':
            return 'Allergies'
          case 'contains':
            return 'May contain'
          case 'plan':
            return 'Subscription plan'
          case 'modify':
            return 'Modify'
          case 'level' :
            return 'Level'
          case 'videos' :
            return 'Videos'
          case 'timers' :
            return 'Timers'
          case 'settings':
            return 'Settings'
          case 'language' :
            return 'Language'
          case 'save':
            return 'Save'
          case 'firstName':
            return 'First Name'
          case 'lastName':
            return 'Last Name'
          case 'promoCode':
            return 'Promo Code'
          case 'currentPlan':
            return 'Current Plan'
          case 'preferences':
            return 'Preferences'
          case 'existingAccount':
            return 'I have an account:'
          case 'accessRecipes' :
            return 'Access the free recipes:'
          case 'notConvinced' :
            return 'Not yet convinced?'
          case 'seeAutonomeAction' :
            return 'See Autonomē Kitchen in action'
          case 'visitAutonomeWebsite' :
            return 'Visit our website for more information'
          case 'createPassword' :
            return 'Please create a password (min 6 characters)'
          case 'emailAccount' :
            return 'Please enter a working email address for your Autonomē account'
          case 'moreRecipes' :
            return 'More Recipes'
          case 'featuredRecipe' :
            return 'Featured recipe'
          case 'goFS' :
            return 'Would you like to enable full screen mode to enhance your experience?'
          case 'completeRecipe' :
            return 'It\'s now time to savour your dish!'
          case 'recipeCategory' :
            return 'Recipe Category'
          case 'eulaNotSigned' :
            return 'You must accept the End User Agreements before proceeding'
          case 'doNotShowAgain' :
            return 'Do not show again'
          case 'timeToComplete':
            return 'Time to complete (min):'
          case 'instructionSetDetails':
            return 'Instruction Set Details'  

          // org section
          case 'executeTime' :
            return 'Execution time'
          case 'launchWorkflow' :
            return 'Start workflow'
          case 'viewDetails':
            return 'View Details'
          case 'goToOrg' :
            return 'Go to organisations?'
          case 'congratsWfComplete' :
            return 'Congratulations! You completed: '
          case 'availOrgs' :
            return 'Available Organisations'
            case 'organisation':
              return 'Organisation'
          case 'organisations':
            return 'Organisations'
          case 'orgName' :
            return 'Organisation Name'
          case 'orgNameExists' :
            return 'This organisation name already exists'

            // org member
          case 'memberRoleChange' :
            return 'Are you certain you want to change the role of this member?'
          case 'memberRemove' :
            return 'Are you sure you want to remove this user from the organisation?'

          // products section
          case 'products' :
            return 'Products'
          case 'ourProducts' :
            return 'Our Products'
          case 'purchaseCookingKit':
            return 'Purchase the Autonomē Cooking Kit'
          case 'recipesSubscriptionTitle':
            return 'Subscription to Recipes'
          case 'autonomeKitTitle':
            return 'Autonomē Cooking Kit'

          // Shopping-cart
          case 'items' :
            return 'Items'

          // Shipping
          case 'shipping':
            return 'Shipping'
          case 'shippingIncluded':
            return 'Shipping included via Canada Post'
          case 'shippingAddress':
            return 'Shipping Address'
          case 'enterShippingAddress':
            return 'Enter a shipping address'

          // domains section
          case 'cooking' :
            return 'Cooking'
          case 'jobExamples' :
            return 'Job Examples'
          case 'moreInfo' :
            return 'Additional information'
          case 'goToDomain' :
            return 'Go To Domain'
          case 'domainContent' :
            return 'Content of '
          case 'availableDomains' :
            return 'Available Domains'
          case 'purchaseDomain' :
            return 'Purchase Domain'
          case 'subscribeDomain':
            return 'Subscribe to domain'
          case 'purchasedDomain' :
            return 'Purchased Domain'
          case 'workflows' : 
            return 'Workflows'
          case 'domain':
            return 'Domain'
          case 'domains' :
            return 'Domains'
          case 'getAccessRecipeDomain' :
            return 'Get access to all the recipes for '
          case 'buyRecipeDomain' :
            return 'Purchase access to all the recipes'
          case 'domainAlreadyInCart' :
            return 'You have already added this domain to your cart. Click on the shopping cart icon to finalize your purchase'
          case 'subscriptions' :
            return 'Subscriptions'
          case 'subscription' :
            return 'Subscription'
          case 'purchases' :
            return 'Purchases'

          // Familiarization
          case 'examplesInclude' :
            return 'Here is a list of the '

          // job examples section
          case 'tableHeader' :
            return 'Indicate to what extent the following statement will bother you'
          case 'startAssessment' :
            return 'View video and answer questions'
          case 'continueAssessment' :
            return 'Continue Assessment'
          case 'restartAssessment' :
            return 'Restart Assessment'
          case 'startAssessmentOver' :
            return 'Retake Assessment'
          case 'viewResults' :
            return 'View Assessment Results'
          case 'jobResults' :
            return 'Work Assessement Results'
          case 'assessmentNotCompleted' :
            return 'Assessment Not Completed'
          case 'assessmentInterest' :
            return 'My interest for this type of work :'
          case 'assessmentBother' :
            return 'What bothers me about this type of work :'
          case 'assessmentBotherNone' :
            return 'Nothing bothers me about this type of work'
          case 'assessmentScore' :
            return 'My score on knowledge about type of work :'
          case 'multiChoiceHeader' :
            return 'Multiple choice results'
          case 'goToFamiliarization' :
            return 'Go to job examples'
          case 'goToAutonomeDomains':
            return 'Go to Autonomē domains'
          
            // Price & Payments
          case 'payment':
            return 'Payment'
          case 'monthly' :
            return 'Month'
          case 'yearly' :
            return 'Year'
          case 'addToCart' :
            return 'Add to cart'
          case 'domainAddedToCart' :
            return 'This domain has been added to your cart : '
          case 'recipeAddedToCart':
            return 'This recipe has been added to your cart : '
          case 'workflowAddedToCart' :
            return 'This worklfow has been added to your cart : '
          case 'itemAddedToCart' :
            return 'This item has been added to your cart : '
          case 'shoppingCart' :
            return 'Shopping Cart'
          case 'goToShoppingCart' :
            return 'Go to shopping cart'
          case 'totalPurchases' :
            return 'Total Purchases'
          case 'totalSubscriptions' :
            return 'Total Subscriptions'
          case 'applyPromo' :
            return 'Apply the promo-code'
          case 'submitPayment':
            return 'Submit payment'
          case 'availablePaymentMethods' :
            return 'Méthodes de paiement disponibles'
          case 'proceedPurchase' :
            return 'Proceed to purchase'
          case 'payNow' :
            return 'Payer maintenant'
          
        }
    }
    return 'Unknown label'
  }

  getLocaleLbl(lbl: string) {
    switch (lbl) {
      case 'en':
        return 'English'
      case 'fr':
        return 'Français'
      default:
        return 'English'
    }
  }

  getText(txt : string) {
    switch (this._userLocale) {
      case 'fr' :
        switch (txt) {
          case 'promoteRegister':
            return 'La cuisine simple et délicieuse c\'est possible! \nEnregistrez gratuitment pour en faire l\'expérience!!'
          case 'product_free':
            return 'L\'abbonnement au plan gratuit vous donne accès à 3 recettes. Vous pouvez toujours changer votre abonnement plus tard pour notre plan Bêta qui vous donnera accès à toutes les recettes en plus de recevoir l\'ensemble Autonomē gratuitement.'
          case 'product_beta':
            return 'Avec l\'abonnement bêta vous donne accès à toutes les recettes, et comme boni vous recevrez l\'ensemble Autonomē gratuitement. Abonnez-vous dès maintenant et recevez plus de 66% de rabais sur le prix de l\'abonnement annuel.'
          case 'register':
            return 'Il vous reste que quelques étapes pour accéder à vos recettes GRATUITES!';
          case 'register-beta':
            return 'Il vous reste que quelques étapes pour accèder à vos recettes et de recevoir votre ensemble Autonomē GRATUIT!'
          case 'accountInfoTitle':
            return 'Une dernière étape pour accéder à vos recettes gratuites!'
          case 'purchaseStepsAway':
              return 'Seulement que quelques étapes afin de vous abonner'
          case 'registerFirst':
            return 'Vous n\'avez qu\'a cliquer sur le bouton \'Créer mon compte gratuit\' après avoir inséré votre adresse courriel. Vous recevrez ensuite un courriel avec un lien afin de confirmer votre création de compte. Vous pouvez alors accéder aux recettes gratuites.'
          case 'registerFirst-beta':
            return 'Vous n\'avez qu\'a cliquer sur le bouton \'Créer mon compte gratuit\' après avoir inséré votre adresse courriel. Vous recevrez ensuite un courriel avec un lien afin de confirmer votre création de compte. Vous pouvez alors accéder à l\'application et cliquer sur une recette avec cade.'
          case 'updateSettings':
            return 'Voulez vous mettre à jour vos préférences tel que votre nom et votre niveau d\'expérience?'
          case 'addOrganisation':
            return 'Il n\'y-a aucune organisation dans votre profile. Aimeriez-vous en ajouter une?'
          case 'freeDomain' :
            return 'Ce domaine est gratuit. Vous avez accès illimité à tout son contenu'
          case 'purchaseDomain' :
            return 'Ce domaine est disponible pour achat. Vous avez un accès limité à son contenu'
          case 'purchasedDomain' :
            return 'Vous avez acheter ce domain et tout son contenu vous est disponible'
          case 'recipeDomainPurchase':
            return 'En vous abonnant à ce domaine vous obtiendrez un accès illimité aux recettes existante ainsi que celles qui seront ajoutées.'
          case 'paymentMethodConfirmation' :
            return 'Veuillez confirmer le paiement avec la carte terminant avec ';
          case 'accessRecipe' :
            return 'Voulez-vous obtenir l\'accès à cette recette?'
          case 'registerToContinuePurchase' :
            return 'Veuillez créé votre compte afin de continuer votre achat'
          case 'introFamiliarization' :
            return 'En tant que partenaire du Cirris nous sommes fiers de faire partie du projet de familiarisation au marché du travail. Ce projet permet aux individus de visualiser des exemples de travail et de répondre à des questions sur leurs préférences ainsi que sur leur compréhension de la tâche, de la sécurité et de l\'hygiène.'
          case 'visitFamiliarization' :
            return 'Vous pouvez visiter cette section à tout moment en cliquant sur l\'onglet "Familiarisation à l\'emploi".'
          case 'visitFamiliarizationPortrait' :
            return 'Vous pouvez visiter cette section à tout moment en séléctionnant "Familiarisation à l\'emploi" du menu de navigation.'
          default:
            return ''
        }
      case 'en' :
        switch (txt) {
          case 'promoteRegister':
            return `Simple and easy cooking is possible! \nRegister for free to experience it!!`
          case 'product_free':
            return `With a free subscription you get access to 3 recipes. You can always upgrade to the Beta plan if you want to have access to all the recipes and receive the Autonomē Kit for free.`
          case 'product_beta':
            return `The Beta subscription gives you access to all our recipes, and as a bonus you receive the Autonomē Kit for free. Subscribe now and get 66% discount on the regular annual subscription price.`
          case 'register':
              return 'You\'re only a few steps away from getting access to your FREE recipes!';
          case 'register-beta':
              return 'You\'re only a few steps away from getting access to your recipes and receive your FREE Autonomē kit!';  
          case 'accountInfoTitle':
            return 'One last step to your free recipes!'
          case 'purchaseStepsAway':
            return 'Only a few steps left before you purchase your subscription plan'
          case 'registerFirst':
            return 'Simply click the \'Create free account\' button after entering your email. You will then receive an email inviting you to click on the link to confirm your account creation. You can then access the free recipes.'
          case 'updateSettings':
            return 'Do you wish to update your settings such as your name and your experience level?'
          case 'addOrganisation':
            return 'There are no organizations in your profile. Would you like to add one?'
          case 'freeDomain' :
            return 'This domain is free. You have unlimited access to all of it\'s contents'
          case 'purchaseDomain' :
            return 'This domain is available for purchase. You currently have limited access to the contents of this domain'
          case 'purchasedDomain' :
            return 'You have purchased this domain and all of it\'s content is available to you'
          case 'recipeDomainPurchase':
            return 'By subscribing to this domain you will get access to all the current and future recipes we publish.'
          case 'paymentMethodConfirmation' :
            return 'Please confirm the payment with the card ending with ';
          case 'accessRecipe' :
            return 'Do you want to access this recipe?'
          case 'registerToContinuePurchase' :
            return 'Please create your account to continue your purchase'
          case 'introFamiliarization' :
            return 'Welcome to Autonomē. As an official partner of Cirris we are proud to be part of the job familiarization project. This project allows individuals to view various job examples in the manufacturing, electronics and grocery sectors.'
          case 'visitFamiliarization' :
            return 'You can visit this section any time by clicking on the "Job Examples" tab.'
          case 'visitFamiliarizationPortrait' :
            return 'You can visit this section any time by selecting the "Job Examples" in the pull-down menu.'
          default:
            return ''
        }
      default : return '';
    }
  }
       
  getEula() {
    switch (this._userLocale) {
      case 'fr' :
        return "https://docs.google.com/document/d/e/2PACX-1vRWvyCAYg-P5a97icZg-vRZ3PxwX6pk44sAW84ecbeNJYdm_a8PePZSdnyWkQVONAxV2BAMJs-UyJV0/pub?embedded=true"
      default :
        return "https://docs.google.com/document/d/e/2PACX-1vT3PTcohKUHfYb6DYRUeIQqKVh9eDjKnJHdwu02enIOUB4vv71NsA2r04Pcih_wO4lWpt8eViIBjqOh/pub?embedded=true"
    }
  }

  startsWithVowel(value : string) {
    const vowels=("aeiouAEIOU");
    if(this._userLocale === 'fr') {
      return vowels.indexOf(value[0]);
    } else {
      return -1;
    }
  }
}
