import { Component, HostListener, Input } from '@angular/core';
import { EditorService } from '../../services/editor.service';
import { Workflow } from 'src/app/models/workflow.model';

@Component({
  selector: 'app-recipe-editor-list-item',
  templateUrl: './recipe-editor-list-item.component.html',
  styleUrls: ['./recipe-editor-list-item.component.scss']
})
export class RecipeEditorListItemComponent {

  @Input() recipe: Workflow;

  constructor(
    public editorSvc: EditorService
  ) {
    
  }

  @HostListener("click") onClick(){
    this.editorSvc.selectedWorkflow = this.editorSvc.workflows.find((obj) => {
      return obj.id === this.recipe.id; });
  }

}
