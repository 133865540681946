<div *ngIf="isJobExample then jobExample else complete"></div>

<ng-template #complete>
    <div class="complete-container">
        <div class="complete-wrapper">
            <div class="complete-picto-wrapper">
                <img [class.recipe-jpg]="workflowSvc.selectedWorkflow.recipe_jpg" src="{{domainSvc.currentDomain.id === 'job_examples_manufacturing' ? '/assets/pictos/workflows/' + workflowSvc.selectedWorkflow.id + '.jpg' : (workflowSvc.selectedWorkflow.recipe_jpg ? workflowSvc.selectedWorkflow.recipe_jpg : '/assets/pictos/workflows/' + workflowSvc.selectedWorkflow.id + '.svg')}}">
            </div>
            <div class="complete-text-container">
                <div class="complete-text-wrapper">
                    <h3>{{completeDescription}}</h3>
                </div>
                <div *ngIf="canTakePicture" class="autonome-button-orange" (click)="openWebcamModal()">
                    <p *ngIf="!workflowSvc.selectedWorkflow.recipe_jpg">{{translatorSvc.getLabel('replacePictoWithPicture')}}</p>
                    <p *ngIf="workflowSvc.selectedWorkflow.recipe_jpg">{{translatorSvc.getLabel('reTakePhoto')}}</p>
                </div>
                <div class="autonome-button-orange" (click)="goToRecipes()">
                    <p>{{translatorSvc.getLabel('backToRecipes')}}</p>
                </div>
            </div>
            
        </div>
    </div>
</ng-template>

<ng-template #jobExample>
    <div class="results-container">
        <div class="results-wrapper">
            <div class="results-container-info-top">
                <h3>{{translatorSvc.getLabel('jobResults')}}</h3>
            </div>
            <div class="results-container-info-bottom">
                <div class="results-left">
                    <div class="question-item-wrapper-horizontal">
                        <h4>{{translatorSvc.getLabel('assessmentInterest')}}</h4>
                        <img [src]="workflowSvc.preference.src">
                    </div>
                    <div class="question-item-wrapper-verticle">
                        <h4 style="margin-block-start: 0.5em;">{{translatorSvc.getLabel('multiChoiceHeader')}}</h4>
                        <div class="multi-questions-container">
                            <div *ngFor="let question of workflowSvc.multiple_choice.questions">
                                <div class="question-item">{{question.question}}</div>
                                <div class="question-item-wrapper-horizontal">
                                    <div class="answer-item">{{question.choice}}</div>
                                    <img class="question-check-icon" [src]="question.src">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="results-right">
                    <div class="question-item-wrapper-verticle">
                        <h4 style="margin-block-start: 0.5em;">{{translatorSvc.getLabel('assessmentBother')}}</h4>
                        <ul style="margin-block-end: 0.5em; margin-block-start: 0; ">
                            <li style="line-height: 1.2;"*ngFor="let question of workflowSvc.table">
                                <div>
                                    <div class="question-item">{{question.question}}:</div>
                                    <div class="answer-item">{{question.answer_text}}</div>
                                </div>
                            </li>
                        </ul>   
                    </div>
                </div>
            </div>
            <div class="button-container">
                <div class="button-wrapper">
                    <button class="autonome-button-orange" (click)="submitResults()"><p>{{translatorSvc.getLabel('submit')}}</p></button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #multipleChoice let-loopStep="loopStep">
    <div>{{loopStep.multiple_choice.question}}</div>
    <div *ngFor="let choice of loopStep.multiple_choice.choices">
        <div [class.display_none]="!choice.selected">
            <div>{{choice.choice}}</div>
            <div>{{choice.answer}}</div>
        </div>
    </div>
</ng-template>

<ng-template #notMultipleChoice let-loopStep="loopStep">
    <ng-container [ngTemplateOutlet]="loopStep.stepType === 7 ? table : preferenceTemp"
                                [ngTemplateOutletContext]="{loopStep:loopStep}"></ng-container>
</ng-template>

<ng-template #table let-loopStep="loopStep">
    <div class="job-question-wrapper">
        <div>{{loopStep.table.question}}: {{loopStep.table.answer}}</div>
        <div></div>
    </div>
</ng-template>

<ng-template #preferenceTemp let-loopStep="loopStep">
    <ng-container [ngTemplateOutlet]="loopStep.stepType === 6 ? preference : empty"
                                [ngTemplateOutletContext]="{loopStep:loopStep}"></ng-container>
</ng-template>

<ng-template #preference let-loopStep="loopStep">
    <div class="job-question-wrapper">
        <div>{{loopStep.preference.question}}: {{loopStep.preference.answer}}</div>
        <div></div>
    </div>
    
</ng-template>

<ng-template #empty let-loopStep="loopStep">
    
</ng-template>