<div class="add-member-modal-container">
    <div class="add-member-modal-wrapper">
        <div class="top-wrapper">
            <p>Enter member's email address</p>
            <div class="add-member-email">
                <div class="input-field-wrapper">
                    <label>{{translatorSvc.getLabel('emailAccount')}}</label>
                    <input
                        autocomplete="email"
                        #emailInput 
                        name="emailInput" 
                        type="text" 
                        class="modal-input-field"
                        (focusout) = "emailFocusOut(emailInput.value)"
                        placeholder="{{translatorSvc.getLabel('email')}}" 
                        [(ngModel)]="email" 
                        required 
                        autofocus>
                </div>
                <div class="select-field-wrapper">
                    <select [(ngModel)]="role">
                        <option *ngFor="let role of roleTypes" [ngValue]="role">
                            {{role}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div>
            <button class="modal-text-button" (click)="addMember()">Send invitation</button> 
        </div>
    </div>
</div>
