import { AnswerPreference } from "../enums/answer-preference.enum";
import { PreferenceQuestionI } from "../interfaces/preference-question.interface";

export class QuestionPreference implements PreferenceQuestionI {

    question_en: string;
    question_fr: string;
    answer: AnswerPreference;
    question: string;

    constructor(data : object) {
        this.question_en = Object(data).question_en;
        this.question_fr = Object(data).question_fr;
        this.answer = Object(data).answer;
        this.question = Object(data).question;
    }

    public setLanguage(language: string) {
        switch (language) {
            case 'fr' :
                this.question = this.question_fr;
                break;
            default :
                this.question = this.question_en;
        }
    }
}