import { AfterViewInit, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { EditorService } from '../../services/editor.service';
import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { NewWorkflowComponent } from 'src/app/modal/components/new-workflow/new-workflow.component';
import { Workflow } from 'src/app/models/workflow.model';
import { Step } from 'src/app/models/step.model';
import { Recipe } from 'src/app/models/recipe.model';

@Component({
  selector: 'app-recipe-editor',
  templateUrl: './recipe-editor.component.html',
  styleUrls: ['./recipe-editor.component.scss']
})
export class RecipeEditorComponent implements AfterViewInit {

  //firstWfElement: any;
  userWorkflowLoadedEvent : Subscription;

  constructor(
    public editorSvc: EditorService,
    public translatorSvc : TranslatorService,
    public matDialog: MatDialog) {
  }

  ngAfterViewInit(): void {
    
  }

  createNewWorkflow() {
    this.openNewWorkflowModal();
  }

  saveWorkflow() {
    this.editorSvc.saveWorkflow();
  }

  selectItemType(value: string) {
    this.editorSvc.addWorkflowItemSelectedItemType = value;
  }

  asRecipe(value: Workflow) : Recipe {
    return (value as Recipe);
  }

  openTab(evt, tabName) {

    const tabcontent = Array.from(document.getElementsByClassName("tabcontent") as HTMLCollectionOf<HTMLElement>);
    for(let i=0; i<tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    const tablinks = document.getElementsByClassName("tablinks");
    for(let i=0; i< tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(tabName).style.display = "flex";
    evt.currentTarget.className += " active";
  }

  drop(event: CdkDragDrop<Step[]>) {
    if (event.previousContainer === event.container) {
      // Reorder items within the same list
      if (event.currentIndex === this.editorSvc.selectedWorkflow.steps.length) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex-1);
      } else {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      }
      this.editorSvc.adjustIndex(event.currentIndex);
    } else {
      if (event.currentIndex === this.editorSvc.selectedWorkflow.steps.length) {
        copyArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex-1
        );
      } else {
        copyArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
      // add the actions in the actionlist if the new item is an ingredient
      this.editorSvc.adjustIndex(event.currentIndex);
      this.editorSvc.addWorkflowItemToWorkflow();
      
      // Move items between lists
      //this.editorSvc.selectedWorkflow[this.editorSvc.selectedWorkflowName].workflow.steps.splice(Math.round((event.dropPoint.x)/89), 0, event.previousContainer.data[event.previousIndex]);
      //this.editorSvc.adjustIndex(Math.floor((event.dropPoint.x)/89));
    }
    
  }

  noReturnPredicate() {
    return false;
  }

  openNewWorkflowModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "new-workflow-modal";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.maxWidth = "90vw";
    dialogConfig.data = {
      name: 'new-workflow-modal',
    }
    this.matDialog.open(NewWorkflowComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result) {
        this.editorSvc.createNewWorkflow();
      }
    });
  }

  trackIndex(index: number, workflow:Workflow) {
    return workflow.id;
  }

}
