import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from './services/modal.service';
import { TimerService } from './services/timer.service';
import { ProfileComponent } from './components/profile/profile.component';
import { TimerModalComponent } from './components/timer-modal/timer-modal.component';
import { UserConfirmationComponent } from './components/user-confirmation/user-confirmation.component';
import { VideoModalComponent } from './components/video-modal/video-modal.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from '@angular/forms';
import { RegisterComponent } from './components/register/register.component';
import { EmailSentConfirmComponent } from './components/email-sent-confirm/email-sent-confirm.component';
import { EmailResetComponent } from './components/email-reset/email-reset.component';
import { CycleStepComponent } from './components/cycle-step/cycle-step.component';
import { AutonomeShowcaseComponent } from './components/autonome-showcase/autonome-showcase.component';
import { ShowcaseItemComponent } from './components/showcase-item/showcase-item.component';
import { OrganisationEditComponent } from './components/organisation-edit/organisation-edit.component';
import { OrganisationSelectComponent } from './components/organisation-select/organisation-select.component';
import { OrganisationItemComponent } from './components/organisation-item/organisation-item.component';
import { FullScreenComponent } from './components/full-screen/full-screen.component';
import { ImageCaptureComponent } from './components/image-capture/image-capture.component';
import { EulaComponent } from './components/eula/eula.component';
import { EditOrganisationMemberComponent } from './components/edit-organisation-member/edit-organisation-member.component';
import { AddOrganisationMemberComponent } from './components/add-organisation-member/add-organisation-member.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { EditOrganisationDomainComponent } from './components/edit-organisation-domain/edit-organisation-domain.component';
import { NewFeaturesComponent } from './components/new-features/new-features.component';
import { JobExampleResultsComponent } from './components/job-example-results/job-example-results.component';
import { DomainPromoComponent } from './components/domain-promo/domain-promo.component';
import { AddDomainComponent } from './components/add-domain/add-domain.component';
import { DomainExtraInfoComponent } from './components/domain-extra-info/domain-extra-info.component';
import { ToolModule } from '../tools/tool.module';
import { AddWorkflowItemDetailsComponent } from './components/add-workflow-item-details/add-workflow-item-details.component';
import { AuthCodeComponent } from './components/auth-code/auth-code.component';
import { NewWorkflowComponent } from './components/new-workflow/new-workflow.component';
import { FamiliarizationModalComponent } from './components/familiarization-modal/familiarization-modal.component';



@NgModule({
  declarations: [
    ProfileComponent,
    TimerModalComponent,
    UserConfirmationComponent,
    VideoModalComponent,
    LoginComponent,
    RegisterComponent,
    EmailSentConfirmComponent,
    EmailResetComponent,
    CycleStepComponent,
    AutonomeShowcaseComponent,
    ShowcaseItemComponent,
    OrganisationEditComponent,
    OrganisationSelectComponent,
    OrganisationItemComponent,
    FullScreenComponent,
    ImageCaptureComponent,
    EulaComponent,
    EditOrganisationMemberComponent,
    AddOrganisationMemberComponent,
    SetPasswordComponent,
    EditOrganisationDomainComponent,
    NewFeaturesComponent,
    JobExampleResultsComponent,
    DomainPromoComponent,
    AddDomainComponent,
    DomainExtraInfoComponent,
    AddWorkflowItemDetailsComponent,
    AuthCodeComponent,
    NewWorkflowComponent,
    FamiliarizationModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToolModule
  ],
  providers: [
    ModalService,
    TimerService
  ],
  exports: [
    ProfileComponent,
    TimerModalComponent,
    UserConfirmationComponent,
    VideoModalComponent,
    LoginComponent,
    RegisterComponent,
    ImageCaptureComponent,
    CycleStepComponent,
  ]
})
export class ModalsModule { }
