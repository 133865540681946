export class DirectionInput {

    private _heldDirections: Array<string>;
    private _map : any;

    
    cunstructor() {
        
        
    }

    init() {

        this._heldDirections = [];
        
        this._map = {
            "ArrowUp" : "up",
            "KeyW" : "up",
            "ArrowDown" : "down",
            "KeyS" : "down",
            "ArrowLeft" : "left",
            "KeyA" : "left",
            "ArrowRight" : "right",
            "KeyD" : "right"

        };

        document.addEventListener("keydown", e => {
            const dir = this._map[e.code];
            if(dir && this.heldDirections.indexOf(dir) === -1) {
                this.heldDirections.unshift(dir);
            }
        });
        document.addEventListener("keyup", e => {
            const dir = this._map[e.code];
            const index = this.heldDirections.indexOf(dir);
            if (index > -1) {
                this.heldDirections.splice(index, 1);
            }
        });

        
    }

    get direction() {
        return this.heldDirections[0];
    }

    public get heldDirections() {
       return this._heldDirections;
    }
    public set heldDirections(value) {
        this._heldDirections = value;
    }
}