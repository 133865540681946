import { WorkflowService } from '../../services/workflow.service';
import { AfterViewInit, Component, Input, OnDestroy, Type, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { StepType } from '../../../enums/step-type.enum';
import { Step } from '../../../models/step.model';
import { StepWrapperComponent } from '../../../interfaces/step-wrapper.component';
import { TimerModalComponent } from '../../../modal/components/timer-modal/timer-modal.component';
import { VideoModalComponent } from '../../../modal/components/video-modal/video-modal.component';
import { StepDirective } from './step.directive';
import { ActionComponent } from '../action/action.component';
import { IngredientQtyComponent } from '../ingredient-qty/ingredient-qty.component';
import { IngredientToolComponent } from '../ingredient-tool/ingredient-tool.component';
import { VerificationComponent } from '../verification/verification.component';
import { CompleteComponent } from '../complete/complete.component';
import { ItemComponent } from '../item/item.component';
import { JobQuestionComponent } from '../../../research-projects/components/job-question/job-question.component';
import { JobVideoComponent } from '../../../research-projects/components/job-video/job-video.component';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnDestroy, AfterViewInit {
  @Input() step: Step;
  @ViewChild(StepDirective, {static : true}) stepHost: StepDirective;
  @ViewChild('timerModal', {static: false}) timerModal: TimerModalComponent;
  @ViewChild('videoModal', {static: false}) videoModal: VideoModalComponent;

  hideVideoBtn: boolean = true;
  clickEventsubscription:Subscription;
  playVideoEventSubscription : Subscription;
  hasVideo : boolean = false;
  private _component : Type<ActionComponent | IngredientQtyComponent | IngredientToolComponent | VerificationComponent | ItemComponent | JobVideoComponent | JobQuestionComponent | CompleteComponent>;

  constructor(private wfSvc: WorkflowService) {
      // TODO - ComponentFactory deprecated
  }
  ngOnDestroy(): void {
    if(this.clickEventsubscription) {
      this.clickEventsubscription.unsubscribe();
    }
    if(this.playVideoEventSubscription) {
      this.playVideoEventSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    if(this.step.stepType === StepType.Action) {
      if(this.step.action?.video && this.step.action?.video !== "") {
        this.hasVideo = true;
        this.clickEventsubscription = this.wfSvc.getCloseVideoEvent().subscribe(() => {
          this.close_video();
        });
      }
    }
    if(this.step.action_steps !== undefined && this.step.action_steps.length > 0) {
      for(const aStep of this.step.action_steps) {
        if(aStep.stepType === StepType.Action) {
          if(aStep.action.video && aStep.action.video !== "") {
            this.hasVideo = true;
            if(!this.playVideoEventSubscription) {
              this.playVideoEventSubscription = this.wfSvc.getPlayVideoEvent().subscribe((videoString)=> {
                this.hideVideoBtn = true;
                this.videoModal.openActionStepVideo(videoString);
              });
            }
          }
        }
      }
    }
    
    this.loadComponent();
  }

  loadComponent() {
    this._component = this.getComponent()

    const viewContainerRef = this.stepHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<StepWrapperComponent>(this._component);

    componentRef.instance.step = this.step;
    if(this.step.stepType === StepType.Action) {
      if(this.step.action?.video && this.step.action?.video !== "") {
        this.hideVideoBtn = false;
      }
    }
  }

  getComponent() {
    if(this.step.action){
      return ActionComponent;
    }
    if(this.step.ingredient) {
      if(this.step.quantity) {
        return IngredientQtyComponent;
      } else if(this.step.measure !== undefined && this.step.measure !== null) {
        return IngredientToolComponent;
      } else {
        return IngredientToolComponent;
      }
    }
    if(this.step.verification) {
      return VerificationComponent;
    }
    if(this.step.item) {
      return ItemComponent;
    }
    if(this.step.job_video) {
      return JobVideoComponent;
    }
    if((this.step.preference) ||
        (this.step.table) ||
        (this.step.multiple_choice)) {
      return JobQuestionComponent;
    }

    if(this.step.complete !== undefined && this.step.complete !== null) {
      return CompleteComponent;
    }
    return ActionComponent;
  }

  play_video() {
    this.hideVideoBtn = true;
    this.videoModal.open();
  }

  close_video() {
    this.hideVideoBtn = false;
  }

  overButton() {
    // handle mouse and touch events through eventListener
    this.wfSvc.sendDisableSwiperEvent();
  }

  leaveButton() {
    this.wfSvc.sendEnableSwiperEvent();
  }

  disableTouchMove() {
    this.wfSvc.sendDisableTouchMoveEvent();
  }

  enableTouchMove() {
    this.wfSvc.sendEnableTouchMoveEvent();
  }

}
