<div #register_modal class="register-container">
    <div class="login-wrapper">
        <div *ngIf="!promoTextVisible && !beta" class="promo-text">
            <h3 style="white-space: pre-wrap;">{{translatorSvc.getLabel(registerText)}}</h3>
        </div>
        <div *ngIf="promoTextVisible && !beta" class="promo-text">
            <h3 style="white-space: pre-wrap;">{{translatorSvc.getText('promoteRegister')}}</h3>
        </div>
        <div *ngIf="beta"class="promo-text">
            <h3 style="white-space: pre-wrap;">{{translatorSvc.getText('registerToContinuePurchase')}}</h3>
        </div>
        <div class="form-wrapper">
            
            <div class="input-field-wrapper">
                <label>{{translatorSvc.getLabel('emailAccount')}}</label>
                <input
                autocomplete="email"
                #emailInput 
                name="emailInput" 
                type="text" 
                class="modal-input-field"
                (focusout) = "emailFocusOut(emailInput.value)"
                placeholder="{{translatorSvc.getLabel('email')}}" 
                [(ngModel)]="email" 
                required 
                autofocus>
            </div>
            
            <div class="input-field-wrapper">
                <label>{{translatorSvc.getLabel('createPassword')}}</label> 
                <input
                autocomplete="new-password"
                #pwInput
                name="pwInput" 
                type="password" 
                class="modal-input-field"
                (keyup)="onKeyup()"
                (focusout) = "pwFocusOut()"
                placeholder="{{translatorSvc.getLabel('password')}}" 
                [(ngModel)]="password" 
                required 
                autofocus>
            </div>
            <label class="displayNone" [class.display-error]="!pwValid && pwFocusOutOnce">{{translatorSvc.getErrorMsg('pwLengthError')}}</label>
            <div class="input-field-wrapper">
                <button type="button" (click)="createEmailUser()" class="modal-text-button" [disabled]="!formValid">{{translatorSvc.getLabel('create-account')}}</button>
            </div>
            <div class="social-button-container">
                <button type="button" class="social-login-button" (click)="loginGoogle()"><img class="google-login-svg" src="../../../../assets/brands/google.svg">{{translatorSvc.getLabel('googleLogin')}}</button>
                <!-- <button type="button" class="social-login-button" (click)="authSvc.facebookAuth(true); closeRegister();"><img class="google-login-svg" src="../../../../assets/brands/facebook.svg">Login with Facebook</button> -->
            </div>
            <div >
                <div class="link-text" (click)="goHome()">{{translatorSvc.getLabel('haveAccount')}}</div>
            </div>
            <div class="input-field-wrapper">
                <button type="button" (click)="closeRegister()" class="modal-text-button">{{translatorSvc.getLabel('cancel')}}</button>
            </div>
        </div>
    </div>
</div>
