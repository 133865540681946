import { TranslatorService } from 'src/app/tools/services/translator.service';
import { RecipeService } from '../../services/recipe.service';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { SpinnerOverlayService } from '../../../tools/services/spinner-overlay.service';
import { Router } from '@angular/router';
import { Swiper } from 'swiper';
import { Navigation } from 'swiper/modules';
import { WorkflowService } from '../../services/workflow.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserConfirmationComponent } from 'src/app/modal/components/user-confirmation/user-confirmation.component';
import { Recipe } from 'src/app/models/recipe.model';
import { Workflow } from 'src/app/models/workflow.model';
import { DomainService } from '../../services/domain.service';
import { utils } from 'src/app/utils/utils';
import { FullScreenComponent } from 'src/app/modal/components/full-screen/full-screen.component';
import { RouterExtService } from 'src/app/tools/services/router-ext.service';
import { DisplayService } from 'src/app/tools/services/display.service';
import { AuthService } from 'src/app/admin/services/auth.service';
import { FamiliarizationModalComponent } from 'src/app/modal/components/familiarization-modal/familiarization-modal.component';


@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss']
})
export class RecipesComponent implements AfterViewInit {

  private recipeSwiper: Swiper;
  public seeRecipes: boolean;
  public overflow: boolean = false;
  @ViewChild("phone_recipes_container_recipes") recipes_container: ElementRef;

  constructor(
    public domainSvc : DomainService,
    public recipeSvc : RecipeService,
    public workflowSvc : WorkflowService,
    public spinnerSvc : SpinnerOverlayService,
    public translatorSvc : TranslatorService,
    public matDialog: MatDialog,
    public displaySvc : DisplayService,
    private routerExtSvc : RouterExtService,
    public authSvc : AuthService,
    private router : Router) {
  }

  async ngAfterViewInit(): Promise<void> {
    this.seeRecipes = false;
    if(this.domainSvc.currentDomain.workflows && this.domainSvc.currentDomain.workflows.length > 0) {
      if(!this.workflowSvc.nav_back) {
        this.workflowSvc.nav_back = !this.workflowSvc.nav_back;
      }
      
      if (this.spinnerSvc.active) {
        this.spinnerSvc.hide(2);
      }
    }
    

    if(this.recipes_container) {
      if(this.recipes_container.nativeElement.children) {
        if(this.recipes_container.nativeElement.children.length * this.recipes_container.nativeElement.children[0].offsetHeight > this.recipes_container.nativeElement.offsetHeight) {
          this.overflow = true;
        }
      }
    }
    if(!this.displaySvc.chkFullScreenSize() && !this.displaySvc.fullScreenAsked) {
      this.openFullScreenModal();
      utils.styleModals("app-full-screen", "modal-box", "mdc-style");
    } else {
      if(!this.authSvc.isFamiliarizationHide && !this.routerExtSvc.previousUrls.find((obj) => {
        return obj === '/recipes'}
      )) {
        this.openFamiliarizationModal();
        utils.styleModals("app-familiarization-modal", "comment-box", "mdc-style");
      }
    }
    
    this.recipeSwiper = new Swiper("#recipe-swiper-container", {
      modules: [Navigation],
      slideClass : 'recipe-swiper-slide',
      slidesPerView : 'auto',
      loop: false,
      observer: true,
      observeParents: true,
      direction: 'horizontal',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      });
    
    

      /* const elems = document.getElementsByClassName("recipe-swiper-slide");
      for (let i=0; i< elems.length; i++) {
        elems.item(i).classList.remove("visible");
        elems.item(i).classList.add("visible");
      } */
  }

  asRecipe(value: Workflow) : Recipe {
    return (value as Recipe);
  }

  goEditor() {
    this.router.navigate(['recipe-editor']);
  }

  gotoRecipeDetails() {
    this.router.navigate(['recipe-detail']);
  }

  launchWorkflow() {
    if(!this.workflowSvc.selectedWorkflow.purchased) {
      this.openAccessRecipeModal();
    } else {
      if(this.workflowSvc.selectedWorkflow.id !== this.workflowSvc.selectedWorkflow.id && this.workflowSvc.currentStepId > 0) {
        this.openStopCurrentRecipeModal();
      } else {
        this.workflowSvc.launchWorkflow();
      }
    }
  }

  restartWorkflow() {
    this.workflowSvc.resetWorkflowSteps();
    this.workflowSvc.resetStepTime();
    this.workflowSvc.launchWorkflow();
  }

  showCategories() {
    // show the categories that we can select.
    // Do not show the current one
  }

  async showGoOrgModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "go-org-modal";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "go-to-org",
      message: this.translatorSvc.getLabel('goToOrg'),
      actionButtonText1 : this.translatorSvc.getLabel('no'),
      actionButtonText2 : this.translatorSvc.getLabel('yes'),
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  openAccessRecipeModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "access-recipe",
      message: this.translatorSvc.getText('accessRecipe'),
      actionButtonText1 : this.translatorSvc.getLabel('no'),
      actionButtonText2 : this.translatorSvc.getLabel('yes'),
      
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  openStopCurrentRecipeModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "change-recipe",
      message: this.translatorSvc.getLabel('confirmGoToRecipeDetail'),
      actionButtonText1 : this.translatorSvc.getLabel('no'),
      actionButtonText2 : this.translatorSvc.getLabel('yes'),
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  openFullScreenModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "full-screen-modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.panelClass = "autonome-modal";
  
    this.matDialog.open(FullScreenComponent, dialogConfig).afterClosed().subscribe(() => {
      if(!this.authSvc.isFamiliarizationHide) {
        this.openFamiliarizationModal();
        utils.styleModals("app-familiarization-modal", "comment-box", "mdc-style");
      }
    });
  }

  openFamiliarizationModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "open-familiarization-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    this.authSvc.isFamiliarizationModalOpen = true;
    this.matDialog.open(FamiliarizationModalComponent, dialogConfig).afterClosed().subscribe(() => {
      this.authSvc.isFamiliarizationModalOpen = false;
    });
    
  }

  gotoOrgs() {
    this.router.navigate(['organisations']);
  }

  gotoRecipes() {
    this.seeRecipes = true;
  }

  scrollRecipesDown() {
    utils.scrollDown(this.recipes_container, 200);
  }

  onRecipesScroll() {
    if(this.recipes_container.nativeElement.scrollTop === 0) {
      this.overflow = true;
    } else {
      this.overflow = false;
    }
  }
  
}
