<div class="action-list-item-container">
    <h3 class="action-index">{{stepItem.actionListIndex}}.</h3>
    <div class="action-picto">
        <img class="picto-img" src="/assets/pictos/actions/{{stepItem.action.picto}}.svg">
        <img class="video-button"
            [class.displayNone]="hideVideoBtn"
            src="/assets/base_icons/icon_play_video.svg"
            (touchstart)='disableTouchMove()'
            (touchend)='enableTouchMove()'
            (mouseenter)='overButton()'
            (mouseleave)='leaveButton()'
            (click)="play_video()" alt="Play video">

        
    </div>
    <p class="measure-qty-label" >{{stepItem.action.description}}</p>
</div>
