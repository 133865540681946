import { StepType } from './../enums/step-type.enum';
import { Complete } from './complete.model';
import { Action } from './action.model';
import { Ingredient } from './ingredient.model';
import { Verification } from './verification.model';
import { Item } from './item.model';
import { QuestionPreference } from './question-preference.model';
import { QuestionTable } from './question-table.model';
import { QuestionMultipleChoice } from './question-multiple-choice.model';
import { JobVideo } from './job-video.model';
import { Tool } from './tool.model';
import { StepI } from '../interfaces/step.interface';


export class Step implements StepI {

    action? : Action;
    ingredient? : Ingredient;
    verification? : Verification;
    item? : Item;
    job_video?: JobVideo;
    table?: QuestionTable;
    preference?: QuestionPreference;
    multiple_choice? : QuestionMultipleChoice;
    complete?: object;
    action_steps? : Step[];
    check_steps? : Step[];
    cycle_steps? : Step[];
    quantity? : number;
    measure? : number;
    time? : number;
    extra_time? : number;
    passive_time? : number;
    timer_text? : number;
    svg_string? : string;
    default_picto?: string;

    public _display_id : number;
    private _unit : string;
    private _current : boolean = false;
    private _completed : boolean = false;
    private _original_time : number;
    private _paused_time: number;
    private _running_time : string | undefined;
    private _time_stop : boolean = false;
    private _time_paused : boolean = false;
    private _time_expired : boolean = false;
    private _timer_text : string | undefined;
    private _timer_text_complete : string | undefined;
    private _step_type! : StepType;
    private _steps_completed : number = 0;
    private _steps_total : number = 0;
    private _processed : boolean | undefined;
    private _blocked : boolean | undefined;
    private _can_pursue : boolean | undefined;
    private _initial_checkstep: boolean = false;
    private _timerFlag : boolean = false;
    private _min_competency: number;
    private _actionListIndex: number;
    private _tools: Tool[];
    
    constructor(value: object) {
        const step = Object(value);
        this._actionListIndex = step.actionListIndex;

        if(step.action) {
            this.action = new Action(step.action);
            this._step_type = StepType.Action;
            if(step.time !== null && step.time !== undefined) {
                this.time = step.time;
                this._original_time = this.time;
            }
            if(step.extra_time !== null && step.extra_time !== undefined) {
                this.extra_time = step.extra_time;
            }
            if(step.passive_time !== null && step.passive_time !== undefined) {
                this.passive_time = step.passive_time;
            }
        }
        if(step.ingredient) {
            this.ingredient = new Ingredient(step.ingredient);
            if(step.quantity || step.unit) {
                this._step_type = StepType.IngredientQty;
                if(step.quantity !== null && step.quantity !== undefined) {
                    this.quantity = step.quantity;
                }
                if(step.unit !== null && step.unit !== undefined) {
                    this._unit = step.unit;
                    this.measure = step.measure;
                }
            } else if(step.measure) {
                this._step_type = StepType.IngredientTool;
                this.measure = step.measure;
            } else {
                // Assign ingredient tool as it is most likely a bowl with tool (e.g. ladle)
                this._step_type = StepType.IngredientTool;
            }
            
        }
        if(step.item) {
            this.item = new Item(step.item);
            this.quantity = step.quantity;
            this._step_type = StepType.ItemQty;
        }
        if(step.verification) {
            this.verification = new Verification(step.verification);
            this._step_type = StepType.Verification;
        }

        if(step.job_video) {
            this.job_video = new JobVideo(step.job_video);
            this._step_type = StepType.JobVideo;
        }

        if(step.preference) {
            this.preference = new QuestionPreference(step.preference);
            this._step_type = StepType.Preference;
        }

        if(step.table) {
            this.table = new QuestionTable(step.table);
            this._step_type = StepType.Table;
        }

        if(step.multiple_choice) {
            this.multiple_choice = new QuestionMultipleChoice(step.multiple_choice);
            this._step_type = StepType.MultipleChoice;
        }

        if(step.complete) {
            this.complete = new Complete();
            this._step_type = StepType.Complete;
        }

        if(step._display_id || step._display_id === 0) {
            this._display_id = step._display_id;
        }

        if (step.action_steps && step.action_steps.length > 0) {
            this.action_steps = [];
            for (const aStep of step.action_steps) {
                this.action_steps.push(new Step(aStep));
            }
        }

        if (step.check_steps && step.check_steps.length > 0) {
            this.check_steps = [];
            for (const cStep of step.check_steps) {
                this.check_steps.push(new Step(cStep));
            }
        }

        if (step.cycle_steps && step.cycle_steps.length > 0) {
            this.cycle_steps = [];
            for (const cStep of step.cycle_steps) {
                this.cycle_steps.push(new Step(cStep));
            }
        }
    }

    get stepType() {
        return this._step_type;
    }

    public get tools(): Tool[] {
        return this._tools;
    }
    public set tools(value: Tool[]) {
        this._tools = value;
    }

    get unit() {
        return this._unit;
    }
    set unit(unit: string) {
        this._unit = unit;
    }

    get current() {
        return this._current;
    }
    set current(current : boolean) {
        this._current = current;
    }

    public get paused_time(): number {
        return this._paused_time;
    }
    public set paused_time(value: number) {
        this._paused_time = value;
    }

    get initialCheckStep() {
        return this._initial_checkstep;
    }
    set initialCheckStep(intialCS : boolean) {
        this._initial_checkstep = intialCS;
    }

    get stepsTotal() {
        return this._steps_total;
    }
    set stepsTotal(stepTot : number) {
        this._steps_total = stepTot;
    }

    get stepsCompleted() {
        return this._steps_completed;
    }
    set stepsCompleted(stepTot : number) {
        this._steps_completed = stepTot;
    }

    get completed() {
        return this._completed;
    }
    set completed(completed : boolean) {
        this._completed = completed;
    }

    get timerFlag() {
        return this._timerFlag;
    }
    set timerFlag(flag: boolean) {
        this._timerFlag = flag;
    }

    get originalTime() {
        return this._original_time;
    }
    set originalTime(time:number) {
        this._original_time = time;
    }

    public get min_competency(): number {
        return this._min_competency;
    }
    public set min_competency(value: number) {
        this._min_competency = value;
    }

    public get actionListIndex(): number {
        return this._actionListIndex;
    }
    public set actionListIndex(value: number) {
        this._actionListIndex = value;
    }
}