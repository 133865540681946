import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AdminModule } from '../admin/admin.module';
import { ModalsModule } from '../modal/modals.module';
import { AutonomeWorldComponent } from './components/autonome-world/autonome-world.component';
import { TextMessageComponent } from './components/text-message/text-message.component';
import { BattleComponent } from './components/battle/battle.component';
import { CombatantComponent } from './components/combatant/combatant.component';
import { SubmissionMenuComponent } from './components/submission-menu/submission-menu.component';
import { TeamComponent } from './components/team/team.component';
import { PizzaComponent } from './components/pizza/pizza.component';
import { GlobComponent } from './components/glob/glob.component';
import { SubmissionMenuItemComponent } from './components/submission-menu-item/submission-menu-item.component';
import { SubmissionMenuSubitemsComponent } from './components/submission-menu-subitems/submission-menu-subitems.component';
@NgModule({
  declarations: [
    AutonomeWorldComponent,
    TextMessageComponent,
    BattleComponent,
    CombatantComponent,
    SubmissionMenuComponent,
    TeamComponent,
    PizzaComponent,
    GlobComponent,
    SubmissionMenuItemComponent,
    SubmissionMenuSubitemsComponent
  ],
  imports: [
    AdminModule,
    CommonModule,
    AngularFirestoreModule,
    ModalsModule
  ],
  providers: [
  ]
})
export class AcademyModule { }