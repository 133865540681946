
import { AutonomeWorldMap } from "./autonome-world-map";
import { Subscription } from "rxjs";
import { AcademyService } from "../services/academy.service";
import { GameObject } from "./game-object";
import { SceneTransition } from "../components/scene-transition/scene-transition";
import { Battle } from "./battle";
import { utils } from "../../utils/utils";


export class AutonomeWorldEvent {

    private _map: AutonomeWorldMap;
    private _event: any;
    textMsgClosedSubscription: Subscription;
    battleClosedSubscription: Subscription;

    constructor({map, event}, private academySvc: AcademyService) {

        this._map = map;
        this._event = event;

        
    }

    stand(resolve) {
        const who = this.map.gameObjects[this.event.who];
        who.startBehaviour({
            map: this._map
        },{
            type: "stand",
            direction: this.event.direction,
            time: this.event.time
        })

        // set handler to complete standing
        const completeHandler = e => {
            if(e.detail.whoId === this.event.who) {
                document.removeEventListener("PersonStandComplete", completeHandler);
                resolve();
            }
        }
        document.addEventListener("PersonStandComplete", completeHandler)
    }

    walk(resolve) {
        const who = this.map.gameObjects[this.event.who];
        who.startBehaviour({
            map: this._map
        },{
            type: "walk",
            direction: this.event.direction,
            retry: true
        })

        // set handler to complete walking
        const completeHandler = e => {
            if(e.detail.whoId === this.event.who) {
                document.removeEventListener("PersonWalkingComplete", completeHandler);
                resolve();
            }
        }
        document.addEventListener("PersonWalkingComplete", completeHandler)
    }

    textMessage(resolve) {

        if(this.event.faceHero) {
            const obj = this.map.gameObjects[this.event.faceHero];
            (obj as GameObject).direction = utils.oppositeDirection(this.map.gameObjects["hero"].direction);
        }
        //this.map.showText(this.event.text);
        this.academySvc.textMessage = this.event.text;
        this.textMsgClosedSubscription = this.academySvc.getTextMsgClosedEvent().subscribe(()=> {
            resolve();
            this.textMsgClosedSubscription.unsubscribe();
        });
        
    }

    changeMap(resolve) {
        const sceneTransition = new SceneTransition();
        sceneTransition.init(document.querySelector(".game-container"), () => {
            this.map.autonomeWorld.startMap(this.map.autonomeWorld.autonomeWorldMaps[this.event.map]);
            resolve();
            sceneTransition.fadeOut();
        });
    }

    battle(resolve) {
        this.academySvc.battleShow = true;
        this.battleClosedSubscription = this.academySvc.getBattleClosedEvent().subscribe(() => {
            resolve();
            this.battleClosedSubscription.unsubscribe();
        })
    }

    init() {
        return new Promise(resolve => {
            this[this._event.type](resolve);
        })
    }

    public get map(): AutonomeWorldMap {
        return this._map;
    }
    public set map(value: AutonomeWorldMap) {
        this._map = value;
    }

    public get event(): any {
        return this._event;
    }
    public set event(value: any) {
        this._event = value;
    }
}