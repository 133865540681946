import { AfterViewInit, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Domain } from 'domain'; 
import { AuthService } from 'src/app/admin/services/auth.service';
import { DomainService } from 'src/app/core/services/domain.service';
import { ProductService } from 'src/app/core/services/product.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { DomainExtraInfoComponent } from 'src/app/modal/components/domain-extra-info/domain-extra-info.component';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { TranslatorService } from 'src/app/tools/services/translator.service'; 
import Swiper from 'swiper';

@Component({
  selector: 'app-familiarization',
  templateUrl: './familiarization.component.html',
  styleUrls: ['./familiarization.component.scss']
})


export class FamiliarizationComponent implements AfterViewInit {

  public screenWidth: number;
  private domainSwiper: Swiper;
  public overflow: boolean = false;
  public description_overflow: boolean = false;
  domain: Domain;

  constructor(
    public domainSvc : DomainService,
    public workflowSvc : WorkflowService,
    public authSvc : AuthService,
    public productSvc: ProductService,
    public spinnerSvc : SpinnerOverlayService,
    private matDialog: MatDialog,
    public translatorSvc : TranslatorService
  ) {
    this.screenWidth = window.innerWidth;
    // select first research project domain
    this.domainSvc.selectedDomain = this.domainSvc.domains.filter((obj) => obj.id.includes('job_examples'))[0];
  }

  ngAfterViewInit(): void {
    this.screenWidth = window.innerWidth;
  }

  goToDomain() {
    /* if (document.fullscreenElement === null) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        this.elem.msRequestFullscreen();
      }
    } */
    this.domainSvc.goToDomains();
  }

  openExtraInfoModal(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "domain-extra-info";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    this.matDialog.open(DomainExtraInfoComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
    
  }

}
