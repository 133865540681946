import { Component, OnInit } from '@angular/core';
import { WorkflowService } from '../../services/workflow.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { RecipeService } from '../../services/recipe.service';
import { Router } from '@angular/router';
import { DomainService } from '../../services/domain.service';

@Component({
  selector: 'app-master-chef-workflow',
  templateUrl: './master-chef-workflow.component.html',
  styleUrl: './master-chef-workflow.component.scss'
})
export class MasterChefWorkflowComponent implements OnInit {

  currentStepIndex : number;

  constructor(
    public workflowSvc : WorkflowService,
    public domainSvc : DomainService,
    public recipeSvc : RecipeService,
    public translatorSvc : TranslatorService,
    private router : Router
  ) {
    this.currentStepIndex = this.workflowSvc.currentStepId;
    this.workflowSvc.displaySteps[this.currentStepIndex].current = true;
  }

  ngOnInit(): void {
    
  }

  private resetSteps() {
    for(const step of this.workflowSvc.displaySteps) {
      step.completed = false;
      step.current = false;
    }
  }

  goToRecipes() {
    this.router.navigate(['recipes']);
  }
}
