import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShippingComponent } from './components/shipping/shipping.component';
import { StripeCardElementsComponent } from './components/stripe-card-elements/stripe-card-elements.component';
import { ToolModule } from '../tools/tool.module';
import { PaymentComponent } from './components/payment/payment.component';
import { OrderComponent } from './components/order/order.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { FormsModule } from '@angular/forms';
import { StripeCardItemComponent } from './components/stripe-card-item/stripe-card-item.component';
@NgModule({
  declarations: [
    ShippingComponent,
    StripeCardElementsComponent,
    PaymentComponent,
    OrderComponent,
    ShoppingCartComponent,
    StripeCardItemComponent
  ],
  imports: [
    CommonModule,
    ToolModule,
    FormsModule
  ]
})
export class PaymentModule { }
