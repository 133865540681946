import { Subscription } from "rxjs";
import { AcademyService } from "../services/academy.service";
import { Actions } from "../content/actions";
import { utils } from "../../utils/utils";

export class BattleEvent {

    private _event: any;
    textMsgClosedSubscription: Subscription;
    submissionMenuClosedSubscription: Subscription;

    constructor(event, private academySvc: AcademyService) {
        this._event = event;
    }

    textMessage(resolve) {
        if(this.academySvc.action) {
            this.academySvc.textMessage = this.event.text
            .replace("{CASTER}", this.academySvc.caster.name)
            .replace("{TARGET}", this.academySvc.battleTarget.name)
            .replace("{ACTION}", Actions[this.academySvc.action].name);
        } else {
            this.academySvc.textMessage = this.event.text;
        }
        
        this.textMsgClosedSubscription = this.academySvc.getTextMsgClosedEvent().subscribe(()=> {
            resolve();
            this.textMsgClosedSubscription.unsubscribe();
        });
    }

    async stateChange(resolve) {
        const {damage, recover, status} = this.event;
        let who = this.event.onCaster ? this.academySvc.caster : this.academySvc.battleTarget;
        if(Actions[this.academySvc.action].targetType === "friendly") {
            who = this.academySvc.caster;
        }
        if(damage) {
            this.academySvc.battleTarget.update({
                hp: this.academySvc.battleTarget.hp - damage
            });

            // blinking
            this.academySvc.battleTarget.blink = true;
        }

        if(recover) {
            
            let newHp = who.hp + recover;
            if(newHp > who.maxHp) {
                newHp = who.maxHp;
            }   
            who.update({
                hp: newHp
            });  
        }

        if(status) {
            who.update({
                status: {...status}
            })
        }
        if(status === null) {
            who.update({
                status: null
            })
        }

        await utils.wait(600);        
        this.academySvc.battleTarget.blink = false;

        resolve();
    }

    submissionMenu(resolve) {
        if(this.academySvc.caster.isPlayerControlled) {
            this.academySvc.submissionMenuShow = true;
            this.submissionMenuClosedSubscription = this.academySvc.getSubmissionMenuClosedEvent().subscribe(() => {
                this.academySvc.decide();
                resolve();
                this.submissionMenuClosedSubscription.unsubscribe();
            });
        }
    }

    async animation(resolve) {
        switch(this._event.animation) {
            case 'spin' :
                this.academySvc.caster.spin= true;
            break;
            case 'glob' :
                this.academySvc.caster.glob= true;
                this.academySvc.caster.glob_color = this._event.color;
            break;    
        }

        
        await utils.wait(600);
        resolve();
    }

    init(resolve) {
        this[this._event.type](resolve);
    }

    public get event(): any {
        return this._event;
    }
    public set event(value: any) {
        this._event = value;
    }
}