import { Step } from '../../../models/step.model';
import { StepWrapperComponent } from '../../../interfaces/step-wrapper.component';
import { AfterViewInit, Component, Input } from '@angular/core';
import { Recipe } from '../../../models/recipe.model';
import { TranslatorService } from '../../../tools/services/translator.service'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ImageCaptureComponent } from 'src/app/modal/components/image-capture/image-capture.component';
import { WorkflowService } from '../../services/workflow.service';
import { DomainService } from '../../services/domain.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class CompleteComponent implements AfterViewInit, StepWrapperComponent {
  @Input() step! : Step;

  completeDescription : string;
  isJobExample : boolean = false;
  canTakePicture : boolean = false;
  videoDevice: MediaDeviceInfo;

  constructor(public translatorSvc: TranslatorService,
    public matDialog: MatDialog,
    public domainSvc : DomainService,
    private router : Router,
    public workflowSvc: WorkflowService) {

  }

  async ngAfterViewInit(): Promise<void> {
    if(this.workflowSvc.currentWorkflow.steps[0].job_video) {
      this.isJobExample = true;
    }
    if(!(this.workflowSvc.currentWorkflow instanceof Recipe)) {
      this.completeDescription = this.translatorSvc.getLabel('congratsWfComplete') + this.workflowSvc.currentWorkflow.id;
    } else {
      if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        devices.filter(device => {
          if (device.kind === 'videoinput') {
            /* if (device.label && device.label.length > 0) {
              if (device.label.toLowerCase().indexOf('back') >= 0) {
                this.videoDevice = device;
                this.canTakePicture = true;
              }
            } */
              this.videoDevice = device;
              this.canTakePicture = true;
          }
        });
      }
      this.completeDescription = this.translatorSvc.getLabel('completeRecipe');
    }
  }

  async openWebcamModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-webcam-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "webcam",
      videoDevice: this.videoDevice,
    };
    this.matDialog.open(ImageCaptureComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }

  submitResults() {
    this.workflowSvc.submitJobExampleResults();
    
  }

  goToRecipes() {
    this.router.navigate(['recipes']);
  }

}
