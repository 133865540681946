import { AcademyService } from './../services/academy.service';
import { AutonomeWorldEvent } from './autonome-world-event';
import { AutonomeWorldMap } from './autonome-world-map';
import { Sprite } from "./sprite";

export class GameObject {
    private _x:number;
    private _y:number;
    private _sprite: Sprite;
    private _direction: string;
    private _isMounted: boolean;
    public _id: string;
    private _behaviourLoop: any;
    private _behaviourLoopIndex: number;
    private _isStanding: boolean;
    private _talking;
    
    

    constructor(config, private academySvc : AcademyService) {
        this._id = null;
        this._isMounted = false;
        this.x = config.x || 0;
        this.y = config.y || 0;
        this.direction = config.direction || "down";
        this._sprite = new Sprite({
            gameObject : this,
            src: config.src || "/assets/academy/characters/people/hero.png",
        }, academySvc);
        this._behaviourLoop = config.behaviourLoop || [];
        this._behaviourLoopIndex = 0;

        this._talking = config.talking || [];
    }

    mount(map: AutonomeWorldMap) {
        this._isMounted= true;
        map.addWall(this.x, this.y);

        // if behaviour then start after delay
        setTimeout(() => {
            this.doBehaviourEvent(map);
        }, 10);

    }

    update(state: any) {

    }

    async doBehaviourEvent(map: AutonomeWorldMap) {

        //  do nothing if cutscene or no config
        if(map.isCutscenePlaying || this._behaviourLoop.length ===0 || this.isStanding) {
            return;
        }

        // setup event with right info
        let eventConfig = this._behaviourLoop[this._behaviourLoopIndex];
        eventConfig.who = this._id;

        // Create next event
        const eventHandler = new AutonomeWorldEvent({ map, event: eventConfig}, this.academySvc);
        await eventHandler.init();

        // set the next event
        this.behaviourLoopIndex += 1;
        if(this._behaviourLoopIndex === this._behaviourLoop.length) {
            this._behaviourLoopIndex = 0;
        }

        this.doBehaviourEvent(map);
    }

    public get behaviourLoop(): any {
        return this._behaviourLoop;
    }
    public set behaviourLoop(value: any) {
        this._behaviourLoop = value;
    }

    public get behaviourLoopIndex(): number {
        return this._behaviourLoopIndex;
    }
    public set behaviourLoopIndex(value: number) {
        this._behaviourLoopIndex = value;
    }

    public get isStanding(): boolean {
        return this._isStanding;
    }
    public set isStanding(value: boolean) {
        this._isStanding = value;
    }

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get isMounted(): boolean {
        return this._isMounted;
    }
    public set isMounted(value: boolean) {
        this._isMounted = value;
    }

    public get talking() {
        return this._talking;
    }
    public set talking(value) {
        this._talking = value;
    }

    get x() {
        return this._x;
    }
    set x(value: number) {
        this._x = value
    }

    get y() {
        return this._y;
    }
    set y(value: number) {
        this._y = value
    }

    get sprite() {
        return this._sprite;
    }
    set sprite(value: Sprite) {
        this._sprite = value
    }

    public get direction(): string {
        return this._direction;
    }
    public set direction(value: string) {
        this._direction = value;
    }
}