import { utils } from "../../utils/utils";
import { Battle } from "./battle";

export class Combatant {

    private _battle: Battle;
    private _id: string;
    private _active: boolean;
    private _team: string;
    private _name: string;
    private _src: string;
    private _actions: string[];
    private _blink: boolean = false;
    private _hp: number;
    private _maxHp: number;
    
    private _status: any;

    // animation booleans
    private _spin: boolean = false;
    private _glob: boolean = false;
    private _glob_color: string;

    // submission-menu booleans
    private _isPlayerControlled: boolean = false;
    

    constructor(config: any, battle: Battle) {
        Object.keys(config).forEach(key => {
            this[key] = config[key];
        });

        this._battle = battle;
    }

    update(changes ={}) {
        Object.keys(changes).forEach(key => {
        this[key] = changes[key]});
    }

    getReplacedEvents(originalEvents) {
        if(this.status?.type === "clumsy" && utils.randomFromArray([true, false, false])) {
            return [
                {type: "textMessage", text:`${this._name} flops over!`},

            ]
        } 
        
        return originalEvents;
    }

    getPostEvents() {

        if(this.status?.type === "saucy") {
            return [
                { type: "textMessage", text: "Feelin' saucy!"},
                { type: "stateChange", recover: 5, onCaster: true }
            ]
        }

        return [];
    }

    decrementStatus() {
        if(this.status?.expiresIn > 0) {
            this.status.expiresIn -= 1;
            if(this.status.expiresIn === 0) {
                this.update({
                    status: null
                })
                return {
                    type: "textMessage",
                    text: "No longer saucy!"
                }
            }
        }

        return null;
    }

    public get battle(): Battle {
        return this._battle;
    }
    public set battle(value: Battle) {
        this._battle = value;
    }

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get active(): boolean {
        return this._active;
    }
    public set active(value: boolean) {
        this._active = value;
    }

    public get team(): string {
        return this._team;
    }
    public set team(value: string) {
        this._team = value;
    }

    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    
    public get src(): string {
        return this._src;
    }
    public set src(value: string) {
        this._src = value;
    }

    public get actions(): string[] {
        return this._actions;
    }
    public set actions(value: string[]) {
        this._actions = value;
    }

    public get blink(): boolean {
        return this._blink;
    }
    public set blink(value: boolean) {
        this._blink = value;
    }

    public get hp(): number {
        return this._hp;
    }
    public set hp(value: number) {
        this._hp = value;
    }

    public get maxHp(): number {
        return this._maxHp;
    }
    public set maxHp(value: number) {
        this._maxHp = value;
    }

    public get status(): any {
        return this._status;
    }
    public set status(value: any) {
        this._status = value;
    }


    // animation getter setters
    public get spin(): boolean {
        return this._spin;
    }
    public set spin(value: boolean) {
        this._spin = value;
    }

    public get glob(): boolean {
        return this._glob;
    }
    public set glob(value: boolean) {
        this._glob = value;
    }
    public get glob_color(): string {
        return this._glob_color;
    }
    public set glob_color(value: string) {
        this._glob_color = value;
    }

    // submission menu getters setters
    public get isPlayerControlled(): boolean {
        return this._isPlayerControlled;
    }
    public set isPlayerControlled(value: boolean) {
        this._isPlayerControlled = value;
    }
    
}