import { Competency } from './competency.model';
import { Tool } from './tool.model';

export class Item {
    private _descriptions: any;
    private _item_name: string;
    private _description: string;
    private _svg_string : string;
    private _tools : Array<Tool>;
    private _picto: string;
    
    constructor(itemData : any) {
        
        this._item_name = itemData.item_name;
        this._description = '';
        this._svg_string = itemData.svg_string;
        this._tools = new Array();
        this._descriptions = itemData.descriptions;
        this._picto = itemData.picto;
    }

    public get description(): string {
        return this._description;
    }
    public set description(value: string) {
        this._description = value;
    }

    public get item_name(): string {
        return this._item_name;
    }
    public set item_name(value: string) {
        this._item_name = value;
    }

    public get descriptions(): any {
        return this._descriptions;
    }
    public set descriptions(value: any) {
        this._descriptions = value;
    }

    public get picto(): string {
        return this._picto;
    }
    public set picto(value: string) {
        this._picto = value;
    }

    setDescription(lang : string, qty: number) {
        if(this.    _descriptions !== undefined && this._descriptions !== null) {
            if(qty > 1 && this._descriptions[lang].plural !== '') {
                this._description = this._descriptions[lang].plural;
            } else {
                this._description = this._descriptions[lang].singular;
            }
        }
        // POC key words
        /*const en_description : string = this._description_array.find(({language}) => language === 'en');
        for (const key_word of this._key_words) {
            this._description = this._description.replace(key_word, '<a (click)="openKeyWordModal('+key_word+')">'+key_word+'</a>');
        }
        */
    }
}