import { WorkflowService } from '../../core/services/workflow.service';
import { AuthService } from '../../admin/services/auth.service';
import { Injectable, OnInit } from '@angular/core';
import { ProductService } from '../../core/services/product.service';
import { Router } from '@angular/router';
import { SpinnerOverlayService } from '../../tools/services/spinner-overlay.service';
import getUserLocale from 'get-user-locale';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserConfirmationComponent } from '../components/user-confirmation/user-confirmation.component';
import { EmailSentConfirmComponent } from '../components/email-sent-confirm/email-sent-confirm.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { OrganisationEditComponent } from '../components/organisation-edit/organisation-edit.component';
import { TimerService } from './timer.service';
import { OrganisationService } from 'src/app/organisations/services/organisation.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService implements OnInit{

  _userLocale: string;
  private _chk_box: boolean = false;

  constructor(
    private authSvc : AuthService,
    private wfSvc : WorkflowService,
    private timerSvc: TimerService,
    private productSvc : ProductService,
    private organisationSvc : OrganisationService,
    private spinnerSvc : SpinnerOverlayService,
    private router: Router,
    private matDialog : MatDialog) { }
  
  
  ngOnInit(): void {
    this._userLocale = getUserLocale();
    if(this._userLocale.length>2) {
      this._userLocale = this._userLocale.slice(0,2);
    }
  }

    

  modalAction(inputModalData : object, option: number) {
    const modalData = Object(inputModalData);
    if(modalData.check_box_message) {
      if (this._chk_box) {
        localStorage.setItem("autonome_modal_hide", 'true');
      }
    }
    switch(modalData.name) {
      case "logout" :
        this.authSvc.signOut();
        // TODO : this.recipeSvc.removeRecipes();
        break;
      case "goToRecipeDetails":
        this.router.navigate(['/recipe-detail']);
        break;  
      case "launch_workflow" :
        switch(option) {
          case 1 : this.wfSvc.currentStepId = 0;
        }
        break;
      case "access-recipe" :
        this.router.navigate(['products-domain']);
        break;
      case "change-recipe" :
        this.wfSvc.resetWorkflowSteps();
        this.wfSvc.resetStepTime();
        this.timerSvc.stopAllTimers();
        this.wfSvc.currentWorkflow = this.wfSvc.selectedWorkflow;
        this.wfSvc.launchWorkflow();
        break;
      case "confirm-change-to-free-plan" :
        this.spinnerSvc.show();

        this.authSvc.removeProduct(this.productSvc.selectedPlan.id)
          .then(() => {
            this.spinnerSvc.hide();
            this.authSvc.User.products.forEach((product, index) => {
              if(product === this.productSvc.selectedPlan.id) delete this.authSvc.User.products[index];
            });
            // TODO : this.recipeSvc.resetLocked();
            this.router.navigate(['/home']);
          });
        break;
      case "emailVerificationSend" :
        //this.openConfirmEmailVerificationSentModal();
        break;
      case "modal-inital-settings-update" :
        switch(option) {
          case 1 : this.openProfileModal();
          break;
          case 0 : this.router.navigate(['recipes']);
          break
        }
        break;
      case "modal-add-organisation" :
        this.openAddOrganisationModal();
        break;
      case "modal-email-not-verified" :
        this.authSvc.sendVerificationEmail(false);
        this.openConfModal();
        break;
      case "modal-error-user-exists":
        this.authSvc.resendAuthCode(modalData.email);
        break;
      case "go-to-org" :
        this.router.navigate(['organisations']);
        break;
      case "modal-added-promo-to-cart" :
        this.router.navigate(['shopping-cart']);
        break;
      case "open-familiarization-component":
        this.router.navigate(['familiarization']);
        break;
      case "modal-add-domain-to-cart":
        this.router.navigate(['shopping-cart']);
        break;
      case "removeMemberFromOrganisation" :
        this.organisationSvc.removeMember(this.organisationSvc.currentOrganisation.members[modalData.index].uid);
        break;
      case "memberRoleChange":
        
    } 
  }

  openConfModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "inform-email-sent";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "confirmEmail",
    };
    this.matDialog.open(EmailSentConfirmComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }

  openProfileModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.maxWidth = "90vw";
    this.matDialog.open(ProfileComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  openAddOrganisationModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.maxWidth = "90vw";
    this.matDialog.open(OrganisationEditComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }

  private confirmPwEmailSent() {
    
    let buttonTxt = 'Close'
    let txtMsg = 'Please click on the link in the email to complete your password reset';
    switch(this._userLocale) {
      case 'fr':
        buttonTxt = 'Fermer'
        txtMsg = 'Veuillez cliquer sur le lien dans le courriel envoyé pour compléter le réinitialisation de votre mot de passe';
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-confirm-pw-reset-email-sent";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "confirm-pw-reset-email-sent",
      actionButtonText1 : buttonTxt,
      hideButton2 : true,
      message: txtMsg
    };
    
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  public get chk_box(): boolean {
    return this._chk_box;
  }
  public set chk_box(value: boolean) {
    this._chk_box = value;
  }

  
}
