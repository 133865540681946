import { AfterViewInit, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RegisterComponent } from 'src/app/modal/components/register/register.component';
import { TranslatorService } from '../../../tools/services/translator.service';
//import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Component({
  selector: 'app-register-first',
  templateUrl: './register-first-beta.component.html',
  styleUrls: ['./register-first-beta.component.scss']
})
export class RegisterFirstBetaComponent implements AfterViewInit {

  constructor(
    //private gtmService: GoogleTagManagerService,
    public translatorSvc : TranslatorService,
    public matDialog: MatDialog
  ) { }


  ngAfterViewInit(): void {
    this.showRegisterModal(false);
  }

  async showRegisterModal(promo : boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-register-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      promotionTextVisible: promo,
      origin: 'register-beta',
      beta: true,
    }
    this.matDialog.open(RegisterComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

}

