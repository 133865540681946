<div class="master-chef-container">
    <div class="master-chef-wrapper">
        <section class="master-chef-header">
            <div class="workflow-nav-wrapper">
                <div class="nav-button" (click)="goToRecipes()">
                    <img src="/assets/base_icons/icon_arrow_left_blue.svg">
                    <p *ngIf="domainSvc.currentDomain.id === 'cooking'">{{translatorSvc.getLabel('backToRecipes')}}</p>
                    <p *ngIf="domainSvc.currentDomain.id !== 'cooking'">{{translatorSvc.getLabel('backToFamiliarization')}}</p>
                </div>
                <div class="workflow-selected-workflow-title"><h3>{{workflowSvc.selectedWorkflow.title}}</h3></div>
                <div class="empty-third-hdiv"></div>
            </div>
        </section>
        <div class="master-chef-workflow-buffer">
            <p class="steps-label">{{translatorSvc.getLabel('steps')}}</p>
            <section class="master-chef-workflow-container">
                <ng-container *ngFor="let step of workflowSvc.displaySteps">
                    <div class="step-container" *ngIf="step.action">
                        <div class="step-wrapper">
                            <img src="/assets/pictos/actions/{{step.action!.picto!}}.svg" alt={{step.action!.description!}}>
                            <p>- {{step.action?.description}}</p>
                            <p *ngIf="step.time > 0">&nbsp;{{translatorSvc.getLabel('for')}}&nbsp;{{step.time / 60}}&nbsp;minutes</p>
                        </div>
                    </div>
                    <div class="step-container" *ngIf="step.ingredient">
                        <div class="step-wrapper">
                            <img src="/assets/pictos/ingredients/{{step.ingredient!.picto!}}.svg" alt={{step.ingredient!.description!}}>
                            <p *ngIf="step.quantity > 0">{{step.quantity}} - {{this.step.ingredient?.description}}</p>
                            <p *ngIf="!step.quantity">{{recipeSvc.getMeasureText(step.measure)}} - {{this.step.ingredient?.description}}</p>
                        </div>
                        <div *ngIf="step.action_steps && step.action_steps.length > 0" class="action-step-container">
                            <ng-container *ngFor="let aStep of step.action_steps">
                                <div class="action-step-wrapper" *ngIf="aStep.action">
                                    <img src="/assets/pictos/actions/{{aStep.action!.picto!}}.svg" alt={{aStep.action!.description!}}>
                                    <p>- {{aStep.action?.description}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </section>
        </div>
    </div>
</div>
