<div class="order-selector-container">
    <section class="order-header">
        <div class="order-nav-wrapper">
            <div class="nav-button" (click)="goToShoppingCart()">
                <img src="/assets/base_icons/icon_arrow_left_blue.svg">
                <p>{{translatorSvc.getLabel('backToShoppingCart')}}</p>
            </div>
            <div class="order-title">
                <h3 *ngIf="screenWidth > 660">{{translatorSvc.getLabel('payment')}}</h3>
                <h3 *ngIf="screenWidth <= 660">{{translatorSvc.getLabel('order')}}</h3>
            </div>
            <div class="empty-third-hdiv"></div>
        </div>
    </section>
    <div *ngIf="screenWidth <= 660 then phone else tablet"></div>
</div>

<ng-template #tablet>
    <div class="order-container">
        <div class="order-wrapper">
            <div class="shipping-address-container">
                <div class="shipping-address-title">
                    <h4 *ngIf="paymentSvc.shipping">{{translatorSvc.getLabel('shippingAddress')}}</h4>
                </div>
                <div *ngIf="paymentSvc.shipping" class="shipping-info-wrapper">
                    <div class="shipping-info">
                        <p>{{authSvc.User.accountSettings.firstName}} {{authSvc.User.accountSettings.lastName}}</p>
                        <p>{{paymentSvc.shipping.address.ship_address}}</p>
                        <p *ngIf="paymentSvc.shipping.address.address2">{{paymentSvc.shipping.address.address2}}</p>
                        <p>{{paymentSvc.shipping.address.locality}}, {{paymentSvc.shipping.address.state}}</p>
                        <p *ngIf="paymentSvc.shipping.address.country !== 'Canada'">{{paymentSvc.shipping.address.country}}</p>
                        <p>{{paymentSvc.shipping.address.postcode}}</p>
                    </div>
                    <button class='autonome-button-orange' [disabled]="!paymentSvc.chargeValid" (click)="goToShoppingCart()"><p>{{translatorSvc.getLabel('changeAddress')}}</p></button>
                </div>
            </div>
            <div class="items">
                <hr>
                <div class="items-container" *ngIf="productSvc.domainsToPurchase.length > 0">
                    <h4>{{translatorSvc.getLabel('subscriptions')}}</h4>
                    <div class="order-domains-wrapper" *ngFor="let domain of productSvc.domainsToPurchase">
                        <div class="order-item-row">
                            <div class="domain-item-name"><p>{{domain}}</p></div>
                            <div class="domain-item-price"><p>5.99$ (CAN) / {{translatorSvc.getLabel('yearly')}}</p></div>
                        </div>
                    </div>
                </div>
                <div class="items-container" *ngIf="productSvc.workflowsToPurchase.length > 0">
                    <div class="order-workflows-wrapper" *ngFor="let workflow of productSvc.workflowsToPurchase">
                        <div class="order-item-row">
                            <div class="workflow-item-name"><p>{{workflow}}</p></div>
                            <div class="workflow-item-price"><p>0.99$ (CAN) / {{translatorSvc.getLabel('yearly')}}</p></div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="productSvc.domainsToPurchase.length > 0">
                <div class="items-container" *ngIf="productSvc.itemsToPurchase.length > 0">
                    <h4>{{translatorSvc.getLabel('purchases')}}</h4>
                    <div class="order-items-wrapper" *ngFor="let item of productSvc.itemsToPurchase">
                        <div class="order-item-row">
                            <div class="workflow-item-name"><p>{{item.name}} ({{item.quantity}})</p></div>
                            <div class="workflow-item-price"><p>{{item.quantity*item.price}}$</p></div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="productSvc.itemsToPurchase.length > 0">
                <div class="item" *ngIf="productSvc.itemsToPurchase[0] !== undefined">
                    <p class="item-left">{{productSvc.itemsToPurchase[0].shipping_method}}</p>
                    <p *ngIf="productSvc.itemsToPurchase[0].shipping_fee > 0">{{productSvc.itemsToPurchase[0].shipping_fee}}$ (CAN)/ {{translatorSvc.getLabel('year')}}</p>
                    <p *ngIf="productSvc.itemsToPurchase[0].shipping_fee === 0">{{translatorSvc.getLabel('free')}}</p>
                </div>
            </div>
            <hr *ngIf="productSvc.itemsToPurchase.length > 0">
            <div>
                <div class="item">
                    <p class="item-left">{{translatorSvc.getLabel('subTotal')}}</p>
                    <p>{{subtotalString}}</p>
                </div>
                <div class="item" *ngFor="let taxItem of taxMap | keyvalue">
                    <p class="item-left">{{taxItem.key}}</p>
                    <p>{{taxItem.value}}</p>
                </div>
                <hr>
                <div class="item">
                    <p class="item-left">Total</p>
                    <p>{{totalAmountString}}</p>
                </div>
            </div>
        </div>
        <app-payment></app-payment>
    </div>
</ng-template>

<ng-template #phone>
    <div class="order-phone-container">
        <div class="order-phone-wrapper">
            <div class="shipping-address-container">
                <div class="shipping-address-title">
                    <h4 *ngIf="paymentSvc.shipping">{{translatorSvc.getLabel('shippingAddress')}}</h4>
                </div>
                <div *ngIf="paymentSvc.shipping" class="shipping-info-wrapper">
                    <div class="shipping-info">
                        <p>{{authSvc.User.accountSettings.firstName}} {{authSvc.User.accountSettings.lastName}}</p>
                        <p>{{paymentSvc.shipping.address.ship_address}}</p>
                        <p *ngIf="paymentSvc.shipping.address.address2">{{paymentSvc.shipping.address.address2}}</p>
                        <p>{{paymentSvc.shipping.address.locality}}, {{paymentSvc.shipping.address.state}}</p>
                        <p *ngIf="paymentSvc.shipping.address.country !== 'Canada'">{{paymentSvc.shipping.address.country}}</p>
                        <p>{{paymentSvc.shipping.address.postcode}}</p>
                    </div>
                    <button class='autonome-button-orange' [disabled]="!paymentSvc.chargeValid" (click)="goToShipping()"><p>{{translatorSvc.getLabel('changeAddress')}}</p></button>
                </div>
            </div>
            <div class="items">
                <div class="items-container" *ngIf="productSvc.domainsToPurchase.length > 0">
                    <h4>{{translatorSvc.getLabel('subscriptions')}}</h4>
                    <div class="order-domains-wrapper" *ngFor="let domain of productSvc.domainsToPurchase">
                        <div class="order-item-row">
                            <div class="item-name"><p>{{domain}}</p></div>
                            <div class="item-price"><p>5.99$ (CAN) / {{translatorSvc.getLabel('yearly')}}</p></div>
                        </div>
                    </div>
                </div>
                <div class="items-container" *ngIf="productSvc.workflowsToPurchase.length > 0">
                    <div class="order-workflows-wrapper" *ngFor="let workflow of productSvc.workflowsToPurchase">
                        <div class="order-item-row">
                            <div class="item-name"><p>{{workflow}}</p></div>
                            <div class="item-price"><p>0.99$ (CAN) / {{translatorSvc.getLabel('yearly')}}</p></div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="productSvc.domainsToPurchase.length > 0">
                <div class="items-container" *ngIf="productSvc.itemsToPurchase.length > 0">
                    <h4>{{translatorSvc.getLabel('purchases')}}</h4>
                    <div class="order-items-wrapper" *ngFor="let item of productSvc.itemsToPurchase">
                        <div class="order-item-row">
                            <div class="item-name"><p>{{item.name}}</p></div>
                            <div class="item-price"><p>{{item.price}}$</p></div>
                        </div>
                    </div>
                </div>
                <div class="item" *ngIf="productSvc.itemsToPurchase[0] !== undefined">
                    <div class="item-name"><p>{{productSvc.itemsToPurchase[0].shipping_method}} </p></div>
                    <div class="item-price" *ngIf="productSvc.itemsToPurchase[0].shipping_fee > 0">
                        <p>{{productSvc.itemsToPurchase[0].shipping_fee}}$ (CAN)/ {{translatorSvc.getLabel('year')}}</p></div>
                    <div class="item-price" *ngIf="productSvc.itemsToPurchase[0].shipping_fee === 0"><p>{{translatorSvc.getLabel('free')}}</p></div>
                </div>
            </div>
            <div class="phone-purchase-totals-container">
                <div class="item">
                    <p>{{translatorSvc.getLabel('subTotal')}}</p>
                    <p>{{subtotalString}}</p>
                </div>
                <div class="item" *ngFor="let taxItem of taxMap | keyvalue">
                    <p>{{taxItem.key}}</p>
                    <p>{{taxItem.value}}</p>
                </div>
                <hr>
                <div class="item">
                    <p>Total</p>
                    <p>{{totalAmountString}}</p>
                </div>
            </div>
        </div>
        
        <button class='autonome-button-orange' [disabled]="!paymentSvc.chargeValid" (click)="goToPayment()"><p>{{translatorSvc.getLabel('goToPayment')}}</p></button>
        
    </div>
</ng-template>

