import { AfterViewInit, Component, NgZone, OnInit, } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { first } from 'rxjs/operators';
import { AuthService } from '../../../admin/services/auth.service';
import { SpinnerOverlayService } from '../../services/spinner-overlay.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentService } from '../../../payment/services/payment.service';
import { TranslatorService } from '../../services/translator.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, AfterViewInit {

  autocomplete: google.maps.places.Autocomplete;
  shippingAddressForm : FormGroup;
  saveAddress: boolean = true;
  addressUpdated : boolean = false;
  haveAddress : boolean = false;
  stateDisabled : boolean = true;
  addressValid : boolean = false;

  constructor(
    private _ngZone: NgZone, 
    private fns: AngularFireFunctions,
    private fb: FormBuilder,
    private authSvc: AuthService,
    private paymentSvc: PaymentService,
    private spinnerSvc : SpinnerOverlayService,
    public translatorSvc : TranslatorService) {
      this.shippingAddressForm = this.fb.group({
        ship_address:[''],
        address2:[''],
        locality:[''],
        state:[''],
        postcode:[''],
        country:['']
      });
  }
  ngAfterViewInit(): void {
    this.initAutocomplete();
  }

  ngOnInit(): void {
    this.shippingAddressForm.get('state').disable();
    this.shippingAddressForm.get('country').disable();
    this.shippingAddressForm.get('postcode').disable();
    this.shippingAddressForm.get('locality').disable();
  }

  private initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById("ship_address") as HTMLInputElement, {
      componentRestrictions: { country: ["ca"] },
      fields: ["address_components", "geometry"],
      types: ["address"]
    });
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this._ngZone.run(()=> {
        const place = this.autocomplete.getPlace();
        this.haveAddress = true;
        let address1 = "";
        const postcode = "";
    
        for(const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
          const componentType = component.types[0];
    
          switch(componentType) {
            case "street_number" : {
              address1 = `${component.long_name} ${address1}`;
              this.shippingAddressForm.controls['ship_address'].setValue(address1);
              break;
            }
            case "route" : {
              address1 += component.short_name;
              this.shippingAddressForm.controls['ship_address'].setValue(address1);
              break;
            }
            case "postal_code" : {
              this.shippingAddressForm.controls['postcode'].setValue(`${component.long_name}${postcode}`);
              break;
            }
            case "locality":
              this.shippingAddressForm.controls['locality'].setValue(component.long_name);
              break;
            case "administrative_area_level_1": {
              this.shippingAddressForm.controls['state'].setValue(component.short_name);
              this.addressValid = true;
              break;
            }
            case "country": {
              this.shippingAddressForm.controls['country'].setValue(component.long_name);
              break;
            }
          }
        }
      })
    });
  }

  async onSubmit(): Promise<void> {
    if(this.addressUpdated) {
      this.shippingAddressForm.enable();
      this.shippingAddressForm.get('state').disable();
      this.shippingAddressForm.get('country').disable();
      this.shippingAddressForm.get('postcode').disable();
      this.shippingAddressForm.get('locality').disable();
      this.addressUpdated = false;
      this.paymentSvc.chargeValid = false;
    } else {
      
      this.shippingAddressForm.disable();
      this.addressUpdated = true;
      this.paymentSvc.shipping = {
        address : {
          ship_address : this.shippingAddressForm.get('ship_address').value,
          address2 : this.shippingAddressForm.get('address2').value,
          locality : this.shippingAddressForm.get('locality').value,
          state : this.shippingAddressForm.get('state').value,
          postcode : this.shippingAddressForm.get('postcode').value,
          country : this.shippingAddressForm.get('country').value
        },
        name : this.authSvc.User.accountSettings.firstName + ' ' + this.authSvc.User.accountSettings.lastName
      }
      this.paymentSvc.proceedToOrder();
    }
    
  }

  disableFormControls() {
    this.shippingAddressForm.controls['ship_address'].disable();
    this.shippingAddressForm.controls['address2'].disable();
    this.shippingAddressForm.controls['postcode'].disable();
    this.shippingAddressForm.controls['locality'].disable();
    this.shippingAddressForm.controls['state'].disable();
    this.shippingAddressForm.controls['country'].disable();
  }

  toggleSaveAddress() {
    this.saveAddress = !this.saveAddress;
  }

  private updateStripeCustomer(data: object) : Promise<object> {
    const wfCall = this.fns.httpsCallable('stripeUpdateCustomer');
    return firstValueFrom(wfCall({data: data}).pipe(first()));
  }

  stateValueChange(newStateValue :string) {
    this.addressValid = this.validateState(newStateValue);
  }

  validateState(state: string) {
    switch (state.toLowerCase()) {
      case 'quebec':
      case 'québec':
      case 'pq':
      case 'qc':
      case 'ontario':
      case 'on':
      case 'new brunswick':
      case 'nouveau brunswick':
      case 'nb':
      case 'nova scotia':
      case 'nouvelle ecosse':
      case 'ns':
      case 'pei':
      case 'prince edward island':
      case 'île du prince edouard':
      case 'pe':
      case 'new foundland and labrador':
      case 'new foundland':
      case 'terre neuve et labrador':
      case 'terre neuve':
      case 'nl':
      case 'manitoba':
      case 'mb':
      case 'saskatchewan':
      case 'sk':
      case 'alberta':
      case 'ab':
      case 'british columbia':
      case 'colombie britannique':
      case 'bc':
      case 'nunavut':
      case 'nu':
      case 'northwest territories':
      case 'territoires du nord ouest':
      case 'nt':
      case 'yukon':
      case 'yukon territory':
      case 'territoires du yukon':
      case 'yt':
        return true;
      default:
        return false;
    }
  }

}
