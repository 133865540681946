import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterExtService implements OnDestroy{

  routerSubscription: Subscription | undefined;
  previousUrls: string[] =[];
  currentUrl: string = '/domains';
  showNav: boolean = false;

  constructor(
    private router: Router
  ) {
    if(!this.routerSubscription) {
      this.routerSubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if(this.previousUrls.length === 0) {
            this.previousUrls.push(this.currentUrl);
            this.currentUrl = event.url;
          } else {
            if(event.url !== '/domains') {
              if(this.previousUrls[this.previousUrls.length -1] !== event.url) {
                //this.previousUrls.pop();
                this.previousUrls.push(event.url);
              }
              this.currentUrl = event.url;
            } else {
              this.currentUrl = event.url;
            }
          }

          this.validateUrl(this.currentUrl)
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  resetToHome() {
    if(this.previousUrls.length > 0) {
      this.previousUrls[this.previousUrls.length-1] = '/home';
    }
  }

  validateUrl(currentUrl: string) {
    switch(currentUrl) {
      //case '/recipes':
      case '/recipe-detail':
      case '/workflow':
      case '/products-domain':
      case '/organisations':
      case '/domain':
      case '/shopping-cart':
      case '/shipping':
      case '/order':
      case '/payment':
      case '/recipe-editor':
        this.showNav = true;
        break;
      default:
        this.showNav = false;
    }
  }

  goBack() {
    if(this.previousUrls.length > 0) {
      if(this.previousUrls[this.previousUrls.length -1] === this.currentUrl) {
        this.previousUrls.pop();
      }
      this.router.navigate([this.previousUrls[this.previousUrls.length -1]]);
    }
  }
}
