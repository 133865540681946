import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'autonome-web';
  
  constructor(
    //private gtmService : GoogleTagManagerService,
    private router: Router
    ) {
      /* this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.toString
          };
          //this.gtmService.pushTag(gtmTag);
        }
      }); */
  }

  ngOnInit() {
    if (window.location.href.indexOf('grocery') === -1 && window.location.href.indexOf('products') === -1) {
      this.router.navigate(['/home']);
    }
    /* if(window.innerHeight < 600) {
      screen.orientation['lock']('portrait');
    } else {
      screen.orientation['lock']('landscape');
    } */
  }
  
}
