import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { DisplayService } from 'src/app/tools/services/display.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})

export class PaymentComponent {
  constructor(
    public translatorSvc : TranslatorService,
    private router : Router,
    public displaySvc : DisplayService
  ) {}
  
  goToOrder() {
    this.router.navigate(['order']);
  }

}
