import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/admin/services/auth.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';

@Component({
  selector: 'app-organisation-select',
  templateUrl: './organisation-select.component.html',
  styleUrls: ['./organisation-select.component.scss']
})
export class OrganisationSelectComponent implements OnInit {

  // Select the organisation from list to edit in organisation component

  constructor(public dialogLoginRef: MatDialogRef<OrganisationSelectComponent>,
    public translatorSvc : TranslatorService,
    public authSvc : AuthService) { 
      
    }

  ngOnInit(): void {

  }

  closeSelectOrganisation() {
    this.dialogLoginRef.close(false);
  }

  chooseOrganisation($event) {
    this.dialogLoginRef.close(false);
  }

}
