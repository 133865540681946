

<div class="organisations-container" [class.display-none]="orgSvc.organisations === undefined">
    <div class="organisations-header">
        <div class="selected-organisation-title">
            <h3>{{orgSvc.currentOrganisation}}</h3>
        </div>
    </div>

    <h3>{{translatorSvc.getLabel('organisations')}}</h3>
    <div class="organisation-item-container" *ngFor="let org of orgSvc.organisations" (click)="selectCurrent(org)">{{org.title}}</div>
</div>
<div class="add-organisation">Add organisation</div>
<div class="selected-org-container">
    <app-organisation *ngFor="let org of orgSvc.organisations" [organisation]="org"></app-organisation>
</div>
