<div class="familiarization-container" *ngIf="domainSvc.selectedDomain !== undefined" [class.display-none]="spinnerSvc.active">
    <div *ngIf="screenWidth <= 600 then phone else tablet"></div>
</div>

<ng-template #phone>
    <div class="phone-domain-container">
        <div class="phone-domain-header">
            <h3>{{domainSvc.selectedDomain.title}}</h3>
        </div>
        <div class="phone-selected-domain-container">
            <div class="phone-selected-domain-picto">
                <img src="/assets/pictos/domains/{{domainSvc.selectedDomain.picto!}}.svg" alt={{domainSvc.selectedDomain.title!}}>
            </div>
            <div class="phone-selected-domain-description">
                <p>{{domainSvc.selectedDomain.description}}</p>
                <div *ngIf="domainSvc.selectedDomain.extra_information"
                    class="domain-extra-info" (click)="openExtraInfoModal()">
                    <p>{{domainSvc.selectedDomain.extra_information['link_text_'+authSvc.User.accountSettings.language]}}</p>
                </div>
            </div>
                        
            <div class="phone-selected-domain-buttons">
                <button class="autonome-button-orange" (click)="goToDomain()">
                    <p>{{translatorSvc.getLabel('viewJobExamples')}}</p>
                </button>
            </div>
        </div>
    </div>
    <div #phone_domain_container_domains class="phone-domain-container-domains" >
        <ng-container *ngFor="let domain of domainSvc.domains">
            <app-domain-item *ngIf="domainSvc.isResearchProject(domain)" [domain]="domain"></app-domain-item>
        </ng-container>
    </div>
</ng-template>

<ng-template #tablet>
    <div class="selected-domain-container">
        <div class="selected-domain-header">
            <h3>{{domainSvc.selectedDomain.title}}</h3>
        </div>
        <div class="selected-domain-info-container">
            <div class="selected-domain-container-center">
                <img src="/assets/pictos/domains/{{domainSvc.selectedDomain.picto!}}.svg" alt={{domainSvc.selectedDomain.title!}}>
                <button class="autonome-button-orange" (click)="goToDomain()"><p>{{translatorSvc.getLabel('viewJobExamples')}}</p></button>
            </div>
            <div class="selected-domain-container-right">
                <div class="domain-information-top">
                    <p>{{domainSvc.selectedDomain.description}}</p>
                    <p *ngIf="domainSvc.selectedDomain.extra_information"
                        class="domain-extra-info" (click)="openExtraInfoModal()">
                        {{domainSvc.selectedDomain.extra_information['link_text_'+authSvc.User.accountSettings.language]}}
                    </p>
                    <div class="sameLine">
                        <div class="p">{{translatorSvc.getLabel('examplesInclude')}}&nbsp;</div><div class="p lowercase">{{domainSvc.currentDomain.title}} :</div>
                    </div>
                    <div class="work-examples-container">
                        <ng-container #workflow_example_container *ngFor="let workflow of domainSvc.selectedDomain.workflows">
                            <p>- {{workflow.title}}</p>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="selected-domain-workflow-container">
        <div id="domain-swiper-container" class="domain-swiper-container">
            <div class="swiper-wrapper swiper-adjustment">
                <ng-container *ngFor="let domain of domainSvc.domains">
                    <app-domain-item *ngIf="domainSvc.isResearchProject(domain)" class="domain-swiper-slide"  [domain]="domain"></app-domain-item>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

