<div class="new-workflow-container">
    <div class="workflow-name-wrapper">
        <input [(ngModel)]="editorSvc.newWorkflowName">
    </div>
    <div class="button-container">
        <div class="modal-text-button" (click)="createNewWorkflow()">Create workflow</div>
        <div class="modal-text-button" (click)="closeDialog()">Cancel</div>
    </div>
    
    
</div>
