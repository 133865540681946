import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import getUserLocale from 'get-user-locale';
import { Subscription } from 'rxjs';
import { TranslatorService } from '../../../tools/services/translator.service';
import { PaymentService } from '../../services/payment.service';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';

@Component({
  selector: 'app-stripe-card-elements',
  templateUrl: './stripe-card-elements.component.html',
  styleUrls: ['./stripe-card-elements.component.scss']
})
export class StripeCardElementsComponent implements OnInit {

  chargeValidEventSubscription : Subscription;
  @ViewChild('cardElement') cardElement:ElementRef;
  card : stripe.elements.Element;
  cardErrors: string;
  _userLocale : string;
  saveSource : boolean = false;
  canSubmit : boolean = false;
  

  loading = false;
  confirmation;

  constructor(
    public paymentSvc : PaymentService,
    private spinnerSvc : SpinnerOverlayService,
    public translatorSvc : TranslatorService) { 
  
    this._userLocale = getUserLocale();
    if(this._userLocale.length>2) {
      this._userLocale = this._userLocale.slice(0,2);
    }
    
  }

  
  
  async ngOnInit() {

    const elements = this.paymentSvc.stripe.elements();

    const style = {
      hidePostalCode: true,
      style : {
        base: {
          fontSize: '16px',
          color: "#32325d",
        },
      },
    };

    // Create an instance of the card Element
    this.card = elements.create('card', style);

    // Add the instance to the DOM
    this.card.mount('#card-element');

    
    this.card.addEventListener('change', (event)=> {
      if(event.complete) {
        this.canSubmit = true;
      } else if(event.error) {
        this.cardErrors = event.error && event.error.message;
      }
    });

    const form = document.getElementById('payment-form');
    form.addEventListener('submit', async (event)=> {
      event.preventDefault();

      if(this.paymentSvc.invoice.totalAmount > 0) {
        this.spinnerSvc.show(this.translatorSvc.getLabel('processingPayment'));
        await this.paymentSvc.processPurchase(this.card);
        this.spinnerSvc.hide();
      }
      if(this.paymentSvc.invoice.domain_subscription_total > 0) {
        this.spinnerSvc.show(this.translatorSvc.getLabel('processingSubscription'));
        await this.paymentSvc.processSubscriptions(this.card);
        this.spinnerSvc.hide();
      }

    });
  }

  toggleSource() {
    this.saveSource = !this.saveSource;
  }

}
