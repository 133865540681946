import { Pizzas } from "../content/pizza";
import { Combatant } from "./combatant"

export class Battle {

    private _combatants: {[key: string]: Combatant} ;
    private _active_combatants: any;
    private _pizzas: any;
    
    constructor() {
        this._combatants = {
            
                "player1": new Combatant({
                ...Pizzas.s001,
                team: "player",
                hp:30,
                maxHp: 50,
                xp: 75,
                maxXp: 100,
                level: 1,
                status: null,
                isPlayerControlled: true
            }, this),
            
                "enemy1": new Combatant({
                ...Pizzas.v001,
                team: "enemy",
                hp:20,
                maxHp: 50,
                maxXp: 100,
                xp: 0,
                level: 1,
                status: null,
            }, this),
            
                "enemy2": new Combatant({
                ...Pizzas.f001,
                team: "enemy",
                hp:10,
                maxHp: 50,
                maxXp: 100,
                xp: 0,
                level: 1,
                status: null,
            }, this)}
            
        this._active_combatants = {
            player: "player1",
            enemy: "enemy1",
        }

        this._pizzas = Pizzas;
    }

    public get combatants(): {[key: string]: Combatant} {
        return this._combatants;
    }
    public set combatants(value: {[key: string]: Combatant}) {
        this._combatants = value;
    }

    public get activeCombatants(): any {
        return this._active_combatants;
    }
    public set activeCombatants(value: any) {
        this._active_combatants = value;
    }

    public get pizzas(): any {
        return this._pizzas;
    }
    public set pizzas(value: any) {
        this._pizzas = value;
    }
    


}