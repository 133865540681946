<div #register_modal class="organisation-edit-container">
    <div class="organisation-wrapper">
        <div class="form-wrapper">
            <form (ngSubmit)= "onSubmit()">
                <div class="input-field-wrapper">
                    <input
                    #orgNameInput
                    name="orgNameInput" 
                    type="text" 
                    class="modal-input-field"
                    placeholder="{{translatorSvc.getLabel('orgName')}}" 
                    [(ngModel)]="orgName" 
                    required 
                    autofocus>
                </div>
                <label class="displayNone" [class.display-error]="!orgNameValid">{{translatorSvc.getLabel('orgNameExists')}}</label>
                <div class="input-field-wrapper">
                    <button type="submit" class="modal-text-button" [disabled]="orgName.length==0">{{translatorSvc.getLabel('add')}}</button>
                    <button type="button" (click)="closeOrgEdit()" class="modal-text-button">{{translatorSvc.getLabel('cancel')}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
