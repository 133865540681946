import { Product } from './product.model';
import { Observable } from 'rxjs';
import { AddressI } from '../interfaces/address.interface';
import { UserI } from '../interfaces/user.interface';
import { AccountSettingsI } from '../interfaces/acct-settings.interface';
import { JobExampleI } from '../interfaces/job-example.interface';

export class User implements UserI {

    uid : string;
    accountSettings : AccountSettingsI = {
        firstName: '',
        lastName: '',
        competency: 0,
        language: '',
        new_features: false,
        emailSubscribed: false,
        eulaSigned: false,
        emailVerified: false,
        promoCode: '',
        video: false,
        timer: false,
        email: ''
    };
    address?: AddressI;
    defaultOrganisation?: string;
    organisations?: string[];
    defaultDomain?: string;
    domains?: string[];
    products?: string[];
    recipe_images?: string[];
    stripeCustomerId?: string;
    job_examples?: JobExampleI[];
    purchaseBeta?: boolean;
    purchaseDomains? : string[];
    items?: string[];

    private _initial : string;
    private _active : boolean;
    private _subscriptionPlan! : Product | null;
    private _planLevel : number;
    private _productId : string;
    private _shipping : AddressI;
    authenticated$: Observable<boolean> | undefined;
    private _data: object;
    private _metadata: object;
    
    // organization member
    private _role: string;
    private _status: string;

    // Multi connect properties
    private _connected2Peer : boolean;
    private _sequence : number;
    private _host : boolean;

    constructor (userData: object, metaData: object = null, userLocale: string = null) {
        const userObject = Object(userData);
        this.uid = userObject.uid;
        if(userObject.address) {
            Object.assign(this.address, userObject.address);
        }
        if (userObject.defaultOrganisation) {
            this.defaultOrganisation = userObject.defaultOrganisation;
        }
        if (userObject.organisations) {
            this.organisations = userObject.organisations;
        }
        if (userObject.defaultDomain) {
            this.defaultDomain = userObject.defaultDomain;
        }
        if (userObject.domains) {
            this.domains = userObject.domains;
        }
        if (userObject.products) {
            this.products = userObject.products;
        }
        if (userObject.recipe_images) {
            this.recipe_images = userObject.recipe_images;
        }
        if (userObject.stripeCustomerId) {
            this.stripeCustomerId = userObject.stripeCustomerId;
        }
        if (userObject.job_examples) {
            this.job_examples = JSON.parse(JSON.stringify(userObject.job_examples));
        }

        if (userLocale) {
            this.accountSettings.language = userLocale;
        }

        Object.assign(this.accountSettings, userObject.accountSettings);

        if (userObject.purchaseBeta) {
            this.purchaseBeta = userObject.purchaseBeta;
        }

        if (userObject.purchaseDomains) {
            this.purchaseDomains = userObject.purchaseDomains;
        }

        if (userObject.items) {
            this.items = userObject.items;
        }

        this._metadata = metaData;

        
        // Multi-user properties
        // this._connected2Peer = false;
        // this._sequence = 0;
        // this._host = true;
    }

    get initial() {
        return this._initial;
    }
    set initial(initial : string) {
        this._initial = initial;
    }

    get active() {
        return this._active;
    }
    set active(active : boolean) {
        this._active = active;
    }

    get shipping(): AddressI {
        return this._shipping;
    }
    set shipping(shipping : AddressI) {
        this._shipping = shipping;
    }

    get productId() {
        return this._productId;
    }
    set productId(id : string) {
        this._productId = id;
    }

    get planLevel() {
        return this._planLevel;
    }
    set planLevel(planLvl: number) {
        this._planLevel = planLvl;
    }

    get subscriptionPlan() {
        return this._subscriptionPlan;
    }
    set subscriptionPlan(subscriptionPlan : Product | null) {
        this._subscriptionPlan = subscriptionPlan;
    }

    get isConnected2Peer() {
        return this._connected2Peer;
    }
    set setConnected2Peer(connected2Peer : boolean) {
        this._connected2Peer = connected2Peer;
    }

    get sequence() {
        return this._sequence;
    }
    set sequence(sequence : number) {
        this._sequence = sequence;
    }

    get isHost() {
        return this._host;
    }
    set setHost(host : boolean) {
        this._host = host;
    }

    public get role(): string {
        return this._role;
    }
    public set role(value: string) {
        this._role = value;
    }

    public get status(): string {
        return this._status;
    }
    public set status(value: string) {
        this._status = value;
    }

    public get data(): object {
        return this._data;
    }
    public set data(value: object) {
        this._data = value;
    }

    public get metadata(): object {
        return this._metadata;
    }
    public set metadata(value: object) {
        this._metadata = value;
    }

    public hasItem(item_id: object) {
        if(this._data) {
            if (Object(this._data)["items"]) {
                return Object(this._data)["items"].includes((obj: object) => {
                    return obj === item_id;
                });
            }
        }
        return false;
    }

    public hasDomain(domain_id: object) {
        if(this._data) {
            if(Object(this._data)["domains"]) {
                return Object(this._data)["domains"].includes((obj) => {
                    return obj === domain_id;
                });
            }
        }
        return false;
    }
}