import { AcademyModule } from './../academy.module';
import { AcademyService } from "../services/academy.service";
import { GameObject } from "./game-object";
import { AutonomeWorldEvent } from './autonome-world-event';
import { Person } from './person';
import { AutonomeWorldComponent } from '../components/autonome-world/autonome-world.component';

export class AutonomeWorldMap {
    private _autonomeWorld: AutonomeWorldComponent = null;
    private _gameObjects: {};
    private _lowerImage: HTMLImageElement;
    private _upperImage: HTMLImageElement;
    private _walls: any;
    private _isCutscenePlaying: boolean;
    private _cutsceneSpaces;
    
    constructor(config, private academySvc : AcademyService) {
        this.gameObjects = config.gameObjects;
        this._cutsceneSpaces = config.cutsceneSpaces || {};
        this.walls = config.walls || {};
        
        this.lowerImage = new Image();
        this.lowerImage.src = config.lowerSrc;

        this.upperImage = new Image();
        this.upperImage.src = config.upperSrc;

        this._isCutscenePlaying = false;
    }

    drawLowerImage(ctx: CanvasRenderingContext2D, cameraPerson ) {
        ctx.drawImage(this.lowerImage, 
            this.academySvc.withGrid(10.5) - cameraPerson.x, 
            this.academySvc.withGrid(6) - cameraPerson.y);
    }

    drawUpperImage(ctx: CanvasRenderingContext2D, cameraPerson) {
        ctx.drawImage(this.upperImage, 
            this.academySvc.withGrid(10.5) - cameraPerson.x, 
            this.academySvc.withGrid(6) - cameraPerson.y);
    }

    isSpaceTaken(currentX, currentY, direction) {
        const coords: {x:number, y:number} = this.academySvc.nextPosition(currentX, currentY, direction);
        return this.walls[`${coords.x}, ${coords.y}`] || false;
    }

    mountObjects() {
        Object.keys(this.gameObjects).forEach(key => {
            let object : GameObject = this.gameObjects[key];
            object.id = key;

            // TODO : determine if object should be mounted
            object.mount(this);
        })
    }

    async startCutscene(events) {
        this._isCutscenePlaying = true;

        // start async loop of events
        for(let i=0; i<events.length; i++) {
            const eventHandler = new AutonomeWorldEvent({
                event: events[i],
                map: this,
            }, this.academySvc);
            await eventHandler.init();
        }



        this._isCutscenePlaying = false;

        // Reset NPC's to idle
        Object.values(this.gameObjects).forEach(object => {
            (object as GameObject).doBehaviourEvent(this);
        });
    }

    checkForActionCutscene() {
        const hero = this._gameObjects["hero"];
        const nextCoords = this.academySvc.nextPosition(hero.x, hero.y, hero.direction);
        const match = Object.values(this._gameObjects).find(object => {
            return `${(object as Person).x},${(object as Person).y}` === `${nextCoords.x},${nextCoords.y}`
        });
        if(!this._isCutscenePlaying && match && (match as GameObject).talking.length) {
            this.startCutscene((match as GameObject).talking[0].events)
        }
    }

    checkForFootstepCutscene() {
        const hero = this._gameObjects["hero"];
        const match = this.cutsceneSpaces[`${(hero as Person).x}, ${(hero as Person).y}`];
        if(!this._isCutscenePlaying && match) {
            this.startCutscene(match[0].events);
        }
    }

    addWall(x:number, y:number) {
        this.walls[`${x}, ${y}`] = true;
    }

    removeWall(x:number, y:number) {
        delete this.walls[`${x}, ${y}`];
    }

    moveWall(wasX:number, wasY:number, direction) {
        this.removeWall(wasX, wasY);
        const coords: {x:number, y:number} = this.academySvc.nextPosition(wasX, wasY, direction);
        this.addWall(coords.x, coords.y);
    }

    showText(text: string) {
        this.academySvc.textMessage = text;
    }


    public get walls(): any {
        return this._walls;
    }
    public set walls(value: any) {
        this._walls = value;
    }

    public get gameObjects(): any {
        return this._gameObjects;
    }
    public set gameObjects(value: any) {
        this._gameObjects = value;
    }

    public get isCutscenePlaying(): boolean {
        return this._isCutscenePlaying;
    }
    public set isCutscenePlaying(value: boolean) {
        this._isCutscenePlaying = value;
    }

    public get lowerImage(): any {
        return this._lowerImage;
    }
    public set lowerImage(value: any) {
        this._lowerImage = value;
    }

    public get upperImage(): any {
        return this._upperImage;
    }
    public set upperImage(value: any) {
        this._upperImage = value;
    }

    public get cutsceneSpaces() {
        return this._cutsceneSpaces;
    }
    public set cutsceneSpaces(value) {
        this._cutsceneSpaces = value;
    }

    public get autonomeWorld(): AutonomeWorldComponent {
        return this._autonomeWorld;
    }
    public set autonomeWorld(value: AutonomeWorldComponent) {
        this._autonomeWorld = value;
    }
    
}

