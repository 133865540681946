/**
 * 
 */
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { tap, map, switchMap, first } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { serverTimestamp } from '@angular/fire/firestore';
import firebase from "firebase/compat/app";

@Injectable({
  providedIn: 'root'
})
export class PresenceService {

  constructor(
    private afAuth: AngularFireAuth,
    private afDb : AngularFireDatabase
  ) {
    
    this.updateOnUser().subscribe();
    this.updateOnDisconnect().subscribe();
    this.updateOnAway();
  }

  getPresence(uid: string) {
    return this.afDb.object(`status/${uid}`).valueChanges();
  }

  getUser() {
    return firstValueFrom(this.afAuth.authState.pipe(first()));
  }

  async setPresence(status : string) {
    const user = await this.getUser();
    if (user) {
      this.afDb.object(`status/${user.uid}`).set({ status, timestamp: firebase.database.ServerValue.TIMESTAMP });
    }
  }

  get timestamp() {
    return serverTimestamp();
  }

  updateOnUser() {
    const connection = this.afDb.object('.info/connected').valueChanges().pipe(
      map(connected => connected ? 'online' : 'offline'));

    return this.afAuth.authState.pipe(
      switchMap(user => user ? connection : of('offline')),
      tap(status => this.setPresence(status))
    );
  }

  updateOnAway() {
    document.onvisibilitychange = () => {
      if (document.visibilityState === 'hidden') {
        this.setPresence('away');
      } else {
        this.setPresence('online');
      }
    };
  }

  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap(async user => {
        if(user) {
          firebase.database().ref(`/status/${user.uid}`).onDisconnect().set({
            status: 'offline',
            timestamp : firebase.database.ServerValue.TIMESTAMP
          });
        }
      })
    );
  }
}
