<div *ngIf="organisationSvc.currentOrganisation !== undefined" class="organisation-container" [class.display-none]="spinnerSvc.active">
    <!-- dev phone html -->
    <div *ngIf="displaySvc.screenWidth <= 600 then phone else tablet"></div>
</div>

<ng-template #tablet>
    <div class="organisation-container">
        <p>{{organisationSvc.currentOrganisation.title}}</p>
        <div class="organisation-wrapper">
            <div class="organisation-detail-container">
                <div class="admin-container">
                    <div class="admin-wrapper">
                        <input id="adminName" class="name" value="{{organisationSvc.currentOrganisation.admin}}">
                    </div>
                </div>
                <div class="contact-container">
                    <div class="contact-wrapper">
                        <input id="contactName" class="name" value="{{organisationSvc.currentOrganisation.contact_email}}">
                    </div>
                </div>
                <div>Domains</div>
                <table class="domains-container">
                    <tr>
                        <td>Domain</td>
                        <td>Edit</td>
                    </tr>
                    <tr *ngFor="let domain of organisationSvc.currentOrganisation.domains; let j = index">
                        <td>{{domain.title}}</td>
                        <td><img id="editDomain" class="table-row-edit-button" src="/assets/base_icons/icon_pencil_edit_charcoal.svg" (click)="edit_domain(j)" alt="Edit domain"></td>
                    </tr>
                </table>
                <div>Members</div>
                <table class="members-container">
                    <tr>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Role</td>
                        <td>Status</td>
                        <td>Edit</td>
                    </tr>
                    <tr *ngFor="let member of organisationSvc.currentOrganisation.members; let i = index">
                        <td>{{member.last_name + ', ' + member.first_name}}</td>
                        <td>{{member.email}}</td>
                        <td>{{member.role}}</td>
                        <td>{{member.status}}</td>
                        <td><img id="editMemeber" class="table-row-edit-button" src="/assets/base_icons/icon_pencil_edit_charcoal.svg" (click)="edit_member(i)" alt="Edit member"></td>
                    </tr>
                </table>    
            </div>
            <div class="modal-text-button" (click)="addMember()">+ Add Member</div>
        </div>
        <button>Save</button>
        <button>Cancel</button>
    </div>
</ng-template>



<!-- updated order of template logic to initialize swiper element (was taking phone template by default) -->


<ng-template #phone>
    <!-- <div class="phone-recipe-top-wrapper">
        <div class="phone-recipe-container">
            <div class="phone-recipe-label">
                <h3>{{recipeSvc.currentRecipe.title}}</h3>
            </div>
            <div class="phone-selected-recipe-container">
                <div class="phone-selected-recipes-container-upper">
                    <div class="phone-selected-recipes-container-upper-left">
                        <div class="phone-selected-container-picto">
                            <img class="recipe-img" src="/assets/pictos/recipes/{{recipeSvc.currentRecipe.picto!}}.svg" alt={{recipeSvc.currentRecipe.title!}}>  
                        </div>
                        <div class="phone-selected-recipes-container-buttons">
                                <div class="modal-text-button" (click)="gotoRecipeDetails()">{{translatorSvc.getLabel('seeIngredients')}}</div>
                                <div class="modal-text-button" (click)="launchRecipe()">{{translatorSvc.getLabel('startRecipe')}}</div>
                            
                        </div>
                    </div>
                    <div class="phone-selected-recipes-container-upper-right">
                        <div class="phone-selected-container-info">
                            <div class="info-wrapper">
                                <div class="info-icon">
                                    <img src="/assets/base_icons/icon_yield.svg">
                                </div>
                                <div class="info-text-wrapper">
                                    <div class="yield-text">
                                        <p class="info-text" *ngIf="recipeSvc.currentRecipe.minYield">{{recipeSvc.currentRecipe.minYield}}</p>
                                        <p class="info-text" *ngIf="recipeSvc.currentRecipe.minYield && recipeSvc.currentRecipe.maxYield">-</p>
                                        <p class="info-text" *ngIf="recipeSvc.currentRecipe.maxYield">{{recipeSvc.currentRecipe.maxYield}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="info-wrapper">
                                <div class="info-icon">
                                    <img src="/assets/base_icons/icon_prep_time.svg">
                                </div>
                                <div class="info-text-wrapper">
                                    <div class="yield-text">
                                        <p class="info-text" *ngIf="recipeSvc.currentRecipe.minPrepTime">{{recipeSvc.currentRecipe.minPrepTime}}</p>
                                        <p class="info-text" *ngIf="recipeSvc.currentRecipe.minPrepTime && recipeSvc.currentRecipe.maxPrepTime">-</p>
                                        <p class="info-text" *ngIf="recipeSvc.currentRecipe.maxPrepTime">{{recipeSvc.currentRecipe.maxPrepTime}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="info-wrapper">
                                <div class="info-icon">
                                    <img src="/assets/base_icons/icon_cook_time.svg">
                                </div>
                                <div class="info-text-wrapper">
                                    <div class="yield-text">
                                        <p class="info-text" *ngIf="recipeSvc.currentRecipe.minCookTime">{{recipeSvc.currentRecipe.minCookTime}}</p>
                                        <p class="info-text" *ngIf="recipeSvc.currentRecipe.minCookTime && recipeSvc.currentRecipe.maxCookTime">-</p>
                                        <p class="info-text" *ngIf="recipeSvc.currentRecipe.maxCookTime">{{recipeSvc.currentRecipe.maxCookTime}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="info-wrapper">
                                <div class="info-icon">
                                    <img src="{{recipeSvc.difficultyPicto}}">
                                </div>
                                <div class="info-text-wrapper">
                                    <p class="info-text">{{recipeSvc.difficultyText}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="phone-recipes-container-categories">
            <select [(ngModel)]="recipeSvc.currentCategory">
                <option *ngFor="let c of recipeSvc.recipeCategories" [ngValue]="c">
                    {{c.description}}
                </option>
            </select>
        </div>
    </div>
    <div class="phone-recipes-container-recipes">
        <app-recipe-item *ngFor="let r of recipeSvc.currentCategory.recipes" [recipe]="r"></app-recipe-item>
    </div> -->
</ng-template>

