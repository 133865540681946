import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './auth.guard';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { ModalsModule } from '../modal/modals.module';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { RegisterFirstComponent } from './components/register-first/register-first.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from './utils/global-error-handler';
import { HttpLoadingInterceptor } from './utils/http-loading.interceptor';
//import { GoogleTagManagerModule } from 'angular-google-tag-manager';

@NgModule({
  declarations: [
    WelcomeComponent,
    RegisterFirstComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    ModalsModule,
    FormsModule,
    /*GoogleTagManagerModule.forRoot({
      id: environment.GTM_ID,
    }),*/
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptor,
      multi: true,
    },
  ]
})
export class AdminModule { }
