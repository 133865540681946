<div class="overlay-background" [class.display-none]="!isChecked && !isDomainChecked"></div>
<div *ngIf="authSvc.User" class="navigation-container">
    <div class="navigation-header">
        <div class="logo-header">
            <img src="/assets/logo/autonome_yellow_logo.svg">
        </div>
        <div class="menu-header">
            <div *ngIf="displaySvc.screenWidth >= 600 && displaySvc.landscape then tablet else phone"></div>
        </div>
        <div class="auth-header-container right-justify">
            <div *ngIf="productSvc.domainsToPurchase.length + productSvc.workflowsToPurchase.length + productSvc.itemsToPurchase.length > 0" class="shopping-cart" (click)="goToShoppingCart()">
                <div class="number-circle">{{productSvc.domainsToPurchase.length + productSvc.itemsToPurchase.length}}</div>
                <img src="/assets/base_icons/shopping_cart_white.svg">
            </div>
            <div class="user-header">
                <p>{{authSvc.User?.accountSettings.firstName}}</p>
                <p>{{authSvc.User?.accountSettings.lastName}}</p>
            </div>
            <div class="burger-header" [class.z1000]="isChecked">
                <input [(ngModel)]="isChecked" type="checkbox"  class="openSidebarMenu" id="openSidebarMenu" [attr.readonly]="isDomainChecked ">
                <label for="openSidebarMenu" class="sidebarIconToggle">
                    <div class="spinner diagonal part-1"></div>
                    <div class="spinner horizontal"></div>
                    <div class="spinner diagonal part-2"></div>
                </label>
            </div>
            <div id="sidebarMenu" [class.display-none]="!isChecked" class="sidebarMenu">
                <div class="menu-content-wrapper">
                    <p class="charcoal-text pointer" (click)="openProfileModal()">{{translatorSvc.getLabel('settings')}}</p>
                    <hr>
                    <p class="charcoal-text pointer" (click)="goToProducts()">{{translatorSvc.getLabel('products')}}</p>
                    <hr>
                    <p class="charcoal-text pointer" (click)="goOrganisations()">{{translatorSvc.getLabel('organisations')}}</p>
                    <hr>
                    <p class="charcoal-text pointer" (click)="signOut()">{{translatorSvc.getLabel('signout')}}</p>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #tablet>
    <div *ngIf="(routerExtSvc.currentUrl === '/recipes' || routerExtSvc.currentUrl === '/familiarization')" class="menu-header-wrapper">
        <div class="menu-header-item" [class.menu-selected]="routerExtSvc.currentUrl === '/recipes'" (click)="goToRecipes()">
            <p>{{translatorSvc.getLabel('cooking')}}</p>
        </div>
        <div *ngIf="authSvc.User?.accountSettings.language === 'fr'"
            class="menu-header-item"
            [class.menu-selected]="routerExtSvc.currentUrl === '/familiarization'"
            [class.z-index-1500]="authSvc.isFamiliarizationModalOpen"
            (click)="goToFamiliarization()">
            <p>{{translatorSvc.getLabel('jobExamples')}}</p>
        </div>
    </div>
</ng-template>

<ng-template #phone>
    <div *ngIf="(routerExtSvc.currentUrl === '/recipes' || routerExtSvc.currentUrl === '/familiarization')" class="menu-header-wrapper">
        <input [(ngModel)]="isDomainChecked" type="checkbox" id="domainToggle" [attr.readonly]="isChecked">
        <label for="domainToggle" class="domainToggleLbl" [class.z-index-1500]="authSvc.isFamiliarizationModalOpen">
            <div class="menu-header-item menu-selected" [class.z1000]="isDomainChecked">
                <p *ngIf="routerExtSvc.currentUrl === '/recipes'" >{{translatorSvc.getLabel('cooking')}}</p>
                <p *ngIf="routerExtSvc.currentUrl !== '/recipes'" >{{translatorSvc.getLabel('jobExamples')}}</p>
                <img [class.domainToggle]="isDomainChecked" *ngIf="authSvc.User?.accountSettings.language === 'fr'" src="/assets/base_icons/icon_arrow_down.svg" />
            </div>
        </label>
        <div id="domainMenu" [class.display-none]="!isDomainChecked" class="domainMenu">
            <div class="menu-content-wrapper">
                <p class="charcoal-text pointer" (click)=goToRecipes()>{{translatorSvc.getLabel('cooking')}}</p>
                <!-- <hr>
                <a class="charcoal-text pointer" (click)="goOrganisations()">{{translatorSvc.getLabel('organisations')}}</a> -->
                <hr *ngIf="authSvc.User?.accountSettings.language === 'fr'">
                <p *ngIf="authSvc.User?.accountSettings.language === 'fr'" class="charcoal-text pointer" (click)="goToFamiliarization()">{{translatorSvc.getLabel('jobExamples')}}</p>
            </div>
        </div>
    </div>
</ng-template>