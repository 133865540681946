import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Step } from '../../../models/step.model';
import { WorkflowService } from '../../../core/services/workflow.service';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
  @Input() step!: Step;
  videoUrlWebM! : string | undefined;
  videoUrlMp4! : string | undefined;
  playBtnBackground! : string;
  video! : HTMLVideoElement;
  
  @ViewChild('videoPlayer', { static: true }) videoPlayer!: ElementRef;
  @ViewChild('video_modal', {static: false}) videoModal!: ElementRef;

  constructor(private wfSvc : WorkflowService) { 
    
  }

  ngOnInit(): void {

    if(this.step !== undefined) {
      if(this.step.action !== null && this.step.action !== undefined) {
        if(this.step.action.video !== null && this.step.action.video !== undefined){
          this.videoUrlWebM = '../../../assets/videos/' + this.step.action!.video + '.webm?raw=true';
          this.videoUrlMp4 = '../../../assets/videos/' + this.step.action!.video + '.mp4';
        }
      }
    } else {
      this.videoUrlWebM = '../../../assets/videos/autonome_kitchen_intro.webm?raw=true';
      this.videoUrlMp4 = '../../../assets/videos/autonome_kitchen_intro.mp4';
    }
  }

  open() {
    this.videoPlayer.nativeElement.currentTime = 0;
    this.videoPlayer.nativeElement.play();
    this.videoModal.nativeElement.style.display = 'flex';
  }

  openActionStepVideo(videoString : string) {
    if(this.step.current) {
      this.videoUrlWebM = '../../../assets/videos/' + videoString + '.webm?raw=true';
      this.videoUrlMp4 = '../../../assets/videos/' + videoString + '.mp4';
      this.videoPlayer.nativeElement.load();
      this.videoPlayer.nativeElement.currentTime = 0;
      this.videoPlayer.nativeElement.play();
      this.videoModal.nativeElement.style.display = 'flex';
    }
  }

  closeVideo() {
    this.videoPlayer.nativeElement.pause();
    this.videoModal.nativeElement.style.display = 'none';
    this.wfSvc.closeVideoEvent();
  }

  

  

}
