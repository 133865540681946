import { AnswerTable } from "../enums/answer-table.enum";
import { TableQuestionI } from "../interfaces/table-question.interface";

export class QuestionTable implements TableQuestionI {

    question_fr: string;
    question_en: string;
    answer?: AnswerTable;
    question?: string;
    private _headers? : QuestionTableHeaders [];

    constructor(data: object) {
        this.question_fr = Object(data).question_fr;
        this.question_en = Object(data).question_en;
        this.answer = Object(data).answer;
        this.question = Object(data).question;
    }

    public get headers() : QuestionTableHeaders[] {
        return this._headers;
    }

    public setLanguage(language: string) {
        switch (language) {
            case 'fr' :
                this.question = this.question_fr;
                break;
            default :
                this.question = this.question_en;
        }
    }

    public setHeaders(language: string) {
        this._headers = [];
        switch(language) {
            case 'fr' :
                this._headers.push(new QuestionTableHeaders("Ça ne me dérange pas du tout"));
                this._headers.push(new QuestionTableHeaders("Ça me dérange un peu"));
                this._headers.push(new QuestionTableHeaders("Ça me dérange beaucoup"));
                this._headers.push(new QuestionTableHeaders("Je ne sais pas"));
                break;
            default :
                this._headers.push(new QuestionTableHeaders("It does not bother me at all"));
                this._headers.push(new QuestionTableHeaders("It bothers me a little"));
                this._headers.push(new QuestionTableHeaders("It bothers me a lot"));
                this._headers.push(new QuestionTableHeaders("I don't know"));
        }
    }

}

class QuestionTableHeaders {
    private _selected: boolean = false;
    private _header: string;
    
    constructor(headerValue: string) {
        this._header = headerValue;
    }

    public get selected(): boolean {
        return this._selected;
    }
    public set selected(value: boolean) {
        this._selected = value;
    }

    public get header(): string {
        return this._header;
    }
    public set header(value: string) {
        this._header = value;
    }

}