import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutonomeService {

  constructor() { }

  private openProfileModalSubject = new Subject<number>();
  openProfileModalEvent() {
    this.openProfileModalSubject.next(1);
  }
  getOpenProfileModalEvent(): Observable<number>{ 
    return this.openProfileModalSubject.asObservable();
  }
}
