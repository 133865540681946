<div class="display-workflow-container">
    <div class="display-workflow-wrapper">
        <div id="workflow-tracker-display-swiper-container" class="swiper-container">
            <div class="swiper-wrapper">
                <app-step-item class="display-workflow-swiper-slide" *ngFor="let step of wf_display_steps" [step]="step"></app-step-item>
            </div>
        </div>
    </div>
</div>

