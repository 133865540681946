import { Component, Input } from '@angular/core';
import { Step } from 'src/app/models/step.model';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { WorkflowService } from '../../../core/services/workflow.service';
import { StepType } from 'src/app/enums/step-type.enum';
import { AuthService } from 'src/app/admin/services/auth.service';

@Component({
  selector: 'app-job-question',
  templateUrl: './job-question.component.html',
  styleUrls: ['./job-question.component.scss']
})
export class JobQuestionComponent {
  @Input() step: Step;

  wrongAnswer : boolean = false;
  choiceSubmitted : boolean = false;
  hideNextStep : boolean = true;
  notMulti : boolean = false;
  choiceIndex: number;
  
  playBtnBackground : string;
  alertPlayer = new Audio;
  
  constructor(
    private workflowSvc: WorkflowService,
    private authSvc : AuthService,
    public translatorSvc : TranslatorService) {
  }
  ngOnDestroy(): void {
    
    
  }
  ngOnInit(): void {
    switch(this.step.stepType) {
      case StepType.Table : 
        this.step.table.setHeaders(this.authSvc.User.accountSettings.language);
        this.step.table.setLanguage(this.authSvc.User.accountSettings.language);
        break;
      case StepType.Preference :
        this.step.preference.setLanguage(this.authSvc.User.accountSettings.language);
        break;
      case StepType.MultipleChoice :
        this.step.multiple_choice.setLanguage(this.authSvc.User.accountSettings.language);
        break;
    }
  }

  choiceChecked(j:number) {
    this.step.multiple_choice.choices[j].selected = true;
    this.step.multiple_choice.choice = this.step.multiple_choice.choices[j];
    this.choiceIndex = j;
  }
  

  submitChoice() {
    if(this.step.multiple_choice.choice.answer) {
      this.wrongAnswer = true;
    }
    this.hideNextStep = false;
    this.choiceSubmitted = true;
  }

  headerChecked(j:number) {
    this.step.table.answer = j;
    for(let i=0; i<this.step.table.headers.length; i++) {
      if(i === j) {
        this.step.table.headers[i].selected = true;
      } else {
        this.step.table.headers[i].selected = false;
      }
    }
    this.hideNextStep = false;
  }

  preferenceChecked(value: number) {
    this.step.preference.answer = value
    this.hideNextStep = false;
  }

  choosePreference() {

  }

  next_step() {
    this.workflowSvc.sendNextStepEvent(this.step._display_id, true);
  }

  overButton() {
    // handle mouse and touch events through eventListener
    this.workflowSvc.sendDisableSwiperEvent();
  }

  leaveButton() {
    this.workflowSvc.sendEnableSwiperEvent();
  }

  disableTouchMove() {
    this.workflowSvc.sendDisableTouchMoveEvent();
  }

  enableTouchMove() {
    this.workflowSvc.sendEnableTouchMoveEvent();
  }
}
