import { TranslatorService } from '../../../tools/services/translator.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RegisterComponent } from '../../../modal/components/register/register.component';
import { AuthService } from '../../services/auth.service';
import { MatDialog, MatDialogConfig, MatDialogState } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { VideoModalComponent } from '../../../modal/components/video-modal/video-modal.component';
import { EmailResetComponent } from '../../../modal/components/email-reset/email-reset.component';
//import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { RecipeService } from 'src/app/core/services/recipe.service';
import { AutonomeShowcaseComponent } from 'src/app/modal/components/autonome-showcase/autonome-showcase.component';
import { UserConfirmationComponent } from 'src/app/modal/components/user-confirmation/user-confirmation.component';
import { EmailSentConfirmComponent } from 'src/app/modal/components/email-sent-confirm/email-sent-confirm.component';
import { ToolService } from 'src/app/core/services/tool.service';
import { EulaComponent } from 'src/app/modal/components/eula/eula.component';
import { OrganisationService } from 'src/app/organisations/services/organisation.service';
import { SetPasswordComponent } from 'src/app/modal/components/set-password/set-password.component';
import { DomainService } from 'src/app/core/services/domain.service';
import { ProductService } from 'src/app/core/services/product.service';
import getUserLocale from 'get-user-locale';
import { AuthCodeComponent } from 'src/app/modal/components/auth-code/auth-code.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, AfterViewInit {

  iHeightText : string;
  productTitle : string;
  startedLogin : boolean = false;;
  emailVerified : boolean = false;
  timerValid : boolean = true;
  passwordResetEventsubscription:Subscription;
  emailNotVerifiedEvent: Subscription;
  eulaNotSignedEvent: Subscription;
  signOutEvent : Subscription;
  profileUpdateEvent : Subscription;
  productAssignedEvent : Subscription;
  recipesEventsubscription:Subscription;
  viewRecipes : boolean = true;
  sideMenuExpanded : boolean = false;
  emailValid: boolean = false;
  pwdValid: boolean = false;
  private _email: string;
  private _password: string;
  loginError: boolean;
  errorMsg: string;
  _userLocale: string ='en';
  _beta: boolean = false;
  retUrl:string="home";
  showPassword: boolean = false;

  @ViewChild('welcomeVideoModal', {static: false}) videoModal!: VideoModalComponent;

  constructor(
    //private gtmService: GoogleTagManagerService,
    public authSvc : AuthService,
    private router : Router,
    public matDialog: MatDialog,
    public recipeSvc : RecipeService,
    private orgSvc : OrganisationService, // trigger orgInit Subscription
    private domainSvc : DomainService,    // trigger domainInit Subscription
    private toolSvc: ToolService, // simply to initialize the tools for the user if he is logged on
    private productSvc: ProductService, // trigger productInit Subscription
    private activatedRoute:   ActivatedRoute,
    public translatorSvc : TranslatorService) {

      if(this.authSvc.User) {
        this.router.navigate(['domains']);
      } else {
        this._userLocale = getUserLocale();
        if(this._userLocale.length>2) {
            this._userLocale = this._userLocale.slice(0,2);
        }
      }

      if (this.eulaNotSignedEvent === undefined) {
        this.eulaNotSignedEvent = this.authSvc.getEulaNotSignedEvent()
        .subscribe(() => {
          this.openEulaModal();
        })
      }

      if(this.signOutEvent === undefined) {
        this.signOutEvent = this.authSvc.getSignOutEvent()
        .subscribe(() => {
          this.setIHeight(false);
        });
      }

      if(this.profileUpdateEvent === undefined) {
        this.profileUpdateEvent = this.authSvc.getProfileUpdateEvent()
        .subscribe(() => {
          // TODO : determine what changed
          this.refresh();
        });
      }

      if(this.passwordResetEventsubscription === undefined) {
        this.passwordResetEventsubscription = this.authSvc.getPasswordResetEvent()
        .subscribe(() => {
          // show modal 
          this.openPasswordSetModal();
        })
      }
  }
  ngOnInit(): void {
    this.activatedRoute.queryParamMap
                .subscribe(params => {
            this.retUrl = params.get('beta');
        });
  }

  refresh() : void {
    window.location.reload();
  }

  ngAfterViewInit(): void {
    this.setIHeight(this.authSvc.loggedIn);
  }
  
  setIHeight(loggedIn : boolean) {
    if(loggedIn) {
      this.iHeightText = (window.innerHeight - 116).toString() + 'px';
    } else {
      this.iHeightText = (window.innerHeight).toString() + 'px';
    }
  }
  
  onHeightResize(event) {
    this.iHeightText = (event.target.innerHeight - 88).toString() + 'px';
  } 

  async showRegisterModal(promo : boolean, beta: boolean) {
    this.startedLogin = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-register-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      beta: beta,
      promotionTextVisible: promo,
      registerText: 'registerFree'
    }
    this.matDialog.open(RegisterComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  play_video() {
    this.videoModal.open();
  }

  openEulaModal() {
    if(!this.matDialog.getDialogById('eula-modal')) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = "eula-modal";
      dialogConfig.height = "auto";
      dialogConfig.width = "auto";
      dialogConfig.panelClass = "custom-dialog-container"
      this.matDialog.open(EulaComponent, dialogConfig).afterClosed().subscribe(() => {
        
      });
    } else {
      if(this.matDialog.getDialogById('eula-modal').getState() !== MatDialogState.OPEN) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = "eula-modal";
        dialogConfig.height = "auto";
        dialogConfig.width = "auto";
        dialogConfig.panelClass = "custom-dialog-container"
        this.matDialog.open(EulaComponent, dialogConfig).afterClosed().subscribe(() => {
          
        });
      }
    }
  }

  openEmailNotVerifiedModal(email: string, password: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "inform-email-sent";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      email: email,
      pw: password,
      message: "emailNotVerified",
    };
    this.matDialog.open(AuthCodeComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  openPasswordSetModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-password-reset";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "modal-password-reset",
      
    };
    this.matDialog.open(SetPasswordComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  openConfModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "inform-email-sent";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "confirmEmail",
    };
    this.matDialog.open(EmailSentConfirmComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  openShowcaseModal() {
    this.startedLogin = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-showcase-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    this.matDialog.open(AutonomeShowcaseComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  async enterSettings() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-inital-settings-update";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "modal-inital-settings-update",
      actionButtonText1 : this.translatorSvc.getLabel('no'),
      actionButtonText2 : this.translatorSvc.getLabel('yes'),
      message : this.translatorSvc.getText('updateSettings'),
      hideButton2 : false
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  async loginErrorModal(msg: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-confirm-pw-reset-email";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "modal-confirm-pw-reset-email",
      actionButtonText1 : this.translatorSvc.getLabel('close'),
      message : this.translatorSvc.getFirebaseMessage(msg),
      hideButton2 : true
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  resetPassword() {
    this.showPwModal();
  }

  emailFocusOut() {
    this.emailValid = this.validateEmail();
  }

  pwFocusOut() {
    this.pwdValid = this.validatePw();
  }

  validateEmail() {
    // eslint-disable-next-line no-useless-escape
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return this.emailValid = regularExpression.test(String(this.email).toLowerCase());
   }

  validatePw() {
    return this.pwdValid = this.password.length >= 6;
  }

  public get email(): string {
    return this._email;
  }
  public set email(value: string) {
    this._email = value;
    this.validateEmail();
  }

  public get password(): string {
    return this._password;
  }
  public set password(value: string) {
    this._password = value;
    this.validatePw();
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  async onSubmit() {
    if(this.validateEmail() && this.validatePw()) {
      this.emailValid = true;
      this.pwdValid = true;
      await this.authSvc.emailPwLogin(this._email, this._password)
      .then(async res => {
        if (res) {
          this.authSvc.getFBUser(this._userLocale);
        } else {
          await this.authSvc.resendAuthCode(this._email);
          this.openEmailNotVerifiedModal(this._email, this._password);
        }
        
      }).catch(rej => {
        this.loginError = true;
        this.errorMsg = rej.code;
        switch(this.errorMsg) {
          case 'auth/user-not-found':
            this.emailValid = false;
            break;
          case 'auth/invalid-password' :
          case 'auth/wrong-password':
            this.pwdValid = false;
            break;
          default:
            this.emailValid = false;
            this.pwdValid = false;
        }
      });
    } else {
      this.emailValid = this.validateEmail();
      this.pwdValid = this.validatePw();
    }
  }

  onInputChange() {
    this.startedLogin = true;
    this.loginError = false;
  }

  async showPwModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-email-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    this.matDialog.open(EmailResetComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  

}
