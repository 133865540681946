import { StripeProducts } from './../../../enums/stripe-products.enum';
import { Component, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DomainService } from 'src/app/core/services/domain.service';
import { ProductService } from 'src/app/core/services/product.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { UserConfirmationComponent } from '../user-confirmation/user-confirmation.component';
import { DomainPromoComponent } from '../domain-promo/domain-promo.component';
import { AuthService } from 'src/app/admin/services/auth.service';

@Component({
  selector: 'app-add-domain',
  templateUrl: './add-domain.component.html',
  styleUrls: ['./add-domain.component.scss']
})
export class AddDomainComponent implements AfterViewInit {
  
  promoTitle : string;
  priceDescription : string;
  promoDescription : string;
  purchased: boolean = false;
  promoPurchased: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddDomainComponent>,
    public domainSvc: DomainService,
    private productSvc: ProductService,
    private authSvc : AuthService,
    public translatorSvc: TranslatorService,
    private matDialog: MatDialog
    ) {
      if(this.productSvc.domainsToPurchase.find((obj) => {
        return obj === this.domainSvc.currentDomain.id;
      })) {
        this.purchased = true;
      }
      if(this.authSvc.User.items.some((obj) => obj === StripeProducts[this.domainSvc.currentDomain.id])) {
        this.promoPurchased = true;
      }
  }

  ngAfterViewInit(): void {
    switch(this.authSvc.User.accountSettings.language) {
      case 'fr' :
        this.priceDescription = this.domainSvc.currentDomain.price_description_fr;
        if (this.domainSvc.currentDomain.promotions) {
          this.promoTitle = this.domainSvc.currentDomain.promotions.title_fr;
          this.promoDescription = this.domainSvc.currentDomain.promotions.description_fr;
        }
        break;
      default :
        this.priceDescription = this.domainSvc.currentDomain.price_description_en;
        if (this.domainSvc.currentDomain.promotions) {
          this.promoTitle = this.domainSvc.currentDomain.promotions.title_en;
          this.promoDescription = this.domainSvc.currentDomain.promotions.description_en;
        }
    }
  }

  addDomainToDomain() {
    this.purchased = true;
    this.productSvc.addDomainToCart(this.domainSvc.currentDomain.id);
    this.openDomainAddedUserConfModal();
    this.closeDialog();
  }

  openDomainAddedUserConfModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "full-screen-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "modal-add-domain-to-cart",
      actionButtonText1 : this.translatorSvc.getLabel('close'),
      message : this.translatorSvc.getLabel('domainAddedToCart') + this.domainSvc.currentDomain.title,
      hideButton2: true,
    };

    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }

  openDomainPromoModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "full-screen-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";

    this.matDialog.open(DomainPromoComponent, dialogConfig).afterClosed().subscribe(() => {
    });

  }

  closeDialog() {
    this.dialogRef.close();
  }
}
