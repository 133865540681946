export class Invoice {
    private _subTotal: number;
    private _totalAmount : number;
    private _taxes : Array<TaxItem>;
    private _domain_subscription_total: number;
    private _workflow_subscription_total: number;
    
  
    constructor() {
      this,  
      this._subTotal = 0;
      this._totalAmount = 0;
      this._taxes = [];
      this._domain_subscription_total = 0;
      this._workflow_subscription_total = 0;
    }

    get subTotal() {
        return this._subTotal;
    }
    set subTotal(subTot : number) {
        this._subTotal = subTot;
    }

    get totalAmount() {
        return this._totalAmount;
    }
    set totalAmount(totalAmt : number) {
        this._totalAmount = totalAmt;
    }

    get taxes() {
        return this._taxes;
    }
    set taxes(taxes : Array<TaxItem>) {
        this._taxes = taxes;
    }

    public get domain_subscription_total(): number {
        return this._domain_subscription_total;
    }
    public set domain_subscription_total(value: number) {
        this._domain_subscription_total = value;
    }

    public get workflow_subscription_total(): number {
        return this._workflow_subscription_total;
    }
    public set workflow_subscription_total(value: number) {
        this._workflow_subscription_total = value;
    }
}

  
export class TaxItem {
    private _taxName : string;
    private _taxAmount : number;
    constructor(key: string, value: number) {
      this._taxName = key;
      this._taxAmount = value;
    }

    get taxName() {
        return this._taxName;
    }

    get taxAmount() {
        return this._taxAmount;
    }
}