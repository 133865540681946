

export const menus = {
    getPages() {
        return {
          root: [
            {
              label: "Attack",
              description: "Choose an attack",
              handler: () => {
                this
              }
            },
            {
              label: "Items",
              description: "Choose an item",
              disabled: true,
              handler: () => {
                console.log("Got to items page");
              }
            },
            {
              label: "Swap",
              description: "Change to another pizza",
              handler: () => {
                // do something when  chosen
              }
            }
          ],
          attacks: [
            {
              label: "My first attack",
              description: "Does this...",
              handler: () => {
                //
              }
            }
            
          ]
        }
      } 
}