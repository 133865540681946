import { AfterContentInit, Component, Input } from '@angular/core';
import { StepWrapperComponent } from 'src/app/interfaces/step-wrapper.component';
import { Step } from 'src/app/models/step.model';
import { WorkflowService } from '../../services/workflow.service';
import { AuthService } from 'src/app/admin/services/auth.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements StepWrapperComponent, AfterContentInit {

  @Input() step: Step;
  pictoString : string[] = [];
  folderString = '/assets/pictos/items/';
  competency: number;


  constructor(
    public workflowSvc: WorkflowService,
    public authSvc : AuthService) { }
  
  next_step() {
    this.workflowSvc.sendNextStepEvent(this.step._display_id, true);
  }

  overButton() {
    // handle mouse and touch events through eventListener
    this.workflowSvc.sendDisableSwiperEvent();
  }

  leaveButton() {
    this.workflowSvc.sendEnableSwiperEvent();
  }

  disableTouchMove() {
    this.workflowSvc.sendDisableTouchMoveEvent();
  }

  enableTouchMove() {
    this.workflowSvc.sendEnableTouchMoveEvent();
  }

  ngAfterContentInit(): void {
    if(this.step.quantity > 0) {
      for (let i = 0; i < this.step.quantity; i++) {
        this.pictoString.push(this.folderString + this.step.item.picto + '.svg');
      }
    } else {
      this.pictoString.push(this.folderString + this.step.item.picto + '.svg');
    }
    

  }

}
