export class Canada_tax {
    _hst = new Map([
        ["PE", .15],
        ["NS", .15],
        ["NL", .15],
        ["NB", .15],
        ["ON", .13]
    ])
    _gst = .05;
    _pst = new Map([
        ["SK", .06],
        ["MB", .07],
        ["BC", .07],
        ["QC", .09975]
    ])

    constructor() {}

    getTaxes(province: string) {
        if(this._hst.has(province)) {
            return new Map([
                ["HST", this._hst.get(province)]
            ]);
        } else {
            let taxMap = new Map();
            taxMap.set("GST", this._gst);
            if(this._pst.has(province)) {
                taxMap.set("PST", this._pst.get(province));
            }
            return taxMap;
        }
    }
}