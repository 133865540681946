import { Component, Input, OnInit } from '@angular/core';
import { KeyboardMenuItem } from '../../models/keyboard-menu-item';

@Component({
  selector: 'app-submission-menu-subitems',
  templateUrl: './submission-menu-subitems.component.html',
  styleUrls: ['./submission-menu-subitems.component.scss']
})
export class SubmissionMenuSubitemsComponent implements OnInit {

  @Input() keyboardMenuSubitem : KeyboardMenuItem;
  constructor() { }

  ngOnInit(): void {
  }

}
