<div class="domain-extra-info-container">
    <p class="domain-extra-info-text">
        {{domainSvc.currentDomain.extra_information['text_'+authSvc.User.accountSettings.language]}}
    </p>
    <div class="logos-container">
        <div class="logo-img-item-wrapper" *ngFor="let logoName of domainSvc.currentDomain.extra_information.logos">
            <img  src="/assets/partner_logos/{{logoName}}.png">
        </div>
    </div>
    <button class="autonome-button-orange" (click)="closeDialog()">{{translatorSvc.getLabel('close')}}</button>
</div>
