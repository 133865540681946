import { Workflow } from 'src/app/models/workflow.model';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { Observable, Subject, first, firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/admin/services/auth.service';
import { DomainService } from 'src/app/core/services/domain.service';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { ActionI } from 'src/app/interfaces/action.interface';
import { IngredientI } from 'src/app/interfaces/ingredient.interface';
import { VerificationI } from 'src/app/interfaces/verification.interface';
import { StepI } from 'src/app/interfaces/step.interface';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  private _selectedWorkflow: Workflow;
  private _workflows: Workflow[] = [];
  private _actions: ActionI[];
  private _ingredients: IngredientI[];
  private _verifications: VerificationI[];
  private _addWorkflowItemSelectedItemType: string;
  private _addWorkflowItemSelectedItemName: string;
  private _addWorkflowItemSelectedItem: object;
  newWorkflowSelectedIndex: number = 1;
  ingredientActionSelectedItem : number = 0;
  actionActionSelectedItem : number = 0;
  selectedWfItemName: string;
  private _newWorkflowName: string;
  private _firstStepItem: object;

  constructor(
    public authSvc: AuthService,
    private fns : AngularFireFunctions,
    private spinnerSvc : SpinnerOverlayService,
    private domainSvc : DomainService,
    private router : Router
    ) {
      if(this.workflows.length === 0) {
        this.getUserWorkflows();
      }
  }

  async getUserWorkflows() {
    this.spinnerSvc.show('Loading user recipes...');
    const fb_workflows = await this.getCustomWorkflows();
    if (fb_workflows) {
      /* for (const workflow of fb_workflows) {
        this._workflows.push(new Workflow(workflow, 'cooking'));
      } */
      this.selectedWorkflow = this._workflows[0];
      
      // need to load all actions, ingredients, verifications
      this.loadWfElements();
      this.setSelectedWfItemName(this.selectedWorkflow.steps[1]);
      this.sendUserWorkflowLoadedEvent();
    } else {
      this.router.navigate(['/domains']);
    }
  }

  selectItem(workflowItem: object) {
    if (Object(workflowItem).index !== 0) {
      this.newWorkflowSelectedIndex = Object(workflowItem).index;
      
      this.setSelectedWfItemName(workflowItem);
    }
  }

  actionActionSelectItem(workflowItem: object) {
    this.actionActionSelectedItem = Object(workflowItem).index;
}

  ingredientActionSelectItem(workflowItem: object) {
      this.ingredientActionSelectedItem = Object(workflowItem).index;
  }

  setSelectedWfItemName(workflowItem: object) {
    if (Object(workflowItem).action) {
      this.selectedWfItemName = Object(workflowItem).action.action_name;
    }
    if (Object(workflowItem).ingredient) {
      this.selectedWfItemName = Object(workflowItem).ingredient.ingredient_name;
    }
    if (Object(workflowItem).verification) {
      this.selectedWfItemName = Object(workflowItem).verification.verification_name;
    }
  }

  appendItemDetails(workflowItem: object) {
    if(Object(workflowItem).action) {
      //const actionItem = this._actions.find((obj) => Object(obj).action.action_name === Object(workflowItem).action.action_name);
      /* if(!Object(workflowItem).action.descriptions) {
        Object(workflowItem).action.descriptions = Object.assign([], actionItem.action.descriptions);
      }
      if(!workflowItem.action.video) {
        workflowItem.action.video = actionItem.action.video;
      }
      
      if(!workflowItem.action.picto) {
        workflowItem.action.picto = actionItem.action.picto;
      }
    }
    if(workflowItem.ingredient) {

    }
    if(workflowItem.verification) {
      */
    } 
  }

  public get firstStepItem(): object {
    return this._firstStepItem;
  }
  public set firstStepItem(value: object) {
    this._firstStepItem = value;
  }

  public get newWorkflowName(): string {
    return this._newWorkflowName;
  }
  public set newWorkflowName(value: string) {
    this._newWorkflowName = value;
  }

  public get selectedWorkflow(): Workflow {
    return this._selectedWorkflow;
  }
  public set selectedWorkflow(value: Workflow) {
    this._selectedWorkflow = value;
    this.adjustIndex(1);
  }

  public get workflows(): Workflow[] {
    return this._workflows;
  }
  public set workflows(value: Workflow[]) {
    this._workflows = value;
  }

  public get actions(): ActionI[] {
    return this._actions;
  }
  public set actions(value: ActionI[]) {
    this._actions = value;
  }

  public get ingredients(): IngredientI[] {
    return this._ingredients;
  }
  public set ingredients(value: IngredientI[]) {
    this._ingredients = value;
  }

  public get verifications(): VerificationI[] {
    return this._verifications;
  }
  public set verifications(value: VerificationI[]) {
    this._verifications = value;
  }

  public get addWorkflowItemSelectedItemType(): string {
    return this._addWorkflowItemSelectedItemType;
  }
  public set addWorkflowItemSelectedItemType(value: string) {
    if(value !== this._addWorkflowItemSelectedItemType) {
      this._addWorkflowItemSelectedItemType = value;
      switch(this._addWorkflowItemSelectedItemType) {
        case 'actions' :
          //this.addWorkflowItemSelectedItemName = this._actions[0].action.action_name;
          this.addWorkflowItemSelectedItem = this._actions[0];
          break;
        case 'ingredients' :
          //this.addWorkflowItemSelectedItemName = this._ingredients[0].ingredient.ingredient_name;
          this.addWorkflowItemSelectedItem = this._ingredients[0];
          break;
        case 'verifications' :
          //this.addWorkflowItemSelectedItemName = this._verifications[0].verification.verification_name;
          this.addWorkflowItemSelectedItem = this._verifications[0];
          break;
      }
    }
  }

  public get addWorkflowItemSelectedItemName(): string {
    return this._addWorkflowItemSelectedItemName;
  }
  public set addWorkflowItemSelectedItemName(value: string) {
    this._addWorkflowItemSelectedItemName = value;
  }

  public get addWorkflowItemSelectedItem(): object {
    return this._addWorkflowItemSelectedItem;
  }
  public set addWorkflowItemSelectedItem(value: object) {
    this._addWorkflowItemSelectedItem = value;
  }

  async loadWfElements() {
    this.spinnerSvc.show('Loading actions...');
    //this._actions = await this.getAllActionsFn();
    this.spinnerSvc.text = 'Loading ingredients...';
    //this._ingredients = await this.getAllIngredientsFn();
    /* for (const item of this._ingredients) {
      fetch('/assets/pictos/ingredients/' + item.ingredient.ingredient_name + '.svg').then(res => {
        item.ingredient.picto = '/assets/pictos/ingredients/' + item.ingredient.ingredient_name + '.svg'
        if(res.status === 404) {
          item.ingredient.picto = '/assets/pictos/domains/icon_img_not_found.svg';
        }
      }).catch((error) => {
        
      });
    } */
    /* this.spinnerSvc.text = 'Loading verifications...';
    this._verifications = await this.getAllVerificationsFn();
    for (const item of this._verifications) {
      
    } */
    this.spinnerSvc.hide();
  }

  addWorkflowItemToWorkflow() {
    if(this.selectedWorkflow.steps[this.newWorkflowSelectedIndex].ingredient) {
      /* const action_steps = this.actions.filter((obj) => {
        return obj.action.parent_wf_item === this.selectedWorkflow.steps[this.newWorkflowSelectedIndex].ingredient.ingredient_name;
      });
      if (action_steps.length > 0) {
        this.selectedWorkflow.steps[this.newWorkflowSelectedIndex].action_steps = [];
        let i =0;
        for (const action of action_steps) {
          action.index = i;
          this.selectedWorkflow.steps[this.newWorkflowSelectedIndex].action_steps.push(action);
          i++;
        }
      } */
    }
  }

  removeWorkflowItem(index: number) {
    this.selectedWorkflow.steps.splice(index, 1);
    if(this.selectedWorkflow.steps.length > 0) {
      if(index>0) {
        this.adjustIndex(index-1);
      } else {
        this.adjustIndex(0);
      }
    }
  }

  adjustIndex(newIndex: number) {
    for (let i=0; i< this.selectedWorkflow.steps.length; i++) {
      //this.selectedWorkflow.steps[i].index = i;
    }
    if(newIndex === this.selectedWorkflow.steps.length-1) {
      this.newWorkflowSelectedIndex = newIndex -1;
    } else {
      this.newWorkflowSelectedIndex = newIndex;
    }
    this.setSelectedWfItemName(this.selectedWorkflow.steps[this.newWorkflowSelectedIndex]);
  }

  async saveWorkflow() {
    this.spinnerSvc.show("Saving workflow...");
    const newWorkflow = this.makeSaveObject(this._selectedWorkflow);
    await this.updateCustomWfFn(newWorkflow, this.selectedWorkflow.id);
    this.spinnerSvc.hide();
  }

  createNewWorkflow() {
    const newObject = new Workflow({ [this.newWorkflowName]: {
      data: {
        title_en: 'New',
        title_fr: 'New',
        workflow: {
          steps : [
              {
                action: {
                  action_name : 'wash_hands'
                }
              },
              {
                complete : {}
              }
            ]
          },
        description_en: 'English description',
        description_fr: 'French description',
        allergies: [],
        domains: ['cooking'],
        tools: [],
        min_cookTime: 0,
        max_cookTime: 0,
        min_prepTime: 0,
        max_prepTime: 0,
        min_yield: 0,
        max_yield: 0,
        min_level: 1,
        production: false,
        public: false,
        author: this.authSvc.User.accountSettings.firstName + this.authSvc.User.accountSettings.lastName,
      }
    }}, 'cooking');
    this.workflows.push(newObject);
    this.selectedWorkflow = this.workflows[this.workflows.length-1];
    this.newWorkflowSelectedIndex = 1;
    this.selectedWfItemName = '';
    this.sendUserWorkflowLoadedEvent();
  }

  makeSaveObject(workflow) {
    const newWorkflowObject = {
      title_en: workflow.data.title_en,
      title_fr: workflow.data.title_fr,
      workflow: {steps : this.pruneSteps(workflow)},
      description_en: workflow.data.description_en,
      description_fr: workflow.data.description_fr,
      allergies: workflow.allergies,
      domains: workflow.data.domains,
      tools: workflow.data.tools,
      min_cookTime: workflow.data.min_cookTime,
      max_cookTime: workflow.data.max_cookTime,
      min_prepTime: workflow.data.min_prepTime,
      max_prepTime: workflow.data.max_prepTime,
      min_yield: workflow.data.min_yield,
      max_yield: workflow.data.max_yield,
      min_level: workflow.data.min_level,
      production: workflow.data.production,
      public: false,
      author: workflow.data.author,

    }
    return newWorkflowObject;
  }

  pruneSteps(workflow: Workflow) {
    const newSteps : StepI[] = [];
    for (const step of workflow.steps) {
      if (step.action) {
        /* const newObject : object= {action:{action_name:step.action.action_name}};
        if (step.action.default_picto) {
          newObject.svgString = step.action.default_picto;
        }
        if (step.svg_string) {
          newObject.svg_string = step.svg_string;
        }
        newSteps.push(newObject); */
      }
      if (step.ingredient) {
        /* const newObject = {ingredient:{ingredient_name:step.ingredient.ingredient_name}};
        if (step.ingredient.default_picto) {
          step.svg_string = step.ingredient.default_picto;
        }
        if (step.svg_string) {
          newObject.svg_string = step.svg_string;
        }
        if(step.quantity) {
          newObject.quantity = step.quantity;
        }
        if(step.measure) {
          newObject.measure = step.measure;
        }
        if(step.unit) {
          newObject.unit = step.unit;
        }
        newSteps.push(newObject); */
      }
      if (step.complete) {
        newSteps.push({complete:{}});
      }
    } 
    return newSteps;
  }

  private userWorkflowsLoadedSubject = new Subject<boolean>();
  sendUserWorkflowLoadedEvent() {
    this.userWorkflowsLoadedSubject.next(true);
  }
  getWorkflowLoadedEvent(): Observable<boolean>{
    return this.userWorkflowsLoadedSubject.asObservable();
  }

  private async getCustomWorkflows() : Promise<object> {
    const wfCall = this.fns.httpsCallable('httpsGetCustomWorkflows');
    return await firstValueFrom(wfCall({domain: this.domainSvc.currentDomain.id}))
  }

  private async updateCustomWfFn(workflow: object, workflowName: string) : Promise<object> {
    const wfCall =  this.fns.httpsCallable('httpsUpdateCustomWF');
    return await firstValueFrom(wfCall({workflow: workflow, workflowName: workflowName}).pipe(first()));
  }

  private async getAllActionsFn() : Promise<object> {
    const wfCall = this.fns.httpsCallable('httpsGetAllActions');
    return await firstValueFrom(wfCall({}).pipe(first()));
  }
  private async getAllIngredientsFn() : Promise<object> {
    const wfCall = this.fns.httpsCallable('httpsGetAllIngredients');
    return await firstValueFrom(wfCall({}).pipe(first()));
  }
  private async getAllVerificationsFn() : Promise<object> {
    const wfCall = this.fns.httpsCallable('httpsGetAllVerifications');
    return await firstValueFrom(wfCall({}).pipe(first()));
  }

}
