/**
 * Modal component that allows the following actions to a member:
 * Modify role, delete member from organisation
 * 
 */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { OrgRole } from 'src/app/enums/org-roles.enum';
import { OrganisationService } from 'src/app/organisations/services/organisation.service';
import { UserConfirmationComponent } from '../user-confirmation/user-confirmation.component';
import { MemberI } from 'src/app/interfaces/member.interface';

@Component({
  selector: 'app-edit-organisation-member',
  templateUrl: './edit-organisation-member.component.html',
  styleUrls: ['./edit-organisation-member.component.scss']
})
export class EditOrganisationMemberComponent {

  public roleTypes;
  public index: number;
  private initialRole: string;
  public member: MemberI;

  constructor(
    @Inject(MAT_DIALOG_DATA) modalData: object,
    public dialogRef: MatDialogRef<EditOrganisationMemberComponent>,
    public organisationSvc : OrganisationService,
    public translatorSvc : TranslatorService,
    private matDialog : MatDialog,
  ) {
    this.index = Object(modalData).memberIndex;
    this.member = this.organisationSvc.currentOrganisation.members[this.index];
    this.roleTypes = Object.values(OrgRole).filter(value => typeof value === 'string');
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveNewRoleAssignment() {
    this.closeDialog();
    this.confirmMemberAction('memberRoleChange', 'memberRoleChange', this.index);
  }

  removeMember() {
    this.closeDialog();
    this.confirmMemberAction('removeMemberFromOrganisation', 'memberRemove', this.index);
  }
  
  async confirmMemberAction(name: string, text: string, index: number ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-confirm-pw-reset-email";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: name,
      actionButtonText1 : this.translatorSvc.getLabel('cancel'),
      actionButtonText2 : this.translatorSvc.getLabel('yes'),
      message : this.translatorSvc.getLabel(text),
      index: index
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }
}
