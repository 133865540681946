<div class="battle_hero">
    <img src="{{hero_img}}" alt="Hero"/>
</div>
<div class="battle_enemy">
    <img src="{{enemy_img}}" alt="Enemy"/>
</div>
<app-combatant *ngFor="let combatant of academySvc.battle.combatants | keyvalue" 
class="combatant" 
[battle]="battle" 
[combatant]="combatant.value" 
[ngClass]="combatant.value.team ==='player' ? 'playerCombatant':'enemyCombatant'"
[class.active]="combatant.value.active"></app-combatant>
<app-pizza *ngFor="let combatant of academySvc.battle.combatants | keyvalue "
class="pizza"
[combatant] = "combatant.value"
[class.pizza-player]="combatant.value.team ==='player'"
[class.pizza-enemy]="combatant.value.team ==='enemy'"
[class.active]="combatant.value.active"
[class.battle-damage-blink]="combatant.value.blink"
[ngClass]="combatant.value.team === academySvc.caster.team ? (combatant.value.spin ? (combatant.value.team === 'player' ? 'battle-spin-right':'battle-spin-left'):''):''"></app-pizza>
<app-glob *ngFor="let combatant of academySvc.battle.combatants | keyvalue "
class="glob-orb"
[combatant] = "combatant.value"
[ngClass]="combatant.value.team === academySvc.caster.team ? (combatant.value.glob ? (combatant.value.team === 'player' ? 'battle-glob-right':'battle-glob-left'):''):''"></app-glob>

<app-submission-menu
class="keyboard-menu battle-menu"
[class.display-none]="!academySvc.submissionMenuShow"></app-submission-menu>



