
export class ShowcaseItem {
    _imgStr : string;
    _text: string;

    constructor(img_str: string, txt: string) {
        this._imgStr = img_str;
        this._text = txt;
    }

    get imgStr() {
        return this._imgStr;
    }
    set imgStr(img_str : string) {
        this._imgStr = img_str;
    }

    get text() {
        return this._text;
    }
    set text(txt: string) {
        this._text = txt;
    }
}

