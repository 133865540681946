import { UserConfirmationComponent } from '../../../modal/components/user-confirmation/user-confirmation.component';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from '../../../admin/services/auth.service';
import { TranslatorService } from '../../../tools/services/translator.service';
import { ProfileComponent } from '../../../modal/components/profile/profile.component';
import { OrganisationSelectComponent } from 'src/app/modal/components/organisation-select/organisation-select.component';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { ProductService } from '../../services/product.service';
import { RouterExtService } from 'src/app/tools/services/router-ext.service';
import { TimerService } from 'src/app/modal/services/timer.service';
import { WorkflowService } from '../../services/workflow.service';
import { DomainService } from '../../services/domain.service';
import { DisplayService } from 'src/app/tools/services/display.service';


@Component({
  selector: 'app-autonome',
  templateUrl: './autonome.component.html',
  styleUrls: ['./autonome.component.scss']
})
export class AutonomeComponent implements OnInit, AfterViewInit {

  loggedIn : boolean;
  home : boolean = true;
  recipes : boolean = false;
  recipeDetails : boolean = false;
  workflow : boolean = false;
  products : boolean = false;
  products_domain : boolean = false;
  organisation : boolean = false;
  organisations : boolean = false;
  domains : boolean = false;
  domain : boolean = false;
  loginEventsubscription:Subscription;
  signoutEventSubscription : Subscription;
  isFullScreen : boolean = false;
 
  firstName: string;
  lastName: string;
  isChecked: boolean = false;
  isDomainChecked: boolean = false;

  constructor(
    public authSvc: AuthService,
    public productSvc: ProductService,
    public workflowSvc: WorkflowService,
    private router: Router,
    public matDialog: MatDialog,
    public translatorSvc: TranslatorService,
    public spinnerOverlaySvc : SpinnerOverlayService,
    private timerSvc : TimerService,
    public routerExtSvc: RouterExtService,
    private domainSvc: DomainService,
    public displaySvc: DisplayService) {


      this.loginEventsubscription = authSvc.getLoginEvent().subscribe(()=> {
        this.loggedIn = authSvc.loggedIn;
      });

      this.signoutEventSubscription = authSvc.getSignOutEvent().subscribe(() => {
        this.loggedIn = authSvc.loggedIn;
      });
  }

  ngOnInit(): void {
    this.loggedIn = this.authSvc.loggedIn;
  }

  ngAfterViewInit(): void {
    this.displaySvc.init();
  }

  goBack() {
    // need to warn of exiting workflow if in active workflow
    /* if (this.routerExtSvc.currentUrl === '/workflow' && this.timerSvc.timerRunning && !this.timerSvc.timerPaused) {
      this.timerSvc.notifications[this.timerSvc.mainTimerId].paused.next(true);
      this.timerSvc.timerPaused = true;
      this.timerSvc.timerButton = "/assets/base_icons/time_play.svg";
    } */
    this.routerExtSvc.goBack();
  }

  goOrganisations() {
    this.isChecked = false;
    this.router.navigate(['organisations']);
  }

  goToShoppingCart() {
    this.router.navigate(['shopping-cart']);
  }

  goToFamiliarization() {
    this.domainSvc.currentDomain = this.domainSvc.domains.find((obj)=> { return obj.id === 'job_examples' });
    this.isDomainChecked = false;
    this.router.navigate(['familiarization']);
  }

  goToRecipes() {
    this.domainSvc.currentDomain = this.domainSvc.domains.find((obj)=> { return obj.id === 'cooking' });
    this.domainSvc.selectedDomain = this.domainSvc.currentDomain;
    this.isDomainChecked = false;
    this.router.navigate(['recipes']);
  }

  goToProducts() {
    this.isChecked = false;
    this.router.navigate(['products-domain']);
  }

  goToDomains() {
    this.router.navigate(['domains']);
  }

  openProfileModal() {
    this.isChecked = false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.maxWidth = "90vw";
    this.matDialog.open(ProfileComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  async openOrganisation() {
    this.isChecked = false;
    if(this.authSvc.User.organisations !== undefined && this.authSvc.User.organisations !== null) {
      this.openSelectOrganisationModal();
    } else {
      this.openAddOrganisationModal();
    }
  }

  openConfModal() {
     const messageData = {
      name: "goToRecipeDetails",
      message: this.translatorSvc.getLabel('confirmGoToRecipeDetail'),
      actionButtonText1 : this.translatorSvc.getLabel('yes'),
      actionButtonText2 : this.translatorSvc.getLabel('no')
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = messageData;
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result) {
        //this.router.navigate(['/recipe-detail']);
      }
    });
  }

  async openAddOrganisationModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-add-organisation";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "modal-add-organisation",
      actionButtonText1 : this.translatorSvc.getLabel('close'),
      message : this.translatorSvc.getText('addOrganisation'),
      actionButtonText2 : this.translatorSvc.getLabel('yes'),
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  openSelectOrganisationModal() {
    this.isChecked = false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.maxWidth = "90vw";
    this.matDialog.open(OrganisationSelectComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }
  
  async signOut() {
    this.isChecked = false;
    await this.authSvc.signOut();
    this.displaySvc.closeFullscreen();
  }
}
