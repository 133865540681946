import { Component, HostListener, Input } from '@angular/core';
import { WorkflowCategory } from 'src/app/models/workflow-category.model';
import { DomainService } from '../../services/domain.service';
import { WorkflowService } from '../../services/workflow.service';
import { AuthService } from 'src/app/admin/services/auth.service';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.scss']
})
export class CategoryItemComponent {

  @Input() category: WorkflowCategory;
  hasFreeCategory: boolean = false

  constructor(
    private domainSvc : DomainService,
    private workflowSvc : WorkflowService,
    public authSvc: AuthService
  ) {
    
    this.hasFreeCategory = this.domainSvc.currentDomain.categories.some((obj) => obj.category_name === 'free');
  }

  @HostListener("click") onClick(){
    if(this.domainSvc.currentCategory.category_name !== this.category.category_name) {
        this.domainSvc.currentCategory = this.category;
        this.workflowSvc.selectedWorkflow = this.category.workflows[0];
      }
  }

}
