<div *ngIf="workflowSvc.selectedWorkflow !== undefined" class="recipes-container" [class.display-none]="spinnerSvc.active">
    <!-- job examples overlay -->
    <!-- <div class="overlay-container">
        <div class="modal-container">
            <div class="modal-wrappaer">
                <div class="modal-header">
                    
                </div>
            </div>
        </div>
    </div> -->
    <div *ngIf="displaySvc.screenWidth <= 600 then phone else tablet"></div>
</div>


<!-- updated order of template logic to initialize swiper element (was taking phone template by default) -->

<!-- <div class="recipes-selected-container">
    <div class="recipes-selected-left">
        <div class="recipes-selected-info">
            <div class="info-container"></div>
            <div class="info-container"></div>
            <div class="info-container"></div>
            <div class="info-container"></div>
        </div>
        <div class="recipes-selected-button-container">
            <div class="orange-button"></div>
        </div>
    </div>
    <div class="recipes-selected-middle"></div>
    <div class="recipes-selected-right"></div>
</div>
<div class="recipes-categories-container"></div>
<div class="recipes-swiper-container"></div> -->

<ng-template #tablet>
    <div class="recipe-header">
        <div class="selected-recipe-title">
            <h3>{{workflowSvc.selectedWorkflow.title}}</h3>
        </div>
    </div>
    <div class="selected-recipes-container">
        <div class="starred-recipe-container">
            <div class="starred-recipes-container-left">
                <div class="starred-recipes-container-info">
                    <div class="info-wrapper">
                        <div class="info-icon">
                            <img src="/assets/base_icons/icon_yield.svg">
                        </div>
                        <div class="info-text-wrapper">
                            <div class="info-label"><p>{{translatorSvc.getLabel('yield')}}&nbsp;:</p></div>
                            <div>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_yield">{{asRecipe(workflowSvc.selectedWorkflow).min_yield}}&nbsp;</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_yield && asRecipe(workflowSvc.selectedWorkflow).max_yield">-&nbsp;</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_yield">{{asRecipe(workflowSvc.selectedWorkflow).max_yield}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-wrapper">
                        <div class="info-icon">
                            <img src="/assets/base_icons/icon_prep_time.svg">
                        </div>
                        <div class="info-text-wrapper">
                            <div class="info-label"><p>{{translatorSvc.getLabel('prepTime')}}&nbsp;:</p></div>
                            <div>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_prepTime">{{asRecipe(workflowSvc.selectedWorkflow).min_prepTime}}&nbsp;</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_prepTime && asRecipe(workflowSvc.selectedWorkflow).max_prepTime">-&nbsp;</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_prepTime">{{asRecipe(workflowSvc.selectedWorkflow).max_prepTime}}</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_prepTime || asRecipe(workflowSvc.selectedWorkflow).min_prepTime">&nbsp;mins</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-wrapper">
                        <div class="info-icon">
                            <img src="/assets/base_icons/icon_cook_time.svg">
                        </div>
                        <div class="info-text-wrapper">
                            <div class="info-label"><p>{{translatorSvc.getLabel('cookTime')}}&nbsp;:</p></div>
                            <div>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_cookTime">{{asRecipe(workflowSvc.selectedWorkflow).min_cookTime}}&nbsp;</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_cookTime && asRecipe(workflowSvc.selectedWorkflow).max_cookTime">-&nbsp;</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_cookTime">{{asRecipe(workflowSvc.selectedWorkflow).max_cookTime}}</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_cookTime || asRecipe(workflowSvc.selectedWorkflow).min_cookTime">&nbsp;mins</p>
                            </div>
                        </div>
                    </div>
                    <div id="difficultyInfoWrapper" class="info-wrapper">
                        <div class="info-icon">
                            <img src="{{workflowSvc.difficultyPicto}}">
                        </div>
                        <div class="info-text-wrapper">
                            <div class="info-label"><p>{{translatorSvc.getLabel('difficulty')}}&nbsp;:</p></div>
                            <div>
                                <p class="info-text">{{workflowSvc.difficultyText}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="autonome-button-orange" (click)="gotoRecipeDetails()">
                    <p>{{translatorSvc.getLabel('seeIngredients')}}</p>
                </div>
                <!-- <div class="autonome-button-orange" (click)="goEditor()">
                    <p>Editor</p>
                </div> -->
            </div>
            <div class="starred-recipes-container-middle">
                <!-- <div class="starred-recipe-title">
                    <h3>{{workflowSvc.selectedWorkflow.title}}</h3>
                </div> -->
                <div class="starred-recipes-container-picto">
                    <img [class.recipe-jpg]="workflowSvc.selectedWorkflow.recipe_jpg" src="{{domainSvc.currentDomain.id === 'job_examples_manufacturing' ? '/assets/pictos/workflows/' + workflowSvc.selectedWorkflow.id + '.jpg' : (workflowSvc.selectedWorkflow.recipe_jpg ? workflowSvc.selectedWorkflow.recipe_jpg : '/assets/pictos/workflows/' + workflowSvc.selectedWorkflow.id + '.svg')}}"  alt={{workflowSvc.selectedWorkflow.title!}}>  
                </div>
            </div>
            <div class="starred-recipes-container-right">
                <div class="starred-recipes-container-description">
                    <p>{{workflowSvc.selectedWorkflow.description}}</p>
                </div>
                <div class="starred-recipes-container-buttons">
                    <div class="autonome-button-orange" *ngIf="workflowSvc.selectedWorkflow === workflowSvc.currentWorkflow && workflowSvc.currentStepId > 0" (click)="launchWorkflow()">
                        <p>{{translatorSvc.getLabel('continueRecipe')}}</p><img src="/assets/base_icons/icon_arrow_right.svg">
                    </div>
                    <div class="autonome-button-orange" *ngIf="workflowSvc.selectedWorkflow !== workflowSvc.currentWorkflow || workflowSvc.currentStepId === 0" (click)="launchWorkflow()">
                        <p>{{translatorSvc.getLabel('startRecipe')}}</p><img src="/assets/base_icons/icon_arrow_right.svg">
                    </div>
                    <div class="autonome-button-orange" *ngIf="workflowSvc.selectedWorkflow === workflowSvc.currentWorkflow && workflowSvc.currentStepId > 0" (click)="restartWorkflow()">
                        <p>{{translatorSvc.getLabel('restartRecipe')}}</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="lower-recipes-container">
        <app-category-item class="category-item-container" *ngFor="let category of domainSvc.currentDomain.categories" [category]="category"></app-category-item>
    </div>
     
    <!-- let's keep the template selection incase we have a dramatic change in regard to the content -->
    <!-- <div *ngIf="recipeSvc.templateSelection === 0 then recipes else techniques"></div> -->
    
    <div id="recipe-swiper-container" class="recipe-swiper-container">
        <div class="swiper-wrapper swiper-adjustment">
            <app-workflow-item class="recipe-swiper-slide" *ngFor="let workflow of domainSvc.currentCategory.workflows" [workflow]="workflow"></app-workflow-item>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
    </div>
        
</ng-template>

<ng-template #phone>
    <div class="phone-recipe-top-wrapper">
        <div class="phone-recipe-header">
            <h3>{{workflowSvc.selectedWorkflow.title}}</h3>
        </div>
        <div class="phone-selected-recipe-container">
            <div class="phone-selected-recipe-info-container">
                <div class="phone-selected-recipe-info-wrapper">
                    <div class="info-wrapper">
                        <div class="info-icon">
                            <img src="/assets/base_icons/icon_yield.svg">
                        </div>
                        <div class="info-text-wrapper">
                            <div class="yield-text">
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_yield">{{asRecipe(workflowSvc.selectedWorkflow).min_yield}}</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_yield && asRecipe(workflowSvc.selectedWorkflow).max_yield">-</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_yield">{{asRecipe(workflowSvc.selectedWorkflow).max_yield}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-wrapper">
                        <div class="info-icon">
                            <img src="/assets/base_icons/icon_prep_time.svg">
                        </div>
                        <div class="info-text-wrapper">
                            <div class="yield-text">
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_prepTime">{{asRecipe(workflowSvc.selectedWorkflow).min_prepTime}}</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_prepTime && asRecipe(workflowSvc.selectedWorkflow).max_prepTime">-</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_prepTime">{{asRecipe(workflowSvc.selectedWorkflow).max_prepTime}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-wrapper">
                        <div class="info-icon">
                            <img src="/assets/base_icons/icon_cook_time.svg">
                        </div>
                        <div class="info-text-wrapper">
                            <div class="yield-text">
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_cookTime">{{asRecipe(workflowSvc.selectedWorkflow).min_cookTime}}</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_cookTime && asRecipe(workflowSvc.selectedWorkflow).max_cookTime">-</p>
                                <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_cookTime">{{asRecipe(workflowSvc.selectedWorkflow).max_cookTime}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-wrapper">
                        <div class="info-icon">
                            <img src="{{workflowSvc.difficultyPicto}}">
                        </div>
                        <div class="info-text-wrapper">
                            <div class="yield-text">
                                <p class="info-text">{{workflowSvc.difficultyText}}</p>
                            </div>                                                                                                                      
                        </div>
                    </div>
                </div>

                <div class="autonome-button-orange" *ngIf="workflowSvc.currentStepId === 0" (click)="gotoRecipeDetails()"><p>{{translatorSvc.getLabel('seeIngredients')}}</p></div>
            </div>
            <div class="phone-selected-recipe-picto-container">
                <img class="recipe-img" [class.recipe-jpg]="workflowSvc.selectedWorkflow.recipe_jpg" src="{{domainSvc.currentDomain.id === 'job_examples_manufacturing' ? '/assets/pictos/workflows/' + workflowSvc.selectedWorkflow.id + '.jpg' : (workflowSvc.selectedWorkflow.recipe_jpg ? workflowSvc.selectedWorkflow.recipe_jpg : '/assets/pictos/workflows/' + workflowSvc.selectedWorkflow.id + '.svg')}}" alt={{workflowSvc.selectedWorkflow.title!}}> 
            </div>
            <div class="phone-button-container">
                <div class="autonome-button-orange" *ngIf="workflowSvc.currentStepId > 0 && workflowSvc.selectedWorkflow.id === workflowSvc.currentWorkflow.id" (click)="launchWorkflow()"><p>{{translatorSvc.getLabel('continueRecipe')}}</p></div>
                <div class="autonome-button-orange" *ngIf="workflowSvc.currentStepId === 0 || workflowSvc.selectedWorkflow.id !== workflowSvc.currentWorkflow.id" (click)="launchWorkflow()"><p>{{translatorSvc.getLabel('startRecipe')}}</p></div>
                <div class="autonome-button-orange" *ngIf="workflowSvc.currentStepId > 0 && workflowSvc.selectedWorkflow.id === workflowSvc.currentWorkflow.id" (click)="restartWorkflow()"><p>{{translatorSvc.getLabel('restartRecipe')}}</p></div>
            </div>
        </div>
        <div class="phone-recipes-categories-container">
            <div class="phone-recipes-categories-select">
                <select [(ngModel)]="domainSvc.currentCategory">
                    <option *ngFor="let category of domainSvc.currentDomain.categories" [ngValue]="category">
                        {{category.getTitle(authSvc.User.accountSettings.language)}}
                    </option>
                </select>
            </div>
            <div class="phone-recipes-overflow-wrapper">
                <div #phone_recipes_container_recipes class="phone-recipes-container-recipes" (scroll)="onRecipesScroll()">
                    <app-workflow-item class="recipe-swiper-slide visible" *ngFor="let workflow of domainSvc.currentCategory.workflows" [workflow]="workflow"></app-workflow-item>
                </div>
            </div>
        </div>
    </div>
</ng-template>



