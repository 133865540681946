<div class="shopping-cart-container">
    <div *ngIf="screenWidth <= 660 then phone else tablet"></div>
</div>

<ng-template #phone>
    <div class="shopping-cart-phone-container">
        <div class="shopping-cart-phone-header">
            <div class="nav-button" (click)="goToDomainProducts()">
                <img src="/assets/base_icons/icon_arrow_left_blue.svg">
            </div>
            <div class="shopping-cart-phone-title"><h3>{{translatorSvc.getLabel('shoppingCart')}}</h3></div>
            <div class="empty-third-hdiv"></div>
        </div>
        <div class="shopping-cart-phone-wrapper">
            <div class="section-wrapper">
                <section class="shopping-cart-phone-items">
                    <h4>{{translatorSvc.getLabel('items')}}</h4>
                    <div *ngIf="productSvc.domainsToPurchase.length > 0">
                        <div class="shopping-cart-domains-wrapper" *ngFor="let domain of productSvc.domainsToPurchase">
                            <div class="shopping-cart-item-row">
                                <div class="domain-item-name"><p>{{getDomainTitle(domain)}} ({{translatorSvc.getLabel('subscription')}})</p></div>
                                <div class="domain-item-price"><p>5.99$ (CAN) / {{translatorSvc.getLabel('yearly')}}</p></div>
                            </div>
                            <div class="shopping-cart-item-row-waste-basket">
                                <img class="delete-item-icon" (click)="removeDomainItem(domain)" src="/assets/base_icons/icon_waste.svg">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="productSvc.workflowsToPurchase.length > 0">
                        <div class="shopping-cart-workflows-wrapper" *ngFor="let workflow of productSvc.workflowsToPurchase">
                            <div class="shopping-cart-item-row">
                                <div class="workflow-item-name"><p>{{workflow}}</p></div>
                                <div class="workflow-item-price"><p>0.99$ (CAN) / {{translatorSvc.getLabel('yearly')}}</p></div>
                            </div>
                            <div class="shopping-cart-item-row-waste-basket">
                                <img class="delete-item-icon" (click)="removeWorkflowItem(workflow)" src="/assets/base_icons/icon_waste.svg">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="productSvc.itemsToPurchase.length > 0">
                        <div class="shopping-cart-items-wrapper" *ngFor="let item of productSvc.itemsToPurchase">
                            <div class="shopping-cart-item-row">
                                <div class="workflow-item-name"><p>{{item.name}}</p></div>
                                <div class="workflow-item-price"><p>{{item.quantity}} x {{item.price}}$ : {{item.quantity*item.price}}$</p></div>
                            </div>
                            <div class="shopping-cart-item-row-waste-basket">
                                <img class="delete-item-icon" (click)="removePurchaseItem(item)" src="/assets/base_icons/icon_waste.svg">
                            </div>
                        </div>
                    </div>
                </section>
                <hr>
                <section class="shopping-cart-total">
                    <div *ngIf="totalYearSubscriptions > 0" class="shopping-cart-total-subscriptions">
                        <div class="shopping-cart-total-subscriptions-label"><p>{{translatorSvc.getLabel('totalSubscriptions')}}</p></div>
                        <div class="shopping-cart-total-subscriptions-total"><p>{{totalYearSubscriptions}}$ (CAN)/ {{translatorSvc.getLabel('yearly')}}</p></div>
                    </div>
                    <div *ngIf="totalItems > 0" class="shopping-cart-total-items">
                        <div class="shopping-cart-total-items-label"><p>{{translatorSvc.getLabel('totalPurchases')}}</p></div>
                        <div class="shopping-cart-total-items-total"><p>{{totalItems}}$ (CAN)   </p></div>
                    </div>
                </section>
            </div>
            <section *ngIf="totalItems > 0 || totalYearSubscriptions > 0"class="shopping-cart-buttons">
                <button *ngIf="!productSvc.itemsToPurchase || productSvc.itemsToPurchase.length === 0" (click)="paymentSvc.proceedToOrder()" class="autonome-button-orange">
                    <p>{{translatorSvc.getLabel('proceedToOrder')}}</p>
                </button>
                <button *ngIf="productSvc.itemsToPurchase && productSvc.itemsToPurchase.length > 0"class="autonome-button-orange" (click)="goToShipping()">
                    <p>{{translatorSvc.getLabel('proceedShipping')}}</p>
                </button>
            </section>
        </div>
        
    </div>
</ng-template>

<ng-template #tablet>
    <div class="shopping-cart-tablet-container">
        <div class="shopping-cart-tablet-header">
            <div class="shopping-cart-tablet-nav-wrapper">
                <div class="nav-button" (click)="goToDomainProducts()">
                    <img src="/assets/base_icons/icon_arrow_left_blue.svg">
                    <p>{{translatorSvc.getLabel('backToDomainProducts')}}</p>
                </div>
                <div class="shopping-cart-tablet-title"><h3>{{translatorSvc.getLabel('shoppingCart')}}</h3></div>
                <div class="empty-third-hdiv"></div>
            </div>
        </div>
        <div class="shopping-cart-tablet-wrapper">
            <div class="shopping-cart-items-container">
                <section class="shopping-cart-tablet-items">
                    <h4>{{translatorSvc.getLabel('items')}}</h4>
                    <div *ngIf="productSvc.domainsToPurchase.length > 0">
                        <div class="shopping-cart-tablet-domains-wrapper" *ngFor="let domain of productSvc.domainsToPurchase">
                            <div class="shopping-cart-item-row">
                                <div class="domain-item-name"><p>{{getDomainTitle(domain)}} ({{translatorSvc.getLabel('subscription')}})</p></div>
                                <div class="domain-item-price"><p>5.99$ (CAN) / {{translatorSvc.getLabel('yearly')}}</p></div>
                            </div>
                            <div class="shopping-cart-item-row-waste-basket">
                                <img class="delete-item-icon" (click)="removeDomainItem(domain)" src="/assets/base_icons/icon_waste.svg">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="productSvc.workflowsToPurchase.length > 0">
                        <div class="shopping-cart-tablet-workflows-wrapper" *ngFor="let workflow of productSvc.workflowsToPurchase">
                            <div class="shopping-cart-item-row">
                                <div class="workflow-item-name"><p>{{workflow}}</p></div>
                                <div class="workflow-item-price"><p>0.99$ (CAN) / {{translatorSvc.getLabel('yearly')}}</p></div>
                            </div>
                            <div class="shopping-cart-item-row-waste-basket">
                                <img class="delete-item-icon" (click)="removeWorkflowItem(workflow)" src="/assets/base_icons/icon_waste.svg">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="productSvc.itemsToPurchase.length > 0">
                        <div class="shopping-cart-tablet-items-wrapper" *ngFor="let item of productSvc.itemsToPurchase">
                            <div class="shopping-cart-item-row">
                                <div class="workflow-item-name"><p>{{item.name}}</p></div>
                                <div class="workflow-item-price"><p>{{item.quantity}} x {{item.price}}$ : {{item.quantity * item.price}}$</p></div>
                            </div>
                            <div class="shopping-cart-item-row-waste-basket">
                                <img class="delete-item-icon" (click)="removePurchaseItem(item)" src="/assets/base_icons/icon_waste.svg">
                            </div>
                        </div>
                    </div>
                </section>
                <hr>
                <section class="shopping-cart-tablet-total">
                    <div *ngIf="totalYearSubscriptions > 0" class="shopping-cart-total-subscriptions">
                        <div class="shopping-cart-total-subscriptions-label"><p>{{translatorSvc.getLabel('totalSubscriptions')}}</p></div>
                        <div class="shopping-cart-total-subscriptions-total"><p>{{totalYearSubscriptions}}$ (CAN)/{{translatorSvc.getLabel('yearly')}}</p></div>
                    </div>
                    <div *ngIf="totalItems > 0" class="shopping-cart-total-items">
                        <div class="shopping-cart-total-items-label"><p>{{translatorSvc.getLabel('totalPurchases')}}</p></div>
                        <div class="shopping-cart-total-items-total"><p>{{totalItems}}$ (CAN)</p></div>
                    </div>
                    
                </section>
                <section class="promo-tablet-container">
                    <div class="promo-tablet-wrapper">
                        <label class="full-field">
                            <span class="form-label"><p>{{translatorSvc.getLabel('promoCode')}}</p></span>
                            <input
                                [(ngModel)]="promoCode"
                                id="promo_code"
                                name="promo_code"
                                autocomplete="false"
                            />
                        </label>
                    </div>
                    <button *ngIf="!productSvc.itemsToPurchase || productSvc.itemsToPurchase.length === 0" (click)="paymentSvc.proceedToOrder()" class="autonome-button-orange">
                        <p>{{translatorSvc.getLabel('proceedToOrder')}}</p>
                    </button>
                    <button [disabled]="!promoCode || promoCode ==''" (click)="applyPromoCode()" class="autonome-button-orange">
                        <p>{{translatorSvc.getLabel('applyPromo')}}</p>
                    </button>
                </section>
            </div>
            <app-shipping-element *ngIf="productSvc.itemsToPurchase.length > 0" ></app-shipping-element>
        </div>
        
    </div>
    
</ng-template>

