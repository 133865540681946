import { WorkflowService } from '../../services/workflow.service';
import { StepWrapperComponent } from '../../../interfaces/step-wrapper.component';
import { Component, Input } from '@angular/core';
import { Step } from '../../../models/step.model';

@Component({
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements StepWrapperComponent {
  @Input() step! : Step;

  constructor(
    private wfSvc:WorkflowService){}

  next_step() {
    this.wfSvc.sendNextStepEvent(this.step._display_id, true);
  }
  
  overButton() {
    // handle mouse and touch events through eventListener
    this.wfSvc.sendDisableSwiperEvent();
  }

  leaveButton() {
    this.wfSvc.sendEnableSwiperEvent();
  }

  first_checkStep() {
    this.wfSvc.sendNextStepEvent(this.step._display_id, false);
  }

  disableTouchMove() {
    this.wfSvc.sendDisableTouchMoveEvent();
  }

  enableTouchMove() {
    this.wfSvc.sendEnableTouchMoveEvent();
  }

}
