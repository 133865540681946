<div (window:resize)="onHeightResize($event)" style="height:100%" [class.display-none]="spinnerSvc.active">
    <div *ngIf="viewDomain then domainPhone else workflowPhone"></div>
</div>

<ng-template #domainPhone>
    <div class="products-domain-container">
        <section class="products-domain-header">
            <div class="products-domain-nav-wrapper">
                <div class="nav-button" (click)="goToRecipes()">
                    <img src="/assets/base_icons/icon_arrow_left_blue.svg">
                    <p>{{translatorSvc.getLabel('backToRecipes')}}</p>
                </div>
                <div class="products-domain-title"><h3>{{translatorSvc.getLabel('ourProducts')}}</h3></div>
                <div class="empty-third-hdiv"></div>
            </div>
        </section>
        <section class="products-container">
            <div class="subscription-container">
                <h3 class="domain-promo-title">
                    {{translatorSvc.getLabel('recipesSubscriptionTitle')}}
                </h3>
                <p class="add-domain-info">{{translatorSvc.getText('recipeDomainPurchase')}}</p>
                <div class="add-domain-price">
                    <p>{{domainSvc.currentDomain.price}}$ (CAN)/ {{translatorSvc.getLabel(domainSvc.currentDomain.price_subscription_term)}}</p>
                </div>
                <div class="add-domain-price-description">
                    <p>{{priceDescription}}</p>
                </div>
                <div class="domain-promo-button-container">
                    <button class="autonome-button-orange" [disabled]="promoPurchased || domainPurchased" (click)="addDomainToCart()"><p>{{translatorSvc.getLabel('addToCart')}}</p></button>
                </div>
            </div>
            <div class="kit-container">
                <h3 class="domain-promo-title">
                    {{translatorSvc.getLabel('autonomeKitTitle')}}
                </h3>
                <div class="domain-promo-image-container">
                    <div class="domain-promo-image-wrapper" *ngFor="let photoString of domainSvc.currentDomain.promotions.pictures">
                        <img src="/assets/photos/promos/{{photoString}}">
                    </div>
                </div>
                <div class="domain-promo-description-container">
                    <div class="domain-promo-description-wrapper">
                        <p>{{domainSvc.currentDomain.promotions[kitDescription]}}</p>
                    </div>
                    <div class="domain-promo-price-wrapper">
                        <p>{{translatorSvc.getLabel('price')}} : {{domainSvc.currentDomain.promotions.price}}$ (CAN)</p>
                        <div class="domain-promo-price-details"><p>{{translatorSvc.getLabel('shippingIncluded')}}</p></div> 
                    </div>
                    <div class="domain-promo-quantity-container">
                        <div class="domain-promo-quantity-text"><p>{{translatorSvc.getLabel('quantity')}} : </p></div>
                        <input class="domain-promo-quantity" type="number" onKeyPress="if(this.value.length>1) return false" [(ngModel)]="quantity"/>
                    </div>

                </div>
                <div class="domain-promo-button-container">
                    <button class="autonome-button-orange" [disabled]="promoPurchased" (click)="addKitToCart()"><p>{{translatorSvc.getLabel('addToCart')}}</p></button>
                </div>
            </div>
        </section>
    </div>
</ng-template>

<ng-template #workflowPhone>
    
</ng-template>
