<div class="payment-container">
    <section  *ngIf="displaySvc.screenWidth <= 660" class="payment-header">
        <div class="payment-nav-wrapper">
            <div class="nav-button" (click)="goToOrder()">
                <img src="/assets/base_icons/icon_arrow_left_blue.svg">
            </div>
            <div class="payment-title">
                <h3>{{translatorSvc.getLabel('payment')}}</h3>
            </div>
            <div class="empty-third-hdiv"></div>
        </div>
    </section>
    <div class="stripe-element">
        <app-stripe-card-elements></app-stripe-card-elements>
    </div>
</div>


