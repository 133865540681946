import { Injectable, HostListener } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  private _fullScreen: boolean = false;
  private _fullScreenAsked: boolean = false;
  private _landscape: boolean;
  private _screenWidth: number;
  private _screenHeight: number;
  
  elem: HTMLElement;

  constructor(
  ) {
  }

  init() {
    if (document.fullscreenElement) {
      this._fullScreen = true;
    } else {
      this._fullScreen = false;
    } 
    this.elem = document.documentElement;

    this._landscape = window.matchMedia("(orientation: landscape)").matches;
    window.matchMedia("(orientation: landscape)").addEventListener("change", e => {
      this._screenWidth = document.documentElement.scrollWidth;
      this._screenHeight = document.documentElement.scrollHeight;
      const landscape = e.matches;
      if (landscape) {
        this._landscape = true;
      } else {
        this._landscape = false;
      }
    });
    this._screenHeight = document.documentElement.scrollHeight;
    this._screenWidth = document.documentElement.scrollWidth;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])  
  fullscreenmodes(){
    this.chkFullScreenSize();
  }


  chkFullScreenSize() {
    this._screenWidth = window.innerWidth;
    if (document.fullscreenElement) {
      this._fullScreen = true;
      return true;
    } else {
      this._fullScreen = false;
      return false;
    }
  }

  screenFullSize(full: boolean) {
    if(full) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
        this._fullScreen = true;
      }
    } else {
        this._fullScreen = false;
    }
  }

  closeFullscreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  public get fullScreen(): boolean {
    return this._fullScreen;
  }
  public set fullScreen(value: boolean) {
    this._fullScreen = value;
  }

  public get fullScreenAsked(): boolean {
    return this._fullScreenAsked;
  }
  public set fullScreenAsked(value: boolean) {
    this._fullScreenAsked = value;
  }

  public get landscape(): boolean {
    return this._landscape;
  }
  public set landscape(value: boolean) {
    this._landscape = value;
  }

  public get screenWidth(): number {
    return this._screenWidth;
  }
  public set screenWidth(value: number) {
    this._screenWidth = value;
  }

  public get screenHeight(): number {
    return this._screenHeight;
  }
  public set screenHeight(value: number) {
    this._screenHeight = value;
  }
}


