import { AuthService } from 'src/app/admin/services/auth.service';
import { StepWrapperComponent } from '../../../interfaces/step-wrapper.component';
import { Step } from '../../../models/step.model';
import { AfterContentInit, AfterViewInit, Component, Input } from '@angular/core';
import { WorkflowService } from '../../services/workflow.service';
import { RecipeService } from '../../services/recipe.service';
import { DisplayService } from 'src/app/tools/services/display.service';

@Component({
  templateUrl: './ingredient-tool.component.html',
  styleUrls: ['./ingredient-tool.component.scss']
})
export class IngredientToolComponent implements StepWrapperComponent, AfterContentInit, AfterViewInit {
  
  @Input() step : Step;
  measureText : string;
  toolFolderString = '/assets/pictos/tools/';
  toolPictoList = [];
  competency: number;
  screenWidth: number;
  toolPictoListEmpty: boolean=true;

  constructor(
    private wfSvc : WorkflowService,
    public authSvc : AuthService,   
    private recipeSvc : RecipeService,
    public displaySvc : DisplayService) {
  }

  ngAfterViewInit(): void {
  }

  next_step() {
    this.wfSvc.sendNextStepEvent(this.step._display_id, true);
  }

  overButton() {
    // handle mouse and touch events through eventListener
    this.wfSvc.sendDisableSwiperEvent();
  }

  leaveButton() {
    this.wfSvc.sendEnableSwiperEvent();
  }

  disableTouchMove() {
    this.wfSvc.sendDisableTouchMoveEvent();
  }

  enableTouchMove() {
    this.wfSvc.sendEnableTouchMoveEvent();
  }

  ngAfterContentInit(): void {
    if (this.step.tools) {
      this.measureText = this.step.measure.toString();
      // only display one(first) tool for the moment
      for (const tool of this.step.tools) {
        this.toolPictoList.push(this.toolFolderString + tool.id + '.svg');
      }
    } else if (this.step.unit) {
      this.measureText = this.recipeSvc.buildWeightMeasure(this.step.measure);
    } else {
      this.measureText = this.recipeSvc.getMeasureText(this.step.measure);
      this.recipeSvc.toolsList = [];
      this.recipeSvc.buildMeasure(this.step.measure);
      for (const tool of this.recipeSvc.toolsList) {
        this.toolPictoList.push(this.toolFolderString + tool.picto + '.svg');
      }
      this.toolPictoListEmpty = false;
    }

  }
  
}
