import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { resolve } from 'path';
import { first, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(
    private fns : AngularFireFunctions
  ) { }

  setRecipeImage(recipeImageData : any) : Promise<any> {
    const wfCall = this.fns.httpsCallable('httpSetRecipeImage');
    return firstValueFrom(wfCall({recipeData: recipeImageData}).pipe(first()));
  }
}
