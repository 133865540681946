import { Injectable } from '@angular/core';
import { GameObject } from '../models/game-object';
import { Person } from '../models/person';
import { KeyPressListener } from '../models/key-press-listener';
import { Observable, Subject, Subscription } from 'rxjs';
import { Battle } from '../models/battle';
import { TurnCycle } from '../models/turn-cycle';
import { BattleEvent } from '../models/battle-event';
import { Combatant } from '../models/combatant';
import { utils } from '../../utils/utils';
import { AuthService } from 'src/app/admin/services/auth.service';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { KeyboardMenu } from '../models/keyboard-menu';
import { KeyboardMenuItem } from '../models/keyboard-menu-item';
@Injectable({
  providedIn: 'root'
})
export class AcademyService {

  private loginEventsubscription: Subscription

  private actionListener;
  private _battleShow: boolean = false;

  // text message variables
  private _textMessageShow: boolean = false;
  private _textMessage: string;
  private _textMessageArray: Array<string> = [];
  private _warpToDone: boolean = false;
  private _textRevealDone: boolean = true;
  private _menuText: boolean = false;
  
  // battle variables
  private _battle: Battle;
  private _turnCycle: TurnCycle;
  private _currentTeam: string;
  private _caster: Combatant;
  private _enemy: Combatant;
  private _battleTarget: Combatant;
  private _action: string;

  // submission menu variables
  private _submissionMenuShow: boolean = false;
  private _keyBoardMenu: KeyboardMenu;
  
  

  constructor(private authSvc: AuthService,
    private spinnerOverlaySvc: SpinnerOverlayService) {
    
    this.loginEventsubscription = this.authSvc.getLoginEvent().subscribe( user => {
      // hide the spinner
      if(this.spinnerOverlaySvc.active) {
        this.spinnerOverlaySvc.hide(2);
      }
      
    });

    this._battle = new Battle();

    Object.keys(this._battle.combatants).forEach(key => {
      this._battle.combatants[key].id = key;
    });

    this.activateCombatants();

    this.currentTeam = "player";

    this._turnCycle = new TurnCycle({
      onNewEvent : event => {
        return new Promise(resolve => {
          const battleEvent = new BattleEvent(event, this);
          battleEvent.init(resolve);
        });
      }
    }, this);

    this._turnCycle.init();

  }

  withGrid(n : number) {
    return n * 16;
  }

  isPerson(gameObject : Person | GameObject) : gameObject is Person {
    return (gameObject as Person).update !== undefined;
  }

  asGridCoord(x, y) {
    return `${x*16}, ${y*16}`;
  }

  nextPosition(initialX:number, initialY: number, direction:string) : {x:number, y:number} {
    let x = initialX;
    let y = initialY;
    const size = 16;

    switch(direction) {
      case "left":
        x -= size;
        break;
      case "right":
        x += size;
        break;
      case "up":
        y -= size;
        break;
      case "down":
        y += size;
        break;
    }
    return {x:x,y:y};
  }

  

  activateCombatants() {
    Object.values(this._battle.activeCombatants).forEach(value => {
      this._battle.combatants[value.toString()].active = true;
    });
  }

  public get warpToDone(): boolean {
    return this._warpToDone;
  }
  public set warpToDone(value: boolean) {
    this._warpToDone = value;
  }

  public get menuText(): boolean {
    return this._menuText;
  }
  public set menuText(value: boolean) {
    this._menuText = value;
  }

  public get textMessageArray(): Array<string> {
    return this._textMessageArray;
  }
  public set textMessageArray(value: Array<string>) {
    this._textMessageArray = value;
  }

  public get textMessage(): string {
    return this._textMessage;
  }
  public set textMessage(value: string) {
    if(this._textMessage !== value) {
      this._textMessageArray = [];
      this._textMessage = value;
      this.textMessageShow = true;
      if(!this._menuText) {
        if(!this._textRevealDone) {
          this._warpToDone = true;
        }
        this._textMessageArray.push(this._textMessage[0]);
        setTimeout(() => {
          this.setCharacterArray(0);
        }, 40 );
      } else {
        this._textMessageArray = [...this._textMessage];
        this._menuText = false;
      }
    }
    
  }

  setCharacterArray(index) {
    if(!this._warpToDone) {
      this._textRevealDone = false;
      index++;
      let speed = this._textMessage[index] === " " ? 0:40;
      setTimeout(() => {
        
        if(index == this._textMessage.length) {
          this._textRevealDone = true;
          return;
        }
        this._textMessageArray.push(this._textMessage[index]);
        this.setCharacterArray(index);
      }, speed);
    } else {
      for(let i=index+1; i<this._textMessage.length; i++) {
        this._textMessageArray.push(this._textMessage[i]);
      }
      this._warpToDone = false;
      this._textRevealDone = true;
    }
    
  }

  public get textMessageShow(): boolean {
    return this._textMessageShow;
  }
  public set textMessageShow(value: boolean) {
    if(!this.textMessageShow && value === true) {
      this._textMessageShow = value;
      this.actionListener = new KeyPressListener("Enter", () => {
        if(this._textRevealDone) {
          this.actionListener.unbind();
          this.sendTextMsgClosedEvent();
          this._textMessageShow = false;
        } else {
          this._warpToDone =true;
          this._textRevealDone = true;
        }
        
      });
    } else {
      this._textMessageShow = value;
    }
  }

  public get textRevealDone(): boolean {
    return this._textRevealDone;
  }
  public set textRevealDone(value: boolean) {
    this._textRevealDone = value;
  }

  private textMsgClosedSubject = new Subject<any>();
  sendTextMsgClosedEvent() {
    this.textMsgClosedSubject.next(this._textMessageShow);
  }
  getTextMsgClosedEvent(): Observable<any>{
    return this.textMsgClosedSubject.asObservable();
  }

  public get battleShow(): boolean {
    return this._battleShow;
  }
  public set battleShow(value: boolean) {
    this._battleShow = value;
  }

  private battleClosedSubject = new Subject<any>();
  sendBattleClosedEvent() {
    this.battleClosedSubject.next(this._battleShow);
  }
  getBattleClosedEvent(): Observable<any>{
    return this.battleClosedSubject.asObservable();
  }

  public get battle(): Battle {
    return this._battle;
  }
  public set battle(value: Battle) {
    this._battle = value;
  }

  public get caster(): Combatant {
    return this._caster;
  }
  public set caster(value: Combatant) {
    this._caster = value;
  }

  public get enemy(): Combatant {
    return this._enemy;
  }
  public set enemy(value: Combatant) {
    this._enemy = value;
  }

  public get battleTarget(): Combatant {
    return this._battleTarget;
  }
  public set battleTarget(value: Combatant) {
    this._battleTarget = value;
  }

  public get turnCycle(): TurnCycle {
    return this._turnCycle;
  }
  public set turnCycle(value: TurnCycle) {
    this._turnCycle = value;
  }

  public get action(): string {
    return this._action;
  }
  public set action(value: string) {
    this._action = value;
  }

  public get currentTeam(): string {
    return this._currentTeam;
  }
  public set currentTeam(value: string) {
    this._currentTeam = value;
    this.caster = this._battle.combatants[this._battle.activeCombatants[this._currentTeam]];
    this.enemy = this._battle.combatants[this._battle.activeCombatants[this.caster.team ==="player" ? "enemy" : "player"]];
    this.battleTarget = this._enemy;
  }

  public get submissionMenuShow(): boolean {
    return this._submissionMenuShow;
  }
  public set submissionMenuShow(value: boolean) {
    this._submissionMenuShow = value;
  }

  private submissionMenuClosedSubject = new Subject<any>();
  sendSubmissionMenuClosedEvent() {
    // battle action has occured
    this.submissionMenuClosedSubject.next(this._submissionMenuShow);
  }
  getSubmissionMenuClosedEvent(): Observable<any>{
    return this.submissionMenuClosedSubject.asObservable();
  }

  decide() {
    this.action = this._caster.actions[0];
    
  }

  // set key board menu focus from old to new
  keyboardMenuSelectEventSubject = new Subject<any>();
  sendKeyboardMenuSelectEvent(item: number) {
    this.keyboardMenuSelectEventSubject.next(item);
  }
  
  getKeyboardMenuSelectEvent() {
    return this.keyboardMenuSelectEventSubject.asObservable();
  }

  public get keyBoardMenu(): KeyboardMenu {
    return this._keyBoardMenu;
  }
  public set keyBoardMenu(value: KeyboardMenu) {
    this._keyBoardMenu = value;
  }

  returnMenuIndexOf(kbItem: string): number{
    for(let i=0; i < this._keyBoardMenu.keyboardMenuItems.length; i++) {
        if(kbItem === this._keyBoardMenu.keyboardMenuItems[i].label) {
            return i;
        }
    }
    return -1;
} 

}
