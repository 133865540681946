import { NgModule } from "@angular/core";
import { FamiliarizationComponent } from "./components/familiarization/familiarization.component";
import { CommonModule } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ModalsModule } from "../modal/modals.module";
import { FormsModule } from "@angular/forms";
import { CoreModule } from "../core/core.module";
import { JobVideoComponent } from "./components/job-video/job-video.component";
import { JobQuestionComponent } from "./components/job-question/job-question.component";

@NgModule({
    declarations: [
        FamiliarizationComponent,
        JobQuestionComponent,
        JobVideoComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        ModalsModule,
        FormsModule,
        CoreModule,
        /*GoogleTagManagerModule.forRoot({
            id: environment.GTM_ID,
        }),*/
    ]
})
export class ResearchProjectsModule {}