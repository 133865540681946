export class Allergy {
    private _allergy_name : string;
    private _description_array: Array<any>;
    private _description : string;

    constructor(allergyData: any) {
        this._allergy_name = Object.keys(allergyData)[0];
        if(!this._description_array) {
            this._description_array = [];
        }
        this._description_array.push({language:'en', description: allergyData[this._allergy_name].description_en});
        this._description_array.push({language:'fr', description: allergyData[this._allergy_name].description_fr});
    }

    get allergyName() {
        return this._allergy_name;
    }
    set allergyName(allergy_name : string) {
        this._allergy_name = allergy_name;
    }

    get description() {
        return this._description;
    }
    set description(description : string) {
        this._description = description;
    }

    public setDescription(userLocale: string) {
        const langTemp = this._description_array.find(({language}) => language === userLocale);
        this.description = langTemp.description;
    }
}