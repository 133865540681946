import { Component } from '@angular/core';
import { OrganisationService } from '../../services/organisation.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { AuthService } from 'src/app/admin/services/auth.service';
import { Organisation } from 'src/app/models/organisation.model';

@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss']
})
export class OrganisationsComponent{
  
  constructor(
    public orgSvc: OrganisationService,
    public authSvc: AuthService,
    public translatorSvc : TranslatorService) {
      if(!orgSvc.organisations) {
        this.orgSvc.initOrgSvc();
      }
    }

  public selectCurrent(org: Organisation) {
    this.orgSvc.currentOrganisation = org;
  }

}
