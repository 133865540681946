import { ImagesService } from './services/images.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerOverlayService } from './services/spinner-overlay.service';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationService } from './services/validation.service';
import { TranslatorService } from './services/translator.service';
import { PwValidatorDirective } from './directives/pw-validator.directive';
import { FocusOnShowDirective } from './directives/focus-on-show.directive';
import { RouterExtService } from './services/router-ext.service';
import { NewlineAsStringPipe } from './pipes/newline';

@NgModule({
    declarations: [
        AddressFormComponent,
        SpinnerOverlayComponent,
        PwValidatorDirective,
        FocusOnShowDirective,
        NewlineAsStringPipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        SpinnerOverlayService,
        ValidationService,
        TranslatorService,
        ImagesService,
        RouterExtService,
    ],
    exports: [
        AddressFormComponent,
        NewlineAsStringPipe
    ]
})
export class ToolModule { }
