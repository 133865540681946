import { Component, Input } from '@angular/core';
import { Tool } from '../../../models/tool.model';

@Component({
  selector: 'app-tool-list-item',
  templateUrl: './tool-list-item.component.html',
  styleUrls: ['./tool-list-item.component.scss']
})
export class ToolListItemComponent{

  @Input() tool: Tool;
  
  constructor(
  ) {}

}
