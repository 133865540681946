<div class="recipe-detail-container" (window:resize)="onHeightResize($event)" [class.display-none]="spinnerSvc.active">
    <div *ngIf="screenWidth > 600 then tablet else phone"></div>
</div>

<ng-template #phone>
    <section class="phone-recipe-detail-top">
        <div class="phone-recipe-detail-header">
            <div class="nav-button" (click)="goToRecipes()">
                <img src="/assets/base_icons/icon_arrow_left_blue.svg"><p>{{translatorSvc.getLabel('backToRecipes')}}</p>
            </div>
            <h3>{{workflowSvc.selectedWorkflow.title}}</h3>
        </div>
        <div class="recipe-detail-info-container">
            <div class="info-picto-container">
                <div class="phone-picto-wrapper">
                    <img src="/assets/pictos/workflows/{{workflowSvc.selectedWorkflow.id}}.svg">
                    <p>{{workflowSvc.selectedWorkflow.description}}</p>
                </div>
                <div class="recipe-detail-actions">
                    <!-- <img class="favorite-icon"src="{{ workflowSvc.selectedWorkflow.favorite ? '/assets/base_icons/icon_favorite.svg' : '/assets/base_icons/icon_not_favorite.svg'}}" 
                    type="button" (click)="toggleFavorite()"/> -->
                    <img class="share-recipe-icon display-none" src="/assets/base_icons/icon_share.svg" type="button" (click)="shareRecipe()"/>
                </div>
            </div>
            <div class="info-container">
                <div class="info-wrapper">
                    <div class="info-icon">
                        <img src="/assets/base_icons/icon_yield.svg">
                    </div>
                    <div class="info-text-wrapper">
                        <div class="yield-text">
                            <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_yield">{{asRecipe(workflowSvc.selectedWorkflow).min_yield}}</p>
                            <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_yield && asRecipe(workflowSvc.selectedWorkflow).max_yield">-</p>
                            <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_yield">{{asRecipe(workflowSvc.selectedWorkflow).max_yield}}</p>
                        </div>
                    </div>
                </div>
                <div class="info-wrapper">
                    <div class="info-icon">
                        <img src="/assets/base_icons/icon_prep_time.svg">
                    </div>
                    <div class="info-text-wrapper">
                        <div class="yield-text">
                            <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_prepTime">{{asRecipe(workflowSvc.selectedWorkflow).min_prepTime}}</p>
                            <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_prepTime && asRecipe(workflowSvc.selectedWorkflow).max_prepTime">-</p>
                            <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_prepTime">{{asRecipe(workflowSvc.selectedWorkflow).max_prepTime}}</p>
                        </div>
                    </div>
                </div>
                <div class="info-wrapper">
                    <div class="info-icon">
                        <img src="/assets/base_icons/icon_cook_time.svg">
                    </div>
                    <div class="info-text-wrapper">
                        <div class="yield-text">
                            <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_cookTime">{{asRecipe(workflowSvc.selectedWorkflow).min_cookTime}}</p>
                            <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_cookTime && asRecipe(workflowSvc.selectedWorkflow).max_cookTime">-</p>
                            <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_cookTime">{{asRecipe(workflowSvc.selectedWorkflow).max_cookTime}}</p>
                        </div>
                    </div>
                </div>
                <div class="info-wrapper">
                    <div class="info-icon">
                        <img src="{{workflowSvc.difficultyPicto}}">
                    </div>
                    <div class="info-text-wrapper">
                        <p class="info-text">{{workflowSvc.difficultyText}}</p>
                    </div>
                </div>
            </div>
            <div class="allergy-container" [class.display-none] = "!asRecipe(workflowSvc.selectedWorkflow).allergyList || asRecipe(workflowSvc.selectedWorkflow).allergyList.length == 0">
                <h4>{{translatorSvc.getLabel('allergies')}} : </h4>
                <app-allergy-item class="allergy-item" *ngFor="let allergyItem of asRecipe(workflowSvc.selectedWorkflow).allergyList" [allergy]="allergyItem"></app-allergy-item>
            </div>
            <div class="recipe-launch-buttons-container">
                <!-- <div class="recipe-restart modal-text-button" [class.display-none]="!workflowSvc.selectedWorkflow" (click)="onRestartRecipe()">
                    <img src="/assets/base_icons/icon_restart_recipe_beige.svg">
                    <h2>{{translatorSvc.getLabel('restartRecipe')}}</h2>
                </div>
                <div class="recipe-launch modal-text-button" (click)="onLaunchRecipe()">
                    <img src="/assets/base_icons/icon_continue_recipe_beige.svg">
                    <h2 *ngIf="workflowSvc.selectedWorkflow !== null; else startRecipe">{{translatorSvc.getLabel('continueRecipe')}}</h2>
                </div> -->
                <div class="autonome-button-orange" (click)="onLaunchRecipe()">
                    <p>{{translatorSvc.getLabel('startRecipe')}}</p>
                </div>
            </div>
        </div>
    </section>
    <section class="phone-recipe-detail-bottom"> 
        <div class="tab">
            <button class="tablinks active" (click)="openList($event, 'ingredients')"><p>{{translatorSvc.getLabel('ingredients')}}</p></button>
            <button class="tablinks" (click)="openList($event, 'tools')"><p>{{translatorSvc.getLabel('tools')}}</p></button>
        </div>
        <div id="ingredients" class="ingredients-container tabcontent">
            <div class="ingredients-list">
                <app-ingredient-list-item class="ingredient-list-item" *ngFor="let ingredientItem of asRecipe(workflowSvc.selectedWorkflow).ingredientList" [ingredientItem]="ingredientItem"></app-ingredient-list-item>
            </div>
        </div>
        <div id="tools" class="tools-container tabcontent">
            <div class="tools-list">
                <app-tool-list-item class="tool-list-item" *ngFor="let toolItem of asRecipe(workflowSvc.selectedWorkflow).toolList" [tool]="toolItem"></app-tool-list-item>
            </div>
        </div>
    </section>      
</ng-template>

<ng-template #tablet>
    <section class="recipe-detail-top">
        <div class="recipe-detail-header">
            <div class="nav-button" (click)="goToRecipes()">
                <img src="/assets/base_icons/icon_arrow_left_blue.svg"><p>{{translatorSvc.getLabel('backToRecipes')}}</p>
            </div>
            <h3>{{workflowSvc.selectedWorkflow.title}}</h3>
            <div class="empty-third-hdiv"></div>
        </div>
        <div class="recipe-detail-info-wrapper">
            <div class="info-picto-container">
                <div class="info-picto-wrapper">
                    <div class="picto-wrapper">
                        <img src="/assets/pictos/workflows/{{workflowSvc.selectedWorkflow.id}}.svg">
                    </div>
                    <div class="recipe-description">
                        <p>{{workflowSvc.selectedWorkflow.description}}</p>
                    </div>
                    <div class="recipe-launch-buttons-container">
                        <!-- <div class="recipe-restart modal-text-button" [class.display-none]="!workflowSvc.selectedWorkflow" (click)="onRestartRecipe()">
                            <img src="/assets/base_icons/icon_restart_recipe_beige.svg">
                            <h2>{{translatorSvc.getLabel('restartRecipe')}}</h2>
                        </div>
                        <div class="recipe-launch modal-text-button" (click)="onLaunchRecipe()">
                            <img src="/assets/base_icons/icon_continue_recipe_beige.svg">
                            <h2 *ngIf="workflowSvc.currentWorkflow !== null; else startRecipe">{{translatorSvc.getLabel('continueRecipe')}}</h2>
                        </div> -->
                        <div class="autonome-button-orange" (click)="onLaunchRecipe()">
                            <p>{{translatorSvc.getLabel('startRecipe')}}</p>
                        </div>
                    </div>
                    <div class="recipe-detail-actions">
                        <!-- <img class="favorite-icon"src="{{ workflowSvc.selectedWorkflow.favorite ? '/assets/base_icons/icon_favorite.svg' : '/assets/base_icons/icon_not_favorite.svg'}}" 
                        type="button" (click)="toggleFavorite()"/> -->
                        <img class="share-recipe-icon display-none" src="/assets/base_icons/icon_share.svg" type="button" (click)="shareRecipe()"/>
                    </div>
                </div>
                <div class="info-container">
                    <div class="info-header">
                        <div class="info-wrapper">
                            <div class="info-icon">
                                <img src="/assets/base_icons/icon_yield.svg">
                            </div>
                            <div class="info-text-wrapper">
                                <p class="info-label">{{translatorSvc.getLabel('yield')}}</p>
                                <div class="yield-text">
                                    <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_yield">{{asRecipe(workflowSvc.selectedWorkflow).min_yield}}</p>
                                    <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_yield && asRecipe(workflowSvc.selectedWorkflow).max_yield"> - </p>
                                    <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_yield">{{asRecipe(workflowSvc.selectedWorkflow).max_yield}}</p>
                                </div>
                            </div>
                            <!-- <div class="portion-adjust-container display-none" [class.display-flex]="workflowSvc.selectedWorkflow.portionsMultiplier > 0">
                                <div class="portion-picto-wrapper" (click)="workflowSvc.addPortion()">
                                    <img src="/assets/base_icons/icon_add.svg">
                                </div>
                                <div class="portion-picto-wrapper" (click)="workflowSvc.substractPortion()">
                                    <img src="/assets/base_icons/icon_substract.svg">
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="info-header">
                        <div class="info-wrapper">
                            <div class="info-icon">
                                <img src="/assets/base_icons/icon_prep_time.svg">
                            </div>
                            <div class="info-text-wrapper">
                                <p class="info-label">{{translatorSvc.getLabel('prepTime')}}</p>
                                <div class="yield-text">
                                    <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_prepTime">{{asRecipe(workflowSvc.selectedWorkflow).min_prepTime}}</p>
                                    <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_prepTime && asRecipe(workflowSvc.selectedWorkflow).max_prepTime"> - </p>
                                    <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_prepTime">{{asRecipe(workflowSvc.selectedWorkflow).max_prepTime}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info-header">
                        <div class="info-wrapper">
                            <div class="info-icon">
                                <img src="/assets/base_icons/icon_cook_time.svg">
                            </div>
                            <div class="info-text-wrapper">
                                <p class="info-label">{{translatorSvc.getLabel('cookTime')}}</p>
                                <div class="yield-text">
                                    <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_cookTime">{{asRecipe(workflowSvc.selectedWorkflow).min_cookTime}}</p>
                                    <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).min_cookTime && asRecipe(workflowSvc.selectedWorkflow).max_cookTime"> - </p>
                                    <p class="info-text" *ngIf="asRecipe(workflowSvc.selectedWorkflow).max_cookTime">{{asRecipe(workflowSvc.selectedWorkflow).max_cookTime}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info-header">
                        <div class="info-wrapper">
                            <div class="info-icon">
                                <img src="{{workflowSvc.difficultyPicto}}">
                            </div>
                            <div class="info-text-wrapper">
                                <p class="info-label">{{translatorSvc.getLabel('difficulty')}}</p>
                                <p class="info-text">{{workflowSvc.difficultyText}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="allergy-container" [class.display-none] = "!asRecipe(workflowSvc.selectedWorkflow).allergyList || asRecipe(workflowSvc.selectedWorkflow).allergyList.length == 0">
                    <h4>{{translatorSvc.getLabel('contains')}} : </h4>
                    <app-allergy-item class="allergy-item" *ngFor="let allergyItem of asRecipe(workflowSvc.selectedWorkflow).allergyList" [allergy]="allergyItem"></app-allergy-item>
                </div>
            </div>
            <div class="ingredients-tools-container">
                <div class="ingredients-tools-wrapper">
                    <div class="ingredients-container">
                        <div class="ingredients-label">
                            <h3>{{translatorSvc.getLabel('ingredients')}}</h3>
                        </div>
                        <div class="ingredients-list">
                            <app-ingredient-list-item class="ingredient-list-item" *ngFor="let ingredientItem of asRecipe(workflowSvc.selectedWorkflow).ingredientList" [ingredientItem]="ingredientItem"></app-ingredient-list-item>
                        </div>
                    </div>
                    <div class="tools-container">
                        <div class="tools-wrapper">
                            <div class="tools-label">
                                <h3>{{translatorSvc.getLabel('tools')}}</h3>
                            </div>
                            <div class="tools-list">
                                <app-tool-list-item class="tool-list-item" *ngFor="let toolItem of asRecipe(workflowSvc.selectedWorkflow).toolList" [tool]="toolItem"></app-tool-list-item>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    </section>
</ng-template>

<ng-template #startRecipe>
    <h2>{{translatorSvc.getLabel('startRecipe')}}</h2>
</ng-template>