<div #checkout_modal class="shipping-container">
    <div class="shipping-header">
        <div class="nav-button" (click)="goToShoppingCart()">
            <img src="/assets/base_icons/icon_arrow_left_blue.svg">
        </div>
        <div class="shopping-cart-phone-title"><h3>{{translatorSvc.getLabel('shipping')}}</h3></div>
        <div class="empty-third-hdiv"></div>
    </div>
    <app-address-form></app-address-form>
</div>

