import { Component, Input, OnInit } from '@angular/core';
import { StepType } from '../../../enums/step-type.enum';
import { Step } from '../../../models/step.model';
import { WorkflowService } from '../../services/workflow.service';

@Component({
  selector: 'app-step-item',
  templateUrl: './step-item.component.html',
  styleUrls: ['./step-item.component.scss']
})
export class StepItemComponent implements OnInit {

  @Input() step!: Step;
  picto : string | undefined;
  
  constructor(public wfSvc : WorkflowService) { 
    
  }

  ngOnInit(): void {
    switch (this.step.stepType) {
      case StepType.Action : this.picto = "actions/"+this.step.action!.picto;
        break;
      case StepType.IngredientQty :
      case StepType.IngredientTool : this.picto = "ingredients/"+this.step.ingredient!.picto;
        break;
      case StepType.Verification : this.picto = "verifications/verification";
        break;
      case StepType.Complete :
        if(this.wfSvc.currentWorkflow.id) {
          this.picto = 'workflows/'+this.wfSvc.currentWorkflow.id;
        } else {
          this.picto = "actions/serve_and_eat"
        }
        break;
      case StepType.ItemQty : this.picto = "items/"+this.step.item!.picto;
        break;
    }
  }

}
