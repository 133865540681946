import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DisplayService } from 'src/app/tools/services/display.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';

@Component({
  selector: 'app-full-screen',
  templateUrl: './full-screen.component.html',
  styleUrls: ['./full-screen.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class FullScreenComponent implements OnInit {

  elem: any;

  constructor(
    public dialogRef: MatDialogRef<FullScreenComponent>,
    public translatorSvc : TranslatorService,
    private displaySvc : DisplayService) { }

  ngOnInit(): void {
    this.displaySvc.fullScreenAsked = true;
    this.elem = document.documentElement;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  goFS() {
    if (!this.displaySvc.chkFullScreenSize()) {
      this.displaySvc.screenFullSize(true);
      this.dialogRef.close();
    }
  }
}
