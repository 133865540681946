<div class="wf-component-container" (window:resize)="onHeightResize($event)">
    <div class="workflow-header">
        <div class="workflow-nav-wrapper">
            <div class="nav-button" (click)="goToRecipes()">
                <img src="/assets/base_icons/icon_arrow_left_blue.svg">
                <p *ngIf="workflowSvc.currentWorkflow.domain_id === 'cooking'">{{translatorSvc.getLabel('backToRecipes')}}</p>
                <p *ngIf="workflowSvc.currentWorkflow.domain_id !== 'cooking'">{{translatorSvc.getLabel('backToFamiliarization')}}</p>
            </div>
            <div class="workflow-selected-workflow-title"><h3>{{workflowSvc.selectedWorkflow.title}}</h3></div>
            <div class="empty-third-hdiv"></div>
        </div>
    </div>
    <div [class.display-none]="hideTracker">
        <app-workflow-tracker></app-workflow-tracker>
    </div>
    <div class="workflow-container">
        <div class="workflow-wrapper">
            <div id="workflow-display-swiper-container" class="swiper-container">
                <div class="swiper-wrapper">
                    <app-step (next_step)='next_step()' class="swiper-slide" *ngFor="let step of workflowSvc.displaySteps" [step]="step"></app-step>
                </div>
            </div>
            <div id="next_step_button_wrapper">
            </div>
        </div>
    </div>
    
</div>
