// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  reCapthcaV3: "6LfYnZodAAAAALB-Ut-5SZvWHDTQzaTKoRwMsiHW",
  production: false,
  stripePublishable: "pk_live_Y98u7HGpBa4EVw1Ni9amEPNQ00gBLdmIzw",
  // stripePublishable: "pk_test_apkSF7nVlev3yP8HyQjLT9k100ac9Dh1t3",
  firebaseConfig : {
    apiKey: "AIzaSyA6XxtnL-7Wk3OHMbDYg5HGLGPxoZWbqAY",
    authDomain: "teak-medium-184114.firebaseapp.com",
    databaseURL: "https://teak-medium-184114.firebaseio.com",
    projectId: "teak-medium-184114",
    storageBucket: "teak-medium-184114.appspot.com",
    messagingSenderId: "892739553065",
    appId: "1:892739553065:web:21fd5ae73798c222fd5938",
  },
  GTM_ID: "GTM-5FWJBVV"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
