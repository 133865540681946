import { KeyboardMenuItem } from "./keyboard-menu-item";

export class KeyboardMenu {

    private _keyboardMenuItems: KeyboardMenuItem[];
    private _up: any;
    private _down: any;
    private _prevFocus: any;
    private _currentKeyboardMenuItem: KeyboardMenuItem;

    constructor() {
        this._keyboardMenuItems = [];
        this._up = null;
        this._down = null;
        this._prevFocus = null;
    }

    setOptions(kBMItems) {
        this._keyboardMenuItems = kBMItems;
        this._currentKeyboardMenuItem = this._keyboardMenuItems[0];
    }

    public get keyboardMenuItems(): KeyboardMenuItem[] {
        return this._keyboardMenuItems;
    }
    public set keyboardMenuItems(value: KeyboardMenuItem[]) {
        this._keyboardMenuItems = value;
    }

    public get currentKeyboardMenuItem(): KeyboardMenuItem {
        return this._currentKeyboardMenuItem;
    }
    public set currentKeyboardMenuItem(value: KeyboardMenuItem) {
        this._currentKeyboardMenuItem = value;
    }

    
    getPages() {
        return {
            root: [
            {
                label: "Attack",
                description: "Choose an attack",
                handler: () => {
                this
                }
            },
            {
                label: "Items",
                description: "Choose an item",
                disabled: true,
                handler: () => {
                console.log("Got to items page");
                }
            },
            {
                label: "Swap",
                description: "Change to another pizza",
                handler: () => {
                // do something when  chosen
                }
            }
            ],
            attacks: [
            {
                label: "My first attack",
                description: "Does this...",
                handler: () => {
                //
                }
            }
            
            ]
        }
        } 
    

    
}