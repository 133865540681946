<div class="confirm-email-container">
    <div class="confirm-email-wrapper">
        <div class="confirm-email-title">
            <img src="/assets/base_icons/logo_autonome_orange_name_only.svg" class="register-container-logo"/>
            <hr>
            <h3>{{translatorSvc.getLabel('eulaAgreement')}}</h3>
            <p>{{translatorSvc.getText('eaulModalText')}}</p>
        </div>
        <div class="confirm-email-input">
            <p><sup>*</sup></p><input type="checkbox" name="eula_agreement" [(ngModel)]='eulaAgreement'>
            <label>{{translatorSvc.getLabel('eulaAgreementPre')}}{{translatorSvc.getLabel('eulaAgreement')}}</label>
        </div>
        <div class="confirm-email-input">
            <input type="checkbox" name="email_agreement" [(ngModel)]='emailAgreement'>
            <label>{{translatorSvc.getLabel('emailAgreement')}}</label>
        </div>
        <button (click)="submitEula($event)" class="modal-text-button"  [disabled]="!eulaAgreement">{{translatorSvc.getLabel('continue')}}
        </button>

        <iframe [src]='getSafeUrl()'></iframe>
    </div>
</div>
