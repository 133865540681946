<div class="job-question-container">
    <div *ngIf="step.stepType === 8 then multipleChoice else notMultipleChoice">
    </div>
    
    <div class="next-button-container"
        (touchstart)='disableTouchMove()'
        (touchend)='enableTouchMove()'
        (mouseenter)='overButton()'
        (mouseleave)='leaveButton()'>
        <img id="nextStepBtn" class="next-step-button"  [class.display-none]="!step.current || hideNextStep" src="/assets/base_icons/icon_step_check.svg" (click)="next_step()" alt="Go to nest step">
    </div> 
</div>


<ng-template #multipleChoice>
    <div class="job-question-wrapper">
        <p class="multi-question">{{step.multiple_choice.question}}</p>
        <div class="multi-answers" *ngFor="let answer of step.multiple_choice.choices; let j = index">
            <label>
                <input name="multiInput" type="radio" [checked]="answer.selected" [disabled]="choiceSubmitted" (change)="choiceChecked(j)">
                <p>{{answer.choice}}</p>
            </label>
        </div>
        <img *ngIf="choiceSubmitted" class="question-check-icon" [src]="step.multiple_choice.choice && !step.multiple_choice.choice.answer ? '/assets/base_icons/correct.svg' : '/assets/base_icons/error.svg'">
        <p *ngIf="wrongAnswer" class="multi-choice-answer">{{step.multiple_choice.choice.answer}}</p>
        <div class="autonome-button-orange" [class.display-none]="step.stepType === 6 || step.stepType === 7 || !hideNextStep" (click)="submitChoice()">
            <p>{{translatorSvc.getLabel('submit')}}</p></div>
    </div>
</ng-template>

<ng-template #notMultipleChoice>
    <div *ngIf="step.stepType === 7 then table else preference"></div>
</ng-template>

<ng-template #table>
    <div class="job-question-wrapper">
        <p class="table-header">{{translatorSvc.getLabel('tableHeader')}}</p>
        <br>
        <p class="table-question">{{step.table.question}}</p>
        <div class="table-answers" *ngFor="let headerItem of step.table.headers; let j = index">
            <label>
                <input name="tableInput" type="radio" [checked]="headerItem.selected" (change)="headerChecked(j)">
                <p>{{headerItem.header}}</p>
            </label>
        </div>
        <div class="autonome-button-orange" 
            [class.display-none]="step.stepType === 6 || step.stepType === 7 || !hideNextStep || !step.table.answer" 
            (click)="submitChoice()">
            <p>{{translatorSvc.getLabel('submit')}}</p></div>
    </div>
</ng-template>

<ng-template #preference>
    <div class="job-question-wrapper">
        <div class="preference-question"><p>{{step.preference.question}}</p></div>
        <label>
            <input name="preferenceInput" type="radio" (change)="preferenceChecked(0)">
            <img src="/assets/base_icons/happy.svg">
        </label>
        <label>
            <input name="preferenceInput" type="radio" (change)="preferenceChecked(1)">
            <img src="/assets/base_icons/neutral.svg">
        </label>
        <label>
            <input name="preferenceInput" type="radio" (change)="preferenceChecked(2)">
            <img src="/assets/base_icons/unhappy.svg">
        </label>
        <div class="autonome-button-orange" [class.display-none]="step.stepType === 6 || step.stepType === 7 || !hideNextStep || !choiceSubmitted" (click)="submitChoice()">
            <p>{{translatorSvc.getLabel('submit')}}</p></div>
    </div>
    
</ng-template>