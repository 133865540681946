import { AcademyService } from "../services/academy.service";
import { GameObject } from "./game-object";

export class Sprite {

    private _animations;
    private _currentAnimation: string;
    private _currentAnimationFrame: number;
    private _image: HTMLImageElement;
    private _isLoaded: boolean;
    private _gameObject: GameObject;
    private _shadow: HTMLImageElement;
    private _isShadowLoaded: boolean;
    private _useShadow: boolean;
    private _animationFrameLimit: number;
    private _animationFrameProgress: number;

    constructor(config, private academySvc : AcademyService) {

        // Setup the image
        this.image = new Image();
        this.image.src = config.src;
        this.image.onload = () => {
            this.isLoaded = true;
        }

        // Shadow
        this.shadow = new Image();
        this.useShadow = true; // config.useShadow || false
        if(this.useShadow) {
            this.shadow.src = "/assets/academy/characters/shadow.png";
        }

        this.shadow.onload = () => {
            this.isShadowLoaded = true;
        }

        //Animations
        this.animations = config.animations || {
            "idle-down" : [[0,0]],
            "walk-down" : [[1,0], [0,0], [3,0], [0,0]],
        };

        
        
        // Configure animation and initial state
        this.animations = config.animations || {
            "idle-down" : [[0,0]],
            "idle-right" : [[0,1]],
            "idle-up" : [[0,2]],
            "idle-left" : [[0,3]],
            "walk-down" : [[1,0], [0,0], [3,0], [0,0]],
            "walk-right" : [[1,1], [0,1], [3,1], [0,1]],
            "walk-up" : [[1,2], [0,2], [3,2], [0,2]],
            "walk-left" : [[1,3], [0,3], [3,3], [0,3]],
        }
        this.currentAnimation = "idle-right"; //config.currentAnimation || "idle-down";
        this.currentAnimationFrame = 0;

        // Frame cadence
        this._animationFrameLimit = config.animationFrameLimit || 4;
        this._animationFrameProgress = this._animationFrameLimit;

        // Reference the gameObject
        this.gameObject = config.gameObject;
    }

    draw(ctx: CanvasRenderingContext2D, cameraPerson) {
        const x = this.gameObject.x -8 + this.academySvc.withGrid(10.5) - cameraPerson.x;
        const y = this.gameObject.y -18 + this.academySvc.withGrid(6) - cameraPerson.y;

        const [frameX, frameY] = this.frame;

        this.isShadowLoaded && ctx.drawImage (this.shadow,x, y);
        this.isLoaded && ctx.drawImage(this.image,
            frameX * 32, frameY * 32,
            32, 32,
            x, y,
            32, 32
            );

        this.updateAnimationProgress();
    }

    get frame() {
        return this.animations[this.currentAnimation][this.currentAnimationFrame]
    }

    setAnimation(key: string) {
        if(this.currentAnimation !== key) {
            this.currentAnimation = key;
            this.currentAnimationFrame =0;
            this._animationFrameProgress = this._animationFrameLimit;
        }
    }

    updateAnimationProgress() {
        // DownTick framProgress
        if(this._animationFrameProgress > 0) {
            this._animationFrameProgress -= 1;
            return
        }

        //reset counter
        this._animationFrameProgress = this._animationFrameLimit;
        this._currentAnimationFrame += 1;
        if(this.frame === undefined) {
            this.currentAnimationFrame = 0;
        }
    }

    public get useShadow(): boolean {
        return this._useShadow;
    }
    public set useShadow(value: boolean) {
        this._useShadow = value;
    }

    public get isShadowLoaded(): boolean {
        return this._isShadowLoaded;
    }
    public set isShadowLoaded(value: boolean) {
        this._isShadowLoaded = value;
    }
    
    public get shadow(): HTMLImageElement {
        return this._shadow;
    }
    public set shadow(value: HTMLImageElement) {
        this._shadow = value;
    }

    public get isLoaded(): boolean {
        return this._isLoaded;
    }
    public set isLoaded(value: boolean) {
        this._isLoaded = value;
    }

    public get image(): HTMLImageElement {
        return this._image;
    }
    public set image(value: HTMLImageElement) {
        this._image = value;
    }

    get animations() {
        return this._animations;
    }
    set animations(value: any) {
        this._animations = value;
    }

    public get currentAnimation(): string {
        return this._currentAnimation;
    }
    public set currentAnimation(value: string) {
        this._currentAnimation = value;
    }

    public get currentAnimationFrame(): number {
        return this._currentAnimationFrame;
    }
    public set currentAnimationFrame(value: number) {
        this._currentAnimationFrame = value;
    }

    public get gameObject(): GameObject {
        return this._gameObject;
    }
    public set gameObject(value: GameObject) {
        this._gameObject = value;
    }
}