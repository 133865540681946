import { SpinnerOverlayComponent } from '../components/spinner-overlay/spinner-overlay.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {

  private overlayRef!: OverlayRef;

  constructor(private overlay: Overlay) {}

  _active : boolean = false;
  private _text: string;

  private _priority: number = 0;

  public show(message: string = '') {
    if(!this._active) {

      if (!this.overlayRef) {
        this.overlayRef = this.overlay.create();
      }
      this._text = message;
      const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
      this.overlayRef.attach(spinnerOverlayPortal);
      this._active = true;
    }
  }

  public hide(priority: number = 0) {
    if(this._priority > 0) {
      if(priority >= this._priority) {
        if (!!this.overlayRef) {
          this.overlayRef.detach();
          this._active = false;
          this._priority = 0;
        }
      }
    } else {
      if (!!this.overlayRef) {
        this.overlayRef.detach();
        this._active = false;
      }
    }
  }

  get active() {
    return this._active;
  }

  public get text(): string {
    return this._text;
  }
  public set text(value: string) {
    this._text = value;
  }

  public set priority(value: number) {
    this._priority = value;
  }
}
