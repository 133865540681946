
<div class="step-item-overlay" [class.current]="wfSvc.isMainCurrent(step)"></div>
<div class="step-item-container" [class.current-step]="wfSvc.isMainCurrent(step)">
    <div class="step-item-picto">
        <img src="/assets/pictos/{{picto}}.svg">
    </div>
    <div class="step-item-completed-steps">
        <p>{{step.stepsCompleted}}/{{step.stepsTotal}}</p> 
    </div>

</div>

