import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';

@Component({
  selector: 'app-job-example-results',
  templateUrl: './job-example-results.component.html',
  styleUrls: ['./job-example-results.component.scss']
})
export class JobExampleResultsComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) modalData: object,
    private dialogRef: MatDialogRef<JobExampleResultsComponent>,
    private matDialog: MatDialog,
    public workflowSvc : WorkflowService,
    public translatorSvc : TranslatorService) {
      
    }

    closeModal() {
      this.dialogRef.close(false);
    }

    get somethingBothersMe() {
      return this.workflowSvc.table.find((obj) => {
        return (obj.answer === 1 || obj.answer === 2);
      });
    }
}
