<div class="item-qty-container">
    <div *ngIf="authSvc.User.accountSettings.competency < 2 then sous_chef else chef"></div>
    <!-- <div class="item-qty-wrapper">
        <div class="item-picto-wrapper" [class.qty-four-less]="step.quantity < 5">
            <img *ngFor="let image of pictoString" src="{{image}}">
        </div>
        <div class="item-qty-text-wrapper">
            <h1 class="item-qty">{{step.quantity}}</h1>
            <h2>{{step.item!.description!}}</h2>
        </div>
    </div> -->
    <div class="next-button-container"
    (touchstart)='disableTouchMove()'
    (touchend)='enableTouchMove()'
    (mouseenter)='overButton()'
    (mouseleave)='leaveButton()'>
        <img class="next-step-button" src="/assets/base_icons/icon_step_check.svg" [class.hideNextStepBtn]="!step.current" (click)="next_step()" alt="Go to nest step">
    </div> 
</div>
    

<ng-template #chef>
    <div class="chef-item-container">
        <div class="chef-item-wrapper">
            <div class="chef-item-qty-wrapper">
                <div class="chef-item-picto-wrapper" [class.chef-qty-four-less]="step.quantity < 5">
                    <img *ngFor="let image of pictoString" src="{{image}}">
                </div>
                <div class="chef-item-qty-text-wrapper" [class.qty-description-four-less]="step.quantity < 5">
                    
                    <h2 class="chef-item-qty">{{step.quantity}}</h2>
                    
                    <h4>{{step.item!.description!}}</h4>
                </div>
            </div>
        </div>
        <hr class="displayNoneTablet">
        <div class="action-wrapper">
            <div class="action-list">
                <!-- <app-action-list-item class="action-list-item" *ngFor="let stepItem of step.actionSteps" [stepItem]="stepItem"></app-action-list-item> -->
            </div>
        </div>

    </div>
    
</ng-template>


<ng-template #sous_chef>
    <div class="item-qty-wrapper">
        <div class="item-picto-wrapper" [class.qty-four-less]="step.quantity < 5">
            <img *ngFor="let image of pictoString" src="{{image}}">
        </div>
        <div class="item-qty-text-wrapper">
            <h1 class="item-qty">{{step.quantity}}</h1>
            <h2>{{step.item!.description!}}</h2>
        </div>
    </div>
</ng-template>
