import { TranslatorService } from '../../../tools/services/translator.service';
import { TimerService } from '../../../modal/services/timer.service';
import { WorkflowService } from '../../services/workflow.service';
import { StepWrapperComponent } from '../../../interfaces/step-wrapper.component';
import { Component, Input, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Step } from '../../../models/step.model';
import { Observable, Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserConfirmationComponent } from '../../../modal/components/user-confirmation/user-confirmation.component';
import { AuthService } from 'src/app/admin/services/auth.service';
import { DisplayService } from 'src/app/tools/services/display.service';

@Component({
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements StepWrapperComponent, AfterViewInit, OnInit, OnDestroy {
  @Input() step: Step;

  cycleTime : number;
  stepCycleTime : number = 0;
  
  passiveTimeRunning : boolean = false;
  timerMinutes : string = " ";
  timerSeconds : string = " ";
  hideTime : boolean = true;
  hideNextStepBtn : boolean = false;
  timer_description_suffix! : string;
  actionDescription : string | undefined;
  
  pausedTime$ : Observable<number>;
  timerEventsubscription:Subscription;
  timerExpiredSubscription:Subscription;

  //@ViewChild('videoPlayerDots', { static: true }) videoPlayerDots: ElementRef;
  
  constructor(
    private authSvc: AuthService,
    private wfSvc: WorkflowService,
    public timerSvc : TimerService,
    private translatorSvc : TranslatorService,
    public matDialog: MatDialog,
    public displaySvc : DisplayService) {
  }

  ngOnDestroy(): void {
/*     if(this.timerEventsubscription) {
      this.timerEventsubscription.unsubscribe();
    }
    if(this.mainTimerObservable) {
      
    } */
    // Handle exit of component when timer was running.
    if(this.step.time && this.step.time > 0 && this.timerSvc.timerRunning) {
      this.pausedTime$ = this.timerSvc.currentTime;
      this.pausedTime$.subscribe(time => {
        this.wfSvc.pausedTime = time;
      });
      if(!this.timerSvc.timerPaused) {
        this.timerSvc.notifications[this.timerSvc.mainTimerId].paused.next(true);
        this.timerSvc.timerPaused = true;
        if(this.timerSvc.passiveTimerId && this.timerSvc.passiveTimerId !== 0) {
          this.timerSvc.notifications[this.timerSvc.passiveTimerId].paused.next(true);
        }
      }
      if (!this.wfSvc.pausedTimerId) {
        this.timerSvc.stopAllTimers();
      }
    }
    /* else {
      this.timerSvc.mainTimerId = 0;
    }
    this.passiveTimeRunning = false;
    if(this.timerSvc.passiveTimerId && this.timerSvc.passiveTimerId !== 0) {
      this.timerSvc.stopTimer(this.timerSvc.passiveTimerId);
    } */
    
  }

  ngOnInit(): void {
    this.actionDescription =this.step.action?.description;
  }

  ngAfterViewInit(): void {
    if(this.step.time && this.step.time > 0) {
      if(!this.timerSvc.timerRunning) {
        if(!this.timerEventsubscription) {
          this.timerEventsubscription = this.wfSvc.getStartTimerEvent().subscribe(()=> {
            if(this.wfSvc.getWFStepById(this.step._display_id) && this.wfSvc.getWFStepById(this.step._display_id).current) {
              if(this.actionDescription !== this.step.action?.description) {
                this.actionDescription = this.step.action?.description;
              }
              this.hideNextStepBtn = true;
              if (this.step.cycle_steps && this.step.cycle_steps[0]) {
                this.addTimer(this.step.time, this.step.passive_time, this.step.cycle_steps[0].time);
              } else {
                this.addTimer(this.step.time);
              }
              if(!this.timerExpiredSubscription) {
                this.timerExpiredSubscription = this.timerSvc.getTimerStoppedSubjectEvent().subscribe(()=> {
                  this.wfSvc.getWFStepById(this.step._display_id).timerFlag = true;
                  this.hideTime = true;
                  this.hideNextStepBtn = false;
                  this.getStopTimerDescription();
                  //this.videoPlayerDots.nativeElement.pause(); 
                });
              }
            }
          });
        } 
      } else {
        this.hideTime = false;
        this.hideNextStepBtn = true;
        this.addTimer(this.wfSvc.pausedTime);
        if(!this.timerExpiredSubscription) {
          this.timerExpiredSubscription = this.timerSvc.getTimerStoppedSubjectEvent().subscribe(()=> {
            this.wfSvc.getWFStepById(this.step._display_id).timerFlag = true;
            this.hideTime = true;
            this.hideNextStepBtn = false;
            this.getStopTimerDescription();
            //this.videoPlayerDots.nativeElement.pause(); 
          });
        }
      }
    }
  }

  addTimer(mainTime: number, passiveTime: number | undefined = undefined, cycleTime: number|undefined = undefined) {
    this.timerSvc.timerButton = "/assets/base_icons/time_pause.svg";
    this.actionDescription =this.step.action?.description;
    this.timerSvc.addTimer(mainTime, passiveTime, cycleTime);

    this. hideTime = false;
    switch(this.authSvc.User.accountSettings.language) {
      case 'en' : this.timer_description_suffix = " for";
        break;
      case 'fr' : this.timer_description_suffix = " pendant";
        break;
    }
    this.actionDescription = this.actionDescription  + this.timer_description_suffix;
  }

  next_step() {
    this.wfSvc.sendNextStepEvent(this.step._display_id, true);
  }

  overButton() {
    // handle mouse and touch events through eventListener
    this.wfSvc.sendDisableSwiperEvent();
  }

  leaveButton() {
    this.wfSvc.sendEnableSwiperEvent();
  }

  disableTouchMove() {
    this.wfSvc.sendDisableTouchMoveEvent();
  }

  enableTouchMove() {
    this.wfSvc.sendEnableTouchMoveEvent();
  }

  startPauseTimer() {
    this.timerSvc.timerPaused = !this.timerSvc.timerPaused;
    this.timerSvc.notifications[this.timerSvc.mainTimerId].paused.next(this.timerSvc.timerPaused);
    this.timerSvc.timerButton = this.timerSvc.timerPaused ? "/assets/base_icons/time_play.svg" : "/assets/base_icons/time_pause.svg";
  }

  stopTimer() {
    // make the modal come up to see if the user wants to go to the next step
    if(!this.timerSvc.timerPaused) {
      this.timerSvc.notifications[this.timerSvc.mainTimerId].paused.next(true);
      this.timerSvc.timerPaused = true;
      this.openConfModal();
      if(this.timerSvc.passiveTimerId && this.timerSvc.passiveTimerId !== 0) {
        this.timerSvc.notifications[this.timerSvc.passiveTimerId].paused.next(true);
      }
    }
  }

  openConfModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "stopTimer",
      message: this.translatorSvc.getLabel('confirmTimerStop'),
      actionButtonText1 : this.translatorSvc.getLabel('no'),
      actionButtonText2 : this.translatorSvc.getLabel('yes'),
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result) {
        this.wfSvc.getWFStepById(this.step._display_id).timerFlag = true;
        this.passiveTimeRunning = false;
        this.hideNextStepBtn = false;
        //this.videoPlayerDots.nativeElement.pause();
        this.hideTime = true;
        this.timerSvc.stopTimer(this.timerSvc.mainTimerId);
        if(this.timerSvc.passiveTimerId && this.timerSvc.passiveTimerId !== 0) {
          this.timerSvc.stopTimer(this.timerSvc.passiveTimerId);
        }
        this.timerSvc.timerPaused = false;
        this.getStopTimerDescription();
      } else {
        this.timerSvc.notifications[this.timerSvc.mainTimerId].paused.next(false);
        this.timerSvc.timerPaused = false;
      }
    });
  }
  

  getStopTimerDescription() {
    this.actionDescription = this.translatorSvc.getLabel('timeNextStep');
  }
}
