<div #login_modal class="pw-reset-container">
    <div class="pw-reset-wrapper">
        <div class="top-wrapper">
            <div class="login-modal-title">
                <h3>{{translatorSvc.getLabel('pwdResetTitle')}}</h3>
            </div>
        </div>
        <div class="form-wrapper">
            <form class="login-input-field-wrapper" (ngSubmit)= "onSubmit($event)">
                <div class="input-field-wrapper">
                    <input name="emailInput" class="modal-input-field" placeholder="Email" [(ngModel)]="email" #prmEmail="ngModel" required autofocus>
                </div>
                <div *ngIf="prmEmail.errors?.email"> 
                    {{translatorSvc.getLabel('emailInvalid')}}
               </div>
                <div class="input-field-wrapper">
                    <button type="submit" class="modal-text-button margin-0">{{translatorSvc.getLabel('submit')}}</button>
                    <button type="button" (click)="closeDialog()" class="modal-text-button margin-left-10">{{translatorSvc.getLabel('cancel')}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
