import { Actions } from "../content/actions"
import { AcademyService } from "../services/academy.service";

export class TurnCycle {

    private _onNewEvent: any;

    constructor({onNewEvent}, private academySvc: AcademyService) {
        this._onNewEvent = onNewEvent;
    }

    async turn() {
        const submission = await this._onNewEvent({
            type: "submissionMenu"
        });
        
        const resultingEvents = this.academySvc.caster.getReplacedEvents(Actions[this.academySvc.action].success);
        for(let i=0; i<resultingEvents.length; i++) {
            const event = {
                ...resultingEvents[i],
                submission
            }
            await this._onNewEvent(event);
        }

        // check for post events
        const postEvents = this.academySvc.caster.getPostEvents();
        for(let i=0; i<postEvents.length; i++) {
            const event = {
                ...postEvents[i],
                submission
            }
            await this._onNewEvent(event);
        }

        // check status expired
        const expiredEvent = this.academySvc.caster.decrementStatus();
        if(expiredEvent) {
            await this._onNewEvent(expiredEvent);
        }

        this.academySvc.currentTeam = this.academySvc.currentTeam === "player"?"enemy":"player";
        this.turn();
    }

    async init() {
        /* await this._onNewEvent({
            type: "textMessage",
            text: "The battle has begun"
        }); */

        // start first turn
        this.turn();
    }
}