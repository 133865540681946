<div *ngIf="!completeWfItem && workflowItem.index > 0" class="workflow-item-container" [class.selected]="workflowItem.index === editorSvc.newWorkflowSelectedIndex">
    <div class="workflow-item-picto">
        <img src="{{pathString}}">
        <div class="workflow-item-title">{{wfName}}</div>
    </div>
    <div class="workflow-item-modifier-button-container">
        <!-- <div class="workflow-item-modifier-button">
            <img src="/assets/base_icons/icon_pencil_edit_charcoal.svg">
        </div> -->
        <div (click)="removeWorkflowItem()" class="workflow-item-modifier-button">
            <img src="/assets/base_icons/icon_close_charcoal.svg">
        </div>
    </div>
</div>
