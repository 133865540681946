import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EditorService } from 'src/app/editor/services/editor.service';

@Component({
  selector: 'app-new-workflow',
  templateUrl: './new-workflow.component.html',
  styleUrl: './new-workflow.component.scss'
})
export class NewWorkflowComponent {

  newWfName: string;

  constructor(
    public dialogRef: MatDialogRef<NewWorkflowComponent>,
    public editorSvc: EditorService,
  ) {}

  createNewWorkflow() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
