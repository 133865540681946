import { WorkflowCategoryI } from '../interfaces/wokflow-category.interface';
import { Recipe } from './recipe.model';
import { Workflow } from './workflow.model';

export class WorkflowCategory implements WorkflowCategoryI {

    category_name : string;
    title_en : string;
    title_fr : string;
    private _title : string;
    private _workflows: (Recipe | Workflow)[];
    private _selected : boolean = false;

    constructor(data: object) {
        this.category_name = Object.keys(data)[0];
        this.title_en = Object(data)[this.category_name].title_en;
        this.title_fr = Object(data)[this.category_name].title_fr;
        this._workflows = [];
    }

    get selected() {
        return this._selected;
    }
    set selected(value: boolean) {
        this._selected = value;
    }

    public get workflows(): (Recipe | Workflow)[] {
        return this._workflows;
    }
    public set workflows(value: (Recipe | Workflow)[]) {
        this._workflows = value;
    }

    public setTitle(language: string) {
        switch(language) {
            case 'fr': this._title = this.title_fr;
            break;
            default: this._title = this.title_en;
        };
    }

    public getTitle(language: string) {
        switch(language) {
            case 'fr': return this.title_fr;
            break;
            default: return this.title_en;
        };
    }
}