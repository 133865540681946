import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, Subject, Subscription, firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from '../../admin/services/auth.service';
import { Product } from '../../models/product.model';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { ItemI } from 'src/app/interfaces/item.interface';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private _products: Array<Product>;
  _userLocale : string;
  private _selected_plan : Product;
  loginEvent: Subscription;

  domainsToPurchase : string[] = [];
  workflowsToPurchase : string[] = [];
  itemsToPurchase : ItemI[] = [];

  private _purchaseTotal = 0;

  constructor(
    private fns: AngularFireFunctions,
    private spinnerSvc : SpinnerOverlayService,
    private authSvc : AuthService) { 
    
  }

  addDomainToCart(domainId : string) {
    if(this.domainsToPurchase.find((obj) => {
      return obj === domainId
    }) === undefined) {
      this.domainsToPurchase.push(domainId);
      this._purchaseTotal++;
    }
  }

  addWorkflowsToCart(workflowId : string) {
    if(this.workflowsToPurchase.find((obj) => {
      return obj === workflowId
    }) === undefined) {
      this.workflowsToPurchase.push(workflowId);
      this._purchaseTotal++;
    }
  }

  addItemToCart(value : object) {
    const item = Object(value);
    if(!this.itemsToPurchase.some((obj: ItemI) => obj.id === item.id)) {
      if(this.domainsToPurchase.length > 0 && this.domainIsInCart(item.domain_id)) {
        this.domainsToPurchase.splice(this.domainsToPurchase.indexOf(item.domain_id), 1);
        this._purchaseTotal--;
      }
      this.itemsToPurchase.push(item);  
    } else {
      this.itemsToPurchase.find((obj : ItemI) => obj.id === item.id).quantity += item.quantity;
    }
    this._purchaseTotal = this.itemsToPurchase[0].quantity;
  }

  itemIsInCart(domainId : string) {
    return this.itemsToPurchase.find((obj) => {
      return Object(obj).domain_id === domainId;
    });
  }

  domainIsInCart(domainId : string) {
    return this.domainsToPurchase.find((obj) => {
      return obj === domainId;
    });
  }

  /* async applyPromoCode(promoCode: string) {
    this.spinnerSvc.show();
    const promo = Object(await this.getPromoCode(promoCode));
    this.spinnerSvc.hide();
    if(promo) {
      const promoKey = Object.keys(promo)[0];
      // TODO : Treat the subscriptions with the promocode as well
      for (const item of this.itemsToPurchase) {
        if(Object(item).id === promoKey) {
          if (promo.price) {
            Object(item).price = promo.price;
          }
          // TODO : handle shipping
          if(promo.taxes_included) {
            Object(item).taxable = false;
          }
        }
      }
    } else {
      // handle no promo by message
    }
  }  */


  get products() {
    return this._products;
  }

  private getProducts(product: string) : Promise<object> {
    const productCall = this.fns.httpsCallable('stripeGetProducts');
    return firstValueFrom(productCall({product:product}).pipe(first()));
  }

  private getPrices() : Promise<object> {
    const priceCall = this.fns.httpsCallable('stripeGetPrices');
    return firstValueFrom(priceCall({}).pipe(first()));
  }

  private getStripeCustomer() : Promise<object> {
    const customerCall = this.fns.httpsCallable('stripeGetCustomer');
    return firstValueFrom(customerCall({}).pipe(first()));
  }

  public getProductByName(product_name: string){
    for(const product of this._products) {
      if(product.name === product_name) {
        return product;
      }
    }
    return null;
  }

  public getProductById(product_id: string) {
    for(const product of this._products) {
      if(product.id === product_id) {
        return product;
      }
    }
    return null;
  }

  public assignProductToUser(productId : string) {
    if(!this.authSvc.User.products) {
      this.authSvc.User.products = [];
      this.authSvc.User.products.push(productId);
    }
    
  }

  get selectedPlan() {
    return this._selected_plan;
  }
  set selectedPlan(product : Product) {
    this._selected_plan = product;
  }

  public get purchaseTotal() {
    return this._purchaseTotal;
  }
  public set purchaseTotal(value) {
    this._purchaseTotal = value;
  }

  private subject = new Subject<string>();
  sendProductAssignedEvent() {
    this.subject.next(this.authSvc.User.productId);
  }
  getProductAssignedEvent(): Observable<string>{ 
    return this.subject.asObservable();   
  }

  private getPromoCode(promoCode: string) : Promise<object> {
    const paymentsGetInvoiceCall = this.fns.httpsCallable('httpsGetPromoCode');
    return firstValueFrom(paymentsGetInvoiceCall({promoCode:promoCode}).pipe(first()));
  }

}