import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/admin/services/auth.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent{

  passwordPrimary : string;
  passwordConfirm : string;
  formValid : boolean;

  constructor(
    public dialogRef: MatDialogRef<SetPasswordComponent>,
    public translatorSvc : TranslatorService,
    private authSvc : AuthService
  ) { }

  pwPrimaryFocusOut() {}

  pwConfirmFocusOut() {}

  setPassword() {
    this.authSvc.updatePassword(this.passwordConfirm).then((userRecord) => {
      if(userRecord) {
        this.dialogRef.close();
        this.authSvc.signOut();
        this.authSvc.emailPwLogin(Object(userRecord).email, this.passwordConfirm);
      }
    }).catch((error) => {
      console.log('pw update error', error);
      this.authSvc.signOut();
      this.dialogRef.close();
    })
  }

  closeModal(){
    this.authSvc.signOut();
    this.dialogRef.close();
  }
}
