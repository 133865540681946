export const PizzaTypes = {
    normal: "normal",
    spicy: "spicy",
    veggie: "veggie",
    fungi: "fungi",
    chill : "chill"
}

export const Pizzas = {
    "s001": {
        name: "Slice Samurai",
        type: PizzaTypes.spicy,
        src: "/assets/academy/characters/pizzas/s001.png",
        icon: "/assets/academy/icons/spicy.png",
        actions: [ "clumsyStatus", "damage1"], 
    },
    "v001": {
        name: "Call Me Kale",
        type: PizzaTypes.veggie,
        src: "/assets/academy/characters/pizzas/v001.png",
        icon: "/assets/academy/icons/veggie.png",
        actions: [ "damage1"], 
    },
    "f001": {
        name: "Portobello Express",
        type: PizzaTypes.fungi,
        src: "/assets/academy/characters/pizzas/f001.png",
        icon: "/assets/academy/icons/fungi.png",
        actions: [ "damage1"], 
    },
}