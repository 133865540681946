import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Step } from '../../../models/step.model';
import { WorkflowService } from '../../services/workflow.service';
import { Swiper } from 'swiper';

@Component({
  selector: 'app-workflow-tracker',
  templateUrl: './workflow-tracker.component.html',
  styleUrls: ['./workflow-tracker.component.scss']
})
export class WorkflowTrackerComponent implements OnInit, AfterViewInit, OnDestroy {

  currentStepIndex! : number;
  wf_display_steps!:Step[];
  initialCheckStepId! : number;
  wfDisplaySwiper! : Swiper;

  nextStepEventsubscription:Subscription;
  continueWorkflowEventSubscription: Subscription;
  
  constructor(private workflowSvc : WorkflowService) { 
    this.nextStepEventsubscription = this.workflowSvc.getNextStepEvent()
    .subscribe(() => {
      this.next_step();
    });

    if(this.continueWorkflowEventSubscription === undefined || this.continueWorkflowEventSubscription.closed) {
      this.continueWorkflowEventSubscription = this.workflowSvc.getContinueWorkflowEvent()
      .subscribe((id) => {
        this.wfDisplaySwiper.slideTo(id);
      }); 
    }
    
  }
  
  ngOnInit(): void {
    if(this.wf_display_steps === undefined || this.wf_display_steps.length === 0) {
      this.wf_display_steps = this.workflowSvc.wfDisplaySteps;
    }
    if(this.workflowSvc.currentStepId ===0) {
      this.resetSteps();
    }
    if(this.workflowSvc.currentStepId > 0) {
      this.currentStepIndex = this.getCurrentWfDisplayStep(this.workflowSvc.currentStepId);
    } else {
      this.currentStepIndex = 0
    }
    this.wf_display_steps[this.currentStepIndex].current = true;
  }

  ngAfterViewInit(): void {
    this.wfDisplaySwiper = new Swiper("#workflow-tracker-display-swiper-container", {
      slideClass : 'display-workflow-swiper-slide',
      //spaceBetween: 10,
      slidesPerView: 'auto',
      centeredSlides: true,
      centeredSlidesBounds: true,
      loop: false
      });
      this.wfDisplaySwiper.slideTo(0);
  }

  next_step() {
    // validate if the wfsvc current step id is that of a main step
    if(this.isWfDisplayStep(this.workflowSvc.currentStepId)) {
      if(this.wf_display_steps[this.currentStepIndex]._display_id > this.workflowSvc.currentStepId) {
        this.resetCheckSteps();
        this.currentStepIndex = this.getStepIndexById(this.workflowSvc.currentStepId);
      } else {
        this.wf_display_steps[this.currentStepIndex].stepsCompleted++;
        this.wf_display_steps[this.currentStepIndex].current = false;
        this.currentStepIndex++;
      }
      this.wf_display_steps[this.currentStepIndex].current = true;
      this.wfDisplaySwiper.slideTo(this.currentStepIndex);
    } else {
      this.wf_display_steps[this.currentStepIndex].stepsCompleted++;
    }    
  }

  private resetCheckSteps() {
    for(let i=this.workflowSvc.currentStepId; i<this.currentStepIndex; i++) {
      if(this.isWfDisplayStep(i)) {
        this.wf_display_steps[i].completed = false;
        if(this.wf_display_steps[i].stepsCompleted > 0){
          this.wf_display_steps[i].stepsCompleted = 0;
        }
      }
    }
  }

  private resetSteps() {
    for(const step of this.wf_display_steps) {
      step.current = false;
      step.completed = false;
      step.stepsCompleted = 0;
    }
  }

  private isWfDisplayStep(id : number) : boolean{
    for(const step of this.wf_display_steps) {
      if(id === step._display_id) {
        return true;
      }
    }
    return false;
  }

  private getStepIndexById(id : number) {
    for(let i = 0; i <= this.wf_display_steps.length; i++) {
      if(this.wf_display_steps[i]._display_id === id) {
        return i;
      }
    }
    return null;
  }

  private getCurrentWfDisplayStep(index : number) {
    let currentIndex = 0;
    for(let i = 0; i <= index; i++) {
      if(i < this.wf_display_steps.length) {
        if(this.wf_display_steps[i]._display_id <= index) {
          currentIndex = i;
        }
      }
    }
    return currentIndex;
  }
  
  ngOnDestroy() {
    this.nextStepEventsubscription.unsubscribe();
  }

}
