import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import getUserLocale from 'get-user-locale';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { User } from '../../../models/user.model';
import { TranslatorService } from '../../../tools/services/translator.service';
import { UserConfirmationComponent } from '../user-confirmation/user-confirmation.component';
import { AuthService } from 'src/app/admin/services/auth.service';
import { Observable } from 'rxjs';
import { AuthCodeComponent } from '../auth-code/auth-code.component';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  user: Observable<User>;
  email: string;
  password : string;
  emailSent = false;
  promoTextVisible: boolean = false;
  emailValid: boolean = false;
  pwValid: boolean = false;
  pwFocusOutOnce: boolean = false;
  showPwErrorMsg: boolean = true;
  formValid: boolean = false;
  public registerText : string;
  errorMessage: string;
  _userLocale: string = 'en';
  beta : boolean = false;
  domains: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) modalData: object,
    public dialogRef: MatDialogRef<RegisterComponent>,
    public matDialog: MatDialog,
    public translatorSvc : TranslatorService,
    private spinnerSvc : SpinnerOverlayService,
    public authSvc : AuthService,
    private router: Router
    ) { 
      this.promoTextVisible = Object(modalData).promotionTextVisible;
      this.registerText = Object(modalData).registerText;
      if(Object(modalData).beta) {
        this.beta = Object(modalData).beta;
      }
      this.beta = Object(modalData).beta;
      if(Object(modalData).domains) {
        this.domains = Object(modalData).domains;
      }
      

      if(this.authSvc.User) {
        this._userLocale = this.authSvc.User.accountSettings.language;
      } else {
        this._userLocale = getUserLocale();
        if(this._userLocale.length>2) {
            this._userLocale = this._userLocale.slice(0,2);
        }
      }
    }


  loginGoogle() {
    this.authSvc.googleAuth(this.beta, this.domains);
    this.closeRegister();
  }

  closeRegister() {
    this.dialogRef.close(false);
  }

  openConfModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "inform-email-sent";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      email: this.email,
      pw: this.password,
      message: "authCodeText",
    };
    this.matDialog.open(AuthCodeComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }

  async userExists() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-error-user-exists";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "modal-error-user-exists",
      actionButtonText1 : this.translatorSvc.getLabel('close'),
      message : this.translatorSvc.getFirebaseMessage('user-exists'),
      actionButtonText2 : this.translatorSvc.getLabel('authCodeResend'),
      email : this.email,
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }

  createEmailUser() {
    if(this.validateForm()) {
      this.spinnerSvc.show(this.translatorSvc.getLabel('processingRequest'));
      this.authSvc.createNewEmailUser(this.email, this.password, this.beta, this.domains).then(() => {
        this.spinnerSvc.hide();
        this.closeRegister();
        this.openConfModal();
      }).catch((error) => {
        this.closeRegister();
        if(error.message === "The email address is already in use by another account.") {
          // email exists but no verified

          this.userExists();
        }
        this.spinnerSvc.hide();
        console.log(error);
      });
    }
  }

  validateEmail(email : string) {
    const regularExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

  emailFocusOut(email: string) {
    this.emailValid = this.validateEmail(email);
    this.formValid = this.emailValid && this.pwValid;
  }

  validatePw(pw: string) {
    if(pw.length >= 6) {
      return true;
    }
    return false;
  }

  pwFocusOut() {
    this.pwFocusOutOnce = true;
    this.formValid = this.emailValid && this.pwValid;
  }

  onKeyup() {
    this.pwValid = this.validatePw(this.password);
    this.formValid = this.emailValid && this.pwValid;
  }

  validateForm() {
    if(this.validateEmail(this.email) && this.validatePw(this.password)) {
      return true;
    }
    return false;
  }

  goHome() {
    this.router.navigate(['/home']);
    this.closeRegister();
  }

}
