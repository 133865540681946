import { AfterContentChecked, AfterViewInit, Component, Directive, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { KeyboardMenuItem } from '../../models/keyboard-menu-item';
import { AcademyService } from '../../services/academy.service';
import { KeyPressListener } from '../../models/key-press-listener';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-submission-menu-item',
  templateUrl: './submission-menu-item.component.html',
  styleUrls: ['./submission-menu-item.component.scss']
})

export class SubmissionMenuItemComponent {

  @ViewChild('subButton', {read: ElementRef, static: true}) subButton;
  @Input() keyboardMenuItem : KeyboardMenuItem;
  keyboardSubmitEvent: Subscription

  constructor(private academySvc: AcademyService) {
    if(!this.keyboardSubmitEvent) {
      this.keyboardSubmitEvent = this.academySvc.getKeyboardMenuSelectEvent().subscribe((index) => {
        if(index === this.academySvc.returnMenuIndexOf(this.keyboardMenuItem.label)) {
          this.setFocus();
        }
      })
    }
  }


  setFocus() {
    this.academySvc.keyBoardMenu.currentKeyboardMenuItem = this.academySvc.keyBoardMenu.keyboardMenuItems[this.academySvc.returnMenuIndexOf(this.keyboardMenuItem.label)]
    this.subButton.nativeElement.focus();
    this.academySvc.menuText = true;
    this.academySvc.textMessage = this.keyboardMenuItem.description;
  }

}
