import { TranslatorService } from '../../../tools/services/translator.service';
import { ModalService } from '../../services/modal.service';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router} from '@angular/router';
@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.scss']
})
export class 
UserConfirmationComponent {

  modalData : object;
  hide_button: boolean = false;
  check_box: boolean = false;
  message: string;
  actionButtonText1 : string;
  actionButtonText2 : string;
  check_box_message : string;

  @ViewChild('confirmation_modal', {static: false}) confModal: ElementRef;
  
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<UserConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) modalData: object,
    public modalSvc: ModalService,
    public matDialog: MatDialog,
    public translatorSvc : TranslatorService) {
      this.modalData = modalData;
      this.actionButtonText1 = Object(modalData).actionButtonText1;
      this.actionButtonText2 = Object(modalData).actionButtonText2;
      this.message = Object(modalData).message;
      this.check_box_message = Object(modalData).check_box_message;
      if(Object(modalData).hideButton2) {
        this.hide_button = Object(modalData).hideButton2;
      }
    }

  actionFunction1() {
    if(Object(this.modalData).check_box_message) {
      if (this.modalSvc.chk_box) {
        localStorage.setItem("autonome_modal_hide", 'true');
      }
    }
    switch(Object(this.modalData).actionButtonText1) {
      case "Cancel":
      case "Annuler":
      case "No":
      case "Non":
      case "Continue":
      case "Continuer":
      case "Close":
      case "Fermer":
        if(Object(this.modalData).name == 'modal-added-promo-to-cart') {
          this.dialogRef.close(true);
        }
        this.closeModal();
        if(Object(this.modalData).name === 'modal-inital-settings-update' || Object(this.modalData).name === 'open-familiarization-component') {
          this.router.navigate(['recipes']);
        }
        break;
      default :
        this.modalSvc.modalAction(this.modalData, 0);
        this.dialogRef.close(true);
        if(Object(this.modalData).name === 'upgradePlan') {
          this.router.navigate(['products']);
        }
    }
  }

  actionFunction2() {
    if(Object(this.modalData).check_box_message) {
      if (this.modalSvc.chk_box) {
        localStorage.setItem("autonome_modal_hide", 'true');
      }
    }
    switch(Object(this.modalData).actionButtonText2) {
      case "Cancel":
      case "Annuler":
      case "No":
      case "Non":
      case "Continue":
      case "Continuer":
      case "Close":
      case "Fermer":
        this.closeModal();
        if(Object(this.modalData).name === 'modal-inital-settings-update' || Object(this.modalData).name === 'open-familiarization-component') {
          this.router.navigate(['recipes']);
        }
        break;
      default:
        this.modalSvc.modalAction(this.modalData, 1);
        this.dialogRef.close(true);
        
    }

  }

  closeModal() {
    this.dialogRef.close(false);
  }

}
