import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Step } from 'src/app/models/step.model';
import { WorkflowService } from '../../../core/services/workflow.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { StepWrapperComponent } from 'src/app/interfaces/step-wrapper.component';

@Component({
  selector: 'app-job-video',
  templateUrl: './job-video.component.html',
  styleUrls: ['./job-video.component.scss']
})
export class JobVideoComponent implements StepWrapperComponent, AfterViewInit, OnInit, OnDestroy{
  @Input() step: Step;

  @ViewChild('videoPlayer', { static: true }) videoPlayer!: ElementRef;
  videoHeader : string | undefined;
  videoUrlMp4 : string | undefined;
  videoUrlWebM : string | undefined;
  playBtnBackground : string;
  
  constructor(
    private wfSvc: WorkflowService,
    private translatorSvc : TranslatorService) {
  }
  ngOnDestroy(): void {
    
    
  }
  ngOnInit(): void {
    //this.videoHeader =this.step.job_video.header;
    this.videoUrlWebM = '../../../assets/videos/' + this.step.job_video.video_name + '.webm?raw=true';
    this.videoUrlMp4 = '../../../assets/videos/' + this.step.job_video.video_name + '.mp4';
  }

  ngAfterViewInit(): void {
    this.videoPlayer.nativeElement.play();
  }

  next_step() {
    this.videoPlayer.nativeElement.pause();
    this.wfSvc.sendNextStepEvent(this.step._display_id, true);
  }

  overButton() {
    // handle mouse and touch events through eventListener
    this.wfSvc.sendDisableSwiperEvent();
  }

  leaveButton() {
    this.wfSvc.sendEnableSwiperEvent();
  }

  disableTouchMove() {
    this.wfSvc.sendDisableTouchMoveEvent();
  }

  enableTouchMove() {
    this.wfSvc.sendEnableTouchMoveEvent();
  }

}
