<div>
    <div class="cycle-wrapper">
        <div>
            <div class="cycle-gif">
                <img src={{actionGif}}>
            </div>
        </div>
        <div class="cycle-text-wrapper">
            <h2 class="timerText">{{actionDescription}}</h2>
            <h2 class="timerNums">{{timerMinutes}}:{{timerSeconds}}</h2>
            <!-- <div>
                <div class="modal-control-button" [style.background-image]="'url(' + playBtnBackground + ')'" (click)="startPauseTimer()"></div>
                <div class="stopBtn modal-control-button right-button"  (click)="stopTimer()"></div>
            </div> -->
        </div>
        
    </div>
</div>
