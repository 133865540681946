<div *ngIf="workflowSvc.selectedWorkflow !== undefined" class="domain-container" [class.display-none]="spinnerSvc.active">
    <div *ngIf="screenWidth <= 660 then phone else tablet"></div>
</div>


<!-- updated order of template logic to initialize swiper element (was taking phone template by default) -->

<ng-template #tablet>
    <div class="selected-workflow-container">
        <div class="selected-workflow-container-header-wrapper">
            <div *ngIf="authSvc.User.accountSettings.language === 'fr'" class="nav-button" (click)="goToFamiliarization()">
                <img src="/assets/base_icons/icon_arrow_left_blue.svg"><p>{{translatorSvc.getLabel('backToFamiliarization')}}</p>
            </div>
            <div class="starred-workflow-header">
                <h3>{{workflowSvc.selectedWorkflow.title}}</h3>
            </div>
            <div class="empty-third-hdiv"></div>
        </div>
        <div class="starred-workflow-container">
            <div class="starred-workflow-container-left">
                <div class="starred-workflow-container-picto">
                    <img class="recipe-img" 
                    src="{{domainSvc.currentDomain.id === 'job_examples_manufacturing' ? '/assets/pictos/workflows/' + workflowSvc.selectedWorkflow.id + '.jpg' : '/assets/pictos/workflows/' + workflowSvc.selectedWorkflow.id + '.svg'}}" alt={{workflowSvc.selectedWorkflow.title!}}>  
                </div>
                <div class="starred-workflow-container-description">
                    <p>{{workflowSvc.selectedWorkflow.description}}</p>
                </div>
            </div>
            <div class="starred-workflow-container-right">
                <div *ngIf="workflowSvc.selectedWorkflow.steps[0].job_video !== undefined then jobExampleInfo else workflowInfo"></div>
            </div>
        </div>
    </div>
    <div class="lower-container">
        <div class="lower-workflows-container">
            <app-category-item class="category-item-container" *ngFor="let category of domainSvc.currentDomain.categories" [category]="category"></app-category-item>
        </div>
        <div id="workflow-swiper-container" class="workflow-swiper-container">
            <div class="swiper-wrapper swiper-adjustment">
                <app-workflow-item class="workflow-swiper-slide" *ngFor="let workflow of domainSvc.currentCategory.workflows" [workflow]="workflow"></app-workflow-item>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>   
</ng-template>

<ng-template #phone>
    <div class="phone-workflow-top-wrapper">
        <div class="phone-workflow-header">
            <div class="nav-button" (click)="goToFamiliarization()">
                <img src="/assets/base_icons/icon_arrow_left_blue.svg">
            </div>
            <h3>{{workflowSvc.selectedWorkflow.title}}</h3>
        </div>
        <div class="phone-selected-workflow-container">
            <div class="phone-selected-container-picto">
                <img src="/assets/pictos/workflows/{{workflowSvc.selectedWorkflow.picto!}}" alt={{workflowSvc.selectedWorkflow.title!}}>
            </div>
            <div class="phone-selected-container-info">
                <div *ngIf="workflowSvc.selectedWorkflow.steps[0].job_video !== undefined then jobExampleInfo else workflowInfo"></div>
            </div>
        </div>
        <div class="phone-workflow-container-categories">
            <div class="phone-workflow-container-select">
                <select [(ngModel)]="domainSvc.currentCategory">
                    <option *ngFor="let category of domainSvc.currentDomain.categories" [ngValue]="category">
                        {{category.getTitle(authSvc.User.accountSettings.language)}}
                    </option>
                </select>
            </div>
            <div class="phone-workflow-container-overflow-wrapper">
                <div #phone_workflow_container class="phone-workflow-container" (scroll)="onWorkflowsScroll()">
                    <app-workflow-item class="workflow-swiper-slide" *ngFor="let workflow of domainSvc.currentCategory.workflows" [workflow]="workflow"></app-workflow-item>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #jobExampleInfo>
    <div class="job-example-container-info">
        <div class="job-example-container-header"><h3>{{translatorSvc.getLabel('jobResults')}}</h3></div>
        <div class="job-example-container-info-bottom" [class.display-none]="workflowSvc.selectedWorkflow.job_answered"><p>{{translatorSvc.getLabel('assessmentNotCompleted')}}</p></div>
        <div class="job-example-container-info-bottom" [class.display-none]="!workflowSvc.selectedWorkflow.job_answered">
            <div class="question-item-wrapper-horizontal">
                <p class="header-item" >{{translatorSvc.getLabel('assessmentInterest')}}</p>
                <img [src]="workflowSvc.preference.src">
            </div>
            <div class="question-item-wrapper-horizontal"><p>{{translatorSvc.getLabel('multiChoiceHeader')}}:</p><p>{{workflowSvc.multiple_choice.score}}</p></div>
        </div>
    </div>
    <div class="starred-workflow-container-buttons">
        <div *ngIf="workflowSvc.selectedWorkflow.job_answered" class="autonome-button-orange" (click)="showResultsModal()">
            <p>{{translatorSvc.getLabel('viewResults')}}</p></div>
        <div *ngIf="workflowSvc.selectedWorkflow.job_answered" class="autonome-button-orange" (click)="restartJobExampleWorkflow()">
            <p>{{translatorSvc.getLabel('startAssessmentOver')}}</p></div>
        <div *ngIf="!workflowSvc.selectedWorkflow.job_answered && workflowSvc.currentStepId === 0" class="autonome-button-orange" (click)="launchWorkflow()">
            <p>{{translatorSvc.getLabel('startAssessment')}}</p></div>
        <div *ngIf="!workflowSvc.selectedWorkflow.job_answered && workflowSvc.currentStepId > 0" class="autonome-button-orange" (click)="launchWorkflow()">
            <p>{{translatorSvc.getLabel('continueAssessment')}}</p></div>
        <div *ngIf="!workflowSvc.selectedWorkflow.job_answered && workflowSvc.currentStepId > 0" class="autonome-button-orange" (click)="restartJobExampleWorkflow()">
            <p>{{translatorSvc.getLabel('restartAssessment')}}</p></div>
    </div>
</ng-template>

<ng-template #workflowInfo>
    <div class="starred-workflow-container-info">
        <div class="starred-workflow-container-info-top">
            <h4>{{translatorSvc.getLabel('instructionSetDetails')}}</h4>
            <div class="display-flex">
                <div>{{translatorSvc.getLabel('timeToComplete')}}</div>
                <div style="margin-left: 8px;">{{workflowSvc.selectedWorkflow.time_to_complete}}</div>
            </div>
            <div class="display-flex">
                <div>{{translatorSvc.getLabel('difficulty')}}: </div>
                <div style="margin-left: 8px;">{{workflowSvc.selectedWorkflow.difficulty}}</div>
            </div>
            
            
        </div>
        <div class="starred-workflow-container-info-bottom">
            
        </div>
    </div>
    <div class="starred-workflow-container-buttons">
       <!--  <div class="modal-text-button margin-left-0" (click)="gotoWorkflowDetails()">{{translatorSvc.getLabel('viewDetails')}}</div> -->
        <div class="modal-text-button" (click)="launchWorkflow()">{{translatorSvc.getLabel('launchWorkflow')}}</div>
    </div>
</ng-template>