export enum StepType {
    Action = 0,
    IngredientTool,
    IngredientQty,
    Verification,
    ItemQty,
    ItemMeasure,
    Preference,
    Table,
    MultipleChoice,
    JobVideo,
    Complete
  }