<div class="stripe-card-element-container">
  <div class="stripe-card-element-wrapper">
    <div class="stripe-payment-methods-list" *ngIf="paymentSvc.customerPaymentMethods">
      <h4>{{translatorSvc.getLabel('availablePaymentMethods')}}</h4>
      <app-stripe-card-item *ngFor="let paymentMethodObject of paymentSvc.customerPaymentMethods.data" [paymentMethodObject]="paymentMethodObject"></app-stripe-card-item>

    </div>
    <form class="stripe-element-form" id="payment-form">
      <div class="form-row">
        <label for="card-element">
          <p>{{translatorSvc.getLabel('creditCard')}}</p>
        </label>
        <div id="card-element">
          <!-- Stripe element will be inserted here -->
        </div>
        <!-- Display payment errors -->
        <div id="card-errors" role="alert">{{cardErrors}}</div>

      </div>
      <div class="payment-submit-wrapper">
        <button [disabled]="!paymentSvc.chargeValid || !canSubmit" type="submit" class="autonome-button-orange self-justify-center"><p>{{translatorSvc.getLabel('submitPayment')}}</p></button>
      </div>
      
    </form>
  </div>
</div>
