import { NgModule } from "@angular/core";
import { RecipeEditorComponent } from "./components/recipe-editor/recipe-editor.component";
import { RecipeEditorListItemComponent } from "./components/recipe-editor-list-item/recipe-editor-list-item.component";
import { CommonModule } from "@angular/common";
import { ModalsModule } from "../modal/modals.module";
import { FormsModule } from "@angular/forms";
import { RecipeStepItemComponent } from './components/recipe-step-item/recipe-step-item.component';
import { AddWorkflowItemTypeComponent } from "./components/add-workflow-item-type/add-workflow-item-type.component";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
    declarations: [
        RecipeEditorComponent,
        RecipeEditorListItemComponent,
        RecipeStepItemComponent,
        AddWorkflowItemTypeComponent
    ],
    imports: [
        CommonModule,
        ModalsModule,
        FormsModule,
        DragDropModule,
        /*GoogleTagManagerModule.forRoot({
            id: environment.GTM_ID,
        }),*/
    ],
    providers: [
        
    ],
    exports: [
    ]
})
export class EditorModule { }