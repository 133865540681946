import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/admin/services/auth.service';
import { DomainService } from 'src/app/core/services/domain.service';
import { ProductService } from 'src/app/core/services/product.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { Swiper } from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { UserConfirmationComponent } from '../user-confirmation/user-confirmation.component';
import { StripeProducts } from 'src/app/enums/stripe-products.enum';
Swiper.use([Navigation, Pagination, Autoplay]);

@Component({
  selector: 'app-domain-promo',
  templateUrl: './domain-promo.component.html',
  styleUrls: ['./domain-promo.component.scss']
})

export class DomainPromoComponent implements OnInit, AfterViewInit {
  public get matDialog(): MatDialog {
    return this._matDialog;
  }
  public set matDialog(value: MatDialog) {
    this._matDialog = value;
  }

private promoSwiper: Swiper;
public quantity: number = 1;
public description: string;
public title: string; 
public purchased : boolean = false;

  constructor(
    public translatorSvc : TranslatorService,
    public domainSvc : DomainService,
    private authSvc : AuthService,
    private productSvc : ProductService,
    public dialogRef: MatDialogRef<DomainPromoComponent>,
    private _matDialog: MatDialog,
  ) {
    if(this.productSvc.itemsToPurchase.find((obj) => {
      return obj.id === this.domainSvc.currentDomain.promotions.id;
    })) {
      this.purchased = true;
    }
  }

  ngOnInit(): void {
    /* this.elem = document.documentElement;
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      // Firefox
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      // IE/Edge 
      this.elem.msRequestFullscreen();
    } */
  }


  ngAfterViewInit(): void {

    switch(this.authSvc.User.accountSettings.language) {
      case 'fr' :
        this.description = this.domainSvc.currentDomain.promotions.description_fr;
        this.title = this.domainSvc.currentDomain.promotions.title_fr;
        break;
      default : 
        this.description = this.domainSvc.currentDomain.promotions.description_en;
        this.title = this.domainSvc.currentDomain.promotions.title_en;
        break;
    }

    this.promoSwiper = new Swiper("#domain-promo-slider-container", {
      modules: [Autoplay, Navigation, Pagination],
      spaceBetween: 40,
      slidesPerView: 1,
      centeredSlides: true,
      observeParents: true,
      observer: true,
      autoplay: {
        delay: 3000,
      },
      speed: 1500,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      loop: true
      });
  }

  addToCart() {
    if(this.productSvc.itemsToPurchase && this.quantity > 0) {
      this.productSvc.addItemToCart({
        id : this.domainSvc.currentDomain.promotions.id,
        stripe_id : StripeProducts[this.domainSvc.currentDomain.promotions.id],
        domain_id: this.domainSvc.currentDomain.id,
        name : this.domainSvc.currentDomain.promotions['name_'+this.authSvc.User.accountSettings.language],
        price: this.domainSvc.currentDomain.promotions.price,
        shipping_method: this.domainSvc.currentDomain.promotions.shipping_method,
        shipping_fee: this.domainSvc.currentDomain.promotions.shipping_fee,
        taxable: this.domainSvc.currentDomain.promotions.taxable,
        quantity: this.quantity,
      });
      this.purchased = true;
    }
    this.dialogRef.close(true);
    this.openDomainAddedUserConfModal();
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  openDomainAddedUserConfModal() {
    let name : string;
    switch(this.authSvc.User.accountSettings.language) {
      case 'fr' :
        name = this.domainSvc.currentDomain.promotions.name_fr;
        break;
      default :
        name = this.domainSvc.currentDomain.promotions.name_en;
        break;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "full-screen-component";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "modal-added-promo-to-cart",
      actionButtonText1 : this.translatorSvc.getLabel('goToShoppingCart'),
      message : this.translatorSvc.getLabel('itemAddedToCart') + name,
      hideButton2: true,
    };

    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }


}
