import { Ingredient } from '../../../models/ingredient.model';
import { Component, Input, OnInit } from '@angular/core';
import { RecipeService } from '../../services/recipe.service';

@Component({
  selector: 'app-ingredient-list-item',
  templateUrl: './ingredient-list-item.component.html',
  styleUrls: ['./ingredient-list-item.component.scss']
})
export class IngredientListItemComponent implements OnInit {

  @Input() ingredientItem : Ingredient;
  measureLabel : string;

  constructor(private recipeSvc : RecipeService) { }

  ngOnInit(): void {
    if(this.ingredientItem.totalQty !== undefined && this.ingredientItem.totalQty > 0) {
      this.measureLabel = " -";
    } else {
      if (this.ingredientItem.unit !== undefined && this.ingredientItem.unit.length > 0) {
        if(this.ingredientItem.unit === 'g') {
          this.ingredientItem.measureText = this.recipeSvc.buildWeightMeasure(this.ingredientItem.totalMeasure);
          this.measureLabel = this.ingredientItem.measureText + " -";
        } else {
          this.measureLabel = " ml -";
        }
      } else {
        this.measureLabel = " ml -";
      }
    }
    this.measureLabel = this.measureLabel + " " + this.ingredientItem.description;

  }

}
