import { NgModule } from '@angular/core';
import { MatToolbarModule } from  '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { AutonomeService } from './services/autonome.service';
import { RecipeService } from './services/recipe.service';
import { ProductService } from './services/product.service';
import { ToolService } from './services/tool.service';
import { WorkflowService } from './services/workflow.service';
import { ActionComponent } from './components/action/action.component';
import { AutonomeComponent } from './components/autonome/autonome.component';
import { CompleteComponent } from './components/complete/complete.component';
import { IngredientListItemComponent } from './components/ingredient-list-item/ingredient-list-item.component';
import { IngredientQtyComponent } from './components/ingredient-qty/ingredient-qty.component';
import { IngredientToolComponent } from './components/ingredient-tool/ingredient-tool.component';
import { RecipeCategoryComponent } from './components/recipe-category/recipe-category.component';
import { RecipeDetailComponent } from './components/recipe-detail/recipe-detail.component';
import { RecipesComponent } from './components/recipes/recipes.component';
import { StepComponent } from './components/step/step.component';
import { StepItemComponent } from './components/step-item/step-item.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { ToolListItemComponent } from './components/tool-list-item/tool-list-item.component';
import { UserComponent } from './components/user/user.component';
import { VerificationComponent } from './components/verification/verification.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { WorkflowTrackerComponent } from './components/workflow-tracker/workflow-tracker.component';
import { ModalsModule } from '../modal/modals.module';
import { StepDirective } from './components/step/step.directive';
import { FormsModule } from '@angular/forms';
import { ActionListItemComponent } from './components/action-list-item/action-list-item.component';
import { AllergyItemComponent } from './components/allergy-item/allergy-item.component';
//import { environment } from '../../environments/environment';
//import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { RecipeItemComponent } from './components/recipe-item/recipe-item.component';
import { CategoryItemComponent } from './components/category-item/category-item.component';
import { DomainsComponent } from './components/domains/domains.component';
import { WorkflowItemComponent } from './components/workflow-item/workflow-item.component';
import { DomainItemComponent } from './components/domain-item/domain-item.component';
import { ItemComponent } from './components/item/item.component';
import { DomainComponent } from './components/domain/domain.component';
import { WorkflowExampleItemComponent } from './components/workflow-example-item/workflow-example-item.component';
import { DomainListItemComponent } from './components/domain-list-item/domain-list-item.component';
import { WorkflowListItemComponent } from './components/workflow-list-item/workflow-list-item.component';
import { ProductsDomainComponent } from './components/products-domain/products-domain.component';
import { ProductsWorkflowsComponent } from './components/products-workflows/products-workflows.component';
import { MasterChefWorkflowComponent } from './components/master-chef-workflow/master-chef-workflow.component';

@NgModule({
    declarations: [
        ActionComponent,
        AutonomeComponent,
        CompleteComponent,
        IngredientListItemComponent,
        IngredientQtyComponent,
        IngredientToolComponent,
        RecipeItemComponent,
        RecipeCategoryComponent,
        RecipeDetailComponent,
        RecipesComponent,
        StepComponent,
        StepDirective,
        StepItemComponent,
        ProductsComponent,
        ProductItemComponent,
        ToolListItemComponent,
        UserComponent,
        VerificationComponent,
        WorkflowComponent,
        WorkflowTrackerComponent,
        ActionListItemComponent,
        AllergyItemComponent,
        CategoryItemComponent,
        DomainsComponent,
        WorkflowItemComponent,
        DomainItemComponent,
        ItemComponent,
        DomainComponent,
        WorkflowExampleItemComponent,
        DomainListItemComponent,
        WorkflowListItemComponent,
        ProductsDomainComponent,
        ProductsWorkflowsComponent,
        MasterChefWorkflowComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        ModalsModule,
        FormsModule,
        /*GoogleTagManagerModule.forRoot({
            id: environment.GTM_ID,
        }),*/
    ],
    providers: [
        AutonomeService,
        RecipeService,
        ProductService,
        ToolService,
        WorkflowService
    ],
    exports: [
        AutonomeComponent,
        RecipesComponent,
        DomainItemComponent,
        WorkflowExampleItemComponent,
    ]
})
export class CoreModule { }
