import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserConfirmationComponent } from 'src/app/modal/components/user-confirmation/user-confirmation.component';
import { Recipe } from 'src/app/models/recipe.model';
import { Workflow } from 'src/app/models/workflow.model';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { Swiper } from 'swiper';
import { WorkflowService } from '../../services/workflow.service';
import { JobExampleResultsComponent } from 'src/app/modal/components/job-example-results/job-example-results.component';
import { DomainService } from '../../services/domain.service';
import { AuthService } from 'src/app/admin/services/auth.service';
@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss']
})
export class DomainComponent implements AfterViewInit {

  private workflowSwiper: Swiper;
  public screenWidth: number;
  public seeWorkflow: boolean;
  public overflow: boolean = false;
  public rightAnswerRation: number;
  @ViewChild("phone_workflow_container") workflows_container: ElementRef;

  constructor(
    public workflowSvc : WorkflowService,
    public spinnerSvc : SpinnerOverlayService,
    public translatorSvc : TranslatorService,
    public matDialog: MatDialog,
    public domainSvc: DomainService,
    public authSvc: AuthService,
    private router : Router) {
    
  }

  async ngAfterViewInit(): Promise<void> {
    this.seeWorkflow = false;
    this.screenWidth = window.innerWidth;
    if(this.domainSvc.currentDomain.workflows && this.domainSvc.currentDomain.workflows.length > 0) {
      if(!this.workflowSvc.nav_back) {
          this.workflowSvc.nav_back = !this.workflowSvc.nav_back;
        }
      if (this.spinnerSvc.active) {
          this.spinnerSvc.hide(2);
        }
    }
    this.workflowSwiper = new Swiper("#workflow-swiper-container", {
      slideClass : 'workflow-swiper-slide',
      slidesPerView : 'auto',
      loop: false,
      observer: true,
      observeParents: true,
      direction: 'horizontal',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      });
    
    if(this.workflows_container) {
      if(this.workflows_container.nativeElement.children) {
        if(this.workflows_container.nativeElement.children.length * this.workflows_container.nativeElement.children[0].offsetHeight > this.workflows_container.nativeElement.offsetHeight) {
          this.overflow = true;
        }
      }
    }
  }

  asRecipe(value: Workflow) : Recipe {
    return (value as Recipe);
  }

  gotoRecipeDetails() {
    this.router.navigate(['recipe-detail']);
  }

  gotoWorkflowDetails() {
    this.router.navigate(['workflow-detail']);
  }

  goToFamiliarization() {
    this.router.navigate(['familiarization']);
  }

  launchWorkflow() {
      this.workflowSvc.launchWorkflow();
  }

  restartJobExampleWorkflow() {
    this.workflowSvc.resetCurrentWorkflowJobExamples();
    this.workflowSvc.launchWorkflow();
  }

  showCategories() {
    // show the categories that we can select.
    // Do not show the current one
  }

  get somethingBothersMe() {
    return this.workflowSvc.table.find((obj) => {
      return (Object(obj).answer === 1 || Object(obj).answer === 2);
    });
  }

  async showResultsModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "go-org-modal";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    this.matDialog.open(JobExampleResultsComponent, dialogConfig).afterClosed().subscribe(() => {
      
    });
  }

  async showGoOrgModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "go-org-modal";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "go-to-org",
      message: this.translatorSvc.getLabel('goToOrg'),
      actionButtonText1 : this.translatorSvc.getLabel('no'),
      actionButtonText2 : this.translatorSvc.getLabel('yes'),
    };
    this.matDialog.open(UserConfirmationComponent, dialogConfig).afterClosed().subscribe(() => {

    });
  }

  gotoOrgs() {
    this.router.navigate(['organisations']);
  }

  gotoRecipes() {
    this.seeWorkflow = true;
  }

  onWorkflowsScroll() {
    if(this.workflows_container.nativeElement.scrollTop === 0) {
      this.overflow = true;
    } else {
      this.overflow = false;
    }
  }

}
