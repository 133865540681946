import { Component, Input} from '@angular/core';
import { OrganisationService } from '../../services/organisation.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';
import { SpinnerOverlayService } from 'src/app/tools/services/spinner-overlay.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditOrganisationMemberComponent } from 'src/app/modal/components/edit-organisation-member/edit-organisation-member.component';
import { AddOrganisationMemberComponent } from 'src/app/modal/components/add-organisation-member/add-organisation-member.component';
import { DisplayService } from 'src/app/tools/services/display.service';
import { Organisation } from 'src/app/models/organisation.model';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent {
 
  
  @Input() organisation: Organisation;

  constructor(
    public organisationSvc : OrganisationService,
    private matDialog : MatDialog,
    public translatorSvc : TranslatorService,
    public spinnerSvc : SpinnerOverlayService,
    public displaySvc : DisplayService
  ) { 
    
  }

  launchWorkflow(){
  }

  clickRecipeEvent() {
    
  }

  edit_member(index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "edit_member";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "edit_member",
      memberIndex: index
    };
    this.matDialog.open(EditOrganisationMemberComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }

  edit_domain(index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "edit_domain";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "edit_domain",
      memberIndex: index
    };
    this.matDialog.open(EditOrganisationMemberComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }

  addMember() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "add_member";
    dialogConfig.height = "auto";
    dialogConfig.width = "auto";
    dialogConfig.data = {
      name: "add_member"
    };
    this.matDialog.open(AddOrganisationMemberComponent, dialogConfig).afterClosed().subscribe(() => {
    });
  }

}
