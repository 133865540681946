/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { first, firstValueFrom } from "rxjs";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private authSvc : AuthService,
        private fns: AngularFireFunctions,
    ) {
        
    }

    handleError(error: any) {
        if(!(error instanceof HttpErrorResponse)) {
            if (!error.stack.includes('http://localhost:4200')) {
                this.logError(error);
            } else {
                console.log(error.message);
                console.log(error.stack);
            }
        }
    }

    async logError(error: any) : Promise<any> {
        const logErrorCall = this.fns.httpsCallable('httpsLogError');
        if (this.authSvc.User) {
            return await firstValueFrom(logErrorCall({error: error, user: this.authSvc.User.uid}).pipe(first()));
        } else {
            return await firstValueFrom(logErrorCall({error: error, user: null}).pipe(first()));
        }
      }
}