import { AnswerPreference } from "../enums/answer-preference.enum";
import { JobChoiceI } from "../interfaces/job-choice.interface";
import { MultiChoiceQuestionI } from "../interfaces/multi-choice-question.interface";

export class QuestionMultipleChoice implements MultiChoiceQuestionI {

    choices: JobChoice[];
    question_fr: string;
    question_en? : string;
    question?: string;
    choice? : JobChoice;
    answer? : AnswerPreference;
    private _correct: boolean;

    constructor(data: object) {
        this.question_fr = Object(data).question_fr;
        this.question_en = Object(data).question_en;
        this.choices = [];
        for (const choice of Object(data).choices) {
            this.choices.push(new JobChoice(choice));
        }
        this.choice = Object(data).choice;
        this.question = Object(data).question;
        this.answer = Object(data).answer;
    }

    getQuestion(language: string) {
        switch (language) {
            case 'fr' : return this.question_fr;
            default: return this.question_en;
            
        }
    }

    
    setLanguage(language: string) {
        switch (language) {
            case 'fr':
                this.question = this.question_fr;
                break;
            default:
                this.question = this.question_en;
        }
        for (const choice of this.choices) {
            choice.setLanguage(language);
        }
    }

}

export class JobChoice implements JobChoiceI {
    private _selected: boolean;
    private _choice: string;
    private _answer: string;
    choice_en?: string;
    choice_fr: string;
    answer_en?: string;
    answer_fr?: string;

    constructor(data: object) {
        this.choice_en = Object(data).choice_en;
        this.choice_fr = Object(data).choice_fr;
        this.answer_en = Object(data).answer_en;
        this.answer_fr = Object(data).answer_fr;
    }

    public get selected(): boolean {
        return this._selected;
    }
    public set selected(value: boolean) {
        this._selected = value;
    }

    public get choice(): string {
        return this._choice;
    }
    public set choice(value: string) {
        this._choice = value;
    }

    public get answer(): string {
        return this._answer;
    }
    public set answer(value: string) {
        this._answer = value;
    }

    setLanguage(language: string) {
        switch (language) {
            case 'fr':
                this._choice = this.choice_fr;
                this._answer = this.answer_fr;
                break;
            default:
                this._choice = this.choice_en;
                this._answer = this.answer_en;
        }
    }
}
