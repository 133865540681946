import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/admin/services/auth.service';
import { DomainService } from 'src/app/core/services/domain.service';
import { TranslatorService } from 'src/app/tools/services/translator.service';

@Component({
  selector: 'app-domain-extra-info',
  templateUrl: './domain-extra-info.component.html',
  styleUrls: ['./domain-extra-info.component.scss']
})
export class DomainExtraInfoComponent {

  constructor(public domainSvc: DomainService,
    public authSvc : AuthService,
    public translatorSvc : TranslatorService,
    public dialogRef: MatDialogRef<DomainExtraInfoComponent>) {
    }

  closeDialog() {
    this.dialogRef.close(false);
  }

}
