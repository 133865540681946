/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, first, firstValueFrom } from "rxjs";
import { AuthService } from "../services/auth.service";
import { AngularFireFunctions } from "@angular/fire/compat/functions";

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
    constructor(
        private authSvc : AuthService,
        private fns : AngularFireFunctions
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => 
                this.logError(error)
            )
        ) as Observable<HttpEvent<any>>;
    }

    async logError(error: any) : Promise<any> {
        if (!error.stack.includes('http://localhost:4200')) {
            const logErrorCall = this.fns.httpsCallable('httpsLogError');
            if (this.authSvc.User) {
                return await firstValueFrom(logErrorCall({error: error, user: this.authSvc.User.uid}).pipe(first()));
            } else {
                return await firstValueFrom(logErrorCall({error: error, user: null}).pipe(first()));
            }
        }
      }
}
