<div class="agm-places-form-container">
  <div class="agm-places-form-wrapper">
    <form [formGroup]="shippingAddressForm" 
        class="form-group" 
        id="address-form" 
        action="" 
        method="get" 
        autocomplete="off" 
        (keydown.enter)="$event.preventDefault()" 
        (ngSubmit)="onSubmit()">
      <div class="upperForm">
        <h4 class="title">{{translatorSvc.getLabel('shippingAddress')}}</h4>
        <p class="note"><em>* {{translatorSvc.getLabel('requiredField')}}</em></p>
        <div class="addressFields">
          <label>
            <!-- Avoid the word "address" in id, name, or label text to avoid browser autofill from conflicting with Place Autocomplete. Star or comment bug https://crbug.com/587466 to request Chromium to honor autocomplete="off" attribute. -->
            <span><p>{{translatorSvc.getLabel('deliverTo')}}</p></span>
            <input
              id="ship_address"
              name="ship_address"
              autocomplete="false"
              formControlName="ship_address"
              placeholder="{{translatorSvc.getLabel('enterShippingAddress')}}"
            />
          </label>
          <label>
            <span><p>{{translatorSvc.getLabel('address2')}}</p></span>
            <input id="address2" name="address2" formControlName="address2"  autocomplete="false" />
          </label>
        </div> 
      </div> 
      <div *ngIf="haveAddress" class="address-result-container">
        <label>
          <span class="fade-color"><p>{{translatorSvc.getLabel('city')}}</p></span>
          <input class="no-top-padding" id="locality" name="locality" required formControlName="locality" autocomplete="false"/>
        </label>
        <label>
          <span class="fade-color"><p>{{translatorSvc.getLabel('stateProvince')}}</p></span>
          <input id="state" name="state" required formControlName="state" autocomplete="false" (ngModelChange)="stateValueChange($event)">
        </label>
        <label for="postal_code">
          <span class="fade-color"><p>{{translatorSvc.getLabel('postCodeZip')}}</p></span>
          <input class="no-top-padding" id="postcode" name="postcode" required formControlName="postcode" autocomplete="false">
        </label>
        <label>
          <span class="fade-color"><p>{{translatorSvc.getLabel('countryRegion')}}</p></span>
          <input class="no-top-padding" id="country" name="country" formControlName="country" autocomplete="false">
        </label>
        <div *ngIf="addressUpdated" class="submit-container">
          <div class="save-address-wrapper">
            <input type="checkbox" [checked]="saveAddress" (change)="toggleSaveAddress()" id="saveAddress" name="saveAddress" value="Save Shipping Address"/>
            <label for="saveAddress"><p>{{translatorSvc.getLabel('saveAddress')}}</p></label>
          </div>
        </div>
      </div>
      <div class="submit-wrapper">
        <button [disabled]="!addressValid" type="submit" class="autonome-button-orange">
          <p>{{!addressUpdated ? translatorSvc.getLabel('submitAddress') : translatorSvc.getLabel('changeAddress')}}</p>
        </button>
      </div>
    </form>
  </div>
</div>
